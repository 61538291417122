import axios from "axios";
import store from "../store"

export async function getAutocompleteResponse(lookup) {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/companydetails/getAsicDetails?companySearch=${lookup}`, 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}


export async function findByAbn(lookup) {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/companydetails/getAsicDetails?abnSearch=${lookup}`, 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}