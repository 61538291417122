export const BROKER_ACTION_TYPES = {
    FETCH_LOADING: 'brokerAgentFetchLoading',
    FETCH_SUCCESS: 'brokerAgentFetchSuccess',
    FETCH_FAILURE: 'brokerAgentFetchFailure',
    SAVE_SUCCESS: "saveSuccessful",
    SAVE_OKAY_CLOSE: "saveOkayClose",
    MOBILE_UPDATE_VALIDATION_FAIL: "mobileNumberValidationFail", 
    AGENCY_DETAILS_VALIDATION_FAIL: "agencyDetailsSaveValidationFail",
    AGENCY_DETAILS_SAVE_SUCCESS: "agencyDetailsSaveSuccess",
    AGENCY_DETAILS_CLOSE: "agencyDetailsModalClose",
    AGENCY_DETAILS_UPDATED: "agencyDetailsUpdated",
    SUBSCRIPTION_LOADING: 'subscriptionFetchLoading',
    SUBSCRIPTION_SUCCESS: 'subscriptionFetchSuccess',
    SUBSCRIPTION_FAILURE: 'subscriptionFetchFailure',
    EXTRAS_LOADING: 'extrasLoading',
    EXTRAS_SUCCESS: 'extrasSuccess',
    EXTRAS_FAILURE: 'extrasFailure',
}
export default function getActionType(success) {
    const type = success? BROKER_ACTION_TYPES.FETCH_SUCCESS : BROKER_ACTION_TYPES.FETCH_ERROR
    return type
}

export const fetchLoading = () => {
    return {
        type: BROKER_ACTION_TYPES.FETCH_LOADING
    }
}

export const fetchSuccess = brokerAgentPost => {
    return {
        type: BROKER_ACTION_TYPES.FETCH_SUCCESS,
        payload: brokerAgentPost
    }
}

export const fetchFailure = error => {
    return {
        type: BROKER_ACTION_TYPES.FETCH_FAILURE,
        payload: error
    }
}


export const fetchExtrasLoading = () => {
    return {
        type: BROKER_ACTION_TYPES.EXTRAS_LOADING
    }
}

export const fetchExtrasSuccess = extras => {
    return {
        type: BROKER_ACTION_TYPES.EXTRAS_SUCCESS,
        payload: extras
    }
}

export const fetchExtrasFailure = error => {
    return {
        type: BROKER_ACTION_TYPES.EXTRAS_FAILURE,
        payload: error
    }
}

export const fetchSubscriptionLoading = () => {
    return {
        type: BROKER_ACTION_TYPES.SUBSCRIPTION_LOADING
    }
}

export const fetchSubscriptionSuccess = subscription => {
    return {
        type: BROKER_ACTION_TYPES.SUBSCRIPTION_SUCCESS,
        payload: subscription
    }
}

export const fetchSubscriptionFailure = error => {
    return {
        type: BROKER_ACTION_TYPES.SUBSCRIPTION_FAILURE,
        payload: error
    }
}

export const mobileUpdateValidationFail = () => {
    return {
        type: BROKER_ACTION_TYPES.MOBILE_UPDATE_VALIDATION_FAIL,
    }
}

export const saveSuccess = (source) => {
    return {
        type: BROKER_ACTION_TYPES.SAVE_SUCCESS,
        payload: source,
    }
}

export const saveSuccessClose = () => {
    return {
        type: BROKER_ACTION_TYPES.SAVE_OKAY_CLOSE,
    }
}

export const mobileUpdateError = errors => {
    return {
            type: BROKER_ACTION_TYPES.MOBILE_UPDATE_VALIDATION_FAIL,
            payload: {errors: errors}
    }
}

export const agencyDetailsSaveErrors = errors => {
    return {
            type: BROKER_ACTION_TYPES.AGENCY_DETAILS_VALIDATION_FAIL,
            payload: {errors: errors}
    }
}

export const agencyDetailsSaveSuccess = () => {
    return {
        type: BROKER_ACTION_TYPES.AGENCY_DETAILS_SAVE_SUCCESS,
        
    }
}

export const agencyDetailsUpdated = (agency) => {
    return {
        type: BROKER_ACTION_TYPES.AGENCY_DETAILS_UPDATED,
        payload: agency,
    }
}

