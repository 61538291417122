import { ACTIVITIES_ACTION_TYPES } from "./actionTypes"
import { getActivities, getBorrower, getProspectForBrokerAndBorrower } from "./request"

export const fetchActivities = (page, pagesize) => async (dispatch) => {
    getActivities(page, pagesize)
        .then(response => {
            //console.log(response)
            if (response.status >= 400 && response.status < 600) {
                dispatch(fetchFailure({success:false, errorMessage:'We are currently experiencing issues. Sorry for the inconvenience.'}))
            } else {
                dispatch(
                    fetchSuccess(
                        ACTIVITIES_ACTION_TYPES.FETCH_SUCCESS,
                        {activities:response.data, page: page}))
            }
        })
        .catch(error => {
            dispatch(fetchFailure(error))
        })
    getActivities(page+1, pagesize)
        .then(response => {
            if (response.status >= 400 && response.status < 600) {
                dispatch(fetchFailure({success:false, errorMessage:'We are currently experiencing issues. Sorry for the inconvenience.'}))
            } else {
                dispatch(
                    fetchSuccess(ACTIVITIES_ACTION_TYPES.FETCH_HAS_MORE_ACTIVITIES,
                        {hasMoreActivities:response.data && response.data.length > 0}))
            }
        })
        .catch(error => {
            dispatch(fetchFailure(error))
        })

}

export const fetchBorrowerDetails = (borrowerId) => async (dispatch) => {
    getBorrower(borrowerId)
        .then(response => {
            if (response.status >= 400 && response.status < 600) {
                dispatch(fetchFailure({success:false, errorMessage:'We are currently experiencing issues. Sorry for the inconvenience.'}))
            } else {
                var borrower = JSON.parse("{\"" + borrowerId + "\":" + JSON.stringify(response.data) + "}")
                dispatch(
                    fetchSuccess(
                        ACTIVITIES_ACTION_TYPES.FETCH_BORROWER_SUCCESS, borrower)
                        )
            }
        })
        .catch(error => {
            dispatch(fetchFailure(error))
        })
}

export const fetchProspectDetails = (borrowerId, onSuccess) => async (dispatch) => {
    getProspectForBrokerAndBorrower(borrowerId)
        .then(response => {
            if (response.status >= 400 && response.status < 600) {
                dispatch(fetchFailure({success:false, errorMessage:'We are currently experiencing issues. Sorry for the inconvenience.'}))
            } else {
                onSuccess(response.data)
            }
        })
        .catch(error => {
            dispatch(fetchFailure(error))
        })
}


export const fetchSuccess = (type,data) => {
    return {
        type: type,
        payload: data
    }
}

export const fetchFailure = error => {
    return {
        type: ACTIVITIES_ACTION_TYPES.FETCH_FAILURE,
        payload: error
    }
}
