import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import { Link } from "react-router-dom";

import { fetchActivities, fetchBorrowerDetails, fetchProspectDetails } from '../../../store/dashboardActivities/actions';
import { showClientsBookPage, setClientInDetailView } from '../../../store/clients/actions';
import { fetchProposal } from '../../../store/sales/actions';
import ClientDetailsModal from "../Clients/ClientDetailsModal";

function getActivityTime(timestamp) {
    const givenTs = moment(timestamp)
    return givenTs.isBefore(moment().startOf('year')) ? givenTs.format("DD/MM/YYYY") : givenTs.isBefore(moment().startOf('day')) ? givenTs.format("DD/MM"): givenTs.format("hh:mm A")
}

function getBorrower(borrowers, lookupBorrowerId) {
    var borrower = null
    for (const [borrowerId, borrowerDetails] of Object.entries(borrowers)) {
        borrower = borrowerId == lookupBorrowerId ? borrowerDetails : borrower;
    }
    return borrower
}

function Activity(props) {
    return (
        <div class="row align-items-center no-gutters">
            <div class="col me-2">
                <div class="hstack gap-2 d-flex flex-grow-1 justify-content-between">
                    <div class="hstack gap-2">{props?.icon}
                        <h6 class="mb-0"><strong>{props?.header}</strong></h6>
                        </div><span class="fw-lighter" style={{"font-size": "12px"}}>{props.timestamp ? getActivityTime(props.timestamp) : null}</span>
                </div>
                {props.children}
            </div>
        </div>
    );
}


function LoanRequestCreated(props) {
    const borrower = props.activity.borrowerId ? getBorrower(props.borrowers, props.activity.borrowerId) : null

    if (!borrower)
        props.fetchBorrowerDetails(props.activity.borrowerId)
    
    return (
        <>
        {borrower ? 
            <Activity 
                header={borrower.firstName + ' has just joined LoanShopper'}
                timestamp={props.activity.createdTs}
                icon={<svg class="bi bi-emoji-smile fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                    <path d="M4.285 9.567a.5.5 0 0 1 .683.183A3.498 3.498 0 0 0 8 11.5a3.498 3.498 0 0 0 3.032-1.75.5.5 0 1 1 .866.5A4.498 4.498 0 0 1 8 12.5a4.498 4.498 0 0 1-3.898-2.25.5.5 0 0 1 .183-.683zM7 6.5C7 7.328 6.552 8 6 8s-1-.672-1-1.5S5.448 5 6 5s1 .672 1 1.5zm4 0c0 .828-.448 1.5-1 1.5s-1-.672-1-1.5S9.448 5 10 5s1 .672 1 1.5z"></path>
                </svg>} 
            >
            <span class="d-lg-flex justify-content-lg-end">{borrower.title} {borrower?.firstName} {borrower?.lastName} from {borrower?.currentAddress?.state} just joined and awaits your loan proposals</span>
            </Activity> : <Activity />}
        </>
    );
}

function ConnectionDeclined(props) {
    const borrower = props.activity.borrowerId ? getBorrower(props.borrowers, props.activity.borrowerId) : null

    if (!borrower)
        props.fetchBorrowerDetails(props.activity.borrowerId)

    return (
        <>
        {borrower ? 
            <Activity 
                header={borrower.firstName + ' replied'}
                timestamp={props.activity.createdTs}
                icon={<svg class="bi bi-bell-slash-fill fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M5.164 14H15c-1.5-1-2-5.902-2-7 0-.264-.02-.523-.06-.776L5.164 14zm6.288-10.617A4.988 4.988 0 0 0 8.995 2.1a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 7c0 .898-.335 4.342-1.278 6.113l9.73-9.73zM10 15a2 2 0 1 1-4 0h4zm-9.375.625a.53.53 0 0 0 .75.75l14.75-14.75a.53.53 0 0 0-.75-.75L.625 15.625z"></path>
                </svg>} 
            >
            <span class="d-lg-flex justify-content-lg-end">Your invite to connect with {borrower.title} {borrower?.firstName} {borrower?.lastName} was declined</span>
            </Activity> : <Activity />}
        </>
    );
}


function ConnectionAdded(props) {
    const borrower = props.activity.borrowerId ? getBorrower(props.borrowers, props.activity.borrowerId) : null

    if (!borrower)
        props.fetchBorrowerDetails(props.activity.borrowerId)

    return (
        <>
        <ClientDetailsModal />
        {borrower ? 
            <Activity 
                header={borrower.firstName + ' connected!'}
                timestamp={props.activity.createdTs}
                icon={<svg class="bi bi-bell fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zM8 1.918l-.797.161A4.002 4.002 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4.002 4.002 0 0 0-3.203-3.92L8 1.917zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5.002 5.002 0 0 1 13 6c0 .88.32 4.2 1.22 6z"></path>
                    </svg>} 
            >
            <span class="d-lg-flex justify-content-lg-end">Congrats! You are now connected with
                <a class="link-info" style={{marginLeft:'1%'}} data-bs-toggle="modal" data-bs-target="#modal-client"  onClick={(e) => {
                    props.fetchProspectDetails(props.activity.borrowerId, props.setClientInDetailView)
                }} >{borrower.firstName}</a>
            </span>            
            </Activity> : <Activity />}
        </>
    );
}

function Welcome(props) {
    
    return (
        <>
        {props.brokerAgent ? 
            <Activity 
                header={'Welcome to Loanseller!'}
                timestamp={props.activity.createdTs}
                icon={<svg class="bi bi-activity fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z"></path>
        </svg> } 
            >
            <span class="d-lg-flex justify-content-lg-end">{props.brokerAgent.firstName}, we're absolutely thrilled to have you on board the LoanSeller journey</span>
            </Activity> : <Activity />}
        </>
    );
}

function BrokerSubscribed(props) {
    
    return (
        <>
        {props.brokerAgent ? 
            <Activity 
                header={'Your subscription is now active!'}
                timestamp={props.activity.createdTs}
                icon={<svg class="bi bi-gem fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
    <path d="M3.1.7a.5.5 0 0 1 .4-.2h9a.5.5 0 0 1 .4.2l2.976 3.974c.149.185.156.45.01.644L8.4 15.3a.5.5 0 0 1-.8 0L.1 5.3a.5.5 0 0 1 0-.6l3-4zm11.386 3.785-1.806-2.41-.776 2.413 2.582-.003zm-3.633.004.961-2.989H4.186l.963 2.995 5.704-.006zM5.47 5.495 8 13.366l2.532-7.876-5.062.005zm-1.371-.999-.78-2.422-1.818 2.425 2.598-.003zM1.499 5.5l5.113 6.817-2.192-6.82L1.5 5.5zm7.889 6.817 5.123-6.83-2.928.002-2.195 6.828z"></path>
</svg>} 
            >
            <span class="d-lg-flex justify-content-lg-end">Great move {props.brokerAgent.firstName}, you can now enjoy our premium features</span>
            </Activity> : <Activity />}
        </>
    );
}



function ProposalAccepted(props) {
    const borrower = props.activity.borrowerId ? getBorrower(props.borrowers, props.activity.borrowerId) : null

    if (!borrower)
        props.fetchBorrowerDetails(props.activity.borrowerId)

    return (
        <>
        {borrower ? 
            <Activity 
                header={'Your loan proposal has been accepted!'}
                timestamp={props.activity.createdTs}
                icon={<svg class="bi bi-person-check-fill fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
    <path fill-rule="evenodd" d="M15.854 5.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L12.5 7.793l2.646-2.647a.5.5 0 0 1 .708 0z"></path>
    <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z"></path>
</svg>} 
            >
            <span class="d-lg-flex justify-content-lg-end">Good news, {borrower.title} {borrower?.firstName} {borrower?.lastName} has accepted your loan proposal. Click &nbsp;</span>
                <Link to="sales" onClick={e => {

                }} ><i>here</i></Link>
            <span>&nbsp; to view your proposal</span>
            </Activity> : <Activity />}
        </>
    );
}

function ProposalDeclined(props) {
    const borrower = props.activity.borrowerId ? getBorrower(props.borrowers, props.activity.borrowerId) : null

    if (!borrower)
        props.fetchBorrowerDetails(props.activity.borrowerId)

    return (
        <>
        {borrower ? 
            <Activity 
                header={'Your loan proposal was declined!'}
                timestamp={props.activity.createdTs}
                icon={<svg class="bi bi-person-x fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
    <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0zm4 8c0 1-1 1-1 1H1s-1 0-1-1 1-4 6-4 6 3 6 4zm-1-.004c-.001-.246-.154-.986-.832-1.664C9.516 10.68 8.289 10 6 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10z"></path>
    <path fill-rule="evenodd" d="M12.146 5.146a.5.5 0 0 1 .708 0L14 6.293l1.146-1.147a.5.5 0 0 1 .708.708L14.707 7l1.147 1.146a.5.5 0 0 1-.708.708L14 7.707l-1.146 1.147a.5.5 0 0 1-.708-.708L13.293 7l-1.147-1.146a.5.5 0 0 1 0-.708z"></path>
</svg>} 
            >
            <span class="d-lg-flex justify-content-lg-end">Unfortuntely, {borrower.title} {borrower?.firstName} {borrower?.lastName} has declined your loan proposal</span>
            </Activity> : <Activity />}
        </>
    );
}

function IntroIcon() {
    return (
        <svg class="bi bi-activity fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z"></path>
        </svg>        
    );
}

class RecentActivities extends Component {
	constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchActivities(this.props.page, this.props.pagesize)
    }


    render () {
        return (
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <h6 class="text-primary fw-bold m-0">Recent activity</h6>
                </div>
                {this.props.recentActivitiesList ? 
                <ul class="list-group list-group-flush" style={{marginTop:"2px"}}>
                {this.props.recentActivitiesList.map(activity => {
                    return (<li class="list-group-item">
                        {
                        activity.type === 'new_agent_setup' ? <Welcome activity={activity}  {...this.props}  /> :
                        activity.type === 'connection_accepted' ? <ConnectionAdded activity={activity}  {...this.props}  /> :
                        activity.type === 'connection_declined' ? <ConnectionDeclined activity={activity} {...this.props} /> :
                        activity.type === 'loanrequest_created' ? <LoanRequestCreated activity={activity} {...this.props} /> :
                        activity.type === 'broker_subscribed' ? <BrokerSubscribed activity={activity} {...this.props} /> :
                        activity.type === 'proposal_declined' ? <ProposalDeclined activity={activity} {...this.props} /> :
                        activity.type === 'proposal_accepted' ? <ProposalAccepted activity={activity} {...this.props} /> :
                            null
                        }
                    </li>);
                })}
                </ul> : null }

                <div class="hstack justify-content-between" style={{margin: "2%"}}>
                    <div class="vstack">
                        {this.props.page == 1 ? null : 
                        <button class="btn btn-light shadow d-xl-flex justify-content-xl-start" type="button" style={{"border-radius": "25px", "box-shadow": "0px 0px"}}
                            onClick={() => {
                                this.props.fetchActivities(1, this.props.pagesize)
                            }}>Show latest</button>}</div>
                    <div class="vstack">
                        {!this.props.hasMoreActivities ? null :
                        <button class="btn btn-light shadow d-xl-flex justify-content-xl-end" type="button" style={{"border-radius": "25px", "box-shadow": "0px 0px"}} 
                            onClick={() => {
                                this.props.fetchActivities(this.props.page + 1, this.props.pagesize)                                
                            }} >Show previous</button>}</div>
                </div>


            </div>
        );
    }
}


const mapStateToProps = ({ dashboardActivities, brokerAgentProfile }) => {
  return { 
        recentActivitiesList: dashboardActivities.activities,
        borrowers: dashboardActivities.borrowers,
        brokerAgent: brokerAgentProfile.brokerAgent,
        page: dashboardActivities.currentPage,
        pagesize: dashboardActivities.maxDisplaySize,
        hasMoreActivities: dashboardActivities.hasMoreActivities
    };
};

const mapDispatchToProps = (dispatch)=> {
    return {
        fetchActivities: (page,pagesize) => dispatch(fetchActivities(page,pagesize)),
        fetchBorrowerDetails : (borrowerId, onSuccess) => dispatch(fetchBorrowerDetails(borrowerId, onSuccess)),
        fetchProspectDetails: (borrowerId, onSuccess) => dispatch(fetchProspectDetails(borrowerId, onSuccess)),
        showClientsBookPage : () => dispatch(showClientsBookPage()),
        setClientInDetailView: (prospect) => dispatch(setClientInDetailView(prospect)),
        loadProposal: (borrowerId) => dispatch(fetchProposal(borrowerId))
    };
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(RecentActivities);