import React, {Component} from 'react';
import {connect} from 'react-redux';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import SelectProductsPageSelector from './SelectProductsPagination';
import { addLoanProduct, fetchEstimatedRepayment } from "../../../store/sales/actions";


const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);



class ProductsTable extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const existingScenarios = this.props?.scenarios ? this.props.scenarios.map(s => s.loanProduct) : []
        const addToScenario = this.props.addLoanProduct
        const fetchRepayment = this.props.fetchEstimatedRepayment
        const cartEnabled = this.props?.editableLoanAmount?.value && this.props?.editableLoanAmount?.value >= 20000
        const amount = this.props.editableLoanAmount
        const loanTerm = this.props.loanTerm
        return (
        <>
        <div class="table-responsive">
        <table class="table table-striped">
            <thead>
                <tr>
                    <th>Product name</th>
                    <th>
                    <div class="hstack gap-0"><span>Variation</span></div>
                    </th>
                    <th>
                    <div class="hstack gap-0"><span>Interest rate</span></div>
                    </th>
                    <th>Comparison rate</th>
                    {cartEnabled ? <th>Add</th> : null }
                </tr>
            </thead>
            <tbody>
                {!this.props?.products ? null :
                    this.props.products.map(p => {
                        var exists = existingScenarios.some(e => p?._id === e?._id)
                        return (
                        <tr key={p._id}>
                            <td>{p.productName}</td>
                            <td>{p?.variation}</td>
                            <td>{p.ratesAndFees.interestRate} %</td>
                            <td>{p.ratesAndFees.comparisonRate} %</td>
                            {cartEnabled ? 
                            <td>
                                <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Add product'})} >
                                {exists ? 
                                    <svg class="bi bi-cart-check-fill fs-2 text-secondary" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" >
                                        <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                                    </svg>
                                    :
                                    <div
                                        onClick={() => {
                                            addToScenario(p, amount)
                                            fetchRepayment(existingScenarios.length, amount.value, loanTerm, p.ratesAndFees.interestRate)
                                            }} >
                                    <svg class="bi bi-cart-plus-fill fs-2 text-primary" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" >
                                        <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM9 5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 1 0z"></path>
                                    </svg>                                    
                                    </div>
                                }
                                </OverlayTrigger>
                                </td> : null }
                        </tr>
                        );
                })}
            </tbody>
        </table></div>
        <SelectProductsPageSelector />

        </>
        )
    }

}


const mapStateToProps = state => {
    return {
        products: state.sales.loanProductRecords,
        scenarios: state.sales.loanScenarios,
        editableLoanAmount: state.sales.editableLoanAmount,
        loanAmount: state.sales.editableLoanAmount,
        loanTerm: state.sales.selectedLoanTermInYrs,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addLoanProduct: (loanProduct, amount) => dispatch(addLoanProduct(loanProduct, amount)),
        fetchEstimatedRepayment: (scenarioIdx, loanAmount, loanTermInYrs, interestRate) => 
            dispatch(fetchEstimatedRepayment(scenarioIdx, loanAmount, loanTermInYrs, interestRate)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsTable);