import React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import ChangePassword from '../buttons/changePasswordConfirmationButton';
import PasswordEntry from './passwordEntry';
import PasswordChangeSuccessfulModal from './passwordChangeSuccessful';


function ChangePasswordForm() {
    const [oldPassword, setOldPassword] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const awaitingEmailVerification = useSelector( state => state.auth.awaitingEmailVerification )
    if (awaitingEmailVerification) {
      setPassword('');
      setPasswordRepeat('');
    }

    const { changePasswordErrors, showChangePasswordSuccess } = useSelector((state) => state.auth);
    return (
        <>
            {showChangePasswordSuccess? (
                < PasswordChangeSuccessfulModal showChangePasswordSuccess={showChangePasswordSuccess} />       
                ) : (
                <div>
                    
                    <div id="single-form-next" class="multisteps-form__panel shadow p-4 rounded bg-white" data-animation="scaleIn">
                        <div class="row d-flex justify-content-center">
                            <div id="form-content" class="multisteps-form__content">
                                
                                {PasswordEntry({        
                                id: 'changePasswordForm_password', placeholder:'Enter your current password',
                                oldPassword:oldPassword, 
                                setPassword:setOldPassword,
                                error:changePasswordErrors.find((element) => element?.type == 'currentPassword')
                                })}

                                {/* New password  */}
                                {PasswordEntry({
                                id: 'registrationForm_password', placeholder:'Enter a safe Password1!',
                                password:password, 
                                setPassword:setPassword,
                                error:changePasswordErrors.find((element) => element?.type == 'password')})}

                                {PasswordEntry({
                                id: 'registrationForm_passwordRepeat', placeholder:'Confirm password',
                                password:passwordRepeat, 
                                setPassword:setPasswordRepeat,
                                error:changePasswordErrors.find((element) => element?.type == 'passwordRepeat')})}

                                
                            </div>
                        </div>
                        
                        < ChangePassword oldPassword={oldPassword} password={password} passwordRepeat={passwordRepeat} />
                        

                    </div>

                            
                </div>
                )}
        </>

     );
}

export default ChangePasswordForm;



