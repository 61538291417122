import React, {Component} from 'react';
import {connect} from 'react-redux';
import Amortization from './Amortization';
import LoanPackage from './LoanPackage';
import SubmissionDocuments from '../NewProposal/SubmissionDocuments';
import ChatSection from '../../Clients/ChatSection'
import { editProposal, redrawAmortisation, loanProposalMenuAction } from "../../../../store/sales/actions";
import { storeProduct } from "../../../../store/sales/proposalSummary/actions";
import { LOAN_PACKAGE_VIEW, REQUIRED_DOCUMENTS_VIEW, AMORTIZATION_VIEW, MESSAGES_VIEW  } from '../../../../store/sales/constants';

function Menu(props) {
    return (
        <>
            <ul class="nav nav-pills flex-column mb-auto">
                <li class="nav-item"><a class={props.view == LOAN_PACKAGE_VIEW ? "nav-link active link-light" : "nav-link link-body-emphasis"} href="#" aria-current="page" 
                    onClick={(e) => {
                        e.preventDefault();
                        props.setView(LOAN_PACKAGE_VIEW)}} >
                    <svg class="bi bi-yin-yang fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M9.167 4.5a1.167 1.167 0 1 1-2.334 0 1.167 1.167 0 0 1 2.334 0Z"></path>
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM1 8a7 7 0 0 1 7-7 3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 0 0 7 7 7 0 0 1-7-7Zm7 4.667a1.167 1.167 0 1 1 0-2.334 1.167 1.167 0 0 1 0 2.334Z"></path>
                    </svg> Summary</a></li>
                <li class="nav-item"><a class={props.view == REQUIRED_DOCUMENTS_VIEW ? "nav-link active link-light" : "nav-link link-body-emphasis"} href="#" 
                    onClick={(e) => {
                        e.preventDefault();
                        props.setView(REQUIRED_DOCUMENTS_VIEW)}} >
                    <svg class="bi bi-filetype-doc fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-7.839 9.166v.522c0 .256-.039.47-.117.641a.861.861 0 0 1-.322.387.877.877 0 0 1-.469.126.883.883 0 0 1-.471-.126.868.868 0 0 1-.32-.386 1.55 1.55 0 0 1-.117-.642v-.522c0-.257.04-.471.117-.641a.868.868 0 0 1 .32-.387.868.868 0 0 1 .471-.129c.176 0 .332.043.469.13a.861.861 0 0 1 .322.386c.078.17.117.384.117.641Zm.803.519v-.513c0-.377-.068-.7-.205-.972a1.46 1.46 0 0 0-.589-.63c-.254-.147-.56-.22-.917-.22-.355 0-.662.073-.92.22a1.441 1.441 0 0 0-.589.627c-.136.271-.205.596-.205.975v.513c0 .375.069.7.205.973.137.271.333.48.59.627.257.144.564.216.92.216.357 0 .662-.072.916-.216.256-.147.452-.356.59-.627.136-.274.204-.598.204-.973ZM0 11.926v4h1.459c.402 0 .735-.08.999-.238a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.59-.68c-.263-.156-.598-.234-1.004-.234H0Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.141 1.141 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082H.79V12.57Zm7.422.483a1.732 1.732 0 0 0-.103.633v.495c0 .246.034.455.103.627a.834.834 0 0 0 .298.393.845.845 0 0 0 .478.131.868.868 0 0 0 .401-.088.699.699 0 0 0 .273-.248.8.8 0 0 0 .117-.364h.765v.076a1.268 1.268 0 0 1-.226.674c-.137.194-.32.345-.55.454a1.81 1.81 0 0 1-.786.164c-.36 0-.664-.072-.914-.216a1.424 1.424 0 0 1-.571-.627c-.13-.272-.194-.597-.194-.976v-.498c0-.379.066-.705.197-.978.13-.274.321-.485.571-.633.252-.149.556-.223.911-.223.219 0 .421.032.607.097.187.062.35.153.489.272a1.326 1.326 0 0 1 .466.964v.073H9.78a.85.85 0 0 0-.12-.38.7.7 0 0 0-.273-.261.802.802 0 0 0-.398-.097.814.814 0 0 0-.475.138.868.868 0 0 0-.301.398Z"></path>
                    </svg>Documents</a></li>
                {props.amortizationLink ? 
                <li class="nav-item"><a class={props.view == AMORTIZATION_VIEW ? "nav-link active link-light" : "nav-link link-body-emphasis"} href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        props.setView(AMORTIZATION_VIEW)}}>
                    <svg class="bi bi-graph-down" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M0 0h1v15h15v1H0zm14.817 11.887a.5.5 0 0 0 .07-.704l-4.5-5.5a.5.5 0 0 0-.74-.037L7.06 8.233 3.404 3.206a.5.5 0 0 0-.808.588l4 5.5a.5.5 0 0 0 .758.06l2.609-2.61 4.15 5.073a.5.5 0 0 0 .704.07Z"></path>
                    </svg>Amortization</a></li> : null}

            </ul>
        </>
    );
}
class ProposalReview extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const getProductDetails = this.props.storeProduct
        const loanProducts = this.props.selectedLoanProducts
        if (this.props?.proposalInView)
            this.props?.proposalInView?.loanPackage?.loanProducts.map(p => {
                if (!loanProducts[p.loanProduct._id])
                    getProductDetails(p.loanProduct._id)
            })

        this.props.redrawAmortisation(this.props.amortizationTable, this.props.lenderProducts, this.props.loanTerm, 
            this.props?.proposalInView?.loanPackage?.loanProducts)
    }

    render() {
        //console.log(this.props)
        return (
        <>
        <section>
            <div class="row">
                <div class="col-2">
                    <div>
                        <Menu setView={this.props.loanProposalMenuAction} view={this.props.selectedMenu} amortizationLink={this.props?.amortizationTable} />
                    </div>
                    <div>
                        <hr />
                        <div class="dropdown">
                            <a class="dropdown-toggle link-body-emphasis d-flex align-items-stretch text-decoration-none" aria-expanded="false" data-bs-toggle="dropdown" role="button">
                                <svg class="bi bi-inboxes" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M4.98 1a.5.5 0 0 0-.39.188L1.54 5H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0A.5.5 0 0 1 10 5h4.46l-3.05-3.812A.5.5 0 0 0 11.02 1zm9.954 5H10.45a2.5 2.5 0 0 1-4.9 0H1.066l.32 2.562A.5.5 0 0 0 1.884 9h12.234a.5.5 0 0 0 .496-.438L14.933 6zM3.809.563A1.5 1.5 0 0 1 4.981 0h6.038a1.5 1.5 0 0 1 1.172.563l3.7 4.625a.5.5 0 0 1 .105.374l-.39 3.124A1.5 1.5 0 0 1 14.117 10H1.883A1.5 1.5 0 0 1 .394 8.686l-.39-3.124a.5.5 0 0 1 .106-.374L3.81.563zM.125 11.17A.5.5 0 0 1 .5 11H6a.5.5 0 0 1 .5.5 1.5 1.5 0 0 0 3 0 .5.5 0 0 1 .5-.5h5.5a.5.5 0 0 1 .496.562l-.39 3.124A1.5 1.5 0 0 1 14.117 16H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .121-.393zm.941.83.32 2.562a.5.5 0 0 0 .497.438h12.234a.5.5 0 0 0 .496-.438l.32-2.562H10.45a2.5 2.5 0 0 1-4.9 0H1.066"></path>
                                </svg><strong style={{'marginLeft':'2%'}}>More actions</strong></a>
                            <div class="dropdown-menu shadow text-small" data-popper-placement="top-start">
                                <a class="dropdown-item" href="#" 
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.props.editProposal()
                                    }}>Edit proposal</a>
                                <a class="dropdown-item" href="#" 
                                    onClick={(e) => {
                                        this.props.loanProposalMenuAction(MESSAGES_VIEW)
                                    }}>Send a message</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-10">
                    {this.props.selectedMenu == LOAN_PACKAGE_VIEW ? <LoanPackage /> :  
                        this.props.selectedMenu == REQUIRED_DOCUMENTS_VIEW && this.props?.prospectInView ? <SubmissionDocuments /> : 
                        this.props.selectedMenu == AMORTIZATION_VIEW && this.props?.amortizationTable ? <Amortization editMode={false} /> : 
                        this.props.selectedMenu == MESSAGES_VIEW ? <ChatSection /> : null}
                </div>
            </div>
        </section>
        </>

        )
    }
}

const mapStateToProps = state => {
    return {
        proposalInView : state.proposalSummary.proposalInView,
        prospectInView : state.clients.prospectInView,
        selectedLoanProducts : state.proposalSummary.selectedLoanProducts,
        amortizationTable: state.sales.amortizationTable,
        selectedMenu : state.sales.selectedMenu,
        loanScenarios: [],
    }
}

const mapDispatchToProps = dispatch => {
    return {
        storeProduct: (productId) => dispatch(storeProduct(productId)),
        redrawAmortisation: 
            (amortization, availableProducts, loanTermInYears, products) =>
            dispatch(
                redrawAmortisation(amortization, availableProducts, loanTermInYears, products)
            ),
        loanProposalMenuAction: (view) => dispatch(loanProposalMenuAction(view)),
        editProposal: () => dispatch(editProposal()),

    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProposalReview);