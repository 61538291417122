import { PROPOSAL_ACTION_TYPES } from "./actionTypes";
import store from '../store';

export const initialState = {
    loading: false,
    post: 'N/A',
    error: '',
    proposals: 'N/A'
}

  
export default function proposalReducer(state = initialState, action) {
    switch (action.type) {
        case PROPOSAL_ACTION_TYPES.FETCH_LOADING:
            return {
                ...state.dashboardProposal,
                loading: true
            }
        case PROPOSAL_ACTION_TYPES.FETCH_SUCCESS:
            return {
                loading: false,
                post: action.payload,
                error: '',
                proposals: action.payload?.totalAmount?.value 
            }
        case PROPOSAL_ACTION_TYPES.FETCH_FAILURE:
            return {
                loading: false,
                post: 'N/A',
                error: "Proposal count could not be retrieved.",
                proposals: 0
            }
        default:
            return state
    }
}
  