export const login = {
  pending: "auth/login/pending",
  success: "auth/login/success",
  fail: "auth/login/fail",
};
export const resetPassword = {
  pending: "reset/pending",
  success: "reset/success",
  fail: "reset/fail",
  validation_fail: "reset/validation_fail",
  error: "reset/error",
  submit: "reset/submit",
};

export const register = {
  validation_fail: "auth/register/validation_fail",
  signup: "auth/register/signup",
  signup_error: "auth/register/signup_error",
  verify_code: "auth/register/verify_code",
  verification_fail: "auth/register/verification_fail",
  error: "auth/register/error",
};

export const logout = "auth/logout";

export const authenticate = "authenticate";

export const changePassword = {
  validation_fail: "auth/changepassword/validation_fail",
  error: "auth/changepassword/error",
  submit: "auth/changepassword/submit",
};

export default { authenticate, login, logout, register, changePassword, resetPassword };
