import { CHATS_ACTION_TYPES } from "./actionTypes";

export const initialState = {
    borrowers: null,
    borrowerDetails : {},
    borrowerMessages : {},
    borrowerInView: null,
    error:null,
}

export default function chatsReducer(state = initialState, action) {
    switch (action.type) {
        case CHATS_ACTION_TYPES.GET_BORROWERS_WITH_CHATS:
            var borrowers = state.borrowers ? state.borrowers : []
            action.payload?.data.forEach(borrowerId => {
                if (!borrowers.find(borrower => borrowerId == borrower))
                    borrowers.push(borrowerId)
            });
            var borrower = borrowers.length == 0 ? null : borrowers[0] 
            return {...state, borrowers : borrowers, borrowerInView: borrower, borrowerDetails : {}, borrowerMessages : {}  }
        case CHATS_ACTION_TYPES.GET_BORROWER_DETAILS:
            return {
                ...state,
                borrowerDetails: {...state.borrowerDetails,...action.payload.data}
            }
        case CHATS_ACTION_TYPES.GET_BORROWER_CHATS:
            return {
                ...state,
                borrowerMessages: {...state.borrowerMessages,...action.payload.data}
            }
        case CHATS_ACTION_TYPES.SET_BORROWER_IN_VIEW:
            return {
                ...state,
                borrowerInView : action.payload, borrowerDetails : {}, borrowerMessages : {}
            }
        case CHATS_ACTION_TYPES.ERROR:
            return {
                ...state,
                error: action.payload
            }
        default:
            return state
    }
}
  