import React, { Component,  } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { downloadDocument, } from "../../../store/clients/actions";


const renderTooltip = props => (
    <Tooltip {...props}>{props?.locationtext}</Tooltip>
);


function DocumentRow(props) {
    const items = [];
    if (props?.documents)
        for (const [index, item] of props.documents.entries()) {
            //console.log(item)
            items.push(
                <>
                    <OverlayTrigger placement="bottom" overlay={renderTooltip({ locationtext: props.requiredDocumentTypes.find( t => t._id == item.metadata.documentTypeId).description })} >
                    <div class="hstack gap-2 justify-content-between">
                        <span>{item.filename}</span>
                        <span>Uploaded {moment(item?.metadata?.receivedDate).format('Do MMMM YYYY')}</span>
                        <a class="link-primary" href="#" onClick={e => {
                            e.preventDefault();
                            props.downloadDocument(item._id)
                        }} >
                            <svg class="bi bi-box-arrow-up-right" xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" fill="currentColor" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"></path>
                                <path fill-rule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z"></path>
                            </svg>
                        </a>
                    </div></OverlayTrigger><hr />
                </>
            )
        }
    return (
        <>
            {props?.documents && props.documents.length > 0 ?
                <div class="vstack">{items}</div> :
                <OverlayTrigger placement="bottom" overlay={renderTooltip({ locationtext: 'None found' })} >
                    <div class="bs-icon-md bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><i class="fas fa-times-circle"></i></div>
                </OverlayTrigger>
            }
        </>
    );
}


class ClientDocuments extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        var bankStatements = this.props?.documents == null ? [] : this.props.documents.filter(item => item.metadata.documentType === 'bank_statement')
        var identityDocument = this.props?.documents == null ? [] : this.props.documents.filter(item => item.metadata.documentType === 'id_proof')
        var incomeProof = this.props?.documents == null ? [] : this.props.documents.filter(item => item.metadata.documentType === 'inc_proof')
        var otherDocuments = this.props?.documents == null ? [] : this.props.documents.filter(item => item.metadata.documentType === 'other_document')
        //console.log(this.props)
        return (
            <div class="card flex-grow-1 align-self-stretch">
                <div class="card-header">
                    <h5 class="mb-0">Documents</h5>
                </div>
                <div class="card-body">
                    {this.props?.documents != null ? 
                    <div class="table-responsive">
                        <table class="table">
                            <tbody>
                                <tr>
                                    <td><span>Bank Statements</span></td>
                                    <td>
                                        <DocumentRow {...this.props} documents={bankStatements} />
                                    </td>
                                </tr>
                                <tr>
                                    <td><span>Identity documents</span></td>
                                    <td>
                                        <DocumentRow {...this.props} documents={identityDocument} />
                                    </td>
                                </tr>
                                <tr>
                                    <td><span>Proof of income</span></td>
                                    <td>
                                        <DocumentRow {...this.props} documents={incomeProof} />
                                    </td>
                                </tr>
                                <tr>
                                    <td><span>Other documents</span></td>
                                    <td>
                                        <DocumentRow {...this.props} documents={otherDocuments} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    : <h4>Awaiting access to client documents</h4> }
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ clients, auth, editDocuments }) => {
    return {
        documents: clients.borrowerDocuments,
        token: auth.token,
        requiredDocumentTypes: editDocuments.requiredDocumentTypes,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        downloadDocument: (documentId) => dispatch(downloadDocument(documentId)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientDocuments);
