import React from 'react';

function CaseNotes() {
    return ( 
        <div class="card shadow flex-fill border-start-success py-2">
            <div class="card-body">
                <div class="hstack justify-content-between">
                    <h5>Case notes</h5><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" class="bi bi-box-arrow-in-down-left">
                        <path fill-rule="evenodd" d="M9.636 2.5a.5.5 0 0 0-.5-.5H2.5A1.5 1.5 0 0 0 1 3.5v10A1.5 1.5 0 0 0 2.5 15h10a1.5 1.5 0 0 0 1.5-1.5V6.864a.5.5 0 0 0-1 0V13.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z"></path>
                        <path fill-rule="evenodd" d="M5 10.5a.5.5 0 0 0 .5.5h5a.5.5 0 0 0 0-1H6.707l8.147-8.146a.5.5 0 0 0-.708-.708L6 9.293V5.5a.5.5 0 0 0-1 0v5z"></path>
                    </svg>
                </div>
                <div class="hstack gap-2 d-xl-flex align-items-xl-start">
                    <div class="vstack">
                        <div class="vstack padding1percent">
                            <div class="alert alert-info" role="alert"><span><strong>6 days ago</strong> comment entered&nbsp;<strong>Team member A</strong><br /></span></div>
                            <div class="alert alert-info" role="alert"><span><strong>6 days ago</strong> comment entered&nbsp;<strong>Team member A</strong><br /></span></div>
                        </div>
                        <div class="hstack gap-2"><button class="btn btn-light border rounded-pill align-self-start" type="button">Show more</button><button class="btn btn-light border rounded-pill align-self-start" type="button">Add a note</button></div>
                    </div>
                </div>
            </div>
        </div>
     );
}

export default CaseNotes;