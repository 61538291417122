import React from 'react';
import { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from  'classnames';
import { submitVerificationCode, showLoginPage } from '../../../store/auth/actions';
import { Button, Modal } from 'react-bootstrap';


function VerificationForm() {
    const [code, setCode] = useState('');
    const dispatch = useDispatch();
    const { username, authorizationUrl, clientId, redirectUri, responseType, oauthState, registrationFormErrors, showVerificationSuccess } = useSelector((state) => state.auth);
    const error = registrationFormErrors.find((element) => element?.type == 'registrationCode');
    var errorMsgClass = classNames({
      '': error == null,
      'alert alert-danger regionError': error != null,
    });
    var txtClass = classNames({
      'form-control': error == null,
      'form-control borderError': error != null,
    }); 

    return ( 
    <>
        {showVerificationSuccess? (
        <Modal.Dialog show={showVerificationSuccess}>
            <Modal.Header>
                <Modal.Title>Congrats</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You are now enrolled for our <strong>Freemium</strong> package. Enjoy the smart features to connect with your client base. You can upgrade to a <strong>Sales generator</strong> package at any time!<br/>&nbsp;</p>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    onClick={() => {
                            showLoginPage(authorizationUrl, responseType, clientId, redirectUri, oauthState);
                        }} 
                        >Login</Button>
            </Modal.Footer>
        </Modal.Dialog>            
        ) : (
        <>
            <div class="container position-relative">
            <div class="row d-flex justify-content-center">
                <div class="col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                    <div class="card mb-5">
                        <div class="card-body p-sm-5">
                            <h2 class="text-center mb-4">Confirm registration</h2>
                            <p class="w-lg-50">Enter verification code sent to your email</p>
                            <form method="post">
                                {
                                    error == null ? 
                                    ( 
                                        <div class="mb-3">
                                            <input className={txtClass} type="text" id="verify-1" name="registrationCode" placeholder="Registration code"
                                                value={code} onChange={(e) => setCode(e.target.value)}/>
                                        </div>
                                    ) : (
                                    <div class="mb-3">
                                        <div className={errorMsgClass} role="alert" >
                                            <input className={txtClass} type="text" id="verify-1" name="registrationCode" placeholder="Registration code"
                                                value={code} onChange={(e) => setCode(e.target.value)}/>
                                        <span><strong>{error.errorMessage}</strong><br/></span>
                                        </div>
                                    </div>
                                    )
                                }
                                    <div><button class="btn btn-primary d-block w-100" type="submit" onClick={(e) => {
                                            e.preventDefault();
                                            dispatch(
                                                submitVerificationCode(username, code));
                                        }}>Verify</button></div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </>
        )
        }

    </>
     );
}

export default VerificationForm;