import { MESSAGE_ACTION_TYPES } from "./actionTypes"

export const initialState = {
    loading: false,
    post: 'N/A',
    error: '',
}
  
export default function messagesReducer(state = initialState, action) {
    switch (action.type) {
        case MESSAGE_ACTION_TYPES.FETCH_LOADING:
            return {
                ...state,
                loading: true
            }
        case MESSAGE_ACTION_TYPES.FETCH_SUCCESS:
            return {
                loading: false,
                post: action.payload,
                error: '',
            }
        case MESSAGE_ACTION_TYPES.FETCH_FAILURE:
            return {
                loading: false,
                post: 'N/A',
                error: "Message count could not be retrieved.",
            }
        default:
            return state
    }
}
  