import React, { Component } from 'react';
import { connect } from "react-redux";
import Pagination from 'react-bootstrap/Pagination';
import { fetchNewConnections } from "../../../store/clients/actions";

function loadPage(props) {
    props.fetchNewConnections(props.page, props.recordsPerPage);
}

class InvitationsPageSelector extends Component {
	constructor(props) {
        super(props);
    }

    render () {
        var hooks = { ...this.props };
        const startIdx = this.props.totalNumRecords == 0 ? 0 : (this.props.displayPage-1)*this.props.recordsPerPage + 1;
        const endIdx = startIdx == 0 && this.props.totalNumRecords == 0 ? 0 : startIdx - 1 + (this.props.records == null ? 0 :  this.props.records.length);
        const showing = this.props.displayPage;
        const maxPages = this.props.recordsPerPage > this.props.totalNumRecords ? 1 : Math.ceil(this.props.totalNumRecords/this.props.recordsPerPage);
        var links = [];
        for (var i = this.props.displayPage - 1;  i <= maxPages && links.length < 3;i++)
                if (i > 0)
                    links.push(i)
        return (
            <div class="hstack gap-4 d-lg-flex align-items-lg-start">
                <span>Showing records <strong>{startIdx}</strong> to <strong>{endIdx}</strong> of <strong>{this.props.totalNumRecords}</strong></span>
                <Pagination>
                    <Pagination.First  
                        onClick={() => {
                            loadPage({...hooks,page:1})
                        }} ></Pagination.First >
                        
                {
                    links.map(function(page){
                            return (<Pagination.Item active={showing === page}
                                        onClick={() => {
                                            loadPage({...hooks,page:page})}} >{page}</Pagination.Item>
                            );
                        })
                }
                    <Pagination.Last 
                        onClick={() => {
                            loadPage({...hooks,page:maxPages})
                        }} ></Pagination.Last>

                </Pagination>
            </div>
      );
  }
}

const mapStateToProps = ({ clients }) => {
  return { 
        recordsPerPage: clients.invitationsPageSize,
        totalNumRecords: clients.invitationsCount,
        displayPage: clients.invitationsDisplayPage,
        records: clients.clientInvitations,
    };
};

const mapDispatchToProps = (dispatch)=> {
    return {
        fetchNewConnections: (page, pagesize) => dispatch(fetchNewConnections(page, pagesize)),
    };
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(InvitationsPageSelector);