import React from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { hideResultModal, showLandingPage } from '../../../store/clients/actions';
import { Modal, Button } from 'react-bootstrap';


function ClientInviteResultModal() {
    const dispatch = useDispatch()
    const showResultModal = useSelector(state => state.clients?.showResultModal)
    const result = useSelector(state => state.clients?.result)
    return ( 
        <Modal show={showResultModal}>
            <Modal.Header>
                <Modal.Title>LoanShopper</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {result.success ? <p>{result.responseMessage}</p> : <p>{result.errorMessage}</p>}
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    onClick={() => {
                            dispatch(hideResultModal())
                            dispatch(showLandingPage());
                        }} 
                        >Close</Button>
            </Modal.Footer>
        </Modal>
     );
}

export default ClientInviteResultModal;