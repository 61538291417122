const getLenders = { success: 'sales/getLenders/success', fail: 'sales/getLenders/fail' };
const changeModalDisplay = 'sales/modal/display'
const changeLoanProposalMenu = 'sales/loanProposal/menu'
const chooseLender = 'sales/chooseLender'
const chooseLoanTerm = 'sales/chooseLoanTerm'
const setAddMode = 'sales/setAddMode'
const setLoanAmount = 'sales/setLoanAmount'
const setBrokersDiscount = 'sales/setBrokersDiscount'
const setProductTableFilter = {applySort: 'sales/setProductTableFilter/applySort', applyFilter:'sales/setProductTableFilter/applyFilter'};
const setLoanProduct = 'sales/setLoanProduct'
const clearErrors = 'sales/clearErrors'
const fetchProducts = {success: 'sales/fetchProducts/success', fail:'sales/fetchProducts/fail'};
const fetchProductCount = {success: 'sales/fetchProductCount/success', fail:'sales/fetchProductCount/fail'};
const fetchProposalsCount = {success: 'sales/fetchProposalsCount/success', fail:'sales/fetchProposalsCount/fail'};
const fetchProposals = {success: 'sales/fetchProposals/success', fail:'sales/fetchProposals/fail'};
const fetchProspectsCount = {success: 'sales/fetchProspectsCount/success', fail:'sales/fetchProspectsCount/fail'};
const fetchProspects = {success: 'sales/fetchProspects/success', fail:'sales/fetchProspects/fail'};
const fetchProspect = {success: 'sales/fetchProspect/success', fail:'sales/fetchProspect/fail'};
const loadProposal = {success: 'sales/loadProposal/success', fail:'sales/loadProposal/fail'};
const fetchBorrowerProposal = {success: 'sales/fetchBorrowerProposal/success', fail:'sales/fetchBorrowerProposal/fail'};
const renderProposal = {success: 'sales/renderProposal/success', fail:'sales/renderProposal/fail'};
const fetchProduct = {success: 'sales/fetchProduct/success', fail:'sales/fetchProduct/fail'};
const fetchAmortization = {success: 'sales/fetchAmortization/success', fail:'sales/fetchAmortization/fail'};
const runAmortization = {success: 'sales/runAmortization/success', fail:'sales/runAmortization/fail',clear: 'sales/runAmortization/clear', reset:'sales/runAmortization/reset'};
const postProposal = {success: 'sales/postProposal/success', fail:'sales/postProposal/fail'};
const fetchProspectBySearchTypeCount = {
  selfEmployedSuccess: 'sales/fetchProspectBySearchTypeCount/selfEmployedSuccess', selfEmployedFail:'sales/fetchProspectBySearchTypeCount/selfEmployedFail',
  investorSuccess: 'sales/fetchProspectBySearchTypeCount/investorSuccess', investorFail:'sales/fetchProspectBySearchTypeCount/investorFail',
  refinanceSuccess: 'sales/fetchProspectBySearchTypeCount/refinanceSuccess', refinanceFail:'sales/fetchProspectBySearchTypeCount/refinanceFail',
  firstHomeSuccess: 'sales/fetchProspectBySearchTypeCount/firstHomeSuccess', firstHomeFail:'sales/fetchProspectBySearchTypeCount/firstHomeFail',
  jointApplicantSuccess: 'sales/fetchProspectBySearchTypeCount/jointApplicantSuccess', jointApplicantFail:'sales/fetchProspectBySearchTypeCount/jointApplicantFail',
  noProposalsSuccess: 'sales/fetchProspectBySearchTypeCount/noProposalsSuccess', noProposalsFail:'sales/fetchProspectBySearchTypeCount/noProposalsFail',
  connectionsSuccess: 'sales/fetchProspectBySearchTypeCount/connectionsSuccess', connectionsFail:'sales/fetchProspectBySearchTypeCount/connectionsFail',
};
const setProspectDisplay = {
  all : 'prospects/showAll',
  selfEmployed : 'prospects/showSelfEmployed',
  refinance : 'prospects/showRefinance',
  investor : 'prospects/showInvestor',
  firstHome : 'prospects/showFirstHome',
  noProposals : 'prospects/showFreshPropsects',
}
const setDocumentSelection = {
  primaryApplicantDriverLicence : 'documents/primaryApplicantDriverLicence',
  primaryApplicantPassport: 'documents/primaryApplicantPassport',
  primaryApplicantMedicare: 'documents/primaryApplicantMedicare',
  primaryApplicantBirthCertificate: 'documents/primaryApplicantBirthCertificate',
  secondaryApplicantDriverLicence: 'documents/secondaryApplicantDriverLicence',
  secondaryApplicantPassport: 'documents/secondaryApplicantPassport',
  secondaryApplicantMedicare: 'documents/secondaryApplicantMedicare',
  secondaryApplicantBirthCertificate: 'documents/secondaryApplicantBirthCertificate',
  primaryApplicantBankStatements: 'documents/primaryApplicantBankStatements',
  primaryApplicantCardStatements: 'documents/primaryApplicantCardStatements',
  primaryApplicantHomeLoanStatements: 'documents/primaryApplicantHomeLoanStatements',
  primaryApplicantPersonalLoanStatements: 'documents/primaryApplicantPersonalLoanStatements',
  secondaryApplicantBankStatements: 'documents/secondaryApplicantBankStatements',
  secondaryApplicantCardStatements: 'documents/secondaryApplicantCardStatements',
  secondaryApplicantHomeLoanStatements: 'documents/secondaryApplicantHomeLoanStatements',
  secondaryApplicantPersonalLoanStatements: 'documents/secondaryApplicantPersonalLoanStatements',
  primaryApplicantPayslips: 'documents/primaryApplicantPayslips',
  primaryApplicantNOA: 'documents/primaryApplicantNOA',
  primaryApplicantTaxReturns: 'documents/primaryApplicantTaxReturns',
  primaryApplicantBAS: 'documents/primaryApplicantBAS',
  secondaryApplicantPayslips: 'documents/secondaryApplicantPayslips',
  secondaryApplicantNOA: 'documents/secondaryApplicantNOA',
  secondaryApplicantTaxReturns: 'documents/secondaryApplicantTaxReturns',
  secondaryApplicantBAS: 'documents/secondaryApplicantBAS',
  rentalIncStmts: 'documents/rentalIncStmts',
  companyTaxReturns: 'documents/companyTaxReturns',
  carAndPersonalLoanStmt: 'documents/carAndPersonalLoanStmt',
  sharesInvestmentStmts: 'documents/sharesInvestmentStmts',
  ratesNotice: 'documents/ratesNotice',
  trustDeed: 'documents/trustDeed',
  leaseAgreement: 'documents/leaseAgreement',

  selectIdDocs: 'documents/selectIdDocs',
  deselectIdDocs: 'documents/deselectIdDocs',
  selectBankStmts: 'documents/selectBankStmts',
  deselectBankStmts: 'documents/deselectBankStmts',
  selectIncomeDocs: 'documents/selectIncomeDocs',
  deselectIncomeDocs: 'documents/deselectIncomeDocs',
  selectOtherDocs: 'documents/selectOtherDocs',
  deselectOtherDocs: 'documents/deselectOtherDocs',
};
const discardUpdatedProposal = 'sales/discardUpdatedProposal'
const discardProposalInView = 'sales/discardProposalInView'
const close = 'sales/close'

export default {
  getLenders,
  changeModalDisplay,
  changeLoanProposalMenu,
  chooseLender,
  chooseLoanTerm,
  setAddMode,
  setLoanAmount,
  setBrokersDiscount,
  setProductTableFilter,
  setLoanProduct,
  fetchProducts,
  fetchProductCount,
  setDocumentSelection,
  fetchProspect,
  fetchProspects,
  fetchProspectsCount,
  fetchProspectBySearchTypeCount,
  fetchProspectsCount,
  fetchProposals,
  fetchProposalsCount,
  fetchProduct,
  fetchAmortization,
  runAmortization,
  setProspectDisplay,
  postProposal,
  renderProposal,
  loadProposal,
  fetchBorrowerProposal,
  discardUpdatedProposal,
  discardProposalInView,
  clearErrors,
  close,
};
