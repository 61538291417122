import React, { Component } from 'react';
import { connect } from "react-redux";
import { fetchNewConnections } from "../../../store/clients/actions";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


const renderTooltip = props => (
  <Tooltip {...props}>{props?.locationtext}</Tooltip>
);
function AmountDisplay(props) {
    var amount = props?.displayAmount == null ? 0 : props.displayAmount.value
    return (
    <span>{ amount.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' }) }</span>        
    );
}

class BorrowingEstimatesCard extends Component {
	constructor(props) {
        super(props);
    }

    render () {
        return (
            <div class="card shadow flex-fill border-start-success py-2">
                <div class="card-body">
                    <div class="row align-items-center no-gutters">
                        <div class="col me-2">
                            <div class="vstack gap-2">
                                <div class="vstack">
                                    <div class="text-uppercase text-success fw-bold text-xs mb-1"><span style={{"color": "var(--bs-purple)"}}>Estimated borrowing limit</span></div>
                                    <div class="text-dark fw-bold h5 mb-0"><AmountDisplay displayAmount={this.props?.clientBorrowingEstimates?.borrowingLimit} /></div>
                                </div>
                                <div class="vstack">
                                    <div class="text-uppercase text-success fw-bold text-xs mb-1"><span style={{"color": "var(--bs-purple)"}}>Estimated repayment</span></div>
                                    <div class="text-dark fw-bold h5 mb-0"><AmountDisplay displayAmount={this.props?.clientBorrowingEstimates?.monthlyRepayment}  /> </div>
                                </div>
                            </div>
                        </div>
                        <div class="col me-2">
                            <div class="vstack gap-2">
                                <div class="vstack">
                                    <div class="text-uppercase text-success fw-bold text-xs mb-1"><span style={{"color": "var(--bs-purple)"}}>Profile score</span></div>
                                    <div class="text-dark fw-bold h5 mb-0"><span>{this.props.prospectInView ? this.props.prospectInView.borrowerProfile?.profileScore : 0}%</span></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-auto">
                            <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Home borrowing estimate'})} >
                            <i class="fas fa-university fa-2x text-gray-300"></i>
                            </OverlayTrigger>
                            </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => {
  return { 
        clientBorrowingEstimates: state.clients.clientBorrowingEstimates,
        prospectInView : state.clients.prospectInView,
    };
};

const mapDispatchToProps = (dispatch)=> {
    return {
        fetchNewConnections: (page, pagesize) => dispatch(fetchNewConnections(page, pagesize)),
    };
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(BorrowingEstimatesCard);
