import _ from './actionType';

const initialState = {
  // subscribers: { totalCount: "", dataByPage: { "<page number>": [] } },

  selectedFilter: '',
  billingIdsByFilter: {
    '<selectedFilter>': {
      totalCount: '',
      dataByPage: {
        '<page number>': []
      },
      loadingByPage: { '<page number>': false },
      selectedPage: 1
    }
  },
  searchBillingLoading: '',
  errorMessage: '',
  pageSize: 10,

  //array as object id is the primary key
  billingList: {}
};

export default function subscribers(state = initialState, action) {
  if (!action.payload) {
    return { ...state };
  }
  const {
    pageSize,
    pageNumber,
    data,
    filter,
    totalCount,
    errorMessage
    // selectedPage,
  } = action.payload;
  // //console.log("action.type");
  // //console.log(action.payload);
  switch (action.type) {
    case _.searchBilling.pending:
      // pageNumber, filter, pageSize;

      if (state.billingIdsByFilter[filter]) {
        return {
          ...state,
          searchBillingLoading: true,
          billingIdsByFilter: {
            ...state.billingIdsByFilter,
            [filter]: {
              ...state.billingIdsByFilter[filter],
              loadingByPage: { [pageNumber]: true }
            }
          }
        };
      } else {
        return {
          ...state,
          searchBillingLoading: true,
          billingIdsByFilter: {
            ...state.billingIdsByFilter,
            [filter]: {
              ...state.billingIdsByFilter[filter],
              loadingByPage: { [pageNumber]: true },
              // totalCount: state.billingIdsByFilter[filter].totalCount,
              totalCount: 0,
              dataByPage: {},
              selectedPage: 1
            }
          }
        };
      }

    case _.searchBilling.success:
      //pageNumber, filter, pageSize
      return {
        ...state,
        searchBillingLoading: false,
        billingIdsByFilter: {
          ...state.billingIdsByFilter,
          [filter]: {
            ...state.billingIdsByFilter[filter],
            totalCount: totalCount,
            dataByPage: {
              ...state.billingIdsByFilter[filter].dataByPage,
              [pageNumber]: [...selectPKs(data)]
            },
            loadingByPage: { [pageNumber]: false }
          }
        },
        billingList: { ...state.billingList, ...arrToObj(data) }
      };

    case _.searchBilling.fail:
      //pageNumber, filter, pageSize
      return {
        ...state,
        searchBillingLoading: false,
        errorMessage: errorMessage,
        billingIdsByFilter: {
          [filter]: {
            totalCount: null,
            dataByPage: {
              ...state.billingIdsByFilter[filter].dataByPage,
              [pageNumber]: []
            },
            loadingByPage: { [pageNumber]: false }
          }
        }
      };

    case _.selectPage:
      //pageNumber, filter, pageSize
      return {
        ...state,
        searchBillingLoading: false,
        billingIdsByFilter: {
          ...state.billingIdsByFilter,
          [filter]: {
            ...state.billingIdsByFilter[filter],
            selectedPage: pageNumber
          }
        }
      };
    case 'updateBilling/update/success':
      return {
        ...state,
        billingList: {
          ...state.billingList,
          [action.payload.updatedBilling.id]: {
            ...action.payload.updatedBilling
          }
        }
      };
    default:
      return state;
  }
}

const selectPKs = (arr) => {
  const primaryKey = 'id';

  return arr.map((e) => e[primaryKey]);
};

const arrToObj = (arr) => {
  const primaryKey = 'id';
  const arrAsObj = {};
  arr.forEach((e) => {
    arrAsObj[e[primaryKey]] = { ...e };
  });

  return arrAsObj;
};
