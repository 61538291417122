import React, { Component } from 'react';
import { connect } from "react-redux";
import { AsyncTypeahead, Menu, MenuItem  } from "react-bootstrap-typeahead";
import ErrorContainer from '../../../common/ErrorContainer';
import { fetchLenders, saveLender, fetchProducts, fetchProductsCount } from "../../../../store/loanProducts/actions";

class LenderAutofill extends Component {
	constructor(props) {
        super(props);
    }

    render () {
        const filterBy = () => true;
        //console.log(this.props?.lenderOptions)
    	return (
          <div>
             <div class="input-group">
                <div class="form-row col-12">
                    <ErrorContainer error={this.props?.error}>
                        <div class="input-group"><span class="input-group-text">Choose lender</span>
                        <AsyncTypeahead
                            disabled={this.props?.readOnly}
                            filterBy={filterBy}
                            id="async-lender-lookup"
                            labelKey={option => {
                                if (option != null) {
                                    this.props.saveSelectedLender(option);
                                    this.props.fetchProductsCount(option.name, this.props.productFilters);
                                    this.props.fetchProductRows(option.name, this.props.productFilters, this.props.sort, 1, this.props.pageSize);
                                }
                                return option === null ? '' : option?.name === null ? `${option.fullName}` : `${option.name}` }}
                            minLength={0}
                            options={this.props?.lenderOptions}
                            renderMenu={(results, menuProps) => (
                                <Menu {...menuProps}  >
                                {results.map((result, index) => (
                                    <MenuItem option={result} position={index} >
                                        {result.name}
                                    </MenuItem>
                                ))}
                                </Menu>
                            )}
                        />                        
                        </div>
                    </ErrorContainer>
                </div>
                </div>
            </div> 
      );
  }
}

const mapStateToProps = ({ loanProducts }) => {
  return { 
        lenderOptions: loanProducts.lenderOptions,
        selectedLender: loanProducts.selectedLender,
        productFilters: loanProducts.filters,
        sort: loanProducts.sort,
        pageSize: loanProducts.pageSize,
        error: loanProducts.error
    };
};

const mapDispatchToProps = (dispatch)=> {
    return {
        fetchLenderOptions: (lookup) => dispatch(fetchLenders(lookup)),
        saveSelectedLender: (option) => dispatch(saveLender(option)),
        fetchProductRows: (lenderName, filters, sort, page, pagesize) => dispatch(fetchProducts(lenderName, filters, sort, page, pagesize)),
        fetchProductsCount: (lenderName, filters) => dispatch(fetchProductsCount(lenderName, filters)),
    };
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(LenderAutofill);
