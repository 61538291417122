import React from 'react';


function ClientInfo() {
    return ( 
        <div class="card text-nowrap shadow d-flex flex-row flex-nowrap border-start-success py-2">
            <div class="card-body">
                <div class="hstack gap-2 justify-content-between">
                    <h5>Mr John Hamm</h5>
                    <div class="hstack gap-2"><button class="btn btn-light border rounded-pill align-self-start" type="button">Client file</button><button class="btn btn-light border rounded-pill align-self-start" type="button">Client proposal</button><button class="btn btn-light border rounded-pill align-self-start" type="button">Client documents</button></div>
                </div>
            </div>
        </div>
     );
}

export default ClientInfo;