import React from 'react';
import classNames from  'classnames';


function PasswordEntry(props) {
    var errorMsgClass = classNames({
      '': props.error == null,
      'alert alert-danger regionError': props.error != null,
    });
    var txtClass = classNames({
      'form-control': props.error == null,
      'form-control borderError': props.error != null,
    }); 
  
  return props.error == null ? 
  ( 
    <div class="mb-3">
      <input className={txtClass} type="password" id={props.id} name={props.id} placeholder={props.placeholder}  
        value={props.password} onChange={(e) => props.setPassword(e.target.value)} />
    </div>
  ) : (
    <div class="mb-3">
        <div className={errorMsgClass} role="alert" >
        <input className={txtClass} type="password" id={props.id} name={props.id} placeholder={props.placeholder}  
          value={props.password} onChange={(e) => props.setPassword(e.target.value)} />
        <span><strong>{props.error.errorMessage}</strong><br/></span></div>
      </div>
  );
}

export default PasswordEntry