import types from "./actionTypes";
import store from "../store"
import { getSubscriptionPackages, postPaymentReference, getPaymentReference } from '../request'
const uri = process.env.REACT_APP_API_baseuri;


export const fetchSubscriptionPackages = () => async (dispatch) => {
    getSubscriptionPackages()
        .then(res => {
            if (res != null && res.data)
                dispatch({
                    type: types.UPGRADE_ACTION_TYPES.FETCH_SUBSCRIPTION_PACKAGES_SUCCESS,
                    payload: res.data,
                });
            else 
                dispatch({
                    type: types.UPGRADE_ACTION_TYPES.FETCH_SUBSCRIPTION_PACKAGES_FAILED,
                });
        })
        .catch((error) => {
            dispatch({
                    type: types.UPGRADE_ACTION_TYPES.FETCH_SUBSCRIPTION_PACKAGES_FAILED,
                payload: { message: 'Failed to fetch subscription packages' },
            });
            return null;
        });

};

export const toggleSalesGenerator = () => {
    return {
        type: types.UPGRADE_ACTION_TYPES.TOGGLE_SALES_GENERATOR,
    }
}

export const showUpgradeWarning = () => {
    return {
        type: types.UPGRADE_ACTION_TYPES.SHOW_UPGRADE_WARNING,
    }
}

export const hideUpgradeWarning = () => {
    return {
        type: types.UPGRADE_ACTION_TYPES.HIDE_UPGRADE_WARNING,
    }
}
   
export const storeCardReference = (paymentReferenceId, last4Digits) => async (dispatch) =>  {
    await fetch(uri + '/paymentreferences/' + paymentReferenceId, {
        method: "PATCH",
        headers: { 'Content-Type': 'application/json', 'Authorization':  store.getState().auth.token, 'x-cardDigits' : last4Digits},
        body: JSON.stringify({operationId: "StoreCardDigits"})
        })
    .then(response => {
        if (response.status >= 400 && response.status < 600) {
            dispatch({success:false, errorMessage:'We are currently experiencing issues. Sorry for the inconvenience.'})
        } else if (response?.data?.success == 'false') {
            dispatch({success:false, errorMessage:'Incorrect parameters for API call'})
        } else {
            dispatch({
                type: types.UPGRADE_ACTION_TYPES.STORE_CARD_DIGITS,
                payload: response.data
            })
        }
    }).catch((error) => {
         dispatch({
                type: types.UPGRADE_ACTION_TYPES.FETCH_FAILURE,
                payload: { message: 'Failed to add chat message' },
            });
    });
}

export const enableUpgrades = () => {
   return {
        type: types.UPGRADE_ACTION_TYPES.UPGRADE_SECTION_ENABLED,
    }
}

export const changeSubscription = (subscriptionPackage) => {
    return {
        type: types.UPGRADE_ACTION_TYPES.CHANGE_SUBSCRIPTION_PACKAGE,
        payload:  { subscriptionPackage : subscriptionPackage }
    }
}

export const showUpgradeModal = () => {
    return function(dispatch) {
        dispatch(types.toggleUpgradeModalVisibility(true))
    }
}

export const hideUpgradeModal = () => {
    return function(dispatch) {
        dispatch(types.toggleUpgradeModalVisibility(false))
    }
}

export const createPaymentReference = (paymentReference) => async (dispatch) =>  {
    postPaymentReference(paymentReference)
        .then(res => {
            if (res?.success) {
                dispatch({
                    type: types.UPGRADE_ACTION_TYPES.CREATE_PAYMENT_REFERENCE_SUCCESS ,
                    payload: res
                });
            } else {
                dispatch({
                    type: types.UPGRADE_ACTION_TYPES.CREATE_PAYMENT_REFERENCE_FAILED ,
                    payload: {...res, success:false, errorMessage: 'We are currently experiencing issues. Sorry for the inconvenience.'}
                });
            }
        })
        .catch(error => {
            dispatch({
                type: types.UPGRADE_ACTION_TYPES.CREATE_PAYMENT_REFERENCE_FAILED ,
                payload: {...error, success:false, errorMessage: 'We are currently experiencing issues. Sorry for the inconvenience.'}
            });
        })
}

export const fetchPaymentReference = (uniqueReference) => async (dispatch) =>  {
    getPaymentReference(uniqueReference)
        .then(res => {
            if (res != null && res.data)
                dispatch({
                        type: types.UPGRADE_ACTION_TYPES.GET_PAYMENT_REFERENCE_SUCCESS,
                        payload: res.data
                    });
            else 
                dispatch({
                    type: types.UPGRADE_ACTION_TYPES.FETCH_FAILURE ,
                    payload: {success:false, errorMessage: 'We are currently experiencing issues. Sorry for the inconvenience.'} });
        })
        .catch((error) => {
            dispatch({
                type: types.UPGRADE_ACTION_TYPES.FETCH_FAILURE ,
                payload: {success:false, errorMessage: 'We are currently experiencing issues. Sorry for the inconvenience.'} });

            return null;
        });
}

export const getUniqueReference = () => async (dispatch) =>  {
    const result = await fetch(uri + '/uuid', {
        method: "GET",
        headers: { 'Content-Type': 'application/json', 
            'Authorization': store.getState().auth.token, },
        })
    .then(response => {
        if (response.status >= 400 && response.status < 600)
            dispatch({
                type: types.UPGRADE_ACTION_TYPES.FETCH_FAILURE ,
                payload: {success:false, errorMessage: 'We are currently experiencing issues. Sorry for the inconvenience.'} });
        else
            return response.json()
    }).catch((error) => {
        dispatch({
            type: types.UPGRADE_ACTION_TYPES.FETCH_FAILURE ,
            payload: {...error, success:false, errorMessage: 'We are currently experiencing issues. Sorry for the inconvenience.'} });
    });
    dispatch({
        type: types.UPGRADE_ACTION_TYPES.GET_UUID ,
        payload: result?.data });
}


export const toQueryString = (params) => {
    return (
        "?" +
        Object.entries(params)
        .map(
            ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&")
    );
}
