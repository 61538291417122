import _ from './actionType';
import * as constants from './constants';
import moment from 'moment';
const proposalToRender = { 
  selectedLender: null,
  selectedLoanTermInYrs: null,

  // Step 3

  loanScenarios: [],
  selectedLoanProducts:[] ,
  amortizationTable: [],
}

const start = { 
  ...proposalToRender,

  currentDisplay: 'landingPage',

  // Step 1
  maxYears: 30,

  // Step 2
  loanProductRecords: [],
  mainProduct: undefined,

  // Select products table
  selectLoanProductFilters: null,
  salesSelectLoanProductsSort: null,
  maxLoanProductRecords: 0,
  selectLoanProductsPageSize: 3,
  selectLoanProductsDisplayPage: 1,

  // Filters
  selectLoanProductsFilters : Object.assign({  
    "loanPurpose": "any",
    "interestCategory": "any",
    "rateOption": "any",
    "favorites": false,
    "offSale": false,
  }),
  selectLoanProductsSort:"default",

  addMode: true,
  editableLoanAmount: {value: ''},
  reviewMode: false,
  selectedMenu : constants.SUMMARY_VIEW,
  error:null,
};


const initialState = { 
  
  currentDisplay: 'landingPage',

  prospectsPageSize: 4,
  prospectsCount: 0,
  prospectsDisplayPage: 1,
  prospects: [],
  prospectsFilter: null,

  activeSalesPageSize: 4,
  activeSalesCount: 0,
  activeSalesDisplayPage: 1,
  activeSales: [],
  activeSalesLookup: null,
  activeSalesLenderFilter:null,

  connectionCount: 0,
  jointApplicantCount: 0,
  firstHomeCount: 0,
  investorsCount: 0,
  refinanceCount: 0,
  selfEmployedCount: 0,
  noProposalsCount: 0,
  allCount: 0,

  lenderOptions: [],
  prospectInActiveSalesView: null,
  proposalInView: null,
  ...start,
  now: moment()
}

function translateProposalToState (proposal, lenderSelection) {
  const state = proposal && (!lenderSelection || lenderSelection._id === proposal.lender._id) ?  
    { 
      selectedLender: proposal.lender,
      selectedLoanTermInYrs: proposal.loanPackage.loanTermInYears,
      loanScenarios: proposal.loanPackage.loanProducts,    
      editableLoanAmount: {value:''},
      editModeAmortizationTable: [],
      requiredDocs : proposal.requiredDocs,
      addMode: false,
      error: null,
      } : {};

  if (state?.loanScenarios)
    Object.values(state.loanScenarios).map(loan => {
      if (!loan?._id) {
        loan["_id"] = new Date().getMilliseconds() + '_' + loan._id
      }
      if (proposal?.proposalDiscounts) {
          const disc = proposal.proposalDiscounts.find(d => {
            return d?.loanProductId == loan.loanProduct._id
          })
          if (disc)
            loan["brokersDiscount"] = disc?.discount?.homeLoanInterestRateDiscount
      }
    })
  return state;
}
function findMainProduct(products, scenarios) {
  var scenariosCopy = [].concat(scenarios)

  if (scenariosCopy && scenariosCopy.length > 0) {
    scenariosCopy.sort((a,b) => {
      return b.loanAmount.value - a.loanAmount.value
    })
    var mainScenario = scenariosCopy.shift()
    return products.find(p => p._id === mainScenario.loanProduct._id)
  } else
    return undefined
}
export default function sales(state = initialState, action) {
  //console.log(action)
  switch (action.type) {
    case _.changeModalDisplay:
      const viewMode = action.payload
      const proposal = viewMode === 'multiStep' ? 
        { ...proposalToRender } : 
        translateProposalToState(state.proposalInView, state.selectedLender)
      return {
        ...state,
        currentDisplay: viewMode ,
        reviewMode : state.reviewMode || viewMode === 'proposalView',
        ...proposal,
        now: moment(),
      }
    case _.changeLoanProposalMenu:
      return {
        ...state,
        selectedMenu : action.payload,
      }      
    case _.discardUpdatedProposal:
      return {
        ...state,
        ...translateProposalToState(state.proposalInView, state.selectedLender),
        now: moment(),
      }      
    case _.discardProposalInView:
      return {
        ...state,
        loanProductRecords: [],

        addMode: true,
        editableLoanAmount: {value: ''},
        reviewMode: false,

        lenderOptions: [],
        prospectInActiveSalesView: null,
        proposalInView: null,

        ...proposalToRender,
        now: moment(),
      }      
    case _.getLenders.success:
      return {
        ...state,
        lenderOptions: action.payload,
      }
    case _.chooseLender:
      return {
        ...state,
        selectedLender: action.payload,
        loanScenarios: [],
        loanProductRecords: [],
        selectLoanProductFilters: null,
        salesSelectLoanProductsSort: null,
        maxLoanProductRecords: 0,
        selectLoanProductsDisplayPage: 1,
        editableLoanAmount: {value: ''},
        addMode: true,
      }
    case _.chooseLoanTerm:
      return {
        ...state,
        selectedLoanTermInYrs: action.payload,
      }
    case _.setAddMode:
      return {
        ...state,
        addMode: !state.addMode
      }
    case _.setLoanAmount:
      return {
        ...state,
        editableLoanAmount: {value: action.payload}
      }
    case _.setBrokersDiscount:
      // Current scenarios and products
      var scenarios = []
      Object.values(state.loanScenarios).map(loan => {
        if (loan._id === action.payload._id) {
          //console.log(loan)
          loan["brokersDiscount"] = action.payload.brokersDiscount
          loan["offeredRate"] = Math.round((loan.loanProduct.ratesAndFees.interestRate - action.payload.brokersDiscount)*100)/100
        }
        scenarios.push(loan)
      })

      return {
        ...state,
        loanScenarios: scenarios,
      }
    case _.setLoanProduct:
      // Current scenarios and products
      var scenarios = []
      state.loanScenarios.map(e => {
        scenarios.push(e)
      })
      var products = []
      Object.values(state.selectedLoanProducts).map(v => {
        products.push(v)
      })

      if (action.payload?.remove) {
        // Remove product from current scenarios
        scenarios = scenarios.filter(loan => loan.loanProduct._id !== action.payload?.product?._id)
        products = products.filter(p => p._id !== action.payload?.product?._id)
        return {
          ...state,
          loanScenarios: scenarios,
          addMode: state.loanScenarios.length === 1,
          mainProduct: findMainProduct(products, scenarios),
        }
      } else {
        // Add to selected products (if not already there)
        if (!products.find(p => p._id === action.payload.product._id))
          products.push(action.payload.product)

        // Add to scenario
        var scenario =  {}
        scenario["loanProduct"] = action.payload.product
        scenario["loanAmount"] = action.payload.amount
        scenario["_id"] = Math.floor(Math.random() * 1000 * new Date().getMilliseconds()) + '_' + action.payload.product._id
        scenarios.push(scenario)
        return {
          ...state,
          loanScenarios: scenarios,
          editableLoanAmount: {value:''},
          mainProduct: findMainProduct(products, scenarios),
          addMode: false,
        }
      }
    case _.fetchProducts.success:
      return {
        ...state,
        loanProductRecords: action.payload?.records ? action.payload.records : [],
        selectLoanProductsDisplayPage: action.payload.page,
        selectLoanProductsFilters: action.payload.filters,
        selectLoanProductsSort: action.payload.sort,
      }
    case _.fetchProducts.fail:
        return {
            ...state,
            error: action.payload,
            loanProductRecords: [],
            selectLoanProductFilters: null,
            salesSelectLoanProductsSort: null,
            maxLoanProductRecords: 0,
            selectLoanProductsDisplayPage: 1,
        }
    case _.fetchProductCount.success:
      return {
        ...state,
        maxLoanProductRecords: action.payload
      }
    case _.fetchProductCount.fail:
      return {
        ...state,
        error: action.payload,
        loanProductRecords: [],
        selectLoanProductFilters: null,
        salesSelectLoanProductsSort: null,
        maxLoanProductRecords: 0,
        selectLoanProductsDisplayPage: 1,
      }
      
    case _.fetchProduct.success:
      const loanProduct = []
      loanProduct[action.payload._id] = action.payload
      return {
        ...state,
        selectedLoanProducts: {...state.selectedLoanProducts, ...loanProduct},
        now: moment(),
      }
    case _.fetchProspects.success:
      return {
        ...state,
        prospects: action.payload.data,
        prospectsDisplayPage: action.payload.page,
      }
    case _.fetchProspect.success:
      return {
        ...state,
        prospectInActiveSalesView: action.payload.data,
      }
    case _.renderProposal:
      return {
        ...state,
        proposalInView : action.payload,
      }
    case _.loadProposal.success:
      return {
        ...state,
        proposalInView : action.payload.data,
      }
    case _.fetchBorrowerProposal.success:
      return {
        ...state,
        proposalInView : action.payload.data !== undefined || action.payload.data.length > 0 ? action.payload.data.shift() : undefined,
      }
    case _.fetchProposals.success:
      return {
        ...state,
        activeSales: action.payload.data,
        activeSalesDisplayPage: action.payload.page,
      }
    case _.fetchProposalsCount.success:
      return {
        ...state,
        activeSalesCount: action.payload.data.count ,
      }      
    case _.fetchProposalsCount.fail:
      return {
        ...state,
      }
    case _.fetchProspectsCount.success:
      return {
        ...state,
        prospectsCount: state.prospectsFilter === null ? action.payload.data.prospectCount : state.prospectsCount,
        allCount: action.payload?.data ? action.payload.data.prospectCount : 0,
      }
    case _.fetchProspectBySearchTypeCount.selfEmployedSuccess:
      return {
        ...state,
        prospectsCount: state.prospectsFilter === 1  ? action.payload.data.prospectCount : state.prospectsCount,
        selfEmployedCount: action.payload?.data ? action.payload.data.prospectCount : 0,
      }
    case _.fetchProspectBySearchTypeCount.noProposalsSuccess:
      return {
        ...state,
        prospectsCount: state.prospectsFilter === 5  ? action.payload.data.prospectCount : state.prospectsCount,
        noProposalsCount: action.payload?.data ? action.payload.data.prospectCount : 0,
      }
    case _.fetchProspectBySearchTypeCount.refinanceSuccess:
      return {
        ...state,
        prospectsCount: state.prospectsFilter === 2  ? action.payload.data.prospectCount : state.prospectsCount,
        refinanceCount: action.payload?.data ? action.payload.data.prospectCount : 0,
      }
    case _.fetchProspectBySearchTypeCount.investorSuccess:
      return {
        ...state,
        prospectsCount: state.prospectsFilter === 3  ? action.payload.data.prospectCount : state.prospectsCount,
        investorsCount: action.payload?.data ? action.payload.data.prospectCount : 0,
      }
    case _.fetchProspectBySearchTypeCount.firstHomeSuccess:
      return {
        ...state,
        prospectsCount: state.prospectsFilter === 4  ? action.payload.data.prospectCount : state.prospectsCount,
        firstHomeCount: action.payload?.data ? action.payload.data.prospectCount : 0,
      }
    case _.fetchAmortization.success:
      const lScenarios = []
      state.loanScenarios.map(e => {
        lScenarios.push(e)
      })
      lScenarios[action.payload.scenarioIdx]["instalmentAmount"] = action.payload.data.instalmentAmount.value
      lScenarios[action.payload.scenarioIdx]["reducingBalances"] = action.payload.data.reducingBalances

      return {
        ...state,
        loanScenarios: lScenarios,
        milestoneYears: action.payload.data.milestoneYears,
      }

    case _.runAmortization.clear:
      return {
          ...state,
          amortizationTable: [] ,
        }

    case _.runAmortization.success:
      var amortizationTable = []
      amortizationTable[action.payload.index] = {
        loanProductId : action.payload.loanProductId,
        instalmentAmount : action.payload.data.instalmentAmount.value,
        interestRate: action.payload.interestRate,
        ...action.payload.data
      }
      amortizationTable["milestoneYears"] = action.payload.data.milestoneYears
      const newState = action.payload.editMode ? 
        {
          ...state,
          editModeAmortizationTable: {...state.editModeAmortizationTable, ...amortizationTable},
          now:moment(),
        } :
        {
          ...state,
          amortizationTable: {...state.amortizationTable, ...amortizationTable} ,
          now:moment(),
        }
        return newState

        case _.setProspectDisplay.all:
      return {
        ...state,
        prospectsCount: 0,
        prospectsDisplayPage: 1,
        prospects: [],
        prospectsFilter: null,
        }
    case _.setProspectDisplay.selfEmployed:
      return {
        ...state,
        prospectsCount: 0,
        prospectsDisplayPage: 1,
        prospects: [],
        prospectsFilter: 1
        }
    case _.setProspectDisplay.refinance:
      return {
        ...state,
        prospectsCount: 0,
        prospectsDisplayPage: 1,
        prospects: [],
        prospectsFilter: 2
        }
    case _.setProspectDisplay.investor:
      return {
        ...state,
        prospectsCount: 0,
        prospectsDisplayPage: 1,
        prospects: [],
        prospectsFilter: 3
        }
    case _.setProspectDisplay.firstHome:
      return {
        ...state,
        prospectsCount: 0,
        prospectsDisplayPage: 1,
        prospects: [],
        prospectsFilter: 4
        }
    case _.setProspectDisplay.noProposals:
      return {
        ...state,
        prospectsCount: 0,
        prospectsDisplayPage: 1,
        prospects: [],
        prospectsFilter: 5
        }

    case _.setProductTableFilter.applyFilter:
        return {
            ...state,
            selectLoanProductsFilters: action.payload
        }
    case _.setProductTableFilter.applySort:
        return {
            ...state,
            selectLoanProductsSort: action.payload
        }

    case _.setDocumentSelection.primaryApplicantDriverLicence:
      var primaryApplicantDocs = !state.primaryApplicantDriverLicence ? 
        state.primaryApplicantDocs.concat([{type:constants.ID_DOCS, description: constants.DRIVER_LICENCE_COPY_TEXT}]) :
        state.primaryApplicantDocs.filter(d => d.description !== constants.DRIVER_LICENCE_COPY_TEXT)
        return {
            ...state,
            primaryApplicantDriverLicence: !state.primaryApplicantDriverLicence,
            primaryApplicantDocs : primaryApplicantDocs ,
            selectIdDocs: false, deselectIdDocs: false,
        }
    case _.setDocumentSelection.primaryApplicantPassport:
      var primaryApplicantDocs = !state.primaryApplicantPassport ? 
        state.primaryApplicantDocs.concat([{type:constants.ID_DOCS, description: constants.PASSPORT_COPY_TEXT}]) :
        state.primaryApplicantDocs.filter(d => d.description !== constants.PASSPORT_COPY_TEXT)
        return {
            ...state,
            primaryApplicantPassport: !state.primaryApplicantPassport,
            primaryApplicantDocs : primaryApplicantDocs ,
            selectIdDocs: false, deselectIdDocs: false,
        }
    case _.setDocumentSelection.primaryApplicantMedicare:
      var primaryApplicantDocs = !state.primaryApplicantMedicare ? 
        state.primaryApplicantDocs.concat([{type:constants.ID_DOCS, description: constants.MEDICARE_TEXT}]) :
        state.primaryApplicantDocs.filter(d => d.description !== constants.MEDICARE_TEXT)
        return {
            ...state,
            primaryApplicantMedicare: !state.primaryApplicantMedicare,
            primaryApplicantDocs : primaryApplicantDocs ,
            selectIdDocs: false, deselectIdDocs: false,
        }
    case _.setDocumentSelection.primaryApplicantBirthCertificate:
      var primaryApplicantDocs = !state.primaryApplicantBirthCertificate ? 
        state.primaryApplicantDocs.concat([{type:constants.ID_DOCS, description: constants.BIRTH_CERTIFICATE_TEXT}]) :
        state.primaryApplicantDocs.filter(d => d.description !== constants.BIRTH_CERTIFICATE_TEXT)
        return {
            ...state,
            primaryApplicantBirthCertificate: !state.primaryApplicantBirthCertificate,
            primaryApplicantDocs : primaryApplicantDocs ,
            selectIdDocs: false, deselectIdDocs: false,
        }
    case _.setDocumentSelection.secondaryApplicantDriverLicence:
      var secondaryApplicantDocs = !state.secondaryApplicantDriverLicence ? 
        state.secondaryApplicantDocs.concat([{type:constants.ID_DOCS, description: constants.DRIVER_LICENCE_COPY_TEXT}]) :
        state.secondaryApplicantDocs.filter(d => d.description !== constants.DRIVER_LICENCE_COPY_TEXT)
        return {
            ...state,
            secondaryApplicantDriverLicence: !state.secondaryApplicantDriverLicence,
            secondaryApplicantDocs : secondaryApplicantDocs ,
            selectIdDocs: false, deselectIdDocs: false,
        }
    case _.setDocumentSelection.secondaryApplicantPassport:
      var secondaryApplicantDocs = !state.secondaryApplicantPassport ? 
        state.secondaryApplicantDocs.concat([{type:constants.ID_DOCS, description: constants.PASSPORT_COPY_TEXT}]) :
        state.secondaryApplicantDocs.filter(d => d.description !== constants.PASSPORT_COPY_TEXT)
        return {
            ...state,
            secondaryApplicantPassport: !state.secondaryApplicantPassport,
            secondaryApplicantDocs : secondaryApplicantDocs ,
            selectIdDocs: false, deselectIdDocs: false,
        }
    case _.setDocumentSelection.secondaryApplicantMedicare:
      var secondaryApplicantDocs = !state.secondaryApplicantMedicare ? 
        state.secondaryApplicantDocs.concat([{type:constants.ID_DOCS, description: constants.MEDICARE_TEXT}]) :
        state.secondaryApplicantDocs.filter(d => d.description !== constants.MEDICARE_TEXT)
        return {
            ...state,
            secondaryApplicantMedicare: !state.secondaryApplicantMedicare,
            secondaryApplicantDocs : secondaryApplicantDocs ,
            selectIdDocs: false, deselectIdDocs: false,
        }
    case _.setDocumentSelection.secondaryApplicantBirthCertificate:
      var secondaryApplicantDocs = !state.secondaryApplicantBirthCertificate ? 
        state.secondaryApplicantDocs.concat([{type:constants.ID_DOCS, description: constants.BIRTH_CERTIFICATE_TEXT}]) :
        state.secondaryApplicantDocs.filter(d => d.description !== constants.BIRTH_CERTIFICATE_TEXT)
        return {
            ...state,
            secondaryApplicantBirthCertificate: !state.secondaryApplicantBirthCertificate,
            secondaryApplicantDocs : secondaryApplicantDocs ,
            selectIdDocs: false, deselectIdDocs: false,
        }
    case _.setDocumentSelection.primaryApplicantBankStatements:
      var primaryApplicantDocs = !state.primaryApplicantBankStatements ? 
        state.primaryApplicantDocs.concat([{type:constants.BANK_DOCS, description: constants.BANK_AC_3_MNTHS_STMT_TEXT}]) :
        state.primaryApplicantDocs.filter(d => d.description !== constants.BANK_AC_3_MNTHS_STMT_TEXT)
        return {
            ...state,
            primaryApplicantBankStatements: !state.primaryApplicantBankStatements,
            primaryApplicantDocs : primaryApplicantDocs ,
            selectBankStmts: false, deselectBankStmts: false,
        }
    case _.setDocumentSelection.primaryApplicantCardStatements:
      var primaryApplicantDocs = !state.primaryApplicantBankStatements ? 
        state.primaryApplicantDocs.concat([{type:constants.BANK_DOCS, description: constants.CREDIT_CARD_3_MNTHS_STMT_TEXT}]) :
        state.primaryApplicantDocs.filter(d => d.description !== constants.CREDIT_CARD_3_MNTHS_STMT_TEXT)
        return {
            ...state,
            primaryApplicantCardStatements: !state.primaryApplicantCardStatements,
            primaryApplicantDocs : primaryApplicantDocs ,
            selectBankStmts: false, deselectBankStmts: false,
        }
    case _.setDocumentSelection.primaryApplicantHomeLoanStatements:
      var primaryApplicantDocs = !state.primaryApplicantHomeLoanStatements ? 
        state.primaryApplicantDocs.concat([{type:constants.BANK_DOCS, description: constants.HOME_LOAN_6_MNTHS_STMT_TEXT}]) :
        state.primaryApplicantDocs.filter(d => d.description !== constants.HOME_LOAN_6_MNTHS_STMT_TEXT)
        return {
            ...state,
            primaryApplicantHomeLoanStatements: !state.primaryApplicantHomeLoanStatements,
            primaryApplicantDocs : primaryApplicantDocs ,
            selectBankStmts: false, deselectBankStmts: false,
        }
    case _.setDocumentSelection.primaryApplicantPersonalLoanStatements:
      var primaryApplicantDocs = !state.primaryApplicantPersonalLoanStatements ? 
        state.primaryApplicantDocs.concat([{type:constants.BANK_DOCS, description: constants.PERSONAL_LOAN_3_MNTHS_STMT_TEXT}]) :
        state.primaryApplicantDocs.filter(d => d.description !== constants.PERSONAL_LOAN_3_MNTHS_STMT_TEXT)
        return {
            ...state,
            primaryApplicantPersonalLoanStatements: !state.primaryApplicantPersonalLoanStatements,
            primaryApplicantDocs : primaryApplicantDocs ,
            selectBankStmts: false, deselectBankStmts: false,
        }
    case _.setDocumentSelection.secondaryApplicantBankStatements:
      var secondaryApplicantDocs = !state.secondaryApplicantBankStatements ? 
        state.secondaryApplicantDocs.concat([{type:constants.BANK_DOCS, description: constants.BANK_AC_3_MNTHS_STMT_TEXT}]) :
        state.secondaryApplicantDocs.filter(d => d.description !== constants.BANK_AC_3_MNTHS_STMT_TEXT)
        return {
            ...state,
            secondaryApplicantBankStatements: !state.secondaryApplicantBankStatements,
            secondaryApplicantDocs : secondaryApplicantDocs ,
            selectBankStmts: false, deselectBankStmts: false,
        }
    case _.setDocumentSelection.secondaryApplicantCardStatements:
      var secondaryApplicantDocs = !state.secondaryApplicantCardStatements ? 
        state.secondaryApplicantDocs.concat([{type:constants.BANK_DOCS, description: constants.CREDIT_CARD_3_MNTHS_STMT_TEXT}]) :
        state.secondaryApplicantDocs.filter(d => d.description !== constants.CREDIT_CARD_3_MNTHS_STMT_TEXT)
        return {
            ...state,
            secondaryApplicantCardStatements: !state.secondaryApplicantCardStatements,
            secondaryApplicantDocs : secondaryApplicantDocs ,
            selectBankStmts: false, deselectBankStmts: false,
        }
    case _.setDocumentSelection.secondaryApplicantHomeLoanStatements:
      var secondaryApplicantDocs = !state.secondaryApplicantHomeLoanStatements ? 
        state.secondaryApplicantDocs.concat([{type:constants.BANK_DOCS, description: constants.HOME_LOAN_6_MNTHS_STMT_TEXT}]) :
        state.secondaryApplicantDocs.filter(d => d.description !== constants.HOME_LOAN_6_MNTHS_STMT_TEXT)
        return {
            ...state,
            secondaryApplicantHomeLoanStatements: !state.secondaryApplicantHomeLoanStatements,
            secondaryApplicantDocs : secondaryApplicantDocs ,
            selectBankStmts: false, deselectBankStmts: false,
        }
    case _.setDocumentSelection.secondaryApplicantPersonalLoanStatements:
      var secondaryApplicantDocs = !state.secondaryApplicantPersonalLoanStatements ? 
        state.secondaryApplicantDocs.concat([{type:constants.BANK_DOCS, description: constants.PERSONAL_LOAN_3_MNTHS_STMT_TEXT}]) :
        state.secondaryApplicantDocs.filter(d => d.description !== constants.PERSONAL_LOAN_3_MNTHS_STMT_TEXT)
        return {
            ...state,
            secondaryApplicantPersonalLoanStatements: !state.secondaryApplicantPersonalLoanStatements,
            secondaryApplicantDocs : secondaryApplicantDocs ,
            selectBankStmts: false, deselectBankStmts: false,
        }
    case _.setDocumentSelection.primaryApplicantPayslips:
      var primaryApplicantDocs = !state.primaryApplicantPayslips ? 
        state.primaryApplicantDocs.concat([{type: constants.INC_PROOF, description: constants.PAYSLIPS_TEXT}]) :
        state.primaryApplicantDocs.filter(d => d.description !== constants.PAYSLIPS_TEXT)
        return {
            ...state,
            primaryApplicantPayslips: !state.primaryApplicantPayslips,
            primaryApplicantDocs : primaryApplicantDocs ,
            selectIncomeDocs: false, deselectIncomeDocs: false,
        }
    case _.setDocumentSelection.primaryApplicantNOA:
      var primaryApplicantDocs = !state.primaryApplicantNOA ? 
        state.primaryApplicantDocs.concat([{type: constants.INC_PROOF, description: constants.NOA_TEXT}]) :
        state.primaryApplicantDocs.filter(d => d.description !== constants.NOA_TEXT)
        return {
            ...state,
            primaryApplicantNOA: !state.primaryApplicantNOA,
            primaryApplicantDocs : primaryApplicantDocs ,
            selectIncomeDocs: false, deselectIncomeDocs: false,
        }
    case _.setDocumentSelection.primaryApplicantTaxReturns:
      var primaryApplicantDocs = !state.primaryApplicantTaxReturns ? 
        state.primaryApplicantDocs.concat([{type: constants.INC_PROOF, description: constants.TAX_RETURNS_TEXT}]) :
        state.primaryApplicantDocs.filter(d => d.description !== constants.TAX_RETURNS_TEXT)
        return {
            ...state,
            primaryApplicantTaxReturns: !state.primaryApplicantTaxReturns,
            primaryApplicantDocs : primaryApplicantDocs ,
            selectIncomeDocs: false, deselectIncomeDocs: false,
        }
    case _.setDocumentSelection.primaryApplicantBAS:
      var primaryApplicantDocs = !state.primaryApplicantTaxReturns ? 
        state.primaryApplicantDocs.concat([{type: constants.INC_PROOF, description: constants.BAS_TEXT}]) :
        state.primaryApplicantDocs.filter(d => d.description !== constants.BAS_TEXT)
        return {
            ...state,
            primaryApplicantBAS: !state.primaryApplicantBAS,
            primaryApplicantDocs : primaryApplicantDocs ,
            selectIncomeDocs: false, deselectIncomeDocs: false,
        }
    case _.setDocumentSelection.secondaryApplicantPayslips:
      var secondaryApplicantDocs = !state.secondaryApplicantPayslips ? 
        state.secondaryApplicantDocs.concat([{type: constants.INC_PROOF, description: constants.PAYSLIPS_TEXT}]) :
        state.secondaryApplicantDocs.filter(d => d.description !== constants.PAYSLIPS_TEXT)
        return {
            ...state,
            secondaryApplicantPayslips: !state.secondaryApplicantPayslips,
            secondaryApplicantDocs : secondaryApplicantDocs ,
            selectIncomeDocs: false, deselectIncomeDocs: false,
        }
    case _.setDocumentSelection.secondaryApplicantNOA:
      var secondaryApplicantDocs = !state.secondaryApplicantNOA ? 
        state.secondaryApplicantDocs.concat([{type: constants.INC_PROOF, description: constants.NOA_TEXT}]) :
        state.secondaryApplicantDocs.filter(d => d.description !== constants.NOA_TEXT)
        return {
            ...state,
            secondaryApplicantNOA: !state.secondaryApplicantNOA,
            secondaryApplicantDocs : secondaryApplicantDocs ,
            selectIncomeDocs: false, deselectIncomeDocs: false,
        }
    case _.setDocumentSelection.secondaryApplicantTaxReturns:
      var secondaryApplicantDocs = !state.secondaryApplicantTaxReturns ? 
        state.secondaryApplicantDocs.concat([{type: constants.INC_PROOF, description: constants.TAX_RETURNS_TEXT}]) :
        state.secondaryApplicantDocs.filter(d => d.description !== constants.TAX_RETURNS_TEXT)
        return {
            ...state,
            secondaryApplicantTaxReturns: !state.secondaryApplicantTaxReturns,
            secondaryApplicantDocs : secondaryApplicantDocs ,
            selectIncomeDocs: false, deselectIncomeDocs: false,
        }
    case _.setDocumentSelection.secondaryApplicantBAS:
      var secondaryApplicantDocs = !state.secondaryApplicantBAS ? 
        state.secondaryApplicantDocs.concat([{type: constants.INC_PROOF, description: constants.BAS_TEXT}]) :
        state.secondaryApplicantDocs.filter(d => d.description !== constants.BAS_TEXT)
        return {
            ...state,
            secondaryApplicantBAS: !state.secondaryApplicantBAS,
            secondaryApplicantDocs : secondaryApplicantDocs ,
            selectIncomeDocs: false, deselectIncomeDocs: false,
        }
    case _.setDocumentSelection.rentalIncStmts:
      var otherDocs = !state.rentalIncStmts ? 
        state.otherDocs.concat([{type: constants.OTHER_DOCS, description: constants.RENTAL_INC_STMT_TEXT}]) :
        state.otherDocs.filter(d => d.description !== constants.RENTAL_INC_STMT_TEXT)
        return {
            ...state,
            rentalIncStmts: !state.rentalIncStmts,
            otherDocs : otherDocs ,
            selectIncomeDocs: false, deselectIncomeDocs: false,
        }
    case _.setDocumentSelection.companyTaxReturns:
      var otherDocs = !state.rentalIncStmts ? 
        state.otherDocs.concat([{type: constants.OTHER_DOCS, description: constants.CO_TAX_RET_TEXT}]) :
        state.otherDocs.filter(d => d.description !== constants.CO_TAX_RET_TEXT)
        return {
            ...state,
            companyTaxReturns: !state.companyTaxReturns,
            otherDocs : otherDocs ,
            selectIncomeDocs: false, deselectIncomeDocs: false,
        }
    case _.setDocumentSelection.carAndPersonalLoanStmt:
      var otherDocs = !state.rentalIncStmts ? 
        state.otherDocs.concat([{type: constants.OTHER_DOCS, description: constants.PERSONAL_CAR_LOAN_STMT_TEXT}]) :
        state.otherDocs.filter(d => d.description !== constants.PERSONAL_CAR_LOAN_STMT_TEXT)
        return {
            ...state,
            carAndPersonalLoanStmt: !state.carAndPersonalLoanStmt,
            otherDocs : otherDocs ,
            selectIncomeDocs: false, deselectIncomeDocs: false,
        }
    case _.setDocumentSelection.sharesInvestmentStmts:
      var otherDocs = !state.sharesInvestmentStmts ? 
        state.otherDocs.concat([{type: constants.OTHER_DOCS, description: constants.SHARES_ACC_STMT_TEXT}]) :
        state.otherDocs.filter(d => d.description !== constants.SHARES_ACC_STMT_TEXT)
        return {
            ...state,
            sharesInvestmentStmts: !state.sharesInvestmentStmts,
            otherDocs : otherDocs ,
            selectIncomeDocs: false, deselectIncomeDocs: false,
        }
    case _.setDocumentSelection.ratesNotice:
      var otherDocs = !state.ratesNotice ? 
        state.otherDocs.concat([{type: constants.OTHER_DOCS, description: constants.COUNCIL_RATES_TEXT}]) :
        state.otherDocs.filter(d => d.description !== constants.COUNCIL_RATES_TEXT)
        return {
            ...state,
            ratesNotice: !state.ratesNotice,
            otherDocs : otherDocs ,
            selectOtherDocs: false, deselectOtherDocs: false,
        }
    case _.setDocumentSelection.trustDeed:
      var otherDocs = !state.trustDeed ? 
        state.otherDocs.concat([{type: constants.OTHER_DOCS, description: constants.TRUST_DEED_TEXT}]) :
        state.otherDocs.filter(d => d.description !== constants.TRUST_DEED_TEXT)
        return {
            ...state,
            trustDeed: !state.trustDeed,
            otherDocs : otherDocs ,
            selectOtherDocs: false, deselectOtherDocs: false,
        }
    case _.setDocumentSelection.leaseAgreement:
      var otherDocs = !state.leaseAgreement ? 
        state.otherDocs.concat([{type: constants.OTHER_DOCS, description: constants.LEASE_AGREEMENT_TEXT}]) :
        state.otherDocs.filter(d => d.description !== constants.LEASE_AGREEMENT_TEXT)
        return {
            ...state,
            leaseAgreement: !state.leaseAgreement,
            otherDocs : otherDocs ,
            selectOtherDocs: false, deselectOtherDocs: false,
        }
    case _.setDocumentSelection.selectIdDocs:
      var primaryApplicantDocs = state.primaryApplicantDocs.filter(d => 
            d.description !== constants.DRIVER_LICENCE_COPY_TEXT &&
            d.description !== constants.PASSPORT_COPY_TEXT &&
            d.description !== constants.MEDICARE_TEXT &&
            d.description !== constants.BIRTH_CERTIFICATE_TEXT )
      primaryApplicantDocs = state.selectIdDocs ? primaryApplicantDocs :
        primaryApplicantDocs.concat([
          constants.DRIVER_LICENCE_COPY_TEXT, 
          constants.PASSPORT_COPY_TEXT, 
          constants.MEDICARE_TEXT, 
          constants.BIRTH_CERTIFICATE_TEXT].map(t => {
            return {type:constants.ID_DOCS,description:t}
          }))

      var secondaryApplicantDocs = state.secondaryApplicantDocs.filter(d => 
            d.description !== constants.DRIVER_LICENCE_COPY_TEXT &&
            d.description !== constants.PASSPORT_COPY_TEXT &&
            d.description !== constants.MEDICARE_TEXT &&
            d.description !== constants.BIRTH_CERTIFICATE_TEXT )
      secondaryApplicantDocs = state.selectIdDocs ? secondaryApplicantDocs :
        secondaryApplicantDocs.concat([
          constants.DRIVER_LICENCE_COPY_TEXT, 
          constants.PASSPORT_COPY_TEXT, 
          constants.MEDICARE_TEXT, 
          constants.BIRTH_CERTIFICATE_TEXT].map(t => {
            return {type:constants.ID_DOCS,description:t}
          }))
        return {
            ...state,
            selectIdDocs: !state.selectIdDocs,
            deselectIdDocs: false,
            primaryApplicantDriverLicence: !state.selectIdDocs,
            primaryApplicantPassport: !state.selectIdDocs,
            primaryApplicantMedicare: !state.selectIdDocs,
            primaryApplicantBirthCertificate: !state.selectIdDocs,
            secondaryApplicantDriverLicence:  !state.selectIdDocs,
            secondaryApplicantPassport: !state.selectIdDocs,
            secondaryApplicantMedicare: !state.selectIdDocs,
            secondaryApplicantBirthCertificate: !state.selectIdDocs,
            primaryApplicantDocs : primaryApplicantDocs,
            secondaryApplicantDocs : secondaryApplicantDocs,
        }
    case _.setDocumentSelection.deselectIdDocs:
        return {
            ...state,
            selectIdDocs: false,
            deselectIdDocs: !state.deselectIdDocs,
            primaryApplicantDriverLicence: state.deselectIdDocs,
            primaryApplicantPassport: state.deselectIdDocs,
            primaryApplicantMedicare: state.deselectIdDocs,
            primaryApplicantBirthCertificate: state.deselectIdDocs,
            secondaryApplicantDriverLicence: state.deselectIdDocs,
            secondaryApplicantPassport: state.deselectIdDocs,
            secondaryApplicantMedicare: state.deselectIdDocs,
            secondaryApplicantBirthCertificate: state.deselectIdDocs,
            primaryApplicantDocs : !state.deselectIdDocs ? state.primaryApplicantDocs.filter(d => 
              d.description !== constants.DRIVER_LICENCE_COPY_TEXT &&
              d.description !== constants.PASSPORT_COPY_TEXT &&
              d.description !== constants.MEDICARE_TEXT &&
              d.description !== constants.BIRTH_CERTIFICATE_TEXT ) : state.primaryApplicantDocs,
            secondaryApplicantDocs : !state.deselectIdDocs ? state.secondaryApplicantDocs.filter(d => 
              d.description !== constants.DRIVER_LICENCE_COPY_TEXT &&
              d.description !== constants.PASSPORT_COPY_TEXT &&
              d.description !== constants.MEDICARE_TEXT &&
              d.description !== constants.BIRTH_CERTIFICATE_TEXT ) : state.secondaryApplicantDocs,
        }
    case _.setDocumentSelection.selectBankStmts:
      var primaryApplicantDocs = state.primaryApplicantDocs.filter(d => 
            d.description !== constants.BANK_AC_3_MNTHS_STMT_TEXT &&
            d.description !== constants.CREDIT_CARD_3_MNTHS_STMT_TEXT &&
            d.description !== constants.HOME_LOAN_6_MNTHS_STMT_TEXT &&
            d.description !== constants.PERSONAL_LOAN_3_MNTHS_STMT_TEXT )
      primaryApplicantDocs = state.selectBankStmts ? primaryApplicantDocs :
        primaryApplicantDocs.concat([
          constants.BANK_AC_3_MNTHS_STMT_TEXT, 
          constants.CREDIT_CARD_3_MNTHS_STMT_TEXT, 
          constants.HOME_LOAN_6_MNTHS_STMT_TEXT, 
          constants.PERSONAL_LOAN_3_MNTHS_STMT_TEXT].map(t => {
            return {type:constants.BANK_DOCS,description:t}
          }))

      var secondaryApplicantDocs = state.secondaryApplicantDocs.filter(d => 
            d.description !== constants.BANK_AC_3_MNTHS_STMT_TEXT &&
            d.description !== constants.CREDIT_CARD_3_MNTHS_STMT_TEXT &&
            d.description !== constants.HOME_LOAN_6_MNTHS_STMT_TEXT &&
            d.description !== constants.PERSONAL_LOAN_3_MNTHS_STMT_TEXT )
      secondaryApplicantDocs = state.selectBankStmts ? secondaryApplicantDocs :
        secondaryApplicantDocs.concat([
          constants.BANK_AC_3_MNTHS_STMT_TEXT, 
          constants.CREDIT_CARD_3_MNTHS_STMT_TEXT, 
          constants.HOME_LOAN_6_MNTHS_STMT_TEXT, 
          constants.PERSONAL_LOAN_3_MNTHS_STMT_TEXT].map(t => {
            return {type:constants.BANK_DOCS,description:t}
          }))
        return {
            ...state,
            selectBankStmts: !state.selectBankStmts,
            deselectBankStmts: false,
            primaryApplicantBankStatements: !state.selectBankStmts,
            primaryApplicantCardStatements: !state.selectBankStmts,
            primaryApplicantHomeLoanStatements: !state.selectBankStmts,
            primaryApplicantPersonalLoanStatements: !state.selectBankStmts,
            secondaryApplicantBankStatements: !state.selectBankStmts,
            secondaryApplicantCardStatements: !state.selectBankStmts,
            secondaryApplicantHomeLoanStatements: !state.selectBankStmts,
            secondaryApplicantPersonalLoanStatements: !state.selectBankStmts,
            primaryApplicantDocs : primaryApplicantDocs,
            secondaryApplicantDocs : secondaryApplicantDocs,
        }
    case _.setDocumentSelection.deselectBankStmts:
        return {
            ...state,
            selectBankStmts: false,
            deselectBankStmts: !state.deselectBankStmts,
            primaryApplicantBankStatements: state.deselectBankStmts,
            primaryApplicantCardStatements: state.deselectBankStmts,
            primaryApplicantHomeLoanStatements: state.deselectBankStmts,
            primaryApplicantPersonalLoanStatements: state.deselectBankStmts,
            secondaryApplicantBankStatements: state.deselectBankStmts,
            secondaryApplicantCardStatements: state.deselectBankStmts,
            secondaryApplicantHomeLoanStatements: state.deselectBankStmts,
            secondaryApplicantPersonalLoanStatements: state.deselectBankStmts,
            primaryApplicantDocs : !state.deselectBankStmts ? state.primaryApplicantDocs.filter(d => 
              d.description !== constants.BANK_AC_3_MNTHS_STMT_TEXT &&
              d.description !== constants.CREDIT_CARD_3_MNTHS_STMT_TEXT &&
              d.description !== constants.HOME_LOAN_6_MNTHS_STMT_TEXT &&
              d.description !== constants.PERSONAL_LOAN_3_MNTHS_STMT_TEXT ) : state.primaryApplicantDocs,
            secondaryApplicantDocs : !state.deselectBankStmts ? state.secondaryApplicantDocs.filter(d => 
              d.description !== constants.BANK_AC_3_MNTHS_STMT_TEXT &&
              d.description !== constants.CREDIT_CARD_3_MNTHS_STMT_TEXT &&
              d.description !== constants.HOME_LOAN_6_MNTHS_STMT_TEXT &&
              d.description !== constants.PERSONAL_LOAN_3_MNTHS_STMT_TEXT ) : state.secondaryApplicantDocs,
        }
    case _.setDocumentSelection.selectIncomeDocs:
      var primaryApplicantDocs = state.primaryApplicantDocs.filter(d => 
            d.description !== constants.PAYSLIPS_TEXT &&
            d.description !== constants.NOA_TEXT &&
            d.description !== constants.TAX_RETURNS_TEXT &&
            d.description !== constants.BAS_TEXT )
      primaryApplicantDocs = state.selectBankStmts ? primaryApplicantDocs :
        primaryApplicantDocs.concat([
          constants.PAYSLIPS_TEXT, 
          constants.NOA_TEXT, 
          constants.TAX_RETURNS_TEXT, 
          constants.BAS_TEXT].map(t => {
            return {type: constants.INC_PROOF,description:t}
          }))
      var secondaryApplicantDocs = state.secondaryApplicantDocs.filter(d => 
            d.description !== constants.PAYSLIPS_TEXT &&
            d.description !== constants.NOA_TEXT &&
            d.description !== constants.TAX_RETURNS_TEXT &&
            d.description !== constants.BAS_TEXT )
      secondaryApplicantDocs = state.selectIncomeDocs ? secondaryApplicantDocs :
        secondaryApplicantDocs.concat([
          constants.PAYSLIPS_TEXT, 
          constants.NOA_TEXT, 
          constants.TAX_RETURNS_TEXT, 
          constants.BAS_TEXT].map(t => {
            return {type: constants.INC_PROOF,description:t}
          }))
      var otherDocs = state.otherDocs.filter(d => 
            d.description !== constants.RENTAL_INC_STMT_TEXT &&
            d.description !== constants.SHARES_ACC_STMT_TEXT &&
            d.description !== constants.CO_TAX_RET_TEXT &&
            d.description !== constants.PERSONAL_CAR_LOAN_STMT_TEXT &&
            d.description !== constants.COUNCIL_RATES_TEXT &&
            d.description !== constants.TRUST_DEED_TEXT &&
            d.description !== constants.LEASE_AGREEMENT_TEXT )
      otherDocs = state.selectOtherDocs ? otherDocs :
        otherDocs.concat([
          constants.RENTAL_INC_STMT_TEXT, 
          constants.SHARES_ACC_STMT_TEXT, 
          constants.CO_TAX_RET_TEXT, 
          constants.PERSONAL_CAR_LOAN_STMT_TEXT].map(t => {
            return {type: constants.OTHER_DOCS,description:t}
          }))      
        return {
            ...state,
            selectIncomeDocs: !state.selectIncomeDocs,
            deselectIncomeDocs: false,
            primaryApplicantPayslips: !state.selectIncomeDocs,
            primaryApplicantNOA: !state.selectIncomeDocs,
            primaryApplicantTaxReturns: !state.selectIncomeDocs,
            primaryApplicantBAS: !state.selectIncomeDocs,
            secondaryApplicantPayslips: !state.selectIncomeDocs,
            secondaryApplicantNOA: !state.selectIncomeDocs,
            secondaryApplicantTaxReturns: !state.selectIncomeDocs,
            secondaryApplicantBAS: !state.selectIncomeDocs,
            rentalIncStmts: !state.selectIncomeDocs,
            companyTaxReturns: !state.selectIncomeDocs,
            carAndPersonalLoanStmt: !state.selectIncomeDocs,
            sharesInvestmentStmts: !state.selectIncomeDocs,
            primaryApplicantDocs : primaryApplicantDocs,
            secondaryApplicantDocs : secondaryApplicantDocs,
            otherDocs : otherDocs,
        }
    case _.setDocumentSelection.deselectIncomeDocs:
        return {
            ...state,
            selectIncomeDocs: false,
            deselectIncomeDocs: !state.deselectIncomeDocs,
            primaryApplicantPayslips: state.deselectIncomeDocs,
            primaryApplicantNOA: state.deselectIncomeDocs,
            primaryApplicantTaxReturns: state.deselectIncomeDocs,
            primaryApplicantBAS: state.deselectIncomeDocs,
            secondaryApplicantPayslips: state.deselectIncomeDocs,
            secondaryApplicantNOA: state.deselectIncomeDocs,
            secondaryApplicantTaxReturns: state.deselectIncomeDocs,
            secondaryApplicantBAS: state.deselectIncomeDocs,
            rentalIncStmts: state.deselectIncomeDocs,
            companyTaxReturns: state.deselectIncomeDocs,
            carAndPersonalLoanStmt: state.deselectIncomeDocs,
            sharesInvestmentStmts: state.deselectIncomeDocs,
            primaryApplicantDocs : !state.deselectIncomeDocs ? state.primaryApplicantDocs.filter(d => 
              d.description !== constants.PAYSLIPS_TEXT &&
              d.description !== constants.NOA_TEXT &&
              d.description !== constants.TAX_RETURNS_TEXT &&
              d.description !== constants.BAS_TEXT ) : state.primaryApplicantDocs,
            secondaryApplicantDocs : !state.deselectIncomeDocs ? state.secondaryApplicantDocs.filter(d => 
              d.description !== constants.PAYSLIPS_TEXT &&
              d.description !== constants.NOA_TEXT &&
              d.description !== constants.TAX_RETURNS_TEXT &&
              d.description !== constants.BAS_TEXT ) : state.secondaryApplicantDocs,
            otherDocs : !state.deselectIncomeDocs ? state.otherDocs.filter(d => 
              d.description !== constants.RENTAL_INC_STMT_TEXT &&
              d.description !== constants.SHARES_ACC_STMT_TEXT &&
              d.description !== constants.CO_TAX_RET_TEXT &&
              d.description !== constants.PERSONAL_CAR_LOAN_STMT_TEXT ) : state.otherDocs,
        }
    case _.setDocumentSelection.selectOtherDocs:
      var otherDocs = state.otherDocs.filter(d => 
            d.description !== constants.COUNCIL_RATES_TEXT &&
            d.description !== constants.TRUST_DEED_TEXT &&
            d.description !== constants.LEASE_AGREEMENT_TEXT )
      otherDocs = state.selectOtherDocs ? otherDocs :
        otherDocs.concat([
          constants.COUNCIL_RATES_TEXT, 
          constants.TRUST_DEED_TEXT, 
          constants.LEASE_AGREEMENT_TEXT].map(t => {
            return {type: constants.OTHER_DOCS,description:t}
          }))
        return {
            ...state,
            selectOtherDocs: !state.selectOtherDocs,
            deselectOtherDocs: false,
            ratesNotice: !state.selectOtherDocs,
            trustDeed: !state.selectOtherDocs,
            leaseAgreement: !state.selectOtherDocs,
            otherDocs : otherDocs,
        }
    case _.setDocumentSelection.deselectOtherDocs:
        return {
            ...state,
            selectOtherDocs: false,
            deselectOtherDocs: !state.deselectOtherDocs,
            ratesNotice: state.deselectOtherDocs,
            trustDeed: state.deselectOtherDocs,
            leaseAgreement: state.deselectOtherDocs,
            otherDocs : !state.deselectOtherDocs ? state.otherDocs.filter(d => 
              d.description !== constants.COUNCIL_RATES_TEXT &&
              d.description !== constants.TRUST_DEED_TEXT &&
              d.description !== constants.LEASE_AGREEMENT_TEXT ) : state.otherDocs,
        }
    case _.runAmortization.fail:
    case _.fetchProspectBySearchTypeCount.firstHomeFail:
    case _.fetchProspectBySearchTypeCount.investorFail:
    case _.fetchProspectBySearchTypeCount.noProposalsFail:
    case _.fetchProspectBySearchTypeCount.refinanceFail:
    case _.fetchProspectBySearchTypeCount.selfEmployedFail:
    case _.fetchProspectsCount.fail:
    case _.fetchProposals.fail:
    case _.fetchProspect.fail:
    case _.loadProposal.fail:
    case _.fetchProspects.fail:
    case _.fetchProduct.fail:
    case _.fetchBorrowerProposal.fail:
    case _.getLenders.fail:
      return {
        ...state,
        error: action?.payload,
      }

    case _.clearErrors:
      return {
          ...state,
          error: null
      }
    case _.close:
      return {
          ...state,
          ...start
        }
    default:
      return state;
  }
}