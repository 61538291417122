import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, } from "react-redux";
import { updateMobileNumber } from '../../../../store/brokerAgent/actions';

function SaveAgentButton(props) {
    const dispatch = useDispatch()
    return ( 
        <div class="d-flex justify-content-center align-content-top">
            <Button 
            type="submit"
            style={{visibility:"visible"}}
            onClick={(e) => {
                e.preventDefault();
                dispatch(updateMobileNumber(props))
            }}
            >Save</Button>
        </div>
     );
}

export default SaveAgentButton;