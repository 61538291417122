import { PROSPECTS_ACTION_TYPES } from "./actionTypes";
import { formatResponseToList, sortByTimestamp } from "./timestampFormatting";
import getRenderableProspects from "./renderProspectsList";

export const initialState = {
    loading: false,
    post: 'N/A',
    error: '',
    recentProspectsList: 'N/A',
    prospectsRenderElement: 'N/A'
}


export default function recentProspectsReducer(state = initialState, action) {
    switch (action.type) {
        case PROSPECTS_ACTION_TYPES.FETCH_LOADING:
            return {
                ...state.dashboardProspects,
                loading: true
            }
        case PROSPECTS_ACTION_TYPES.FETCH_SUCCESS:
            return {
                loading: false,
                post: action.payload,
                error: '',
                recentProspectsList: sortByTimestamp(action.payload),
                prospectsRenderElement: getRenderableProspects(action.payload, 5) 
            }
        case PROSPECTS_ACTION_TYPES.FETCH_FAILURE:
            return {
                loading: false,
                post: 'N/A',
                error: "Recent prospects could not be retrieved.",
                recentProspectsList: 'N/A',
                prospectsRenderElement: 'N/A'
            }
        default:
            return state
    }
}
  