import _ from "./actionType";
import { getLenders, getProducts, getProductsMetadata } from "../request";


export const showProduct = (product) =>  {
  return {
      type: _.productActions.show,
      payload: product
    };
}

export const changePageSize = (pagesize) =>  {
  return {
      type: _.fetchProductsActions.changepagesize,
      payload: pagesize
    };
}

export const applySort = (sort) =>  {
  return {
      type: _.fetchProductsActions.applysortorder,
      payload: sort,
    };
}

export const applyFilters = (filters) =>  {
  return {
      type: _.fetchProductsActions.applyfilters,
      payload: filters,
    };
}

export const fetchLenders = () => async (dispatch) => {
  getLenders()
    .then(res => {
      //console.log(res)
      if (res != null) {
        dispatch({
          type: _.fetchLenderOptions.success,
          payload: res.data,
          });
        }
    })
    .catch((error) => {
      dispatch({
        type: _.fetchLenderOptions.failed,
        payload: error ,
      });
    return null;	  						  	
    });
};

export const saveLender = (lenderOption) =>  {
  return {
      type: _.saveOptions.saveLender,
      payload: lenderOption
    };
}

export const fetchProducts = (lenderName, filters, sort, page, pagesize) => async (dispatch) => {
  getProducts(lenderName, filters, sort, page, pagesize)
    .then(res => {
      if (res != null) {
        dispatch({
          type: _.fetchProductsActions.success,
          payload: {records: res.data, page: page},
          });
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: _.fetchProductsActions.failed,
        payload: error ,
      });
      return null;
    });
};

export const fetchProductsCount = (lenderName, filters) => async (dispatch) => {
  getProductsMetadata(lenderName, filters)
    .then(res => {
      if (res != null) {
        dispatch({
          type: _.fetchProductsActions.countsuccess,
          payload: res.data,
          });
      }
    })
    .catch((error) => {
      dispatch({
        type: _.fetchProductsActions.countfailed,
        payload: error ,
      });
    return null;	  						  	
    });
};

export const actions = { fetchLenders, saveLender, fetchProducts, fetchProductsCount, applyFilters, changePageSize, applySort };