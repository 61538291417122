import React from 'react';
import classNames from  'classnames';

class ErrorModal extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var errorMsgClass = classNames({
        '': this.props.error == null,
        'alert alert-danger regionError': this.props.error != null,
        });
        return (
            <div>
            <div id="modal-errors" class="modal fade show" role="dialog" tabindex="-1" style={{display: "block", borderWidth:1, borderColor:"#00b4f1"}} >
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h4 class="modal-title">Oops!</h4><button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" 
                            onClick={this.props?.onClose ? this.props.onClose() : () => window.open("/","_self")} ></button>
                        </div>
                        <div class="modal-body">
                            <div class="mb-3">
                              <div className={errorMsgClass} role="alert" >
                              {this.props.error?.displayMessage ? 
                              <span><strong>{this.props.error.displayMessage}</strong><br/></span> : 
                              <span><strong>We're sorry we seem to have a problem contacting out server</strong><br/>Please try again and if the issue persists contact our support team to report the error<br/></span>
                              }
                              </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>              
            </div>
        );
    }
}

export default ErrorModal;