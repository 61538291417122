import {  } from "react-bootstrap";
import React from "react";
import CompanyDetails from  '../../Profile/multistepForm/companyDetails';

const AsicDetails = (props) => {

  return (
    <>
        <CompanyDetails {...props} />
    </>
);
};

export default AsicDetails;
