import React, { Component } from 'react';
import { connect } from "react-redux";
import { AsyncTypeahead, Menu, MenuItem  } from "react-bootstrap-typeahead";
import ErrorContainer from '../../common/ErrorContainer';
import { fetchLenders, chooseLender, fetchProducts, fetchProductCount } from "../../../store/sales/actions";

class LenderAutofill extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        this.props.fetchLenders()
        if (this.props.selectedLender && !this.props.selectedLoanProducts) {
            this.props.fetchProductCount(this.props.selectedLender.name,null)
            this.props.fetchProducts(this.props.selectedLender.name,null,null,1,999);
        }
    }

    render () {
        const filterBy = () => true;
    	return (
          <div>
             <div class="input-group">
                <div class="form-row col-12">
                    <ErrorContainer error={this.props?.error}>
                    <div class="vstack gap-0">
                        <div class="input-group"><span class="input-group-text">Choose lender</span>
                        <AsyncTypeahead
                            onInputChange={e => {
                                this.setState({lenderPrefix : e})
                            }}
                            onChange={e => {
                                var lender = e?.length && e.length > 0 ? e[0] : null;
                                if (lender) {
                                    this.props.chooseLender(lender);
                                    this.props.fetchProducts(lender.name,null,null,1,3);
                                    this.props.fetchProductCount(lender.name,null)
                                }
                                this.setState({lenderPrefix : undefined})
                            }}
                            placeholder={this.props?.selectedLender?.name}
                            filterBy={l => {
                                return this.state?.lenderPrefix ? l.name.toUpperCase().startsWith(this.state.lenderPrefix.toUpperCase()) : true
                            }}
                            id="async-lender-select"
                            labelKey={option => {
                                return option === null ? '' : `${option.name}` }}
                            minLength={0}
                            options={this.props?.lenderOptions}
                            renderMenu={(results, menuProps) => (
                                <Menu {...menuProps}  >
                                {results.map((result, index) => (
                                    <MenuItem option={result} position={index} >
                                        {result.name}
                                    </MenuItem>
                                ))}
                                </Menu>
                            )}
                        />                        
                        </div>
                    </div>                        
                    </ErrorContainer>
                </div>
                </div>
            </div> 
      );
  }
}

const mapStateToProps = ({ sales }) => {
  return { 
        lenderOptions: sales.lenderOptions,
        selectedLender: sales.selectedLender,
        filters: sales.selectLoanProductsFilters,
        sort: sales.selectLoanProductsSort,
        selectedLoanProducts : sales.selectedLoanProducts,
    };
};

const mapDispatchToProps = (dispatch)=> {
    return {
        fetchLenders: (lookup) => dispatch(fetchLenders(lookup)),
        chooseLender: (lender) => dispatch(chooseLender(lender)),
        fetchProducts: (lenderName, filters, sort, page, pagesize) => dispatch(fetchProducts(lenderName, filters, sort, page, pagesize)),
        fetchProductCount: (lenderName, filters) => dispatch(fetchProductCount(lenderName, filters)),
    };
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(LenderAutofill);
