export const PROSPECTS_ACTION_TYPES = {
    FETCH_LOADING: 'prospectsFetchLoading',
    FETCH_SUCCESS: 'prospectsFetchSuccess',
    FETCH_FAILURE: 'prospectsFetchFailure'
}

export default function getActionType(success) {
    const type = success? PROSPECTS_ACTION_TYPES.FETCH_SUCCESS : PROSPECTS_ACTION_TYPES.FETCH_ERROR
    return type
}

export const fetchLoading = () => {
    return {
        type: PROSPECTS_ACTION_TYPES.FETCH_LOADING
    }
}

export const fetchSuccess = prospectsPost => {
    return {
        type: PROSPECTS_ACTION_TYPES.FETCH_SUCCESS,
        payload: prospectsPost
    }
}

export const fetchFailure = error => {
    return {
        type: PROSPECTS_ACTION_TYPES.FETCH_FAILURE,
        payload: error
    }
}