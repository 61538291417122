export const PROPOSAL_ACTION_TYPES = {
    FETCH_LOADING: 'proposalFetchLoading',
    FETCH_SUCCESS: 'proposalFetchSuccess',
    FETCH_FAILURE: 'proposalFetchFailure'
}

export default function getActionType(success) {
    const type = success? PROPOSAL_ACTION_TYPES.FETCH_SUCCESS : PROPOSAL_ACTION_TYPES.FETCH_ERROR
    return type
}

export const fetchLoading = () => {
    return {
        type: PROPOSAL_ACTION_TYPES.FETCH_LOADING
    }
}

export const fetchSuccess = proposalsPost => {
    return {
        type: PROPOSAL_ACTION_TYPES.FETCH_SUCCESS,
        payload: proposalsPost
    }
}

export const fetchFailure = error => {
    return {
        type: PROPOSAL_ACTION_TYPES.FETCH_FAILURE,
        payload: error
    }
}