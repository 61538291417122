import React, {Component} from 'react';
import {connect} from 'react-redux';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import classNames from  'classnames';
import { countDisplay, buttonClass, fetchNoProposalsCount, toggleProspectDisplay, fetchProspects, searchTypeFromFilter } from "../../../store/sales/actions";

const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);


const _filter = 5

class NoProposalButton extends Component {
    constructor(props) {
        super(props)
    }

	componentDidMount() {
		this.props.fetchCount()
	}

    render() {
        return (
        <>
        <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Fresh prospects'})}  >
        <div class="text-center d-flex flex-column justify-content-center align-items-center py-3">
            <div class={ this.props.buttonClass(this.props.prospectsFilter === _filter) } 
                onClick={() => {
                    this.props.fetchCount()
                    this.props.fetchProspects(1, this.props.prospectsPageSize, searchTypeFromFilter[_filter])
                    this.props.toggleProspectDisplay(_filter)
                }}>
                <svg class="bi bi-send-plus" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855a.75.75 0 0 0-.124 1.329l4.995 3.178 1.531 2.406a.5.5 0 0 0 .844-.536L6.637 10.07l7.494-7.494-1.895 4.738a.5.5 0 1 0 .928.372l2.8-7Zm-2.54 1.183L5.93 9.363 1.591 6.602l11.833-4.733ZM16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-3.5-2a.5.5 0 0 1 .5.5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 .5-.5Z"></path>
                </svg></div>
            <div class="px-3">
                <h4 class="fw-bold mb-0">{this.props.countDisplay(this.props.noProposalsCount)}</h4>
            </div>
        </div>
        </OverlayTrigger>
        </>
        )
    }
}


const mapStateToProps = state => {
    return {
        ...state.sales
    }
}

const mapDispatchToProps = dispatch => {
    return {
        countDisplay: (count) => countDisplay(count),
        buttonClass: (dark) => buttonClass(dark),
        fetchCount: () => dispatch(fetchNoProposalsCount()),
        fetchProspects: (page, pagesize, searchType) => dispatch(fetchProspects(page, pagesize, searchType)),
        toggleProspectDisplay: (filter) => dispatch(toggleProspectDisplay(filter)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NoProposalButton);