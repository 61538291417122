const chooseLoanTerm = 'sales/editLender/chooseLoanTerm'
const setupFromProposal = 'sales/editLender/setupFromProposal'
const getLenders = { success: 'sales/editLender/getLenders/success', fail: 'sales/editLender/getLenders/fail' };
const chooseLender = 'sales/editLender/chooseLender'
const fetchProducts = {success: 'sales/editLender/fetchProducts/success', fail:'sales/editLender/fetchProducts/fail'};
const fetchProductCount = {success: 'sales/editLender/fetchProductCount/success', fail:'sales/editLender/fetchProductCount/fail'};

export default {
  chooseLoanTerm,
  setupFromProposal,
  getLenders,
  chooseLender,
  fetchProducts,
  fetchProductCount,
};
