import React, { Component } from 'react';
import { connect } from "react-redux";
import { applyFilters } from "../../../../store/loanProducts/actions";
import Accordion from 'react-bootstrap/Accordion';


class ProductFilters extends Component {
    constructor(props) {
        super(props);
    }

    render () {
    	return (
            <>
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Product search</Accordion.Header>
                            <Accordion.Body>

                            <div class="card bg-dark">
                                <div class="card-body">
                                <div class="vstack gap-1">
                                    <div class="hstack gap-4 d-xl-flex justify-content-between align-items-xl-start">
                                        <div class="vstack gap-1">
                                            <h4>Loan purpose</h4>
                                            <div>
                                                <div class="form-check"><input class="form-check-input" type="radio" id="formCheck-7" name="loanPurpose" 
                                                    checked={this.props.filters.loanPurpose === "Residential"} onChange={ e => this.props.filter({...this.props.filters, loanPurpose:e.target.value })}
                                                    value="Residential"/>
                                                    <label class="form-check-label" for="formCheck-7">Residential</label></div>
                                                <div class="form-check"><input class="form-check-input" type="radio" id="formCheck-1" name="loanPurpose" 
                                                    checked={this.props.filters.loanPurpose === "Investor"} onChange={ e => this.props.filter({...this.props.filters, loanPurpose:e.target.value })}
                                                    value="Investor" />
                                                    <label class="form-check-label" for="formCheck-1">Investment</label></div>
                                                <div class="form-check"><input class="form-check-input" type="radio" id="formCheck-8" name="loanPurpose" 
                                                    checked={this.props.filters.loanPurpose === "any"} onChange={ e => this.props.filter({...this.props.filters, loanPurpose:e.target.value })}
                                                    value="any" />
                                                    <label class="form-check-label" for="formCheck-8">Any</label></div>
                                            </div>
                                        </div>
                                        <div class="vstack gap-1">
                                            <h4>Interest category</h4>
                                            <div>
                                                <div class="form-check"><input class="form-check-input" type="radio" id="formCheck-9" name="interestCategory" 
                                                    checked={this.props.filters.interestCategory === "P&I"} onChange={ e => this.props.filter({...this.props.filters, interestCategory:e.target.value })}
                                                    value="P&I"/>
                                                    <label class="form-check-label" for="formCheck-9">Principal and Interest</label></div>
                                                <div class="form-check"><input class="form-check-input" type="radio" id="formCheck-2" name="interestCategory" 
                                                    checked={this.props.filters.interestCategory === "IO"} onChange={ e => this.props.filter({...this.props.filters, interestCategory:e.target.value })}
                                                    value="IO"/>
                                                    <label class="form-check-label" for="formCheck-2">Interest Only</label></div>
                                                <div class="form-check"><input class="form-check-input" type="radio" id="formCheck-10" name="interestCategory" 
                                                    checked={this.props.filters.interestCategory === "any"} onChange={ e => this.props.filter({...this.props.filters, interestCategory:e.target.value })}
                                                    value="any"/>
                                                    <label class="form-check-label" for="formCheck-10">Any</label></div>
                                            </div>
                                        </div>
                                        <div class="vstack gap-1">
                                            <h4>Rate option</h4>
                                            <div>
                                                <div class="form-check"><input class="form-check-input" type="radio" id="formCheck-6" name="rateOption" 
                                                    checked={this.props.filters.rateOption === "Fixed"} onChange={ e => this.props.filter({...this.props.filters, rateOption:e.target.value })}
                                                    value="Fixed"/>
                                                    <label class="form-check-label" for="formCheck-6">Fixed</label></div>
                                                <div class="form-check"><input class="form-check-input" type="radio" id="formCheck-11" name="rateOption" 
                                                    checked={this.props.filters.rateOption === "Variable"} onChange={ e => this.props.filter({...this.props.filters, rateOption:e.target.value })}
                                                    value="Variable"/>
                                                    <label class="form-check-label" for="formCheck-11">Variable</label></div>
                                                <div class="form-check"><input class="form-check-input" type="radio" id="formCheck-12" name="rateOption" 
                                                    checked={this.props.filters.rateOption === "any"} onChange={ e => this.props.filter({...this.props.filters, rateOption:e.target.value })}
                                                    value="any"/>
                                                    <label class="form-check-label" for="formCheck-12">Any</label></div>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="btn btn-primary align-self-end" type="button" onClick={e => {
                                        this.props.filter({
                                            loanPurpose: "any", 
                                            interestCategory: "any", 
                                            rateOption: "any", 
                                            favorites: false, 
                                            offSale: false })
                                    }} >
                                        Clear filters
                                    </button>
                                </div>
                                </div>
                            </div>
                            </Accordion.Body>
                        </Accordion.Item>
                </Accordion>
            </>            
      );
  }
}

const mapStateToProps = ({ loanProducts }) => {
  return { 
        filters: loanProducts.filters
    };
};

const mapDispatchToProps = (dispatch)=> {
    return {
        filter: (updated) => dispatch(applyFilters(updated)),
    };
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(ProductFilters);

