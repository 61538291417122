import React, {Component, useState, useEffect } from 'react';
import {connect} from 'react-redux';
import classNames from  'classnames';
import CurrencyFormat from "react-currency-format";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import ProductsTable from './ProductsTable';
import SelectedProductsPanel from './SelectedProductsPanel';
import { addLoanAmount, fetchProducts, fetchProductCount, toggleAddMode } from "../../../store/sales/actions";

const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);


function Amount(props) {
    const [amount,setAmount] = useState(0)
    const [formatted,setFormatted] = useState(false)
    useEffect(() => {
        if(!amount && props?.loanAmount)
            setAmount(props.loanAmount.value)
    },props)
    return (
        <>
        {formatted ? 
        <CurrencyFormat class="form-control" thousandSeparator={true} prefix={'$'} allowNegative={false}
                    value={props?.loanAmount?.value ? props?.loanAmount?.value : 0} 
                        style={20000 <= props?.loanAmount?.value ? null : {color:"red"}}
                        decimalScale={2} fixedDecimalScale={true} 
                        onClick={e => { setFormatted(false) }}  /> : 
        <input class="form-control" type="text" inputmode="numeric" placeholder="Enter loan amount per loan account" 
            onChange={e => {
                const value = e.target.value
                setAmount(e.target.value)
                if (isNaN(value))
                    props.addLoanAmount(0)
                else
                    props.addLoanAmount(parseInt(value))
            }}
            onBlur={e => { setFormatted(true) }}
            /> }
        </>    );
}

class AddProductsPanel extends Component {

    render() {
        var last = this.props.scenarios.length > 0 ?  this.props.scenarios[this.props.scenarios.length - 1] : undefined
        //console.log(this.props)

        return (
        <>
        <div class="vstack gap-2">
            {!this.props.addMode ? null : 
            <div class="pb-2 pb-lg-1">
                <h5 class="fw-bold d-xxl-flex justify-content-xxl-start mb-2">1. Enter loan amount</h5>
                <Amount {...this.props} />
            </div> 
            }

            {!this.props.addMode || this.props.loanAmount?.value === '' ? null : 
            <div class="pb-2 pb-lg-1">
                <h5 class="fw-bold d-xxl-flex justify-content-xxl-start mb-2">2. Choose loan product</h5>
                <div class="vstack gap-2">
                    <div class="hstack gap-2 justify-content-evenly">
                        <div class="btn-group btn-group-sm shadow-lg" role="group">
                            <button class={this.loanPurposeBtnClass("Residential",this.props.filters)} type="button" 
                                onClick={() => {
                                    var filters = {...this.props.filters, loanPurpose:"Residential"}
                                    this.refresh({...this.props,filters:filters, page: 1})
                                    }} >Residential</button>
                            <button class={this.loanPurposeBtnClass("Investor",this.props.filters)}  type="button" 
                                onClick={() => {
                                    var filters = {...this.props.filters, loanPurpose:"Investor"}
                                    this.refresh({...this.props, filters:filters, page: 1})}}>Investment</button></div>
                        <div class="btn-group btn-group-sm shadow-lg" role="group">
                            <button class={this.rateOptionBtnClass("Fixed", this.props.filters)} type="button" 
                                onClick={() => {
                                    var filters = {...this.props.filters, rateOption:"Fixed"}
                                    this.refresh({...this.props,filters:filters, page: 1})
                                    }}>Fixed</button>
                            <button class={this.rateOptionBtnClass("Variable", this.props.filters)} type="button" 
                                onClick={() => {
                                    var filters = {...this.props.filters, rateOption:"Variable"}
                                    this.refresh({...this.props,filters:filters, page: 1})
                                    }}>Variable</button></div>
                        <div class="btn-group btn-group-sm shadow-lg" role="group">
                            <button class={this.interestCategoryBtnClass("P&I", this.props.filters)} type="button" 
                                onClick={() => {
                                    var filters = {...this.props.filters, interestCategory:"P&I"}
                                    this.refresh({...this.props,filters:filters, page: 1})
                                    }}>P & I</button>
                            <button class={this.interestCategoryBtnClass("IO", this.props.filters)} type="button" 
                                onClick={() => {
                                    var filters = {...this.props.filters, interestCategory:"IO"}
                                    this.refresh({...this.props,filters:filters, page: 1})
                                    }}>Interest Only</button></div>
                    </div>
                    <ProductsTable  />

                </div>
            </div>
            }
            <div class="hstack gap-2 justify-content-evenly">
            {last ? <SelectedProductsPanel /> : null }
            </div>
        </div>
        </>
        )
    }

    refresh(props) {
        props.fetchProductRows(props.selectedLender.name, props.filters, props.sort, props.page, props.recordsPerPage);
        props.fetchProductCount(props.selectedLender.name, props.filters);
    }

    loanPurposeBtnClass(loanPurpose, filters) {
        return classNames({
            'btn btn-primary': loanPurpose === filters?.loanPurpose ,
            'btn btn-secondary': loanPurpose === filters?.loanPurpose ,
            }, 'btn btn-primary');

    }

    rateOptionBtnClass(rateOption, filters) {
        return classNames({
            'btn btn-primary': rateOption === filters?.rateOption ,
            'btn btn-secondary': rateOption === filters?.rateOption ,
            }, 'btn btn-primary');

    }

    interestCategoryBtnClass(interestCategory, filters) {
        return classNames({
            'btn btn-primary': interestCategory === filters?.interestCategory ,
            'btn btn-secondary': interestCategory === filters?.interestCategory ,
            }, 'btn btn-primary');

    }

}

const mapStateToProps = state => {
    return {
        oldProposal : state.proposalSummary.proposalInView,
        prospectInView : state.clients.prospectInView,
        proposalInView : state.sales.proposalInView,
        scenarios: state.sales.loanScenarios,
        page: state.sales.selectLoanProductsDisplayPage,
        recordsPerPage: state.sales.selectLoanProductsPageSize,
        filters: state.sales.selectLoanProductsFilters,
        sort: state.sales.selectLoanProductsSort,
        selectedLender: state.sales.selectedLender,
        loanAmount: state.sales.editableLoanAmount,
        addMode: state.sales.addMode,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addLoanAmount: (loanAmount) => dispatch(addLoanAmount(loanAmount)),
        fetchProductRows: (lenderName, filters, sort, page, pagesize) => dispatch(fetchProducts(lenderName, filters, sort, page, pagesize)),
        fetchProductCount: (lenderName, filters) => dispatch(fetchProductCount(lenderName, filters)),
        toggleAddMode: () => dispatch(toggleAddMode()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddProductsPanel);