import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { showLoginPage } from '../../../store/auth/actions';
import Loanseller from "../../layout/LoanSeller_Light.png";
import ForgotPasswordModal from "./ForgotPasswordModal";
import RegistrationModal from "./RegistrationModal";

function LoginCard() {

    const { authorizationUrl, clientId, redirectUri, responseType, oauthState, } = useSelector((state) => state.auth);
    return (
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-md-6 col-xl-4">
                    <div class="card mb-5">
                        <div class="card-body d-inline-flex flex-column align-items-center">
                            <div class="my-4"><img src={Loanseller} width="95" /></div>
                            <div class="text-center" >
                                <p class="w-lg-50">Turbo charge your mortgage broking business</p>
                                <div class="row">
                                    <div class="col">
                                        <div class="mb-3"><Button
                                                    className="btn btn-primary d-block w-100"
                                                    onClick={() => {
                                                        showLoginPage(authorizationUrl, responseType, clientId, redirectUri, oauthState)
                                                    }}
                                                    >
                                                    Login
                                                    </Button></div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="mb-3">
                                            <ForgotPasswordModal />
                                            </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="mb-3"><RegistrationModal /></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            

        </div>                                                    
     );
}

export default LoginCard;