import { fetchFailure, fetchLoading, fetchSuccess } from "./actionTypes";
import { getMessagesResponse } from "./request";

export const fetchMessageCount = () => {
    return function(dispatch) {
        dispatch(fetchLoading()) 
        getMessagesResponse()
            .then(responseData => {
                dispatch(fetchSuccess(responseData))
            })
            .catch(error => {
                dispatch(fetchFailure(error))
            })
    }
}

