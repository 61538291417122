import React from 'react';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


const renderTooltip = props => (
    <Tooltip {...props}>{props?.locationtext}</Tooltip>
  );

function ProgressBar(props) {
    
    return ( 
        <div id="progress-bar-button" class="multisteps-form">
            <div class="d-lg-flex justify-content-lg-center row">
                <div class="col-12 col-lg-8 ml-auto mr-auto mb-4">
                    <div class="multisteps-form__progress">

                        {/* Personal Info */}
                        <OverlayTrigger placement="bottom" overlay={renderTooltip({...props,locationtext:'Personal Info'})} >
                        <a class="btn multisteps-form__progress-btn js-active" role="button" title="User Info">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" data-bs-toggle="tooltip" data-bss-tooltip="" viewBox="0 0 16 16" class="bi bi-person-circle fs-2">
                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"></path>
                                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"></path>
                            </svg>
                        </a>
                        </OverlayTrigger>

                        {/* Agency Details */}
                        <OverlayTrigger placement="bottom" overlay={renderTooltip({...props,locationtext:'Agency Details'})} >
                        <a class="btn multisteps-form__progress-btn" role="button" title="User Info">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" data-bs-toggle="tooltip" data-bss-tooltip="" viewBox="0 0 16 16" class="bi bi-info fs-2">
                                <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                            </svg>
                        </a>
                        </OverlayTrigger>

                        {/* Change Password */}
                        <OverlayTrigger placement="bottom" overlay={renderTooltip({...props,locationtext:'Change Password'})} >
                        <a class="btn multisteps-form__progress-btn" role="button" title="User Info">
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" data-bs-toggle="tooltip" data-bss-tooltip="" viewBox="0 0 16 16" class="bi bi-key fs-2">
                                <path d="M0 8a4 4 0 0 1 7.465-2H14a.5.5 0 0 1 .354.146l1.5 1.5a.5.5 0 0 1 0 .708l-1.5 1.5a.5.5 0 0 1-.708 0L13 9.207l-.646.647a.5.5 0 0 1-.708 0L11 9.207l-.646.647a.5.5 0 0 1-.708 0L9 9.207l-.646.647A.5.5 0 0 1 8 10h-.535A4 4 0 0 1 0 8zm4-3a3 3 0 1 0 2.712 4.285A.5.5 0 0 1 7.163 9h.63l.853-.854a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.793-.793-1-1h-6.63a.5.5 0 0 1-.451-.285A3 3 0 0 0 4 5z"></path>
                                <path d="M4 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"></path>
                            </svg>
                        </a>
                        </OverlayTrigger>
                        
                    </div>
                </div>
            </div>
        </div>
     );
}

export default ProgressBar;