import React, {Component} from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ProspectsPagination from './ProspectsPagination';
import FirstHomeButton from './FirstHomeButton';
import InvestorButton from './InvestorButton';
import RefinanceButton from './RefinanceButton';
import SelfEmployedButton from './SelfEmployedButton';
import NoProposalButton from './NoProposalButton';
import AllProspectsButton from './AllProspectsButton';
import HiddenText from '../../common/HiddenText'
import { loanProposalMenuAction, buttonClass, countDisplay, fetchProspectCount, fetchProspects } from "../../../store/sales/actions";
import { setClientInDetailView, fetchBorrowerChatMessages } from '../../../store/clients/actions';
import { SUMMARY_VIEW } from '../../../store/sales/constants';

const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);

class SalesProspects extends Component {

	componentDidMount() {
        this.props.fetchCount(this.props.prospectsDisplayPage, this.props.prospectsPageSize, this.props.prospectsFilter)
	}


    viewProspect(props) {
        if (props) {
            props.fetchBorrowerChatMessages(props.prospect.mainBorrower._id)
			props.setClientInDetailView(props.prospect)
			props.loanProposalMenuAction(SUMMARY_VIEW)
        }
    }	
	render() {
        const viewProspect = this.viewProspect
        const properties = this.props
		const accessRoles = this.props.accessRoles
		return (
		<>
		<div class="vstack">
			<div class="card shadow flex-fill border-start-warning py-2">
				<div class="card-body">
					<div class="row align-items-center no-gutters">
						<div class="col me-2">
							<div class="text-uppercase text-success fw-bold text-xs mb-1">
                                <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Recent clients and LoanShopper prospects to send your initial loan proposal'})} >
								<span style={{"color": "var(--bs-primary)"}}>PROSPECTS</span>
								</OverlayTrigger>
								</div>
							<div class="text-dark d-xxl-flex justify-content-xxl-end fw-bold h5 mb-0"><span></span></div>
						</div>
						<div class="col-auto"><svg class="fa-2x text-gray-300" xmlns="http://www.w3.org/2000/svg" viewBox="0 -64 640 640" width="1em" height="1em" fill="currentColor">
								<path d="M319.9 320c57.41 0 103.1-46.56 103.1-104c0-57.44-46.54-104-103.1-104c-57.41 0-103.1 46.56-103.1 104C215.9 273.4 262.5 320 319.9 320zM369.9 352H270.1C191.6 352 128 411.7 128 485.3C128 500.1 140.7 512 156.4 512h327.2C499.3 512 512 500.1 512 485.3C512 411.7 448.4 352 369.9 352zM512 160c44.18 0 80-35.82 80-80S556.2 0 512 0c-44.18 0-80 35.82-80 80S467.8 160 512 160zM183.9 216c0-5.449 .9824-10.63 1.609-15.91C174.6 194.1 162.6 192 149.9 192H88.08C39.44 192 0 233.8 0 285.3C0 295.6 7.887 304 17.62 304h199.5C196.7 280.2 183.9 249.7 183.9 216zM128 160c44.18 0 80-35.82 80-80S172.2 0 128 0C83.82 0 48 35.82 48 80S83.82 160 128 160zM551.9 192h-61.84c-12.8 0-24.88 3.037-35.86 8.24C454.8 205.5 455.8 210.6 455.8 216c0 33.71-12.78 64.21-33.16 88h199.7C632.1 304 640 295.6 640 285.3C640 233.8 600.6 192 551.9 192z"></path>
							</svg></div>
					</div>
					{
						this.props?.prospects.length == 0 ? null :
							<div class="table-responsive">
								<table class="table">
									<tbody>
									{
										this.props.prospects.map(function(prospect) {
										const showPII = 
											accessRoles.filter(r => r.roleName === 'masked_prospect').length == 0  ||
											(prospect?.clientConnectionId && prospect?.connection.status === 'accepted' )
											return (
												<tr>
													<td>
														<a onClick={() => {
															viewProspect({...properties, prospect: prospect})
															}}>
														<OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Create proposal'})} >
															<svg class="bi bi-telegram fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" data-bs-toggle="modal" data-bs-target="#modal-loanproposal">
																<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"></path>
															</svg>
														</OverlayTrigger></a>
													</td>
													<td><HiddenText unmasked={showPII}>{prospect.mainBorrower.title} {prospect.mainBorrower.firstName} {prospect.mainBorrower.lastName}</HiddenText></td>
													<td>{prospect.mainBorrower?.currentAddress?.state}</td>
													<td>
														<OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Profile completion rate'})} >
															<span>{prospect.borrowerProfile?.profileScore}%</span>
														</OverlayTrigger>
														</td>
													<td>
														<OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Profile updated'})} >
															<span>{moment(prospect.loanRequest.lastUpdatedTs).format('Do MMM YYYY')}</span>
														</OverlayTrigger>
														</td>
													<td>{moment().diff(moment(prospect.mainBorrower.dateOfBirth),'years')} yrs</td>
												</tr>)
										})
									}
									</tbody>
								</table>
							</div>
					}
					<ProspectsPagination />
					<div class="hstack gap-1 d-xl-flex justify-content-evenly align-items-xl-start">
						<FirstHomeButton />
						<InvestorButton />
						<RefinanceButton />
						<SelfEmployedButton />
						<NoProposalButton />
						<AllProspectsButton />
					</div>
				</div>
			</div>
		</div>
		</>
		);
	}
}


const mapStateToProps = state => {
    return {
		...state.sales,
		accessRoles : state.brokerAgentProfile.accessRoles,
    }
}


const mapDispatchToProps = dispatch => {
    return {
        fetchCount: (page, pagesize, prospectsFilter) => dispatch(fetchProspectCount(page, pagesize, prospectsFilter)),
        fetchProspects: (page, pagesize, prospectsFilter) => dispatch(fetchProspects(page, pagesize, prospectsFilter)),
        countDisplay: (count) => countDisplay(count),
		buttonClass: (dark) => buttonClass(dark),
		setClientInDetailView: (prospect) => dispatch(setClientInDetailView(prospect)),
        fetchBorrowerChatMessages: (borrowerId) => { dispatch(fetchBorrowerChatMessages(borrowerId)) },
        loanProposalMenuAction: (view) => dispatch(loanProposalMenuAction(view)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SalesProspects);