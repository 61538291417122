import React, { } from 'react'
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { changeSubscription, toggleSalesGenerator } from '../../../../store/upgrades/actions';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);

const BasicPackage = (props) => {
    const subscriptionPackages = useSelector(state => state.upgradePage?.subscriptionPackages)
    const subscriptionPackage = useSelector(state => state.upgradePage?.subscriptionPackage)
    const salesGeneratorAddIn = useSelector(state => state.upgradePage?.salesGeneratorAddIn)
    const basicAnnualRate = useSelector(state => state.upgradePage?.basicAnnualRate)
    const basicQuartelyRate = useSelector(state => state.upgradePage?.basicQuartelyRate)
    const commissionRate = useSelector(state => state.upgradePage?.commissionRate)
    const [annualOption, setAnnualOption] = React.useState(subscriptionPackage !== null && subscriptionPackage?.type === 'Annual')
    const [addSalesGenerator, setAddSalesGenerator] = React.useState(salesGeneratorAddIn)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    if (!props?.readOnly) {
        if (annualOption)
            dispatch(changeSubscription(
                    subscriptionPackages.find(subscription => subscription.name === 'Basic' && subscription.type === 'Annual')
                    ))
        else
            dispatch(changeSubscription(
                    subscriptionPackages.find(subscription => subscription.name === 'Basic' && subscription.type === 'Quarterly')
                    ))
    }

    return (
    <>
        <div class="col upgradeBasic">
            <div class="card mb-4">
                <div class="card-body text-center p-4">
                    <h4 class="fw-bold card-subtitle">Sales generator</h4>
                    <p>Single user client data and documents platform</p>
                    <div class="vstack">
                        <div class="hstack gap-2 d-xl-flex justify-content-xl-center">
                            <h5>3 months</h5>
                            <div class="form-check form-switch text-primary">
                                <input class="form-check-input" type="checkbox" id="formCheck-1" 
                                    checked={annualOption ? "checked" : ""} disabled={props.readOnly}
                                    onChange={() => {
                                        setAnnualOption(!annualOption)
                                    }
                                    }></input>
                                    <label class="form-check-label" for="formCheck-1">
                                    </label>
                            </div>
                            <h5>12 months</h5>
                        </div>
                        { annualOption? 
                        (<h4 class="display-5 fw-bold">${basicAnnualRate} <span class="fs-4 fw-normal text-muted">Save $97</span></h4>):
                        (<h4 class="display-5 fw-bold">${basicQuartelyRate} <span class="fs-4 fw-normal text-muted"></span></h4>) 
                        }
                    </div>
                    <div class="vstack">
                        Plus &nbsp;<h4 class="display-5 fw-bold">{commissionRate}%<span class="fs-4 fw-normal text-muted">&nbsp; of loan amount for home loans discharged to Loanshopper prospects</span></h4>
                    </div>                    
                    <span>All prices exclude GST</span>
                    {!props.hideStartToday && 
                    <a class="btn btn-primary d-block w-100" role="button" 
                        onClick={() => { 
                            navigate("/dashboard/subscribe",{replace:true})
                            props.onHide()}} >Start today</a>}
                </div>
            </div>

            <div id="basicInfoAndExtras" class="accordion" role="tablist">
                <div class="accordion-item">
                    <h2 class="accordion-header" role="tab"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#basicInfoAndExtras .item-1" aria-expanded="false" aria-controls="basicInfoAndExtras .item-1">More info</button></h2>
                    <div class="accordion-collapse collapse item-1" role="tabpanel" data-bs-parent="#basicInfoAndExtras">
                        <div class="accordion-body">
                            <div class="bg-light border rounded border-light p-4">
                                <p class="fw-bold">Package features</p>
                                <ul class="list-unstyled">
                                    <li class="d-flex mb-2"><span class="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"><svg class="bi bi-check-lg text-primary" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                                            </svg></span><span><b>Fact find</b> from your client data submissions</span></li>
                                    <li class="d-flex mb-2"><span class="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" class="bi bi-check-lg text-primary">
                                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                                            </svg></span><span>Secure collection of <b>sensitive documents</b> from clients</span></li>
                                    <li class="d-flex mb-2"><span class="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" class="bi bi-check-lg text-primary">
                                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                                            </svg></span><span><b>Direct messaging</b> with clients on LoanShopper mobile app</span></li>
                                    <li class="d-flex mb-2"><span class="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" class="bi bi-check-lg text-primary">
                                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                                            </svg></span><span>Send loan proposals for over <b>7250+ loan products</b> from <b>100+ lenders</b></span></li>
                                    <li class="d-flex mb-2"><span class="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" class="bi bi-check-lg text-primary">
                                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                                            </svg></span><span>Send loan proposals to <b>Loanshopper</b> qualified borrower prospects database</span></li>
                                </ul>
                            </div>
                            <div class="bg-light border rounded border-light p-4">
                                <p class="fw-bold">You should choose this if</p>
                                <ul class="list-unstyled">
                                    <li class="d-flex mb-2"><span class="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" class="bi bi-check-lg text-primary">
                                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                                            </svg></span><span>You want to <b>increase your sales footprint</b> and access a wider client base with minimal investment<br/></span></li>
                                    <li class="d-flex mb-2"><span class="bs-icon-xs bs-icon-rounded bs-icon-primary-light bs-icon me-2"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" class="bi bi-check-lg text-primary">
                                                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z"></path>
                                            </svg></span><span>You want <b>reduce turnaround times</b> for borrower engagement using thier LoanShopper app</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default BasicPackage;