import { COMPANY_NAME_ACTION_TYPES } from "./actionTypes";

export const initialState = {
    loading: false,
    autocompleteOptions: [],
    error: null,
    selectedCompany: null,
    abn: "ABN",
    companyName: "Business or Company Name",
    brokerAgencyErrors: [],
}

export default function companyNameAutocompleteReducer(state = initialState, action) {
    switch (action.type) {
        case COMPANY_NAME_ACTION_TYPES.FETCH_LOADING:
            return {
                ...state,
                loading: true
            }
        case COMPANY_NAME_ACTION_TYPES.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                autocompleteOptions: action.payload,
                error: null,
            }
        case COMPANY_NAME_ACTION_TYPES.VALIDATION_ERROR:
            return {
                ...state,
                loading: false,
                autocompleteOptions: [], 
                error: action.payload[0],
            }
        case COMPANY_NAME_ACTION_TYPES.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                autocompleteOptions: [], 
                error: {type:"asicDetails", 
                    valid : false,
                    errorMessage : action.payload,
                },
            }
        case COMPANY_NAME_ACTION_TYPES.SAVE_SUCCESS:
            return {
                ...state,
                selectedCompany: action.payload,
                abn: action.payload.abn, 
                companyName: action.payload.name ? action.payload.name : action.payload.companyName,
                error:null
            }
        default:
            return state
    }
}
  