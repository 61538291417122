export const ADDRESS_ACTION_TYPES = {
    FETCH_LOADING: 'addressFetchLoading',
    FETCH_SUCCESS: 'addressFetchSuccess',
    FETCH_FAILURE: 'addressFetchFailure',
    SAVE_SUCCESS: 'addressSaveSuccess',
    VALIDATION_ERROR: 'addressValidationError'
}

export default function getActionType(success) {
    const type = success? ADDRESS_ACTION_TYPES.FETCH_SUCCESS : ADDRESS_ACTION_TYPES.FETCH_ERROR
    return type
}

export const fetchLoading = () => {
    return {
        type: ADDRESS_ACTION_TYPES.FETCH_LOADING
    }
}

export const fetchSuccess = addressOptions => {
    return {
        type: ADDRESS_ACTION_TYPES.FETCH_SUCCESS,
        payload: addressOptions 
    }
}

export const fetchFailure = error => {
    return {
        type: ADDRESS_ACTION_TYPES.FETCH_FAILURE,
        payload: error
    }
}

export const validationError = errors => {
    return {
        type: ADDRESS_ACTION_TYPES.VALIDATION_ERROR,
        payload: errors
    }
}

export const saveSuccess = selectedAddress => {
    return {
        type: ADDRESS_ACTION_TYPES.SAVE_SUCCESS,
        payload: selectedAddress 
    }
}