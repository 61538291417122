import _ from "./actionType";

const initialState = {
  paymentReference: null,
  lastPayment: null,
  overdueAmount: null,
  nextPaymentDate: null,
  cardDigits: null,

  // Pagination
  receipts: [],
  invoiceReferences: [],
  numInvoices: 0,
  pageSize:6,
  displayPage: 0, 
  error: null, 
};

function getCardDigits(dataSet) {
  const dataSetList = [];
  dataSet.map(dataSetTerm => {
    if(dataSetTerm.key==="cardDigits"){
      dataSetList.push(dataSetTerm)
    }
  })
  return dataSetList[0].value
}

export default function billing(state = initialState, action) {
  switch (action.type) {
    case _.fetchPayment.success:
      return {
        ...state,
        paymentReference: action.payload
      }
    case _.fetchInvoice.success:
      return {
        ...state,
        latestInvoice: action.payload,
        cardDigits: action.payload?.paymentReference?.dataSet ? getCardDigits(action.payload.paymentReference.dataSet) : null,
      }
    case _.fetchInvoiceReference.success:
      var invoiceReferences = []
      state.invoiceReferences.map(e => {
        invoiceReferences.push(e)
      })
      invoiceReferences.push(action.payload)
      return {
        ...state, invoiceReferences: invoiceReferences
      }
    case _.fetchInvoicesMetadata.success:
      return {
        ...state,
        numInvoices: action.payload,
        error: null,
      }
    case _.fetchInvoices.success:
      return {
        ...state,
        displayPage: action.payload?.page || 0,
        receipts : action.payload?.records  || [],
        error: null,
      }
    case _.fetchPayment.failed:
    case _.fetchInvoice.failed:
    case _.fetchInvoiceReference.failed:
    case _.fetchInvoices.failed:
    case _.fetchInvoicesMetadata.failed:
      return {
        ...state,
        error: action.payload
      }
    default:
      return state;
  }
}
