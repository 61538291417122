import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes as Switch,
  Route,
  useLocation, useNavigate
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaAngleUp } from "react-icons/fa";

import Login from "./components/pages/Login";
import Dashboard from "./components/pages/Dashboard";
import Clients from "./components/pages/Clients";
import Sales from "./components/pages/Sales";
import Teams from "./components/pages/Teams";
import Billing from "./components/pages/Billing";
import Profile from "./components/pages/Profile";
import Documents from "./components/pages/Documents";
import Chats from "./components/pages/Chats";

import Topbar from "./components/layout/Topbar";
import LoanProducts from "./components/pages/Dashboard/LoanProducts";
import CreateSubscription from "./components/pages/Dashboard/Upgrades/CreateSubscription";
import { authenticate } from "./store/auth/actions";
import { fetchPaymentReference } from "./store/billing/actions";

function Root() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const accessCode =  useSelector((state) => state.auth.accessCode ? state.auth.accessCode : queryParams.get("accessCode") ) ;
  const [pathname, setPathname] = useState(location.pathname);

  if (!isAuthenticated && accessCode!= null) {
    dispatch(authenticate(accessCode));
  } else if (isAuthenticated && queryParams.get("route")) {
    dispatch(
      fetchPaymentReference(queryParams.get("uniqueReference"), queryParams.get("accessCode")));
      switch (queryParams.get("route")) {
        case "paymentResult":
          navigate("/dashboard/billing/paymentResult")
          break;
        default:
      }
  }

  return (
    <div id="wrapper">
    <div class="d-flex flex-column" id="content-wrapper">
        <div id="content">
        <div>
      {
      isAuthenticated ? (
          <div>
            <Topbar pathName={pathname} />
            <div>
              <Switch>
                <Route exact path="/"
                  element={<Dashboard pathName={setPathname} />}
                />
                <Route 
                  path="/dashboard"
                  element={<Dashboard pathName={setPathname} />}
                />
                <Route
                  path="/dashboard/profile"
                  element={<Profile pathName={setPathname} />}
                />
                <Route
                  path="/dashboard/products"
                  element={<LoanProducts pathName={setPathname} />}
                />
                <Route
                  path="/dashboard/subscribe"
                  element={<CreateSubscription  /> }
                />
                <Route
                  path="/dashboard/billing"
                  element={<Billing pathName={setPathname} />}
                />
                <Route
                  path="/dashboard/billing/paymentResult"
                  element={<Billing 
                    showPaymentResult={true} 
                    uniqueReference={queryParams.get("uniqueReference")}
                    accessCode={queryParams.get("accessCode")} />}
                />
                <Route
                  path="/clients"
                  element={<Clients pathName={setPathname} />}
                />
                <Route
                  path="/sales"
                  element={<Sales pathName={setPathname} />}
                />
                <Route
                  path="/teams"
                  element={<Teams pathName={setPathname} />}
                />
                <Route
                  path="/documents"
                  element={<Documents />}
                />
                <Route
                  path="/chats"
                  element={<Chats />}
                />
              </Switch>
            </div>
          </div>
        ) : (
          <Login 
            resetPassword={pathname === '/reset_password'}
            email={queryParams.get("email")}
            code={queryParams.get("amp;code")} />
        )}
        </div>
        <footer class="bg-white sticky-footer">
            <div class="container my-auto">
                <div class="text-center my-auto copyright"><span>Copyright Loan Analysis Solutions 2022</span></div>
            </div>
        </footer>
        </div>
      </div>
      <a class="border rounded d-inline scroll-to-top" href="#page-top">
        <FaAngleUp style={{ color: "#dbdbdb" }} size="30" /></a>
    </div>
  );
}

export default Root;
