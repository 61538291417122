/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": process.env.REACT_APP_API_aws_cloud_logic_custom_endpoint,
            "region": "ap-southeast-2"
        }
    ],
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": process.env.REACT_APP_API_aws_user_pools_id,
    "aws_user_pools_web_client_id": process.env.REACT_APP_API_aws_user_pools_web_client_id,
    "oauth": {
        "domain": process.env.REACT_APP_API_oauth_domain,
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": process.env.REACT_APP_API_oauth_redirectSignIn,
        "redirectSignOut": process.env.REACT_APP_API_oauth_redirectSignOut,
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GENDER",
        "GIVEN_NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
