import React, {Component} from 'react';
import {connect} from 'react-redux';
import classNames from  'classnames';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import LenderAutofill from './LenderAutofill';
import CloseButton from './CloseButton'
import { showLanding, chooseLoanTerm, fetchEstimatedRepayment, loanProposalMenuAction } from "../../../store/sales/actions";
import { SUMMARY_VIEW } from '../../../store/sales/constants';


const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);

class LenderSelection extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        var buttonClass = classNames({
            'btn btn btn-primary ml-auto js-btn-next': this.props.nextEnabled,
            'btn disabled btn btn-primary ml-auto js-btn-next': !this.props.nextEnabled,
            }); 
        var years = [];
        for(var i = 0; i< this.props.maxYears;)
            years.push(++i);
        const scenarios = this.props.loanScenarios
        const fetchRepayment = this.props.fetchEstimatedRepayment
        return (
        <>
        <div id="select-lender" class="multisteps-form__panel shadow p-4 rounded bg-white js-active" data-animation="scaleIn">
            <div id="lender-form-content" class="multisteps-form__content">
                <div class="vstack gap-2">
                    <div class="vstack gap-1 d-xxl-flex flex-row flex-grow-1 flex-fill">
                        <div class="hstack justify-content-between gap-1 d-xxl-flex flex-row flex-grow-1 flex-fill">
                            <h2 class="fw-bold d-xxl-flex mb-2">Select lender and loan term</h2>
                            <div class="hstack justify-content-between">
                                <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Next'})} >
                                <button class={buttonClass} type="button" title="Next"  >Next</button>
                                </OverlayTrigger>
                                <CloseButton />
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <LenderAutofill />
                        </div>
                        <div class="col">
                            <div class="input-group"><span class="input-group-text">Loan term</span>
                            <select class="form-select"  id="years-select"
                                defaultValue={this.props?.loanTerm}
                                onChange={e => {
                                    this.props.chooseLoanTerm(e.target.value)
                                    const products = this.props.selectedLoanProducts
                                    this.props.loanScenarios.map(s => {
                                        fetchRepayment(
                                            scenarios.findIndex(e => s.loanProduct._id === e.loanProduct._id), 
                                            s.loanAmount.value, 
                                            e.target.value, 
                                            products[s.loanProduct._id].ratesAndFees.interestRate)
                                    })
                                    
                                    }} >
                                <optgroup>
                                    <option value="">Select number of years</option> 
                                {years.map(y => {
                                    return (
                                        <option value={y} selected={y === this.props?.loanTerm}>{y}</option> 
                                    );
                                })}
                                </optgroup>
                            </select></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
        )
    }
}


const mapStateToProps = state => {
    return {
        maxYears: state.sales.maxYears,
        loanTerm: state.sales.selectedLoanTermInYrs,
        loanScenarios: state.sales.loanScenarios,
        selectedLoanProducts : state.sales.selectedLoanProducts,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showLanding: () => dispatch(showLanding()),
        chooseLoanTerm: (loanTermInYrs) => dispatch(chooseLoanTerm(loanTermInYrs)),
        fetchEstimatedRepayment: (scenarioIdx, loanAmount, loanTermInYrs, interestRate) => dispatch(fetchEstimatedRepayment(scenarioIdx, loanAmount, loanTermInYrs, interestRate)),
        loanProposalMenuAction: (view) => dispatch(loanProposalMenuAction(view)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LenderSelection);