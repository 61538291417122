import React, { useState, useRef, Component } from 'react';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

import '../../../styles/App.css';
import { Button, Modal } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { changeSubscription } from '../../../store/upgrades/actions';

function CloseButton(props) {
  const nav =  useNavigate()
  return (<>
  <Button class="btn btn-primary" onClick={() => { 
                      if (props.onClick != null) props.onClick()
                      else nav(props.location)
  }} >{props?.label ? props.label : 'Close'}</Button></>);
}

class PaymentResult extends Component {

	constructor(props) {
    super(props);
  }


  render () {
      const subscriptionPackage = this.props.paymentReference ? 
        this.props.paymentReference.subscriptions.find(subscription => subscription.name === 'Basic')    :
        null 
    	return (
          <div>
              {this.props?.paymentReference?.transaction?.status === 'payment_failed' ?
                <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>LoanShopper Broker Platform</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Sorry!</strong> We were unable to process the payment</p>
                </Modal.Body>
                <Modal.Footer>
                    <CloseButton {...this.props} location="/dashboard/subscribe"
                      onClick={() => {
                        this.props.changeSubscription(subscriptionPackage)}} />
                </Modal.Footer>
                </Modal.Dialog> 
                :
                this.props?.paymentReference?.transaction?.status === 'payment_success' ?
                <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>LoanShopper Broker Platform</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>Thanks! You're on your way!!</strong>Our friendly sales team will be in touch soon to help you with your onboarding and to assis with any help you may need!</p>
                </Modal.Body>
                <Modal.Footer>
                    <CloseButton {...this.props} onClick={()=>  window.open("/","_self")} label='Logout to login again' />
                </Modal.Footer>
                </Modal.Dialog> 
                : !this.props?.paymentReference ?
                <Modal.Dialog>
                <Modal.Header>
                    <Modal.Title>LoanShopper Broker Platform</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Processing....</p>
                    <Spinner animation="grow" variant="danger" />
                </Modal.Body>
                </Modal.Dialog> 
                : null
              }
          </div>
      );
  }
}

const mapStateToProps = ({ billingReducer }) => {
  const { paymentReference } = billingReducer
  return { paymentReference };
};
export default connect(mapStateToProps, { changeSubscription })(PaymentResult);