export const LENDER_ACTION_TYPES = {
    FETCH_LOADING: 'lenderProfileFetchLoading',
    FETCH_SUCCESS: 'lenderProfileFetchSuccess',
    FETCH_FAILURE: 'lenderProfileFetchFailure'
}

export default function getActionType(success) {
    const type = success? LENDER_ACTION_TYPES.FETCH_SUCCESS : LENDER_ACTION_TYPES.FETCH_ERROR
    return type
}

export const fetchLoading = () => {
    return {
        type: LENDER_ACTION_TYPES.FETCH_LOADING
    }
}

export const fetchSuccess = lenderProfilesPost => {
    return {
        type: LENDER_ACTION_TYPES.FETCH_SUCCESS,
        payload: lenderProfilesPost
    }
}

export const fetchFailure = error => {
    return {
        type: LENDER_ACTION_TYPES.FETCH_FAILURE,
        payload: error
    }
}