import _ from './actionType';

function translateProposalToState (proposal) {
  const state = true ?  
    {
      selectedLender: proposal.lender,
      selectedLoanTermInYrs: proposal.loanPackage.loanTermInYears,
      error: null,
      } : {};
  return state;
}

const initialState = { 
  selectedLender: null,
  selectedLoanTermInYrs: null,
}

export default function sales(state = initialState, action) {
  switch (action.type) {
    case _.setupFromProposal:
      return {
        ...state,
        ...translateProposalToState(action.payload),    
      }
    case _.chooseLoanTerm:
      return {
        ...state,
        selectedLoanTermInYrs: action.payload.loanPackage.loanTermInYears,
      }
    case _.chooseLender:
      return {
        ...state,
        selectedLender: action.payload,
      }

    default:
      return state;
  }
}