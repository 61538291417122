import React, {Component} from 'react';
import {connect} from 'react-redux';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import classNames from  'classnames';
import { countDisplay, buttonClass, fetchSelfEmployedCount, toggleProspectDisplay, fetchProspects, searchTypeFromFilter } from "../../../store/sales/actions";

const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);

const _filter = 1

class SelfEmployedButton extends Component {
    constructor(props) {
        super(props)
    }

	componentDidMount() {
		this.props.fetchCount()
	}

    render() {
        return (
        <>
        <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Self employed'})}  >
        <div class="text-center d-flex flex-column justify-content-center align-items-center py-3">
            <div class={ this.props.buttonClass(this.props.prospectsFilter === _filter) } 
                onClick={() => {
                    this.props.fetchCount()
                    this.props.fetchProspects(1, this.props.prospectsPageSize, searchTypeFromFilter[_filter])
                    this.props.toggleProspectDisplay(_filter)
                }}>
                <svg class="bi bi-calculator" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h8zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4z"></path>
                    <path d="M4 2.5a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5h-7a.5.5 0 0 1-.5-.5v-2zm0 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm0 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-4z"></path>
                </svg></div>
            <div class="px-3">
                <h4 class="fw-bold mb-0">{this.props.countDisplay(this.props.selfEmployedCount)}</h4>
            </div>
        </div>
        </OverlayTrigger>
        </>
        )
    }
}


const mapStateToProps = state => {
    return {
        ...state.sales
    }
}

const mapDispatchToProps = dispatch => {
    return {
        countDisplay: (count) => countDisplay(count),
        buttonClass: (dark) => buttonClass(dark),
        fetchCount: () => dispatch(fetchSelfEmployedCount()),
        fetchProspects: (page, pagesize, searchType) => dispatch(fetchProspects(page, pagesize, searchType)),
        toggleProspectDisplay: (filter) => dispatch(toggleProspectDisplay(filter)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelfEmployedButton);