import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Nav, Container, Row, Col, Button } from 'react-bootstrap';
import MembersTab from './membersTab';
import CasesTab from './casesTab';
import WorkplanTab from './workplanTab';
import TabsBar from './tabsBar';

const Index = (props) => {

  props.pathName('Teams');

  return (
    <>
        <div class="container-fluid d-flex flex-row flex-grow-1">
            <div>
                < TabsBar />
                <div class="tab-content">
                    < MembersTab />
                    < CasesTab />
                    < WorkplanTab />
                </div>
            </div>
        </div>
    </>
  );
};

export default Index;
