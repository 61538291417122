import React, { useState, useEffect } from 'react';
import classNames from  'classnames';
import { Button, Modal } from 'react-bootstrap';
import RegistrationForm from './registrationForm';

const RegistrationModal = () => {
    const [show, setShow] = useState(false);
    return (
        <div>
            <Button hidden={!show}
                    className="btn btn-primary d-block w-100"
                    onClick={() => {
                        setShow(true)
                    }}
                    >
                    Register here
                    </Button>
            <Modal show={show} onHide={() => {
                                setShow(false)
                            }}>
                <Modal.Header closeButton>
                <Modal.Title>Register here</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <RegistrationForm />
                </Modal.Body>
            </Modal>
        </div>
    );
}


export default RegistrationModal;