import { Button } from "react-bootstrap";
import React from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import UpgradePackages from "./UpgradePackages";
import { hideUpgradeModal, showUpgradeModal, getUniqueReference } from "../../../../store/upgrades/actions";

const Upgrade = (props) => {
  const dispatch = useDispatch()
  const modalShow = useSelector(state => state.upgradePage?.upgradeModalVisible)
  const agency = useSelector( state => state?.brokerAgentProfile?.agency)
  console.log(agency)
  dispatch(getUniqueReference())
  return (
    <section class="py-4 py-xl-5">
      <div class="pb-2 pb-lg-1">
          <h2 class="fw-bold mb-2">Freemium package</h2>
              <div class="vstack gap-5 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
              <p class="mb-0">Congrats! We're pleased that you chose Loanseller to stay connected with your clients. You can use the <strong>Clients</strong> section to invite clients to connect with you and securely submit thier documents and thier sensitive personal and financial information</p>
                  {agency?.companyDetails ? null :  <Link to="/dashboard/profile" >
                        <div class="my-2"><Button
                      onClick={(e) => e.preventDefault}
                      className=""
                      style={{ width: "210px", fontSize: "20px", padding: "8px" }}
                    >Update company details</Button></div>
                      </Link>}
              <p class="mb-0">Sign up to one of our unbeatable packages. We help you connect with clients and provide your clients a stress free lending journey, while at the same time increasing your team&#39;s productivity</p>
              </div>
              <div class="hstack gap-5 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                  <div class="my-2">
                    <Button
                      onClick={() => dispatch(showUpgradeModal())}
                      className=""
                      style={{ width: "210px", fontSize: "20px", padding: "8px" }}
                    >Upgrade your subscription</Button></div>
              </div>
          <UpgradePackages
            show={modalShow}
            onHide={() => dispatch(hideUpgradeModal())}
            {...props}
          />

      </div>
    </section>
);
};

export default Upgrade;
