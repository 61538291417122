import React, {Component} from 'react';
import { connect } from 'react-redux';
import SalesMultiStep from "./NewProposal/SalesMultiStep";
import EditProposalMultiStep from "./EditProposal/EditProposalMultiStep";
import ProposalReview from "./ProposalSummary/ProposalReview";
import ClientPanel from './ClientPanel';
import Loanseller from "../../layout/LoanSeller_Light.png"

import { clearAllDocs } from '../../../store/sales/editDocuments/actions'
import { showMultiStep, showClient, showProposal, editProposal, discardProposal, exit, } from '../../../store/sales/actions';
import { discardProposalInSummary } from '../../../store/sales/proposalSummary/actions';
import { fetchBorrowerChatMessages, } from '../../../store/clients/actions';

const ClientPullInContainer = (props) => {
    return (<>
                <div class="row">
                    <div class="col">{props.children}</div>
                    <div class="col-1">
                    <button class="btn btn-primary" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasClientInfo-1" aria-controls="offcanvasClientInfo-1">Show client info</button>
                    </div>
                    <div class="offcanvas offcanvas-end" data-bs-scroll="true" data-bs-backdrop="false" tabindex="-1" id="offcanvasClientInfo-1" aria-labelledby="offcanvasClientInfo-1Label">
                    <div class="offcanvas-header">
                        <h5 class="offcanvas-title" id="offcanvasClientInfo-1Label">Client information</h5>
                        <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                        <ClientPanel />
                    </div>
                    </div>
                </div>    
    </>)
} 

class ModalLoanProposal extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.showClient()
    }

    render() {
        return (
            <>
            <div id="modal-loanproposal" class="modal fade" role="dialog" tabindex="-1">
                <div class="modal-dialog modal-lg modal-dialog-centered modal-fullscreen" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="vstack"><img src={Loanseller} width="95" /></div>
                            <div class="hstack">
                                <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close" 
                                    onClick={() => {
                                            this.props.discardProposal()
                                            this.props.discardProposalInSummary()
                                            this.props.clearAllDocs()
                                            this.props.exit()
                                            this.props.showClient()
                                        }}></button>
                            </div>
                        </div>

                        <div class="modal-body">
                            <div class="vstack gap-2">
                                {
                                    this.props.currentDisplay === 'client' ? <ClientPanel /> :
                                    this.props.currentDisplay === 'multiStep' ? 
                                        <ClientPullInContainer><SalesMultiStep /></ClientPullInContainer> :            
                                    this.props.currentDisplay === 'proposalEdit' ? 
                                        <ClientPullInContainer><EditProposalMultiStep /></ClientPullInContainer> :            
                                    this.props.currentDisplay === 'proposalView' && this.props?.proposalInView  != null ? 
                                        <ClientPullInContainer><ProposalReview /></ClientPullInContainer> : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.sales,
        prospectInView : state.clients.prospectInView,
        trialAccountMode: state.upgradePage.trialAccountMode,
        borrowerMessages : state.clients.borrowerMessages,
        proposalInView : state.proposalSummary.proposalInView,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showProposal: () => dispatch(showProposal()),
        showMultiStep: () => dispatch(showMultiStep()),
        showClient: () => dispatch(showClient()),
        editProposal: () => dispatch(editProposal()),
        clearAllDocs: () => dispatch(clearAllDocs()),
        exit: () => dispatch(exit()),
        discardProposal: () => dispatch(discardProposal()),
        fetchBorrowerChatMessages: (borrowerId) => { dispatch(fetchBorrowerChatMessages(borrowerId)) },
        discardProposalInSummary: () => dispatch(discardProposalInSummary()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ModalLoanProposal);