import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip as ChartToolTip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { redrawAmortisation, getProposalAmortizationLineChartData } from "../../../../store/sales/actions";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  ChartToolTip,
  Legend
);

const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);
const options = {
    responsive: true,
    plugins: {
        legend: {
        position: 'top',
        },
        title: {
        display: true,
        text: 'Estimated loan amortization',
        },
    },
};


class Amortization extends Component {
    constructor(props) {
        super(props)
    }

    redrawAmortization() {
        this.props.redrawAmortisation(this.props.amortizationTable, 
            this.props.lenderProducts, 
            this.props.loanTerm, 
            this.props?.loanPackage)        
    }

    componentDidMount() {
        this.redrawAmortization()
    }


    render() {
        const pAndIProducts = this.props.loanPackage.filter(product => product?.loanProduct?.features?.loanInterestCategory !== 'Interest Only')
        return (
        <>
        {!pAndIProducts || pAndIProducts.length == 0 ? <h2 class="fw-bold mb-2">Amortization is only shown for proposals with P and I loans</h2> : 
        <div class="vstack" style={{"max-width": "500px",overflow: "visible"}}>
            <div class="card shadow flex-grow-1 mb-4">
                <div class="card-body">
                    <div class="d-xxl-flex align-items-center justify-content-xxl-start pb-2 pb-lg-1">
                    <OverlayTrigger placement="right-end" overlay={renderTooltip({locationtext:'Chart showing estimates of reducing balance of loan amounts for P&I loans. Actual performance and balance figures may vary.'})} >
                        <h4 class="fw-bold d-xxl-flex justify-content-xxl-center mb-2">Amortization</h4>
                        </OverlayTrigger>
                    </div>
                    {!this.props?.loanPackage ? null :

                    <Line options={options} data={
                        getProposalAmortizationLineChartData(
                            this.props.amortizationTable, 
                            this.props?.loanPackage, 
                            this.props.lenderProducts)} />
                        
                            }
                </div>
            </div>
        </div>
        }
        </>
        )
    }
}

const mapStateToProps = state => {
    return {
        amortizationTable: state.sales.amortizationTable,
        loanPackage:state.proposalSummary.proposalInView?.loanPackage?.loanProducts,
        lenderProducts : state.proposalSummary.selectedLoanProducts,
        loanTerm: state.proposalSummary.proposalInView?.loanPackage?.loanTermInYears,
        discounts: state.proposalSummary?.proposalDiscounts,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        redrawAmortisation: 
            (amortization, availableProducts, loanTermInYears, products) =>
            dispatch(
                redrawAmortisation(amortization, availableProducts, loanTermInYears, products,  false)
            )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Amortization);