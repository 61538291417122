import React from 'react';
import classNames from  'classnames';

class ErrorContainer extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        var error = this.props.error
        var errorMsgClass = classNames({
        '': error == null,
        'alert alert-danger regionError': error != null,
        });
        return (
            <div>
              {error == null || error.length == 0 ? 
              (
              <div class="mb-3">
                {this.props.children}
              </div>
              ) : (
              <div class="mb-3">
                <div className={errorMsgClass} role="alert" >
                {this.props.children}
                {error.errorMessage ? 
                <span style={{"font-size": "10px"}}><strong>{error.errorMessage}</strong><br/></span> : null 
                }
                </div>
            </div>
              )}
            </div>
        );
    }
}

export default ErrorContainer;
