import React, {Component} from 'react';
import {connect} from 'react-redux';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import classNames from  'classnames';
import { countDisplay, buttonClass, fetchProspectCount, toggleProspectDisplay, fetchProspects } from "../../../store/sales/actions";

const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);

const _search_type = undefined
const _filter = null

class AllProspectsButton extends Component {
    constructor(props) {
        super(props)
    }

	componentDidMount() {
		this.props.fetchCount()
	}

    render() {
        return (
        <>
        <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'All'})} >
        <div class="text-center d-flex flex-column justify-content-center align-items-center py-3">
            <div class={ this.props.buttonClass(this.props.prospectsFilter === _filter) }
                onClick={() => {
                        this.props.fetchCount(1, this.props.prospectsPageSize)
                        this.props.toggleProspectDisplay()
                    }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -32 576 576" width="1em" height="1em" fill="currentColor">
                    <path d="M3.853 22.87C10.47 8.904 24.54 0 40 0H472C487.5 0 501.5 8.904 508.1 22.87C514.8 36.84 512.7 53.37 502.1 65.33L396.4 195.6C316.2 212.1 255.1 283 255.1 368C255.1 395.4 262.3 421.4 273.5 444.5C271.8 443.7 270.3 442.7 268.8 441.6L204.8 393.6C196.7 387.6 192 378.1 192 368V288.9L9.042 65.33C-.745 53.37-2.765 36.84 3.854 22.87H3.853zM287.1 368C287.1 288.5 352.5 224 432 224C511.5 224 576 288.5 576 368C576 447.5 511.5 512 432 512C352.5 512 287.1 447.5 287.1 368zM491.3 331.3C497.6 325.1 497.6 314.9 491.3 308.7C485.1 302.4 474.9 302.4 468.7 308.7L432 345.4L395.3 308.7C389.1 302.4 378.9 302.4 372.7 308.7C366.4 314.9 366.4 325.1 372.7 331.3L409.4 368L372.7 404.7C366.4 410.9 366.4 421.1 372.7 427.3C378.9 433.6 389.1 433.6 395.3 427.3L432 390.6L468.7 427.3C474.9 433.6 485.1 433.6 491.3 427.3C497.6 421.1 497.6 410.9 491.3 404.7L454.6 368L491.3 331.3z"></path>
                </svg></div>
            <div class="px-3">
                <h4 class="fw-bold mb-0">{this.props.countDisplay(this.props.allCount)}</h4>
            </div>
        </div>
        </OverlayTrigger>
        </>
        )
    }
}


const mapStateToProps = state => {
    return {
        ...state.sales
    }
}

const mapDispatchToProps = dispatch => {
    return {
        countDisplay: (count) => countDisplay(count),
        buttonClass: (dark) => buttonClass(dark),
        fetchCount: (page, pagesize) => dispatch(fetchProspectCount(page, pagesize)),
        fetchProspects: (page, pagesize) => dispatch(fetchProspects(page, pagesize)),
        toggleProspectDisplay: () => dispatch(toggleProspectDisplay(_filter)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllProspectsButton);