import { useSelector } from 'react-redux';
import Invite from './invite';
import Landing from './landing';
import ClientsBook from './book';
const Index = (props) => {

  const currentDisplay = useSelector( state => state.clients?.currentDisplay)

  return (
      <>
        { currentDisplay === "landing" && < Landing /> }
        { currentDisplay === "inviteNewClient" && < Invite /> } 
        { currentDisplay === "clientsBook" && < ClientsBook /> }
      </>
  );
};

export default Index;