import React, { Component } from 'react';
import { connect } from "react-redux";
import { AsyncTypeahead, Menu, MenuItem  } from "react-bootstrap-typeahead";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { fetchProducts, changePageSize, applySort, showProduct } from "../../../../store/loanProducts/actions";
import Pagination from './Pagination';



const renderTooltip = props => (
    <Tooltip {...props}>{props?.locationtext}</Tooltip>
  );
  
function SortArrow (props)  {
    var sort = props.sort.indexOf(props.sortField) === -1 ? 
                props.sortField + "-down" : 
                props.up ? props.sortField + "-down" : props.sortField + "-up"
    return (
        <>
        <a href="#" onClick={() => {
            props.applySort( sort )
            props.fetchProductRows(props.selectedLender.name, props.filters, sort, props.displayPage, props.pageSize)
                }} >        
        {props.off ?
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-96 0 512 512" width="1em" height="1em" fill="currentColor">
                <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224zM292.3 288H27.66c-24.6 0-36.89 29.77-19.54 47.12l132.5 136.8C145.9 477.3 152.1 480 160 480c7.053 0 14.12-2.703 19.53-8.109l132.3-136.8C329.2 317.8 316.9 288 292.3 288z"></path>
            </svg> : props.up ?
                <svg class="text-info" xmlns="http://www.w3.org/2000/svg" viewBox="-96 0 512 512" width="1em" height="1em" fill="currentColor">
                    <path d="M27.66 224h264.7c24.6 0 36.89-29.78 19.54-47.12l-132.3-136.8c-5.406-5.406-12.47-8.107-19.53-8.107c-7.055 0-14.09 2.701-19.45 8.107L8.119 176.9C-9.229 194.2 3.055 224 27.66 224z"></path>
                </svg> : 
                <svg class="text-info" xmlns="http://www.w3.org/2000/svg" viewBox="-96 0 512 512" width="1em" height="1em" fill="currentColor">
                    <path d="M311.9 335.1l-132.4 136.8C174.1 477.3 167.1 480 160 480c-7.055 0-14.12-2.702-19.47-8.109l-132.4-136.8C-9.229 317.8 3.055 288 27.66 288h264.7C316.9 288 329.2 317.8 311.9 335.1z"></path>
                </svg> }
                </a>
        </>
    );
}

class ProductsTable extends Component {
	constructor(props) {
        super(props);
    }
        
    render () {
        const filterBy = () => true;
        const showProductAction = this.props.showProduct;
        return (
            <div>
                <div class="table-responsive">
                    <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>
                                <div class="hstack gap-0">
                                    <span>Product name</span>
                                    <SortArrow {...this.props} 
                                        sortField={"productName"} 
                                        up={this.props.sort === 'productName-up'} 
                                        off={this.props.sort.indexOf('productName') === -1}  />
                                    </div>
                            </th>
                            <th>
                                <div class="hstack gap-0">
                                    <span>Variation</span>
                                    <SortArrow {...this.props} 
                                        sortField={"variation"} 
                                        up={this.props.sort === 'variation-up'} 
                                        off={this.props.sort.indexOf('variation') === -1}  />
                                    </div>
                            </th>
                            <th>
                                <div class="hstack gap-0">
                                    <span>Interest rate</span>
                                    <SortArrow {...this.props} 
                                        sortField={"ratesAndFees.interestRate"} 
                                        up={this.props.sort === 'ratesAndFees.interestRate-up'} 
                                        off={this.props.sort.indexOf('ratesAndFees.interestRate') === -1}  />
                                    </div>
                            </th>
                            <th>
                                <div class="hstack gap-0">
                                    <span>Comparison rate</span>
                                    <SortArrow {...this.props} 
                                        sortField={"ratesAndFees.comparisonRate"} 
                                        up={this.props.sort === 'ratesAndFees.comparisonRate-up'} 
                                        off={this.props.sort.indexOf('ratesAndFees.comparisonRate') === -1}  />
                                    </div>
                            </th>
                            <th>Select</th>
                        </tr>
                    </thead>
                        
                        {this.props.selectedLender == null ? 
                            <></> : 
                            <tbody>{
                            this.props.lenderProducts.map(function(each){
                                return (<tr>
                                    <td>{each.productName}</td>
                                    <td>{each.variation}</td>
                                    <td>{each.ratesAndFees.interestRatePI == null ? each.ratesAndFees.interestRateIO : each.ratesAndFees.interestRatePI}</td>
                                    <td>{each.ratesAndFees.comparisonRatePI == null ? each.ratesAndFees.comparisonRateIO : each.ratesAndFees.comparisonRatePI}</td>
                                    <td>
                                    <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Show details'})} >
                                    <i class="fas fa-binoculars" data-bs-toggle="modal" data-bs-target="#modal-loanproduct" 
                                    onClick={() => {showProductAction(each)}}
                                    ></i>
                                    </OverlayTrigger>
                                    </td>
                                    </tr>);
                            })
                            }</tbody>
                        }
                    </table>
                </div>

                <div class="row">
                    <div class="col-4">
                        <div class="input-group"><span class="input-group-text">Page size</span>
                        <AsyncTypeahead
                            filterBy={filterBy}
                            id="async-products-page-size"
                            labelKey={option => {
                                if (option != null)
                                    this.props.changePageSize(option)
                                return option === null ? `${this.props.pageSize}` : `${option}` }}
                            minLength={0}
                            options={[6,10,25,50,75,100]}
                            renderMenu={(results, menuProps) => (
                                <Menu {...menuProps}  >
                                {results.map((result, index) => (
                                    <MenuItem option={result} position={index} >{result}</MenuItem>
                                ))}
                                </Menu>
                            )}
                        />   
                        </div>
                    </div>
                    <div class="col d-lg-flex justify-content-lg-end">
                        <Pagination />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ loanProducts }) => {
  return { 
    selectedLender: loanProducts.selectedLender,
    lenderProducts: loanProducts.lenderProducts,
    pageSize: loanProducts.pageSize,
    sort: loanProducts.sort,
    filters: loanProducts.filters,
    displayPage: loanProducts.displayPage,
    };
};

const mapDispatchToProps = (dispatch)=> {
    return {
        fetchProductRows: (lenderName, filters, sort, page, pagesize) => dispatch(fetchProducts(lenderName, filters, sort, page, pagesize)),
        changePageSize: (pageSize) => dispatch(changePageSize(pageSize)),
        applySort: (sort) => {dispatch(applySort(sort))},
        showProduct: (selectedProduct) => {dispatch(showProduct(selectedProduct))}
    };
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(ProductsTable);