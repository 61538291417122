import React, {Component,  } from 'react';
import {connect} from 'react-redux';
import ClientMultiStepForm from './ClientMultiStepForm';
import { clearAllDocs } from '../../../store/sales/editDocuments/actions'
import { fetchBorrowerChatMessages,  } from "../../../store/clients/actions";
import { fetchBorrowerProposal, editProposal, loanProposalMenuAction, showMultiStep } from "../../../store/sales/actions";
import { EXPENSES_VIEW, ASSETS_AND_LIABILITIES_VIEW, REQUIREMENTS_VIEW, DOCUMENTS_VIEW, SUMMARY_VIEW, MESSAGES_VIEW, LOAN_PROPOSALS_VIEW } from '../../../store/sales/constants';


function Menu(props) {
    return (
        <>
            <ul class="nav nav-pills flex-column mb-auto">
                <li class="nav-item"><a class={props.view == SUMMARY_VIEW ? "nav-link active link-light" : "nav-link link-body-emphasis"} href="#" aria-current="page" 
                    onClick={(e) => {
                        e.preventDefault();
                        props.setView(SUMMARY_VIEW)}} >
                    <svg class="bi bi-yin-yang fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M9.167 4.5a1.167 1.167 0 1 1-2.334 0 1.167 1.167 0 0 1 2.334 0Z"></path>
                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM1 8a7 7 0 0 1 7-7 3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 0 0 7 7 7 0 0 1-7-7Zm7 4.667a1.167 1.167 0 1 1 0-2.334 1.167 1.167 0 0 1 0 2.334Z"></path>
                    </svg> Summary and Profile</a></li>
                <li class="nav-item"><a class={props.view == MESSAGES_VIEW ? "nav-link active link-light" : "nav-link link-body-emphasis"} href="#" 
                    onClick={(e) => {
                        e.preventDefault();
                        props.setView(MESSAGES_VIEW)}} >
                    <svg class="bi bi-chat me-2" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894m-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"></path>
                    </svg> Messages </a></li>
                <li class="nav-item"><a class={props.view == DOCUMENTS_VIEW ? "nav-link active link-light" : "nav-link link-body-emphasis"} href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        props.setView(DOCUMENTS_VIEW)}}>
                    <svg class="bi bi-filetype-doc fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M14 4.5V14a2 2 0 0 1-2 2v-1a1 1 0 0 0 1-1V4.5h-2A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v9H2V2a2 2 0 0 1 2-2h5.5L14 4.5Zm-7.839 9.166v.522c0 .256-.039.47-.117.641a.861.861 0 0 1-.322.387.877.877 0 0 1-.469.126.883.883 0 0 1-.471-.126.868.868 0 0 1-.32-.386 1.55 1.55 0 0 1-.117-.642v-.522c0-.257.04-.471.117-.641a.868.868 0 0 1 .32-.387.868.868 0 0 1 .471-.129c.176 0 .332.043.469.13a.861.861 0 0 1 .322.386c.078.17.117.384.117.641Zm.803.519v-.513c0-.377-.068-.7-.205-.972a1.46 1.46 0 0 0-.589-.63c-.254-.147-.56-.22-.917-.22-.355 0-.662.073-.92.22a1.441 1.441 0 0 0-.589.627c-.136.271-.205.596-.205.975v.513c0 .375.069.7.205.973.137.271.333.48.59.627.257.144.564.216.92.216.357 0 .662-.072.916-.216.256-.147.452-.356.59-.627.136-.274.204-.598.204-.973ZM0 11.926v4h1.459c.402 0 .735-.08.999-.238a1.45 1.45 0 0 0 .595-.689c.13-.3.196-.662.196-1.084 0-.42-.065-.778-.196-1.075a1.426 1.426 0 0 0-.59-.68c-.263-.156-.598-.234-1.004-.234H0Zm.791.645h.563c.248 0 .45.05.609.152a.89.89 0 0 1 .354.454c.079.201.118.452.118.753a2.3 2.3 0 0 1-.068.592 1.141 1.141 0 0 1-.196.422.8.8 0 0 1-.334.252 1.298 1.298 0 0 1-.483.082H.79V12.57Zm7.422.483a1.732 1.732 0 0 0-.103.633v.495c0 .246.034.455.103.627a.834.834 0 0 0 .298.393.845.845 0 0 0 .478.131.868.868 0 0 0 .401-.088.699.699 0 0 0 .273-.248.8.8 0 0 0 .117-.364h.765v.076a1.268 1.268 0 0 1-.226.674c-.137.194-.32.345-.55.454a1.81 1.81 0 0 1-.786.164c-.36 0-.664-.072-.914-.216a1.424 1.424 0 0 1-.571-.627c-.13-.272-.194-.597-.194-.976v-.498c0-.379.066-.705.197-.978.13-.274.321-.485.571-.633.252-.149.556-.223.911-.223.219 0 .421.032.607.097.187.062.35.153.489.272a1.326 1.326 0 0 1 .466.964v.073H9.78a.85.85 0 0 0-.12-.38.7.7 0 0 0-.273-.261.802.802 0 0 0-.398-.097.814.814 0 0 0-.475.138.868.868 0 0 0-.301.398Z"></path>
                    </svg> Documents </a></li>
                <li class="nav-item"><a class={props.view == REQUIREMENTS_VIEW ? "nav-link active link-light" : "nav-link link-body-emphasis"} href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        props.setView(REQUIREMENTS_VIEW)}}>
                    <svg class="bi bi-basket fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M5.757 1.071a.5.5 0 0 1 .172.686L3.383 6h9.234L10.07 1.757a.5.5 0 1 1 .858-.514L13.783 6H15a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1v4.5a2.5 2.5 0 0 1-2.5 2.5h-9A2.5 2.5 0 0 1 1 13.5V9a1 1 0 0 1-1-1V7a1 1 0 0 1 1-1h1.217L5.07 1.243a.5.5 0 0 1 .686-.172zM2 9v4.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V9H2zM1 7v1h14V7H1zm3 3a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 4 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 6 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3A.5.5 0 0 1 8 10zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5zm2 0a.5.5 0 0 1 .5.5v3a.5.5 0 0 1-1 0v-3a.5.5 0 0 1 .5-.5z"></path>
                    </svg> Client requirements </a></li>
                <li class="nav-item"><a class={props.view == ASSETS_AND_LIABILITIES_VIEW ? "nav-link active link-light" : "nav-link link-body-emphasis"} href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        props.setView(ASSETS_AND_LIABILITIES_VIEW)}}><svg class="bi bi-piggy-bank-fill fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M7.964 1.527c-2.977 0-5.571 1.704-6.32 4.125h-.55A1 1 0 0 0 .11 6.824l.254 1.46a1.5 1.5 0 0 0 1.478 1.243h.263c.3.513.688.978 1.145 1.382l-.729 2.477a.5.5 0 0 0 .48.641h2a.5.5 0 0 0 .471-.332l.482-1.351c.635.173 1.31.267 2.011.267.707 0 1.388-.095 2.028-.272l.543 1.372a.5.5 0 0 0 .465.316h2a.5.5 0 0 0 .478-.645l-.761-2.506C13.81 9.895 14.5 8.559 14.5 7.069c0-.145-.007-.29-.02-.431.261-.11.508-.266.705-.444.315.306.815.306.815-.417 0 .223-.5.223-.461-.026a.95.95 0 0 0 .09-.255.7.7 0 0 0-.202-.645.58.58 0 0 0-.707-.098.735.735 0 0 0-.375.562c-.024.243.082.48.32.654a2.112 2.112 0 0 1-.259.153c-.534-2.664-3.284-4.595-6.442-4.595Zm7.173 3.876a.565.565 0 0 1-.098.21.704.704 0 0 1-.044-.025c-.146-.09-.157-.175-.152-.223a.236.236 0 0 1 .117-.173c.049-.027.08-.021.113.012a.202.202 0 0 1 .064.199Zm-8.999-.65a.5.5 0 1 1-.276-.96A7.613 7.613 0 0 1 7.964 3.5c.763 0 1.497.11 2.18.315a.5.5 0 1 1-.287.958A6.602 6.602 0 0 0 7.964 4.5c-.64 0-1.255.09-1.826.254ZM5 6.25a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Z"></path>
                    </svg> Assets and Liabilities </a></li>
                <li class="nav-item"><a class={props.view == EXPENSES_VIEW ? "nav-link active link-light" : "nav-link link-body-emphasis"} href="#"
                    onClick={(e) => {
                        e.preventDefault();
                        props.setView(EXPENSES_VIEW)}}>
                    <svg class="bi bi-coin fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                        <path d="M5.5 9.511c.076.954.83 1.697 2.182 1.785V12h.6v-.709c1.4-.098 2.218-.846 2.218-1.932 0-.987-.626-1.496-1.745-1.76l-.473-.112V5.57c.6.068.982.396 1.074.85h1.052c-.076-.919-.864-1.638-2.126-1.716V4h-.6v.719c-1.195.117-2.01.836-2.01 1.853 0 .9.606 1.472 1.613 1.707l.397.098v2.034c-.615-.093-1.022-.43-1.114-.9H5.5zm2.177-2.166c-.59-.137-.91-.416-.91-.836 0-.47.345-.822.915-.925v1.76h-.005zm.692 1.193c.717.166 1.048.435 1.048.91 0 .542-.412.914-1.135.982V8.518l.087.02z"></path>
                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"></path>
                        <path d="M8 13.5a5.5 5.5 0 1 1 0-11 5.5 5.5 0 0 1 0 11zm0 .5A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"></path>
                    </svg> Expenses </a></li>
            </ul>
        </>
    );
}


class ClientPanel extends Component {

    componentDidMount() {
        if (this.props?.prospectInView) {
            this.props.fetchBorrowerChatMessages(this.props.prospectInView.mainBorrower._id)
            this.props.fetchBorrowerProposal(this.props.prospectInView.mainBorrower._id)
        }
    }

    render() {
        return (
        <>
            <section>
               <div class="row">
                {this.props.currentDisplay == 'multiStep' || this.props.currentDisplay === 'proposalEdit' || this.props.currentDisplay === 'proposalView' ? null :
                    <div class="col-2">
                        <div>
                            <Menu setView={this.props.loanProposalMenuAction} view={this.props.selectedMenu}  />
                        </div>
                        <div>
                            <hr />
                            <div class="dropdown">
                                <a class="dropdown-toggle link-body-emphasis d-flex align-items-stretch text-decoration-none" aria-expanded="false" data-bs-toggle="dropdown" role="button"><svg class="bi bi-briefcase fs-2" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                            <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z"></path>
                                        </svg><strong style={{'marginLeft':'2%'}}>Loan proposals</strong></a>
                                <div class="dropdown-menu shadow text-small" data-popper-placement="top-start">
                                    <a class="dropdown-item" href="#"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            this.props.clearAllDocs()
                                            this.props.showMultiStep()
                                        }}>Start a new proposal..</a>
                                    {this.props.prospectInView?.proposals && this.props.prospectInView.proposals.length > 0 ?
                                    <a class="dropdown-item" href="#"
                                        onClick={(e) => {
                                            this.props.loanProposalMenuAction(LOAN_PROPOSALS_VIEW)
                                        }}>Show proposals</a> : null}
                                </div>
                            </div>
                        </div>
                    </div> }        
                    <div class="col"><ClientMultiStepForm /> </div>  
                </div>             
            </section>
        </>
        )
    }
}

const mapStateToProps = state => {
    return {
        prospectInView : state.clients.prospectInView,
        proposalInView : state.sales.proposalInView,
		trialAccountMode: state.upgradePage.trialAccountMode,
		selectedMenu: state.sales?.selectedMenu,
		currentDisplay: state.sales?.currentDisplay,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchBorrowerChatMessages: (borrowerId) => { dispatch(fetchBorrowerChatMessages(borrowerId)) },
        fetchBorrowerProposal: (borrowerId) => { dispatch(fetchBorrowerProposal(borrowerId)) },
        editProposal: () => dispatch(editProposal()),
        showMultiStep: () => dispatch(showMultiStep()),
        clearAllDocs: () => dispatch(clearAllDocs()),
        loanProposalMenuAction: (view) => dispatch(loanProposalMenuAction(view)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClientPanel);