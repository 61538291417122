import axios from 'axios';
import { fetchFailure, fetchLoading, fetchSuccess, 
    fetchSubscriptionFailure, fetchSubscriptionLoading, fetchSubscriptionSuccess,
    fetchExtrasLoading, fetchExtrasFailure, fetchExtrasSuccess,
    mobileUpdateError, agencyDetailsSaveErrors, agencyDetailsSaveSuccess, 
    
    saveSuccess, saveSuccessClose } from "./actionTypes"
import { getActiveSubscription, getBrokerAgent, getExtras, getAccessRoles } from '../request'
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import store from "../store";

function validateAndFormat(mobile) {
    if (mobile == null || mobile == "") return null;
    const phoneUtil = PhoneNumberUtil.getInstance();
    const number = phoneUtil.parseAndKeepRawInput(mobile, 'AU');
    var phone_number = phoneUtil.format(number, PhoneNumberFormat.E164);
    return phone_number
}


export const fetchBrokerAgent = () => {
    return function(dispatch) {
        dispatch(fetchLoading()) 
        dispatch(getBrokerAgent())
            .then(response => {
                dispatch(fetchSuccess(response?.data))
            })
            .catch(error => {
                dispatch(fetchFailure(error))
            })
    }
}

export const fetchSubscription = () => async (dispatch) => {
        dispatch(fetchSubscriptionLoading()) 
        getActiveSubscription()
            .then(response => {
                dispatch(fetchSubscriptionSuccess(response?.data))
            })
            .catch(error => {
                dispatch(fetchSubscriptionFailure(error))
            })
}


export const fetchExtras = () => async (dispatch) => {
        dispatch(fetchExtrasLoading()) 
        getExtras()
            .then(response => {
                //console.log(response)
                dispatch(fetchExtrasSuccess(response?.data))
            })
            .catch(error => {
                dispatch(fetchExtrasFailure(error))
            })
}

export const updateMobileNumber = (props) => async (dispatch) => {
    var regexMobile = /^04[0-9]{8}$/;
    const mobile = props.brokerAgent.contact.primaryPhone
    var errors = [
        {type:"mobile", 
          valid:mobile != null && regexMobile.test(mobile),
          errorMessage : 'Enter a valid mobile phone number',
        }
    ]
    .filter((element) => {return !element?.valid;});
    if (errors.length > 0)
      dispatch(mobileUpdateError(errors));
    else {
        const phoneNumber = validateAndFormat(props.brokerAgent.contact.primaryPhone)
        props.brokerAgent.contact.primaryPhone = phoneNumber
        dispatch(updateBroker(props))
        .then(() => {
            dispatch(fetchBrokerAgent())
        })
        .then(() => {
            dispatch(showModal('basic_details'))
        })
    }    
}

export const saveAgencyDetails = (props) => async (dispatch) => {
    var regexEmail = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
    // This pattern matcher validates all phone numbers must be AU standard
	var regexPhone = /^(04|02|03|07|08)[0-9]{8}$/;

    const phone = props?.agency?.companyDetails?.contact?.primaryPhone
    const email = props?.agency?.companyDetails?.contact?.primaryEmail
    const companyAddress = props?.agency?.companyDetails?.companyAddress

    var errors = [
        {type:"phone", 
            valid:phone != null && regexPhone.test(phone),
            errorMessage : 'Enter a valid mobile phone number',
        },
        {type:"email", 
            valid:email != null && regexEmail.test(email),
            errorMessage : 'Enter a valid email address',
        },
        {type:"companyAddress", 
            valid:companyAddress != null && companyAddress?.externalId != null ,
            errorMessage : 'Missing company address',
        }
    ]
    .filter((element) => {return !element?.valid;});

    if (errors.length > 0)
        dispatch(agencyDetailsSaveErrors(errors));
    else {
        dispatch(patchAgencyDetails(props))
        .then(() => {
            dispatch(agencyDetailsSaveSuccess())

            if (props.onSuccess != null)
                props.onSuccess();
        }).then(() => {
            dispatch(fetchBrokerAgent())
        }).then(() => {
            dispatch(showModal('agency_details'))
        })
    }   
}

export const updateBroker = (props) => async (dispatch) => {
    const brokerAgent = props.brokerAgent
    const brokerAgent_id = props.brokerAgent._id
    const response = await axios.patch(process.env.REACT_APP_API_baseuri + `/brokeragents/${brokerAgent_id}`, 
            brokerAgent,
            { headers: {
                'Authorization': store.getState().auth.token, 'Content-Type': 'application/json',
            }}
            )
    return response
}

export const patchAgencyDetails = (props) => async (dispatch) => {
    const brokerAgent_id = props.agency._id
    const response = await axios.patch(process.env.REACT_APP_API_baseuri + `/brokeragencies/${brokerAgent_id}`, 
            props.agency,
            { headers: {
                'Authorization': store.getState().auth.token, 'Content-Type': 'application/json',
            }}
            )

    return response
}
   

export const showModal = (source) => {

    return function(dispatch) {
        dispatch(saveSuccess(source))
    }
}

export const hideModal= () => {
    return function(dispatch) {
        dispatch(saveSuccessClose())
    }
}

export const actions = { fetchBrokerAgent, updateMobileNumber, saveAgencyDetails, hideModal, };