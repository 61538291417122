import React from "react";
import { Spinner } from 'react-bootstrap';

import { useDispatch, useSelector } from 'react-redux';
import Moment from 'moment';

import AddressAutofill from "../../../common/addressAutofill";
import ErrorContainer from "../../../common/ErrorContainer";
import { createPaymentReference, fetchPaymentReference } from '../../../../store/upgrades/actions';

const PayeeDetails = (props) => {
    const [planChecked, setPlanChecked] = React.useState(false)
    const [paymentAuthorityChecked, setPaymentAuthority] = React.useState(false)
    const [setupChargeChecked, setSetupCharge] = React.useState(false)
    const [title, setTitle] = React.useState()
    const [firstName, setFirstName] = React.useState()
    const [lastName, setLastName] = React.useState()
    const [waiting, setWaiting] = React.useState(false)
    const [billingEmail, setBillingEmail] = React.useState()
    const [formErrors, setFormErrors] = React.useState([])

    const dispatch = useDispatch()
    const displayAddress = useSelector(state => state?.companyAddressAutocomplete?.selectedAddress)
    const uniqueReference = useSelector(state => state.upgradePage?.uniqueReference)
    const subscriptionPackage = useSelector(state => state.upgradePage?.subscriptionPackage)
    const optionalExtras = useSelector(state => state.upgradePage?.optionalExtras)
    const agency = useSelector( state => state?.brokerAgentProfile?.agency)
    const agent = useSelector( state => state?.brokerAgentProfile)
    const setUpFee = useSelector(state => state.upgradePage?.fixedSetUpRate)

    return (
    <div id="form-content-2" class="multisteps-form__content">
        <div>
            <div class="hstack gap-3">
                <h5>Billing details</h5>
            </div>
            <div class="row">
                <div class="col">
                {subscriptionPackage == null || props?.readOnly ? <></> :
                    <div>
                    <ErrorContainer error={formErrors.find((element) =>  element?.type == 'planChecked' && !element?.valid)}>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="formCheck-1" 
                            checked={planChecked ? "checked" : ""} onChange={() => setPlanChecked(!planChecked)} />
                            {subscriptionPackage.name === 'Basic' ? 
                        <label class="form-check-label" for="formCheck-1">
                            Select <strong>{subscriptionPackage.name}</strong> plan valued at <strong>{subscriptionPackage.rate.currency} {subscriptionPackage.rate.value}</strong> plus GST for <strong>{subscriptionPackage.type == 'Quarterly' ? '3 months' : '12 months'}</strong> starting <strong>{Moment().format('Do MMMM YYYY')}</strong></label>
                            : subscriptionPackage.name === 'Teams' ?
                        <label class="form-check-label" for="formCheck-1">
                            Select <strong>{subscriptionPackage.name}</strong> plan valued at <strong>{subscriptionPackage.rate.currency} {subscriptionPackage.rate.value}</strong> plus GST for each <strong>client case</strong> starting <strong>{Moment().format('Do MMMM YYYY')}</strong></label>
                            : null
                            }
                    </div>
                    </ErrorContainer>

                    {
                        subscriptionPackage.name === 'Teams' || props.readOnly ? 
                    <ErrorContainer error={formErrors.find((element) => element?.type == 'setupCharge' && !element?.valid)}>
                    <div class="form-check"><input class="form-check-input" type="checkbox" id="formCheck-2" 
                        checked={setupChargeChecked ? "checked" : ""} onChange={() => setSetupCharge(!setupChargeChecked)} /><label class="form-check-label" for="formCheck-2">
                            If you choose to proceed, you will be charged a non refundable set up charge of <strong>AUD {setUpFee}</strong> <br/></label></div>
                    </ErrorContainer>
                        : null
                    }

                    <ErrorContainer error={formErrors.find((element) => element?.type == 'paymentAuthorityChecked' && !element?.valid)}>
                    <div class="form-check"><input class="form-check-input" type="checkbox" id="formCheck-2" 
                        checked={paymentAuthorityChecked ? "checked" : ""} onChange={() => setPaymentAuthority(!paymentAuthorityChecked)} /><label class="form-check-label" for="formCheck-2">
                            I confirm that I am authorised to accept this payment arrangement on behalf of {agency.companyDetails?.companyName}<br/></label></div>
                    </ErrorContainer>
                    </div>}

                    <div class="row">
                        <div class="col">
                            <div class="vstack">
                                <div id="input-grp-single-14" class="form-row mt-4">
                                    <div class="hstack gap-2">
                                        <div class="hstack d-lg-flex flex-shrink-1 justify-content-lg-start">
                                            <ErrorContainer error={formErrors.find((element) => element?.type == 'title' && !element?.valid)}>
                                                    <div class="form-check"><input class="form-check-input" type="radio" id="formCheck-3" name="title" 
                                                disabled={props?.readOnly}
                                                        checked={title === 'Mr'} onChange={() => {setTitle('Mr')}} /><label class="form-check-label" for="formCheck-3">Mr</label></div>
                                                    <div class="form-check"><input class="form-check-input" type="radio" id="formCheck-4" name="title"
                                                disabled={props?.readOnly}
                                                        checked={title === 'Mrs'} onChange={()=>{setTitle('Mrs')}}/><label class="form-check-label" for="formCheck-4">Mrs</label></div>
                                                    <div class="form-check"><input class="form-check-input" type="radio" id="formCheck-5" name="title"
                                                disabled={props?.readOnly}
                                                        checked={title === 'Ms'} onChange={() => {setTitle('Ms')}}/><label class="form-check-label" for="formCheck-5">Ms</label></div>
                                            </ErrorContainer>
                                        </div>
                                        <div class="vstack gap-2">
                                            <ErrorContainer error={formErrors.find((element) => element?.type === 'firstName' && !element?.valid)}>
                                                            <input class="form-control  multisteps-form__input" type="text" name="firstName"  placeholder="First name" 
                                                disabled={props?.readOnly} onChange={(e)=>{setFirstName(e.target.value)}} />
                                            </ErrorContainer>
                                            <ErrorContainer error={formErrors.find((element) => element?.type === 'lastName' && !element?.valid)}>
                                                            <input class="form-control  multisteps-form__input" type="text" name="lastName" placeholder="Last name" 
                                                disabled={props?.readOnly} onChange={(e)=>{setLastName(e.target.value)}}/>
                                            </ErrorContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="input-grp-single-7" class="form-row mt-4">
                <div class="col-12">
                    <ErrorContainer error={formErrors.find((element) => element?.type == 'billingEmail' && !element?.valid)}>
                        <input class="form-control  multisteps-form__input" type="text" name="billingEmail" placeholder="Billing email" 
                            disabled={props?.readOnly} onChange={(e)=>{setBillingEmail(e.target.value)}}/>
                    </ErrorContainer>
                </div>
            </div>
            <div id="input-grp-single-7" class="form-row mt-4">
                <div class="col-12">
                    <ErrorContainer error={formErrors.find((element) => element?.type == 'address' && !element?.valid)}>
                    <AddressAutofill address={displayAddress} placeholder="Customer address" {...props} />
                    </ErrorContainer>
                </div>
            </div>
            {
                props?.readOnly ? null :
            <div id="input-grp-single-8" class="form-row mt-4">
                <div class="col-12">
                    <div class="hstack gap-2">
                        <button class="btn btn-primary" type="button"
                        onClick={ () => {props.prev()} }
                        >Prev
                        </button>
                        <button class="btn btn-primary" type="button"
                        disabled={!planChecked || !paymentAuthorityChecked || !title || !firstName || !lastName || ! billingEmail || !displayAddress}
                        onClick={ () => {
                            var checks = [
                                {type:"planChecked", valid:planChecked, errorMessage : '', },
                                {type:"paymentAuthorityChecked", valid:paymentAuthorityChecked, errorMessage : '', },
                                {type:"title", valid:title, errorMessage : '', },
                                {type:"firstName", valid:firstName, errorMessage : '', },
                                {type:"lastName", valid:lastName, errorMessage : '', },
                                {type:"billingEmail", valid:billingEmail, errorMessage : 'Billing email is mandatory', },
                                {type:"address", valid:displayAddress, errorMessage : 'Billing address is mandatory', },
                                subscriptionPackage.name == 'Teams' ? {type:"setupCharge", valid:setupChargeChecked, errorMessage : '', } : {valid:true}
                            ];
                            if (checks.find((element) => !element?.valid)) {
                                setFormErrors(checks)
                            } else {
                                const paymentReference = {
                                        title: title, firstName: firstName, lastName: lastName,
                                        billingEmail: billingEmail,
                                        paymentAddress : displayAddress,
                                        description: 'LoanSeller subscription payment',
                                        uniqueReference:  uniqueReference,
                                        subscriptions : 
                                        [
                                            {...subscriptionPackage},
                                        ].concat(optionalExtras)
                                    }
                                setWaiting(true)
                                //console.log(paymentReference)
                                dispatch(createPaymentReference(paymentReference))
                                setTimeout(() => {
                                    props.onSuccess(uniqueReference)
                                },2500)
                            }}}>
                        {waiting ? 
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            /> : <span>Next</span> }
                            
                        </button>
                    </div>
                </div>
            </div>
            }
        </div>
    </div>
);
};

export default PayeeDetails;
