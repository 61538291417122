import { Modal } from "react-bootstrap";
import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import BasicPackage from './BasicPackage';
import TeamsPackage from './TeamsPackage';
import SalesGenerator from './SalesGenerator';

const UpgradePackages = (props) => {
  const dispatch = useDispatch()
    return (
    <>
      <Modal
        {...props}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        dialogClassName="upgradesModal"
      >
        <Modal.Header closeButton style={{ color: "#7d7d7d" }}>
          <Modal.Title id="contained-modal-title-vcenter">
            LoanShopper feature packages
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#7d7d7d" }}>
            <div class="row gy-4 row-cols-1 row-cols-md-2 row-cols-xl-3 d-xl-flex justify-content-between">
                <BasicPackage {...props} hideStartToday={false} />
                <TeamsPackage {...props}/>
            </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default UpgradePackages;
