import { CHATS_ACTION_TYPES } from "./actionTypes";

import { getBrokerUnseenMessages, getProspect, getChatMessages, postChatMessage } from "../request";

export const switchBorrowerInView = (borrowerId) => async (dispatch) => {
    dispatch({
        type: CHATS_ACTION_TYPES.SET_BORROWER_IN_VIEW,
            payload: borrowerId,
        })
}

export const fetchBorrowersWithChats = () => async (dispatch) => {
    getBrokerUnseenMessages()
        .then(res => {
            if (res != null && res?.data) {
                var borrowers = []
                res.data.forEach(message => {
                    if (!borrowers.find(element => element == message.borrowerId)) {
                        borrowers.push(message.borrowerId)
                    }
                })
                dispatch({
                    type: CHATS_ACTION_TYPES.GET_BORROWERS_WITH_CHATS,
                    payload: { data: borrowers },
                });
            } else 
                dispatch({
                    type: CHATS_ACTION_TYPES.ERROR,
                    payload: { message: 'Failed to fetch brokers with unseen chat messages ' + res?.data?.error },
                });
        })
        .catch((error) => {
            dispatch({
                type: CHATS_ACTION_TYPES.ERROR,
                payload: { message: 'Failed to fetch borrowers ' + error },
            });
        });
}

export const addBorrowerChatMessage = (borrowerId, textMessage) => async (dispatch) => {
    postChatMessage(borrowerId, textMessage)
        .then(res => {
            dispatch(fetchBorrowerChatMessages(borrowerId))
            if (res?.success) 
                dispatch({
                    type: CHATS_ACTION_TYPES.POST_CHAT_MESSAGE,
                });
            else
                dispatch({
                type: CHATS_ACTION_TYPES.ERROR,
                    payload: { message: 'Failed to add chat message' },
                });
        })
        .catch((error) => {
            dispatch({
                type: CHATS_ACTION_TYPES.ERROR,
                payload: { message: 'Failed to add chat message' },
            });
        });
}

export const fetchBorrowerDetails = (borrowerId) => async (dispatch) => {
    getProspect(borrowerId)
        .then(res => {
            if (res != null && res?.data) {
                var borrower =  res.data?.mainBorrower ? 
                    JSON.parse("{\"" + res.data?.mainBorrower._id + "\":" + JSON.stringify(res.data.mainBorrower) + "}") : 
                    {}

                dispatch({
                    type: CHATS_ACTION_TYPES.GET_BORROWER_DETAILS,
                    payload: { data: borrower },
                });
            } else 
                dispatch({
                    type: CHATS_ACTION_TYPES.ERROR,
                    payload: { message: 'Failed to fetch brokers with unseen chat messages ' + res?.data?.error },
                });
        })
        .catch((error) => {
            dispatch({
                type: CHATS_ACTION_TYPES.ERROR,
                payload: { message: 'Failed to fetch borrowers ' + error },
            });
        });
}

export const fetchBorrowerChatMessages = (borrowerId) => async (dispatch) => {
    getChatMessages(borrowerId)
        .then(res => {
            if (res == null || res.data.error)
                dispatch({
                type: CHATS_ACTION_TYPES.ERROR,
                    payload: res.data.error,
                })
            else {
                var borrowerMessages = JSON.parse("{\"" + borrowerId + "\":" + JSON.stringify(res.data) + "}")
                dispatch({
                    type: CHATS_ACTION_TYPES.GET_BORROWER_CHATS,
                    payload: {data: borrowerMessages},
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: CHATS_ACTION_TYPES.ERROR,
                payload: { message: 'Failed to fetch chat messages' },
            });
        });
}