import React, {Component} from 'react';
import {connect} from 'react-redux';
import { useNavigate } from "react-router-dom";

import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import CloseButton from './CloseButton'
import EditProposalReview from "./EditProposal/ProposalReview"
import HiddenText from '../../common/HiddenText'


import { showProposal, createProposal, updateProposal, fetchProspects, discardProposalEdits, exit, clearErrors } from "../../../store/sales/actions";
import { showUpgradeWarning } from '../../../store/upgrades/actions';

const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);


function SubmitButton(props) {
    const navigate = useNavigate();
    //console.log(props)
    const callback = props.oldProsposal ?
                () => {
                    props.updateProposal(
                        props.oldProsposal._id,
                        props.selectedLender._id,
                        props.selectedLoanTermInYrs, 
                        props.loanScenarios,
                        props.requiredDocuments,
                        props?.mainProduct?.productName,
                        props?.mainProduct?.ratesAndFees?.interestRate,
                        props?.brokerDiscounts)

                    navigate("/",{replace:true})
                } :
                () => {
                    props.createProposal(
                        props.brokerAgent._id,
                        props.prospectInView.loanRequestId,
                        props.prospectInView._id,
                        props.selectedLender._id,
                        props.selectedLoanTermInYrs, 
                        props.loanScenarios,
                        props.requiredDocuments,
                        props?.mainProduct?.productName,
                        props?.mainProduct?.ratesAndFees?.interestRate,
                        props?.brokerDiscounts)

                    navigate("/",{replace:true})
                }
    return (
        <button class="btn btn btn-primary" type="button" data-bs-dismiss="modal" aria-label="Close" 
                onClick={props.allowSendProposal ? callback: props.upgrade } >
                {props.allowSendProposal ? 
                    props.oldProsposal  ? <span>Send updated proposal</span> : <span>Send proposal</span> :
                    <span>Upgrade to send proposal</span>  
                }
            </button>        
    )   ;
}


class Finalise extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        //console.log(this.props)
        const showPII = 
            this.props.accessRoles.filter(r => r.roleName === 'masked_prospect').length == 0  ||
            (this.props?.prospectInView?.clientConnectionId && this.props?.prospectInView?.connection.status === 'accepted' )
        const allowSendProposal = 
            this.props.accessRoles.filter(r => r.roleName === 'full_proposal').length > 0 ||    
                (this.props?.prospectInView?.clientConnectionId && 
                    this.props?.prospectInView?.connection.status === 'accepted' && 
                    this.props.accessRoles.filter(r => r.roleName === 'partial_prospect').length > 0) 
        const brokerDiscounts = this.props?.loanScenarios && this.props.loanScenarios.map(s => {
            return {
                loanProductId: s?.loanProduct?._id,
                discount : {homeLoanInterestRateDiscount : s?.brokersDiscount} } 
        })
        var applicantName = 
            this.props.prospectInView.mainBorrower.title + ' ' + this.props.prospectInView.mainBorrower.firstName + ' ' + this.props.prospectInView.mainBorrower.lastName
        applicantName  = this.props.prospectInView?.loanRequest?.borrowers?.jointApplicant ? 
                applicantName  + ' and ' + this.props.prospectInView?.loanRequest?.borrowers?.jointApplicant?.title + ' ' + this.props.prospectInView?.loanRequest?.borrowers?.jointApplicant?.firstName + ' ' + this.props.prospectInView?.loanRequest?.borrowers?.jointApplicant?.lastName :
                applicantName 
        return (
        <>
            <div id="single-form-next-prev-2" class="multisteps-form__panel shadow p-2 rounded bg-white" data-animation="scaleIn">

            <div class="vstack gap-1 d-xxl-flex flex-row flex-grow-1 flex-fill mb-1">
                <div class="hstack justify-content-between gap-1 d-xxl-flex flex-row flex-grow-1 flex-fill">
                    <h4 class="fw-bold d-xxl-flex mb-1">
                        <span>Loan proposal for &nbsp;</span><HiddenText unmasked={showPII}><span>{applicantName}</span></HiddenText>
                    </h4>
                    <div class="hstack justify-content-between">
                        <div id="next-prev-buttons-4" class="button-row d-flex">
                            <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Previous'})} >
                                <button class="btn btn btn-primary js-btn-prev" type="button" >Prev</button>
                            </OverlayTrigger>
                        </div>
                        <CloseButton onClose={this.props.discardEdits} />
                    </div>
                    <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Send proposal to borrower'})} >
                        <SubmitButton { ...this.props} allowSendProposal={allowSendProposal} brokerDiscounts={brokerDiscounts} />
                    </OverlayTrigger>
                </div>
            </div>
            <EditProposalReview /> 

            </div>

        </>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.sales,
        ...state.editDocuments,
        brokerAgent: state.brokerAgentProfile.brokerAgent,
        accessRoles: state.brokerAgentProfile.accessRoles,
        prospectInView : state.clients.prospectInView,
        oldProsposal : state.proposalSummary.proposalInView,
		trialAccountMode: state.upgradePage.trialAccountMode,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showProposal: () => dispatch(showProposal()),
        createProposal: (agentId, loanRequestId, prospectId, lenderId, loanProducts, requiredDocs, productName, interestRate, brokerDiscounts, onSuccess) => dispatch(createProposal(agentId, loanRequestId, prospectId, lenderId, loanProducts, requiredDocs, productName, interestRate, brokerDiscounts, onSuccess)),
        updateProposal: (proposalId, agentId, loanRequestId, prospectId, lenderId, selectedLoanTermInYrs, loanProducts, requiredDocs, productName, interestRate, brokerDiscounts, onSuccess, params) => dispatch(updateProposal(proposalId, agentId, loanRequestId, prospectId, lenderId, selectedLoanTermInYrs, loanProducts, requiredDocs, productName, interestRate,brokerDiscounts, onSuccess, params)),
        fetchProspects: (page, pagesize, prospectsFilter) => dispatch(fetchProspects(page, pagesize, prospectsFilter)),
        exit: () => dispatch(exit()),
        upgrade: () => dispatch(showUpgradeWarning()),
        discardEdits: () => dispatch(discardProposalEdits()),
        clearErrors: () => dispatch(clearErrors())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Finalise);