import { CLIENTS_ACTION_TYPES } from "./actionTypes";
import { SUMMARY_VIEW } from "../sales/constants";

export const initialState = {
    loading: false,
    currentDisplay: 'landing',
    post: 'N/A',
    error: '',
    inviteErrors: [],
    showResultModal: false,
    showUpgradeWarning:false,
    result: null,
    acknowledged: false,

    showActiveConnections: true,
    showNewConnections: false,

    invitationsCount: 0,
    activeConnectionCount: 0,
    displayedActiveConnectionCount:0,

    clientInvitations: [],
    clientConnections: [],
    prospects: [],


    invitationsDisplayPage: 1,
    invitationsPageSize:4,
    connectionsDisplayPage: 1,
    connectionsPageSize:3,

    prospectInView: null,
    clientBorrowingEstimates:null,
    borrowerDocuments:null,
    borrowerMessages:null,

    activeClientSearch:null,
    clientMenu : SUMMARY_VIEW,
    //unmasked: false,
}

export default function clientsReducer(state = initialState, action) {
    switch (action.type) {
        case CLIENTS_ACTION_TYPES.ADD_PROSPECT_TO_LIST:
            if (!state.prospects.find(e => e._id === action?.payload?.data?._id))
                state.prospects.push(action.payload.data)
            return { ...state, prospectInView : action.payload?.data, displayedActiveConnectionCount: state.prospects.length }
        case CLIENTS_ACTION_TYPES.CHANGE_DISPLAY:
            return {
                ...state,
                currentDisplay: action.payload
            }
        case CLIENTS_ACTION_TYPES.INVITE_ERRORS:
            return {
                ...state,
                inviteErrors: action.payload
            }
        case CLIENTS_ACTION_TYPES.SHOW_RESULT_MODAL: 
            return {
                ...state,
                showResultModal: true,
                result: action.payload
            }
        case CLIENTS_ACTION_TYPES.HIDE_RESULT_MODAL: 
            return {
                ...state,
                showResultModal: false,
                result: null
            }
        case CLIENTS_ACTION_TYPES.GET_NEW_CONNECTION_COUNT:
            var pageSize = state.invitationsPageSize > action.payload.count ? action.payload.count : state.invitationsPageSize
            return {
                ...state,
                invitationsCount: action.payload?.success  ? action.payload.count : 0, invitationsPageSize : pageSize
            }
        case CLIENTS_ACTION_TYPES.GET_ACTIVE_CONNECTION_COUNT:
            return {
                ...state,
                activeConnectionCount: action.payload?.success  ? action.payload.count : 0,
            }
        case CLIENTS_ACTION_TYPES.SWITCH_BOOK:
            return {
                ...state,
               showActiveConnections: action.payload, showNewConnections: !action.payload 
            }
        case CLIENTS_ACTION_TYPES.GET_NEW_CONNECTIONS:
            return {
                ...state,
                clientInvitations: action.payload.data,
                invitationsDisplayPage: action.payload.page,
            }
        case CLIENTS_ACTION_TYPES.GET_ACTIVE_CONNECTIONS:
            return {
                ...state,
                clientConnections: action.payload.data,
                connectionsDisplayPage: action.payload.page,
                activeClientSearch: action.payload.activeClientSearch,
                prospects: [],
            }
        case CLIENTS_ACTION_TYPES.CLIENT_BOOK_ERRORS:
            return {
                ...state,
                error: action.payload
            }
        case CLIENTS_ACTION_TYPES.CLEAR_CLIENT_BOOK_ERRORS:
            return {
                ...state,
                error: null
            }
        case CLIENTS_ACTION_TYPES.CLIENT_IN_DETAIL_VIEW:
            return {
                ...state,
                prospectInView: action.payload,
            }
        case CLIENTS_ACTION_TYPES.GET_CLIENT_BORROWING_ESTIMATES:
            return {
                ...state,
                clientBorrowingEstimates: action.payload.data,
            }
        case CLIENTS_ACTION_TYPES.GET_BORROWER_DOCUMENTS:
            return {
                ...state,
                borrowerDocuments: action.payload.data,
            }
        case CLIENTS_ACTION_TYPES.GET_DOCUMENT:
            return {...state }
        case CLIENTS_ACTION_TYPES.POST_CHAT_MESSAGE:
            return {...state }
        case CLIENTS_ACTION_TYPES.GET_CHAT_MESSAGES:
            return {
                ...state,
                borrowerMessages: {...action.payload.data},
            }
        case CLIENTS_ACTION_TYPES.SAW_CHAT_MESSAGE:
            return {...state }
        case CLIENTS_ACTION_TYPES.SWITCH_MASKED:
            return { ...state,  }
        case CLIENTS_ACTION_TYPES.CHANGE_MENU:
        return {
            ...state,
            clientMenu : action.payload,
        }      

        default:
            return state
    }
}
  