import React from 'react';
import classNames from  'classnames';
import SaveAgencyButton from '../buttons/saveAgency';
import AddressAutoFill from './companyAddressAutofill';
import NameAutoFill from './companyNameAutofill';
import { useSelector } from 'react-redux';
import ErrorContainer from '../../../common/ErrorContainer';

function CompanyDetails(props) {
    const displayEmail = useSelector(state => state?.brokerAgentProfile?.agency?.companyDetails?.contact?.primaryEmail)
    const [email, setEmail] = React.useState(displayEmail)
    const displayPhone = useSelector(state => state?.brokerAgentProfile?.agency?.companyDetails?.contact?.primaryPhone)
    const [phone, setPhone] = React.useState(displayPhone)
    const displayWebsite = useSelector(state => state?.brokerAgentProfile?.agency?.companyDetails?.companyWebsite)
    const [website, setWebsite] = React.useState(displayWebsite)
    const displayMeta = useSelector(state => state?.brokerAgentProfile?.agency?.companyDetails?.companyFacebook)
    const [meta, setMeta] = React.useState(displayMeta)
    const displayLinkedIn = useSelector(state => state?.brokerAgentProfile?.agency?.companyDetails?.companyLinkedIn)
    const [linkedIn, setLinkedIn] = React.useState(displayLinkedIn)
    const displayTwitter = useSelector(state => state?.brokerAgentProfile?.agency?.companyDetails?.companyTwitter)
    const [twitter, setTwitter] = React.useState(displayTwitter)

    const agencySaveErrors = useSelector( state => state.brokerAgentProfile?.agencySaveErrors )
    const agency = useSelector( state => state?.brokerAgentProfile?.agency)
    const displayCompany = useSelector(state => {
        return state?.companyNameAutocomplete?.selectedCompany  ? 
            state?.companyNameAutocomplete?.selectedCompany : 
            agency && agency?.companyDetails ? { abn: agency?.companyDetails.abn, companyName: agency?.companyDetails.companyName} : 
            null
        } )
    const displayAddress = useSelector(state => {
        return state?.companyAddressAutocomplete?.selectedAddress == null ?
            agency?.companyDetails?.companyAddress :
            state?.companyAddressAutocomplete?.selectedAddress
    })
    const authToken = useSelector(state => state.auth.token)
    var txtEmailClass = classNames({
      'form-control': agencySaveErrors.find((element) => element?.type == 'email') == null,
      'form-control borderError': agencySaveErrors.find((element) => element?.type == 'email') != null,
    });      
    var txtPhoneClass = classNames({
      'form-control': agencySaveErrors.find((element) => element?.type == 'phone') == null,
      'form-control borderError': agencySaveErrors.find((element) => element?.type == 'phone') != null,
    });      
    return (<>
        <div>
            <div class="hstack gap-3">
                <h5>ASIC details</h5>
            </div>
            <NameAutoFill {...props} />
            <hr/>
            <div id="input-grp-single-6" class="form-row mt-4">
                <div class="hstack gap-3">
                    <h5>Location and contact details</h5>
                </div>
                <AddressAutoFill {...props}  />
            </div>
            <div id="input-grp-single-7" class="form-row mt-4">
                <div class="row">
                    <div class="col">
                    <ErrorContainer error={agencySaveErrors.find((element) => element?.type == 'phone')}>
                        <input type="phone" id="agency_phone" name="agency_phone" placeholder="Company Phone" 
                            value={phone} className={txtPhoneClass} 
                            disabled={props?.readOnly}
                            onChange={(e) => setPhone(e.target.value)}  />
                    </ErrorContainer>
                    </div>
                    <div class="col">
                    <ErrorContainer error={agencySaveErrors.find((element) => element?.type == 'email')}>
                        <input type="email" id="agency_email" name="agency_email" placeholder="Company Email" 
                            value={email} className={txtEmailClass} 
                            disabled={props?.readOnly}
                            onChange={(e) => setEmail(e.target.value)}  />
                    </ErrorContainer>
                    </div>
                </div>
                <hr/>
            </div>
            <div class="hstack gap-3">
                <h5>Web and social media</h5>
            </div>
            <div id="input-grp-single-8" class="form-row mt-4">
                <div class="col-12">
                    <input 
                    class="form-control multisteps-form__input" 
                    type="text" 
                    id="agency_meta" name="agency_meta" placeholder="Company Facebook" 
                    disabled={props?.readOnly}
                    value={meta}
                    onChange={(e) => setMeta(e.target.value)}
                    />
                </div>
            </div>
            <div id="input-grp-single-10" class="form-row mt-4">
                <div class="col-12">
                    <input 
                    class="form-control multisteps-form__input" 
                    type="text" 
                    id="agency_linkedIn" name="agency_linkedIn" placeholder="Company Linked In" 
                    disabled={props?.readOnly}
                    value={linkedIn}
                    onChange={(e) => setLinkedIn(e.target.value)}
                    />
                </div>
            </div>
            <div id="input-grp-single-10" class="form-row mt-4">
                <div class="col-12">
                    <input 
                    class="form-control multisteps-form__input" 
                    type="text" 
                    id="agency_twitter" name="agency_twitter" placeholder="Company Twitter" 
                    disabled={props?.readOnly}
                    value={twitter}
                    onChange={(e) => setTwitter(e.target.value)}
                    />
                </div>
            </div>

            <div id="input-grp-single-11" class="form-row mt-4">
                <div class="col-12">
                    <input 
                    class="form-control multisteps-form__input" 
                    type="text" 
                    id="agency_website" name="agency_website" placeholder="Company website" 
                    disabled={props?.readOnly}
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    />
                </div>
            </div>

            <div id="input-grp-single-11" class="form-row mt-4">
                <div class="col-12">
                    {
                        props.readOnly ? null :
                            <SaveAgencyButton 
                                agency={
                                    {
                                        ...agency,
                                        companyDetails : {...displayCompany, 
                                            companyAddress : {...displayAddress},
                                        contact : {primaryEmail : email, primaryPhone: phone},
                                        companyFacebook: meta, companyLinkedIn: linkedIn, 
                                        companyWebsite: website, companyTwitter: twitter }
                                    }
                                } 
                                authToken={authToken}
                                {...props} />
                    }

                </div>
            </div>
        </div>
        </>
     );
}

export default CompanyDetails;