import { LENDER_ACTION_TYPES } from "./actionTypes";
import iterativeRender from "./listRender";

export const initialState = {
    loading: false,
    post: 'N/A',
    lendersList: 'N/A', 
    error: ''
}

export default function lenderProfilesReducer(state = initialState, action) {
    switch (action.type) {
        case LENDER_ACTION_TYPES.FETCH_LOADING:
            return {
                ...state.dashboardLenderProfiles,
                loading: true
            }
        case LENDER_ACTION_TYPES.FETCH_SUCCESS:
            return {
                loading: false,
                post: action.payload,
                lendersList: iterativeRender(action.payload),
                error: ''
            }
        case LENDER_ACTION_TYPES.FETCH_FAILURE:
            return {
                loading: false,
                post: 'N/A',
                lendersList: 'N/A',
                error: "Lender profiles could not be retrieved." 
            }
        default:
            return state
    }
}
  