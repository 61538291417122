import React, {  } from 'react';
import moment from 'moment';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import HiddenText from '../../common/HiddenText';
import { } from "../../../store/request";
import { SUMMARY_VIEW, MESSAGES_VIEW, DOCUMENTS_VIEW, REQUIREMENTS_VIEW, ASSETS_AND_LIABILITIES_VIEW, EXPENSES_VIEW, LOAN_PROPOSALS_VIEW, LOAN_PACKAGE_VIEW } from '../../../store/sales/constants';

const renderTooltip = props => (
    <Tooltip {...props}>{props?.locationtext}</Tooltip>
);

export function NetPositionCard(props) {
    var netAssets = 0
    var netExpenses = 0
    var netEquity = 0

    if (props?.financials?.assets?.assetItem)
        for (const [index, item] of props?.financials?.assets?.assetItem.entries()) {
            netAssets += item.amount.value
        }
    if (props?.financials?.liabilities?.liabilityItem)
        for (const [index, item] of props?.financials?.liabilities?.liabilityItem.entries()) {
            netAssets -= item.amount.value
        }

    const weeklyFilter = (element) => element.frequency == 'Weekly'
    const monthlyFilter = (element) => element.frequency == 'Monthly'
    const annualFilter = (element) => element.frequency == 'Annual'
    if (props?.financials?.expenses?.expenseItem && props?.financials?.expenses?.expenseItem.filter(weeklyFilter)) {
        for (const [index, item] of props?.financials?.expenses?.expenseItem.filter(weeklyFilter).entries()) {
            netExpenses += (item.amount.value * 52 / 12)
        }
    }
    if (props?.financials?.expenses?.expenseItem && props?.financials?.expenses?.expenseItem.filter(monthlyFilter))
        for (const [index, item] of props?.financials?.expenses?.expenseItem.filter(monthlyFilter).entries()) {
            netExpenses += item.amount.value
        }
    if (props?.financials?.expenses?.expenseItem && props?.financials?.expenses?.expenseItem.filter(annualFilter))
        for (const [index, item] of props?.financials?.expenses?.expenseItem.filter(annualFilter).entries()) {
            netExpenses += (item.amount.value / 12)
        }

    if (props?.lvr && props?.loanAmount)
        netEquity = props.loanAmount.value * (100 - props.lvr) / 100

    return (
        <div class="card shadow flex-fill border-start-success py-2">
            <div class="card-body">
                <div class="row align-items-center no-gutters">
                    <div class="col me-2">

                        <div class="vstack gap-2">
                            <div class="vstack">
                                <div class="text-uppercase text-success fw-bold text-xs mb-1"><span>Net asset position</span></div>
                                <div class="text-dark fw-bold h5 mb-0"><span>{netAssets.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</span></div>
                            </div>
                            <div class="vstack">
                                <div class="text-uppercase text-success fw-bold text-xs mb-1"><span>Average monthly expenses</span></div>
                                <div class="text-dark fw-bold h5 mb-0"><span>{netExpenses.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</span></div>
                            </div>
                        </div>

                    </div>
                    <div class="col-auto">
                        <OverlayTrigger placement="bottom" overlay={renderTooltip({ locationtext: 'Net financial position' })} >
                            <i class="fas fa-dollar-sign fa-2x text-gray-300"></i>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function NotProvided(props) {
    return (
        <>
        <div class="card flex-grow-1 align-self-stretch">
            <div class="card-header">
                <h5 class="mb-0">{props.header}</h5>
            </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table">
                        <tbody>
                            <tr>
                                <td><span>{props?.message == null ? 'Information not available' : props.message}</span></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        </>
    );
}

export function Expenses(props) {
    const items = [];
    //console.log(props)
    if (props?.expenses)
        for (const [index, expense] of props.expenses.entries()) {
            if (expense)
                items.push(
                    <div class="hstack gap-2" >
                        <span>{expense.label}</span>
                        <span><b>{expense.amount.value.toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</b></span>
                    </div>                        
                )
        }
    return (
        <div class="vstack" >
            {items}
        </div>
    );
}

export function WeeklyExpenses(props) {
    const weeklyExpenses =
        props?.expenses == null ? null :
            props?.expenses?.expenseItem.filter(element => element.frequency == 'Weekly')
    return (
        <>
            {props.expenses == null ? <NotProvided header={'Weekly'} message={'Not provided'} /> :
                <div class="card flex-grow-1 align-self-stretch">
                    <div class="card-header">
                        <h5 class="mb-0">Weekly</h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <Expenses expenses={weeklyExpenses} />
                        </div>
                    </div>
                </div>}
        </>
    );
}

export function MonthlyExpenses(props) {
    const monthlyExpenses =
        props?.expenses == null ? null :
            props?.expenses?.expenseItem.filter(element => element.frequency == 'Monthly')
    return (
        <>
            {props.expenses == null ? <NotProvided header={'Monthly'} message={'Not provided'} /> :
                <div class="card flex-grow-1 align-self-stretch">
                    <div class="card-header">
                        <h5 class="mb-0">Monthly</h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <Expenses expenses={monthlyExpenses} />
                        </div>
                    </div>
                </div>}
        </>
    );
}

export function AnnualExpenses(props) {
    const annualExpenses =
        props?.expenses == null ? null :
            props?.expenses?.expenseItem.filter(element => element.frequency == 'Annual')
    return (
        <>
            {props.expenses == null ? <NotProvided header={'Annual'} message={'Not provided'} /> :
                <div class="card flex-grow-1 align-self-stretch">
                    <div class="card-header">
                        <h5 class="mb-0">Annual</h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <Expenses expenses={annualExpenses} />
                        </div>
                    </div>
                </div>}
        </>
    );
}

export function AssetsLiabilities(props) {
    const items = [];
    if (props?.list)
        for (const [index, item] of props.list.entries()) {
            items.push(
                <tr>
                    <td><span>{item.label}</span></td>
                    <td><span><b>{item.amount.value
                        .toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</b></span></td>
                </tr>
            )
        }
    return (
        <table class="table">
            <tbody>{items}</tbody>
        </table>
    );
}

export function Liabilities(props) {
    return (
        <>
            {props.liabilities == null ? <NotProvided header={'Liabilities'} message={'Not provided'} /> :
                <div class="card flex-grow-1 align-self-stretch">
                    <div class="card-header">
                        <h5 class="mb-0">Liabilities</h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <AssetsLiabilities list={props?.liabilities?.liabilityItem} />
                        </div>
                    </div>
                </div>}
        </>

    );
}

export function Assets(props) {
    return (
        <>
            {props.assets == null ? <NotProvided header={'Assets'} message={'Not provided'} /> :
                <div class="card flex-grow-1 align-self-stretch">
                    <div class="card-header">
                        <h5 class="mb-0">Assets</h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <AssetsLiabilities list={props?.assets?.assetItem} />
                        </div>
                    </div>
                </div>}
        </>
    );
}

export function CurrentLending(props) {
    return (
        <>
            {props.currentMortgage == null ? <NotProvided header={'Current lending'} message={'Not provided'} /> :
                <div class="card flex-grow-1 align-self-stretch">
                    <div class="card-header">
                        <h5 class="mb-0">Current lending</h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <tbody>
                                    {!props.currentMortgage?.loanAmount ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Current mortgage' })} >
                                                    <i class="fas fa-funnel-dollar" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}></i>
                                                </OverlayTrigger>
                                            </td>
                                            <td><span><b>{!props?.currentMortgage?.loanAmount?.value ? null : props.currentMortgage.loanAmount.value
                                                .toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</b></span></td>
                                        </tr>}
                                    {!props.currentMortgage?.averageMonthlyRepayment ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Current monthly repayment' })} >
                                                    <svg class="bi bi-calendar2-event" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                        <path d="M11 7.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"></path>
                                                        <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"></path>
                                                        <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"></path>
                                                    </svg>
                                                </OverlayTrigger>
                                            </td>
                                            <td><span><b>{!props?.currentMortgage?.averageMonthlyRepayment?.value ? null : props.currentMortgage.averageMonthlyRepayment.value
                                                .toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</b></span></td>
                                        </tr>}
                                    {!props.currentMortgage?.lender ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Current lender' })} >
                                                    <svg class="bi bi-bank" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                        <path d="M8 .95 14.61 4h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.379l.5 2A.5.5 0 0 1 15.5 17H.5a.5.5 0 0 1-.485-.621l.5-2A.5.5 0 0 1 1 14V7H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 4h.89L8 .95zM3.776 4h8.447L8 2.05 3.776 4zM2 7v7h1V7H2zm2 0v7h2.5V7H4zm3.5 0v7h1V7h-1zm2 0v7H12V7H9.5zM13 7v7h1V7h-1zm2-1V5H1v1h14zm-.39 9H1.39l-.25 1h13.72l-.25-1z"></path>
                                                    </svg>
                                                </OverlayTrigger>
                                            </td>
                                            <td><span><b>{props.currentMortgage.lender}</b></span></td>
                                        </tr>}
                                    {!props.currentMortgage?.loanType ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Rate category' })} >
                                                    {props?.loanType == 'Fixed' ?
                                                        <svg class="bi bi-pin" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                            <path d="M4.146.146A.5.5 0 0 1 4.5 0h7a.5.5 0 0 1 .5.5c0 .68-.342 1.174-.646 1.479-.126.125-.25.224-.354.298v4.431l.078.048c.203.127.476.314.751.555C12.36 7.775 13 8.527 13 9.5a.5.5 0 0 1-.5.5h-4v4.5c0 .276-.224 1.5-.5 1.5s-.5-1.224-.5-1.5V10h-4a.5.5 0 0 1-.5-.5c0-.973.64-1.725 1.17-2.189A5.921 5.921 0 0 1 5 6.708V2.277a2.77 2.77 0 0 1-.354-.298C4.342 1.674 4 1.179 4 .5a.5.5 0 0 1 .146-.354zm1.58 1.408-.002-.001.002.001zm-.002-.001.002.001A.5.5 0 0 1 6 2v5a.5.5 0 0 1-.276.447h-.002l-.012.007-.054.03a4.922 4.922 0 0 0-.827.58c-.318.278-.585.596-.725.936h7.792c-.14-.34-.407-.658-.725-.936a4.915 4.915 0 0 0-.881-.61l-.012-.006h-.002A.5.5 0 0 1 10 7V2a.5.5 0 0 1 .295-.458 1.775 1.775 0 0 0 .351-.271c.08-.08.155-.17.214-.271H5.14c.06.1.133.191.214.271a1.78 1.78 0 0 0 .37.282z"></path>
                                                        </svg> :
                                                        <svg class="bi bi-plus-slash-minus" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                            <path fill-rule="evenodd" d="m1.854 14.854 13-13a.5.5 0 0 0-.708-.708l-13 13a.5.5 0 0 0 .708.708ZM4 1a.5.5 0 0 1 .5.5v2h2a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2h-2a.5.5 0 0 1 0-1h2v-2A.5.5 0 0 1 4 1Zm5 11a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5A.5.5 0 0 1 9 12Z"></path>
                                                        </svg>
                                                    }
                                                </OverlayTrigger>
                                            </td>
                                            <td><span><b>{props.currentMortgage.loanType}</b></span></td>
                                        </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>}
        </>
    );
}

export function Extras(props) {
    return (
        <>
            {props?.extras == null ? <NotProvided header={'Desirable extra features'} message={'Not provided'} /> :
                <div class="card flex-grow-1 align-self-stretch">
                    <div class="card-header">
                        <h5 class="mb-0">Desirable extra features</h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <tbody>
                                    {!props.extras?.includeBridgingFinance || !JSON.parse(props.extras?.includeBridgingFinance) ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Bridging loan' })} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -32 576 576" width="1em" height="1em" fill="currentColor" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                        <path d="M544 32C561.7 32 576 46.33 576 64C576 81.67 561.7 96 544 96H504V160H576V288C522.1 288 480 330.1 480 384V448C480 465.7 465.7 480 448 480H416C398.3 480 384 465.7 384 448V384C384 330.1 341 288 288 288C234.1 288 192 330.1 192 384V448C192 465.7 177.7 480 160 480H128C110.3 480 96 465.7 96 448V384C96 330.1 53.02 288 0 288V160H72V96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H544zM456 96H376V160H456V96zM248 96V160H328V96H248zM200 96H120V160H200V96z"></path>
                                                    </svg>
                                                </OverlayTrigger>
                                            </td>
                                            <td><span><b>Bridging loan</b></span></td>
                                        </tr>}
                                    {!props.extras?.offsetAccount || !JSON.parse(props.extras?.offsetAccount) ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Offset account' })} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" fill="currentColor" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                        <path d="M326.7 403.7C304.7 411.6 280.8 416 256 416C231.2 416 207.3 411.6 185.3 403.7C184.1 403.6 184.7 403.5 184.5 403.4C154.4 392.4 127.6 374.6 105.9 352C70.04 314.6 48 263.9 48 208C48 93.12 141.1 0 256 0C370.9 0 464 93.12 464 208C464 263.9 441.1 314.6 406.1 352C405.1 353 404.1 354.1 403.1 355.1C381.7 376.4 355.7 393.2 326.7 403.7L326.7 403.7zM235.9 111.1V118C230.3 119.2 224.1 120.9 220 123.1C205.1 129.9 192.1 142.5 188.9 160.8C187.1 171 188.1 180.9 192.3 189.8C196.5 198.6 203 204.8 209.6 209.3C221.2 217.2 236.5 221.8 248.2 225.3L250.4 225.9C264.4 230.2 273.8 233.3 279.7 237.6C282.2 239.4 283.1 240.8 283.4 241.7C283.8 242.5 284.4 244.3 283.7 248.3C283.1 251.8 281.2 254.8 275.7 257.1C269.6 259.7 259.7 261 246.9 259C240.9 258 230.2 254.4 220.7 251.2C218.5 250.4 216.3 249.7 214.3 249C203.8 245.5 192.5 251.2 189 261.7C185.5 272.2 191.2 283.5 201.7 286.1C202.9 287.4 204.4 287.9 206.1 288.5C213.1 291.2 226.4 295.4 235.9 297.6V304C235.9 315.1 244.9 324.1 255.1 324.1C267.1 324.1 276.1 315.1 276.1 304V298.5C281.4 297.5 286.6 295.1 291.4 293.9C307.2 287.2 319.8 274.2 323.1 255.2C324.9 244.8 324.1 234.8 320.1 225.7C316.2 216.7 309.9 210.1 303.2 205.3C291.1 196.4 274.9 191.6 262.8 187.9L261.1 187.7C247.8 183.4 238.2 180.4 232.1 176.2C229.5 174.4 228.7 173.2 228.5 172.7C228.3 172.3 227.7 171.1 228.3 167.7C228.7 165.7 230.2 162.4 236.5 159.6C242.1 156.7 252.9 155.1 265.1 156.1C269.5 157.7 283 160.3 286.9 161.3C297.5 164.2 308.5 157.8 311.3 147.1C314.2 136.5 307.8 125.5 297.1 122.7C292.7 121.5 282.7 119.5 276.1 118.3V112C276.1 100.9 267.1 91.9 256 91.9C244.9 91.9 235.9 100.9 235.9 112V111.1zM48 352H63.98C83.43 377.9 108 399.7 136.2 416H64V448H448V416H375.8C403.1 399.7 428.6 377.9 448 352H464C490.5 352 512 373.5 512 400V464C512 490.5 490.5 512 464 512H48C21.49 512 0 490.5 0 464V400C0 373.5 21.49 352 48 352H48z"></path>
                                                    </svg>
                                                </OverlayTrigger>
                                            </td>
                                            <td><span><b>Offset account</b></span></td>
                                        </tr>}
                                    {!props.extras?.homeInsurance || !JSON.parse(props.extras?.homeInsurance) ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Home insurance' })} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" fill="currentColor" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                        <path d="M416 128c-.625 0-1.125 .25-1.625 .25C415.5 123 416 117.6 416 112c0-44.25-35.75-80-79.1-80c-24.62 0-46.25 11.25-60.1 28.75C256.4 24.75 219.3 0 176 0C114.3 0 64 50.13 64 112c0 7.25 .7512 14.25 2.126 21.25C27.76 145.8 .0054 181.5 .0054 224c0 53 42.1 96 95.1 96h319.1C469 320 512 277 512 224S469 128 416 128zM198.8 353.9c-12.17-5.219-26.3 .4062-31.52 12.59l-47.1 112c-5.219 12.19 .4219 26.31 12.61 31.53C134.1 511.4 138.2 512 141.3 512c9.312 0 18.17-5.438 22.08-14.53l47.1-112C216.6 373.3 210.1 359.2 198.8 353.9zM81.46 353.9c-12.19-5.219-26.3 .4062-31.52 12.59l-47.1 112C-3.276 490.7 2.365 504.8 14.55 510.1C17.63 511.4 20.83 512 23.99 512c9.312 0 18.17-5.438 22.08-14.53l47.1-112C99.29 373.3 93.64 359.2 81.46 353.9zM316.1 353.9c-12.19-5.219-26.3 .4062-31.52 12.59l-47.1 112c-5.219 12.19 .4219 26.31 12.61 31.53C252.3 511.4 255.5 512 258.7 512c9.312 0 18.17-5.438 22.08-14.53l47.1-112C333.1 373.3 328.3 359.2 316.1 353.9zM433.5 353.9c-12.17-5.219-26.28 .4062-31.52 12.59l-47.1 112c-5.219 12.19 .4219 26.31 12.61 31.53C369.6 511.4 372.8 512 375.1 512c9.312 0 18.17-5.438 22.08-14.53l47.1-112C451.3 373.3 445.6 359.2 433.5 353.9z"></path>
                                                    </svg>
                                                </OverlayTrigger>
                                            </td>
                                            <td><span><b>Home insurance</b></span></td>
                                        </tr>}
                                    {!props.extras?.creditCard || !JSON.parse(props.extras?.creditCard) ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Credit card' })} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -32 576 576" width="1em" height="1em" fill="currentColor" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                        <path d="M168 336C181.3 336 192 346.7 192 360C192 373.3 181.3 384 168 384H120C106.7 384 96 373.3 96 360C96 346.7 106.7 336 120 336H168zM360 336C373.3 336 384 346.7 384 360C384 373.3 373.3 384 360 384H248C234.7 384 224 373.3 224 360C224 346.7 234.7 336 248 336H360zM512 32C547.3 32 576 60.65 576 96V416C576 451.3 547.3 480 512 480H64C28.65 480 0 451.3 0 416V96C0 60.65 28.65 32 64 32H512zM512 80H64C55.16 80 48 87.16 48 96V128H528V96C528 87.16 520.8 80 512 80zM528 224H48V416C48 424.8 55.16 432 64 432H512C520.8 432 528 424.8 528 416V224z"></path>
                                                    </svg>
                                                </OverlayTrigger>
                                            </td>
                                            <td><span><b>Credit card</b></span></td>
                                        </tr>}
                                    {!props.extras?.transactionsAccount || !JSON.parse(props.extras?.transactionsAccount) ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Transactions account' })} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" fill="currentColor" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                        <path d="M326.7 403.7C304.7 411.6 280.8 416 256 416C231.2 416 207.3 411.6 185.3 403.7C184.1 403.6 184.7 403.5 184.5 403.4C154.4 392.4 127.6 374.6 105.9 352C70.04 314.6 48 263.9 48 208C48 93.12 141.1 0 256 0C370.9 0 464 93.12 464 208C464 263.9 441.1 314.6 406.1 352C405.1 353 404.1 354.1 403.1 355.1C381.7 376.4 355.7 393.2 326.7 403.7L326.7 403.7zM235.9 111.1V118C230.3 119.2 224.1 120.9 220 123.1C205.1 129.9 192.1 142.5 188.9 160.8C187.1 171 188.1 180.9 192.3 189.8C196.5 198.6 203 204.8 209.6 209.3C221.2 217.2 236.5 221.8 248.2 225.3L250.4 225.9C264.4 230.2 273.8 233.3 279.7 237.6C282.2 239.4 283.1 240.8 283.4 241.7C283.8 242.5 284.4 244.3 283.7 248.3C283.1 251.8 281.2 254.8 275.7 257.1C269.6 259.7 259.7 261 246.9 259C240.9 258 230.2 254.4 220.7 251.2C218.5 250.4 216.3 249.7 214.3 249C203.8 245.5 192.5 251.2 189 261.7C185.5 272.2 191.2 283.5 201.7 286.1C202.9 287.4 204.4 287.9 206.1 288.5C213.1 291.2 226.4 295.4 235.9 297.6V304C235.9 315.1 244.9 324.1 255.1 324.1C267.1 324.1 276.1 315.1 276.1 304V298.5C281.4 297.5 286.6 295.1 291.4 293.9C307.2 287.2 319.8 274.2 323.1 255.2C324.9 244.8 324.1 234.8 320.1 225.7C316.2 216.7 309.9 210.1 303.2 205.3C291.1 196.4 274.9 191.6 262.8 187.9L261.1 187.7C247.8 183.4 238.2 180.4 232.1 176.2C229.5 174.4 228.7 173.2 228.5 172.7C228.3 172.3 227.7 171.1 228.3 167.7C228.7 165.7 230.2 162.4 236.5 159.6C242.1 156.7 252.9 155.1 265.1 156.1C269.5 157.7 283 160.3 286.9 161.3C297.5 164.2 308.5 157.8 311.3 147.1C314.2 136.5 307.8 125.5 297.1 122.7C292.7 121.5 282.7 119.5 276.1 118.3V112C276.1 100.9 267.1 91.9 256 91.9C244.9 91.9 235.9 100.9 235.9 112V111.1zM48 352H63.98C83.43 377.9 108 399.7 136.2 416H64V448H448V416H375.8C403.1 399.7 428.6 377.9 448 352H464C490.5 352 512 373.5 512 400V464C512 490.5 490.5 512 464 512H48C21.49 512 0 490.5 0 464V400C0 373.5 21.49 352 48 352H48z"></path>
                                                    </svg>
                                                </OverlayTrigger>
                                            </td>
                                            <td><span><b>Transactions account</b></span></td>
                                        </tr>}
                                    {!props.extras?.savingsAccount || !JSON.parse(props.extras?.savingsAccount) ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Savings account' })} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="1em" height="1em" fill="currentColor" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                        <path d="M326.7 403.7C304.7 411.6 280.8 416 256 416C231.2 416 207.3 411.6 185.3 403.7C184.1 403.6 184.7 403.5 184.5 403.4C154.4 392.4 127.6 374.6 105.9 352C70.04 314.6 48 263.9 48 208C48 93.12 141.1 0 256 0C370.9 0 464 93.12 464 208C464 263.9 441.1 314.6 406.1 352C405.1 353 404.1 354.1 403.1 355.1C381.7 376.4 355.7 393.2 326.7 403.7L326.7 403.7zM235.9 111.1V118C230.3 119.2 224.1 120.9 220 123.1C205.1 129.9 192.1 142.5 188.9 160.8C187.1 171 188.1 180.9 192.3 189.8C196.5 198.6 203 204.8 209.6 209.3C221.2 217.2 236.5 221.8 248.2 225.3L250.4 225.9C264.4 230.2 273.8 233.3 279.7 237.6C282.2 239.4 283.1 240.8 283.4 241.7C283.8 242.5 284.4 244.3 283.7 248.3C283.1 251.8 281.2 254.8 275.7 257.1C269.6 259.7 259.7 261 246.9 259C240.9 258 230.2 254.4 220.7 251.2C218.5 250.4 216.3 249.7 214.3 249C203.8 245.5 192.5 251.2 189 261.7C185.5 272.2 191.2 283.5 201.7 286.1C202.9 287.4 204.4 287.9 206.1 288.5C213.1 291.2 226.4 295.4 235.9 297.6V304C235.9 315.1 244.9 324.1 255.1 324.1C267.1 324.1 276.1 315.1 276.1 304V298.5C281.4 297.5 286.6 295.1 291.4 293.9C307.2 287.2 319.8 274.2 323.1 255.2C324.9 244.8 324.1 234.8 320.1 225.7C316.2 216.7 309.9 210.1 303.2 205.3C291.1 196.4 274.9 191.6 262.8 187.9L261.1 187.7C247.8 183.4 238.2 180.4 232.1 176.2C229.5 174.4 228.7 173.2 228.5 172.7C228.3 172.3 227.7 171.1 228.3 167.7C228.7 165.7 230.2 162.4 236.5 159.6C242.1 156.7 252.9 155.1 265.1 156.1C269.5 157.7 283 160.3 286.9 161.3C297.5 164.2 308.5 157.8 311.3 147.1C314.2 136.5 307.8 125.5 297.1 122.7C292.7 121.5 282.7 119.5 276.1 118.3V112C276.1 100.9 267.1 91.9 256 91.9C244.9 91.9 235.9 100.9 235.9 112V111.1zM48 352H63.98C83.43 377.9 108 399.7 136.2 416H64V448H448V416H375.8C403.1 399.7 428.6 377.9 448 352H464C490.5 352 512 373.5 512 400V464C512 490.5 490.5 512 464 512H48C21.49 512 0 490.5 0 464V400C0 373.5 21.49 352 48 352H48z"></path>
                                                    </svg>
                                                </OverlayTrigger>
                                            </td>
                                            <td><span><b>Savings account</b></span></td>
                                        </tr>}
                                    {!props.extras?.homeAndLandPackage || !JSON.parse(props.extras?.homeAndLandPackage) ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Home and land package' })} >
                                                    <svg class="bi bi-house" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                        <path fill-rule="evenodd" d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z"></path>
                                                        <path fill-rule="evenodd" d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z"></path>
                                                    </svg>
                                                </OverlayTrigger>
                                            </td>
                                            <td><span><b>Construction loan</b></span></td>
                                        </tr>}
                                    {!props.extras?.homeImprovementPackage || !JSON.parse(props.extras?.homeImprovementPackage) ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Home improvement package' })} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -32 576 576" width="1em" height="1em" fill="currentColor" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                        <path d="M511.8 287.6L512.5 447.7C512.6 483.2 483.9 512 448.5 512H326.4L288 448L368.8 380.7C376.6 374.1 376.5 362.1 368.5 355.8L250.6 263.2C235.1 251.7 216.8 270.1 227.8 285.2L288 368L202.5 439.2C196.5 444.3 194.1 452.1 199.1 459.8L230.4 512H128.1C92.75 512 64.09 483.3 64.09 448V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L564.8 231.5C572.8 238.5 576.9 246.5 575.8 255.5C575.8 273.5 560.8 287.6 543.8 287.6H511.8z"></path>
                                                    </svg>
                                                </OverlayTrigger>
                                            </td>
                                            <td><span><b>Home improvement loan</b></span></td>
                                        </tr>}
                                    {!props.extras?.redraw || !JSON.parse(props.extras?.redraw) ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Redraw' })} >
                                                    <i class="fas fa-hand-holding-usd" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}></i>
                                                </OverlayTrigger>
                                            </td>
                                            <td><span><b>Redraw facility</b></span></td>
                                        </tr>}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>}
        </>
    );
}

export function Preferences(props) {
    return (
        <>
            {props?.preferences == null ? <NotProvided header={'Preferences'} message={'Not provided'} /> :
                <div class="card flex-grow-1 align-self-stretch">
                    <div class="card-header">
                        <h5 class="mb-0">Preferences</h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <tbody>
                                    {props.preferences?.rateType == null ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Rate category' })} >
                                                    {props.preferences?.rateType == 'Fixed' ?
                                                        <svg class="bi bi-pin" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                            <path d="M4.146.146A.5.5 0 0 1 4.5 0h7a.5.5 0 0 1 .5.5c0 .68-.342 1.174-.646 1.479-.126.125-.25.224-.354.298v4.431l.078.048c.203.127.476.314.751.555C12.36 7.775 13 8.527 13 9.5a.5.5 0 0 1-.5.5h-4v4.5c0 .276-.224 1.5-.5 1.5s-.5-1.224-.5-1.5V10h-4a.5.5 0 0 1-.5-.5c0-.973.64-1.725 1.17-2.189A5.921 5.921 0 0 1 5 6.708V2.277a2.77 2.77 0 0 1-.354-.298C4.342 1.674 4 1.179 4 .5a.5.5 0 0 1 .146-.354zm1.58 1.408-.002-.001.002.001zm-.002-.001.002.001A.5.5 0 0 1 6 2v5a.5.5 0 0 1-.276.447h-.002l-.012.007-.054.03a4.922 4.922 0 0 0-.827.58c-.318.278-.585.596-.725.936h7.792c-.14-.34-.407-.658-.725-.936a4.915 4.915 0 0 0-.881-.61l-.012-.006h-.002A.5.5 0 0 1 10 7V2a.5.5 0 0 1 .295-.458 1.775 1.775 0 0 0 .351-.271c.08-.08.155-.17.214-.271H5.14c.06.1.133.191.214.271a1.78 1.78 0 0 0 .37.282z"></path>
                                                        </svg> :
                                                        <svg class="bi bi-plus-slash-minus" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                            <path fill-rule="evenodd" d="m1.854 14.854 13-13a.5.5 0 0 0-.708-.708l-13 13a.5.5 0 0 0 .708.708ZM4 1a.5.5 0 0 1 .5.5v2h2a.5.5 0 0 1 0 1h-2v2a.5.5 0 0 1-1 0v-2h-2a.5.5 0 0 1 0-1h2v-2A.5.5 0 0 1 4 1Zm5 11a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5A.5.5 0 0 1 9 12Z"></path>
                                                        </svg>
                                                    }
                                                </OverlayTrigger>
                                            </td>
                                            <td><span><b>{props.preferences.rateType} interest</b></span></td>
                                        </tr>}
                                    {props.preferences?.firstPreference &&
                                    (props.preferences.firstPreference.leastInterestRate ||
                                        props.preferences.firstPreference.lowerRepayment ||
                                            props.preferences.firstPreference.longerFixedTerm ||
                                            props.preferences.firstPreference.shorterLoanDuration)
                                    ? 
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Loan scenario prefered' })} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-128 0 512 512" width="1em" height="1em" fill="currentColor" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                        <path d="M256 448c0 17.67-14.33 32-32 32H32c-17.67 0-32-14.33-32-32s14.33-32 32-32h64V123.8L49.75 154.6C35.02 164.5 15.19 160.4 5.375 145.8C-4.422 131.1-.4531 111.2 14.25 101.4l96-64c9.828-6.547 22.45-7.187 32.84-1.594C153.5 41.37 160 52.22 160 64.01v352h64C241.7 416 256 430.3 256 448z"></path>
                                                    </svg>
                                                </OverlayTrigger>
                                            </td>
                                            <td><span>Repayment schedule - <b>{
                                                props.preferences.firstPreference.leastInterestRate ? 'Lowest interest rate' :
                                                    props.preferences.firstPreference.lowerRepayment ? 'Lower repayments' :
                                                        props.preferences.firstPreference.longerFixedTerm ? 'Longer fixed term' :
                                                            props.preferences.firstPreference.shorterLoanDuration ? 'Shorter loan duration' : null
                                            }</b></span></td>
                                        </tr> : null}

                                    {props.preferences?.repaymentFrequency == null ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Prefered repayment schedule' })} >
                                                    <i class="fas fa-money-check-alt" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}></i>
                                                </OverlayTrigger>
                                            </td>
                                            <td><span>Repayments - <b>{
                                                props.preferences.repaymentFrequency == 'A' ? 'Annual' : 
                                                props.preferences.repaymentFrequency == 'M' ? 'Monthly' :
                                                props.preferences.repaymentFrequency == 'F' ? 'Fortnightly' :
                                                props.preferences.repaymentFrequency == 'W' ? 'Weekly' : 'Not available'
                                            }</b></span></td>
                                        </tr>}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>}
        </>
    );
}

export function Addresses(props) {
    const items = [];
    for (const [index, address] of props.mortgageProperty.entries()) {
        items.push(
            <><span>{address.fullAddress}</span><p /></>
        )
    }
    return (
        <>
            {items}
        </>
    );
}

export function Needs(props) {
    return (
        <>
            {props?.prospectInView == null ? <NotProvided header={'Needs'} /> :
                <div class="card flex-grow-1 align-self-stretch">
                    <div class="card-header">
                        <h5 class="mb-0">Needs</h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <tbody>
                                    {props?.prospectInView?.loanRequest?.loanAmount == null ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Loan requirement' })} >
                                                    <i class="fas fa-funnel-dollar" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}></i>
                                                </OverlayTrigger>
                                            </td>
                                            <td><span><b>{props.prospectInView.loanRequest.loanAmount.value
                                                .toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</b></span></td>
                                        </tr>}
                                    {props.prospectInView?.loanRequest?.lvr == null ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'LVR' })} >
                                                    <svg class="bi bi-percent" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                        <path d="M13.442 2.558a.625.625 0 0 1 0 .884l-10 10a.625.625 0 1 1-.884-.884l10-10a.625.625 0 0 1 .884 0zM4.5 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5zm7 6a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3zm0 1a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z"></path>
                                                    </svg>
                                                </OverlayTrigger>
                                            </td>
                                            <td><span>LVR <b>{props.prospectInView.loanRequest.lvr} %</b></span></td>
                                        </tr>}
                                    {props.prospectInView?.loanRequest?.loanProcessing == null ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Loan processing' })} >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24" fill="none" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                        <path d="M9 7H11V12H16V14H9V7Z" fill="currentColor"></path>
                                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z" fill="currentColor"></path>
                                                    </svg>
                                                </OverlayTrigger>
                                            </td>
                                            <td><span><b>{props.prospectInView?.loanRequest?.loanProcessing}</b></span></td>
                                        </tr>}
                                    {props.prospectInView?.loanRequest?.loanProfile == null ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Loan profile' })} >
                                                    {props.prospectInView.loanRequest.loanProfile == 'First mortgage' ?
                                                        <svg class="bi bi-ladder" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                            <path d="M4.5 1a.5.5 0 0 1 .5.5V2h6v-.5a.5.5 0 0 1 1 0v14a.5.5 0 0 1-1 0V15H5v.5a.5.5 0 0 1-1 0v-14a.5.5 0 0 1 .5-.5zM5 14h6v-2H5v2zm0-3h6V9H5v2zm0-3h6V6H5v2zm0-3h6V3H5v2z"></path>
                                                        </svg> :
                                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -64 640 640" width="1em" height="1em" fill="currentColor" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}>
                                                            <path d="M320 352H256C238.3 352 224 366.3 224 384V472C224 494.1 206.1 512 184 512H128.1C126.6 512 125.1 511.9 123.6 511.8C122.4 511.9 121.2 512 120 512H104C81.91 512 64 494.1 64 472V360C64 359.1 64.03 358.1 64.09 357.2V287.6H32.05C14.02 287.6 0 273.5 0 255.5C0 246.5 3.004 238.5 10.01 231.5L266.4 8.016C273.4 1.002 281.4 0 288.4 0C295.4 0 303.4 2.004 309.5 7.014L522.1 193.9C513.6 192.7 504.9 192 496 192C404.2 192 328.8 262.3 320.7 352L320 352zM640 368C640 447.5 575.5 512 496 512C416.5 512 352 447.5 352 368C352 288.5 416.5 224 496 224C575.5 224 640 288.5 640 368zM540.7 324.7L480 385.4L451.3 356.7C445.1 350.4 434.9 350.4 428.7 356.7C422.4 362.9 422.4 373.1 428.7 379.3L468.7 419.3C474.9 425.6 485.1 425.6 491.3 419.3L563.3 347.3C569.6 341.1 569.6 330.9 563.3 324.7C557.1 318.4 546.9 318.4 540.7 324.7H540.7z"></path>
                                                        </svg>}
                                                </OverlayTrigger>
                                            </td>
                                            <td><span><b>{props.prospectInView?.loanRequest?.loanProfile}</b></span></td>
                                        </tr>}
                                    {props.prospectInView?.loanRequest?.loanPurpose == null ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Loan purpose' })} >
                                                    <i class="fas fa-hotel" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}></i>
                                                </OverlayTrigger>
                                            </td>
                                            <td><b>{props.prospectInView?.loanRequest?.loanPurpose == 'Both' ? 'Residential and Investment' : props.prospectInView?.loanRequest?.loanPurpose}</b></td>
                                        </tr>}
                                    {props.prospectInView?.loanRequest?.mortgageProperty == null ? null :
                                        <tr>
                                            <td>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ ...props, locationtext: 'Mortgage properties (residential first)' })} >
                                                    <i class="icon-location-pin" style={{ "transform": "scale(2)", "--bs-primary": "#4e73df", "--bs-primary-rgb": "78,115,223" }}></i>
                                                </OverlayTrigger>
                                            </td>
                                            <td><Addresses {...props.prospectInView?.loanRequest} /></td>
                                        </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>}
        </>
    );
}

export function CoBorrowersAndDependants(props) {
    return (
        <>
            {props?.prospectInView?.loanRequest?.borrowers?.jointApplicant == null && props?.prospectInView?.mainBorrower?.numDependants == null ? <NotProvided header={'Co borrower and Dependents'} /> :
                <div class="card flex-grow-1 align-self-stretch">
                    <div class="card-header">
                        <h5 class="mb-0">Co borrower and Dependents</h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <tbody>
                                    {props.prospectInView?.loanRequest?.borrowers?.jointApplicant == null ? null :
                                        <tr>
                                            <td><span>Joint applicant <HiddenText unmasked={props.unmasked}><b>{props.prospectInView?.loanRequest?.borrowers?.jointApplicant.title} {props.prospectInView?.loanRequest?.borrowers?.jointApplicant.firstName} {props.prospectInView?.loanRequest?.borrowers?.jointApplicant.lastName}</b></HiddenText> </span></td>
                                        </tr>}
                                    {props.prospectInView?.loanRequest?.financials?.annualIncomeJointApplicant == null ? null :
                                        <tr>
                                            <td><span>Est Annual Income Joint applicant<p />
                                            <HiddenText unmasked={props.unmasked}><strong>{
                                                props.prospectInView?.loanRequest?.financials?.annualIncomeJointApplicant.value
                                                    .toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
                                            }</strong></HiddenText>
                                            </span></td>
                                        </tr>}
                                    {props.prospectInView?.mainBorrower?.numDependants == null ? null :
                                        <tr>
                                            <td><span>Number of dependants <HiddenText unmasked={props.unmasked}><strong>{props.prospectInView?.mainBorrower?.numDependants}</strong></HiddenText></span></td>
                                        </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export function IncomeDetails(props) {
    return (
        <>
            {props?.prospectInView == null ? <NotProvided header={'Income details'} /> :
                <div class="card flex-grow-1 align-self-stretch">
                    <div class="card-header">
                        <h5 class="mb-0">Income details</h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <tbody>
                                    {props.prospectInView.mainBorrower.primaryProfession == null ? null :
                                        <tr>
                                            <td><span>Profession<p /><HiddenText unmasked={props.unmasked}><b>{props.prospectInView.mainBorrower.primaryProfession}</b></HiddenText></span></td>
                                        </tr>}
                                    {props.prospectInView.mainBorrower.employmentType == null ? null :
                                        <tr>
                                            <td><span>Income category<p /><HiddenText unmasked={props.unmasked}><b>{props.prospectInView.mainBorrower.employmentType}</b></HiddenText></span></td>
                                        </tr>}
                                    {props.prospectInView?.loanRequest?.financials?.annualIncome == null ? null :
                                        <tr>
                                            <td><span>Est Gross Annual Income<p />
                                            <HiddenText unmasked={props.unmasked}><strong>{
                                                props.prospectInView.loanRequest.financials.annualIncome.value
                                                    .toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
                                            }</strong></HiddenText>
                                            </span></td>
                                        </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>}
        </>
    );
}

export function ContactDetails(props) {
    //console.log(props)
    return (
        <>
            {props?.prospectInView == null ? <NotProvided header={'Contact details'} /> :
                <div class="card flex-grow-1 align-self-stretch">
                    <div class="card-header">
                        <h5 class="mb-0">Contact details</h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <tbody>
                                    {props.prospectInView.mainBorrower?.currentAddress == null ? null :
                                        <tr>
                                            <td>
                                                <div><span>Current address<p />
                                                <HiddenText unmasked={props.unmasked} message={'Contact details will be shared once the proposal is accepted'} >
                                                <strong>{props.prospectInView.mainBorrower.currentAddress.fullAddress}</strong>
                                                </HiddenText> 
                                                </span></div>
                                            </td>
                                        </tr>}
                                    <tr>
                                        <td><span>Mobile <HiddenText unmasked={props.unmasked} message={'Contact details will be shared once the proposal is accepted'}><b>{props.prospectInView.mainBorrower.mobile}</b></HiddenText></span></td>
                                    </tr>
                                    <tr>
                                        <td><span>Email <HiddenText unmasked={props.unmasked} message={'Contact details will be shared once the proposal is accepted'}><b>{props.prospectInView.mainBorrower.email}</b></HiddenText></span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>}
        </>
    );
}

export function PersonalDetails(props) {
    //console.log(props)
    return (
        <>
            {props?.prospectInView == null ? null :
                <div class="card flex-grow-1 align-self-stretch">
                    <div class="card-header">
                        <h5 class="mb-0">Personal details</h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th><HiddenText unmasked={props.unmasked} >{props.prospectInView.mainBorrower.title + ' ' 
                                        + props.prospectInView.mainBorrower.firstName + ' ' 
                                        +  props.prospectInView.mainBorrower.lastName}</HiddenText></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><HiddenText unmasked={props.unmasked}>Age <b>{moment().diff(moment(props.prospectInView.mainBorrower.dateOfBirth), 'years')}</b></HiddenText></td>
                                    </tr>
                                    {props.prospectInView.mainBorrower?.immigrationStatus == null ? null :
                                        <tr>
                                            <td><HiddenText unmasked={props.unmasked}>Immigration status <b>{props.prospectInView.mainBorrower?.immigrationStatus}</b></HiddenText></td>
                                        </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>}
        </>
    );
}

export function StatementOfIntent(props) {
    return (
        <>
            {props?.prospectInView == null || props?.prospectInView?.loanRequest?.statementOfIntent == null ? <NotProvided header={'Statement of intent'} /> :
                <div class="card flex-grow-1 align-self-stretch">
                    <div class="card-header">
                        <h5 class="mb-0">Statement of intent</h5>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table class="table">
                                <tbody>
                                    {props?.prospectInView?.loanRequest?.statementOfIntent == null ? null :
                                        <tr>
                                            <td><HiddenText unmasked={props.unmasked}><span><b>{props.prospectInView.loanRequest.statementOfIntent}</b></span></HiddenText></td>
                                        </tr>}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>}
        </>
    );
}
