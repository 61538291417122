import React, { Component } from 'react';
import { connect } from 'react-redux';
import CurrencyFormat from "react-currency-format";
import moment from 'moment';
import { fetchMostRecentInvoice, fetchPaidInvoicesMetadata , fetchInvoiceReference, downloadInvoice } from '../../../store/billing/actions';
import { fetchExtras } from '../../../store/brokerAgent/actions';

import InvoicesPagination from './InvoicesPagination'

class BillingSubscriber extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchMostRecentInvoice ()
        this.props.fetchPaidInvoicesMetadata()
        this.props.fetchExtras()
    }

    componentDidUpdate() {
        ////console.log(this.props)
    }


    render() {
        const fetchInvoiceReference = this.props.fetchInvoiceReference;
        const invoiceReferences = this.props.invoiceReferences;
        const downloadInvoice = this.props.downloadInvoice;
        return (
        <>
        <div class="container-fluid">
            <div class="hstack gap-2 d-lg-flex align-items-lg-start">
                <div class="card shadow border-start-success py-2">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <div class="vstack gap-2">
                                    <div class="row align-items-center no-gutters">
                                        {this.props?.extras ?
                                        <>
                                        <div class="row align-items-center no-gutters">
                                            <div class="col-auto me-2">
                                                <div class="text-uppercase text-success fw-bold text-xs mb-1"><span>Plan</span></div>
                                                <div class="text-dark fw-bold h5 mb-0">{
                                                    this.props?.extras.map(e => {
                                                        return (<>{e.package.name}</>)
                                                        })}</div>
                                            </div>
                                            <div class="col-auto d-lg-flex flex-fill align-items-end justify-content-lg-end"><i class="fas fa-barcode fa-2x text-gray-300"></i></div>
                                        </div>
                                        <hr /></> : null }
                                        <div class="col-auto me-2">
                                            <div class="text-uppercase text-success fw-bold text-xs mb-1"><span>Last payment</span></div>
                                            <div class="text-dark fw-bold h5 mb-0">
                                                <CurrencyFormat value={this.props?.latestInvoice?.totalAmount?.value} 
                                                displayType={'text'} thousandSeparator={true} prefix={'$'}
                                                decimalScale={2} fixedDecimalScale={true}  /></div>
                                        </div>
                                        {this.props?.latestInvoice?.subscription?.totalOutstandingBalance ? 
                                        <div class="col-auto me-2">
                                            <div class="text-uppercase text-success fw-bold text-xs mb-1"><span>Overdue amount</span></div>
                                            <div class="text-dark fw-bold h5 mb-0">
                                                <CurrencyFormat value={this.props?.latestInvoice?.subscription?.totalOutstandingBalance?.value} 
                                                displayType={'text'} thousandSeparator={true} prefix={'$'}
                                                decimalScale={2} fixedDecimalScale={true}  /> </div>
                                        </div> : null }
                                        <div class="col-auto d-lg-flex flex-fill align-items-end justify-content-lg-end"><i class="fas fa-dollar-sign fa-2x text-gray-300"></i></div>
                                    </div>
                                    <hr />
                                    <div class="row align-items-center no-gutters">
                                        <div class="col-auto me-2">
                                            <div class="text-uppercase text-success fw-bold text-xs mb-1"><span>Next payment date</span></div>
                                            <div class="text-dark fw-bold h5 mb-0">{moment(this.props?.latestInvoice?.subscription?.nextRenewalDate).format('Do MMM YYYY')}</div>
                                        </div>
                                        <div class="col-auto d-lg-flex flex-fill align-items-end justify-content-lg-end"><i class="far fa-calendar-alt fa-2x text-gray-300"></i></div>
                                    </div>
                                    <hr />
                                    <div class="row align-items-center no-gutters">
                                        <div class="col-auto me-2">
                                            <div class="text-uppercase text-success fw-bold text-xs mb-1"><span>Payment method</span></div>
                                            <div class="hstack gap-2">
                                                <div class="text-dark fw-bold h5 mb-0"><span>Credit card ending {this.props?.cardDigits} </span></div>
                                            </div>
                                        </div>
                                        <div class="col-auto d-lg-flex flex-fill align-items-end justify-content-lg-end"><i class="far fa-credit-card fa-2x text-gray-300"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="vstack">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Payment date</th>
                                    <th>Payment amount</th>
                                    <th>Receipt</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.receipts.map((receipt) => {
                                    const invoice = invoiceReferences.find(i => receipt._id === i?.metadata?.receiptReference)
                                    if (!invoice)
                                        fetchInvoiceReference(receipt._id)
                                    return !invoice ? (<><tr>Loading...<td></td></tr></>) : (
                                        <tr>
                                            <td>{moment(receipt?.paymentReference?.createdTs).format('YYYY/MM/DD')}</td>
                                                <td><CurrencyFormat value={receipt?.totalAmount.value} 
                                                        displayType={'text'} thousandSeparator={true} prefix={'$'}
                                                        decimalScale={2} fixedDecimalScale={true}  /></td>
                                            <td>
                                                <a class="link-primary" href="#" onClick={e => {
                                                    e.preventDefault();
                                                    downloadInvoice(invoice._id)
                                                }} ><i class="fas fa-file-invoice-dollar"></i></a></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </div>
                    <InvoicesPagination/>
                </div>
            </div>
        </div>
        </>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.billing,
        subscription : state.brokerAgentProfile.subscription,
        extras : state.brokerAgentProfile.extras,

    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchMostRecentInvoice : () => dispatch(fetchMostRecentInvoice ()),
        fetchPaidInvoicesMetadata : () => dispatch(fetchPaidInvoicesMetadata ()),
        fetchInvoiceReference : (receiptReferenceId) => dispatch(fetchInvoiceReference (receiptReferenceId)),
        downloadInvoice : (invoiceId) => dispatch(downloadInvoice(invoiceId)),
        fetchExtras : () => dispatch(fetchExtras()),
    }
}

export default connect (
    mapStateToProps, mapDispatchToProps 
)(BillingSubscriber)