import React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from  'classnames';
import { submitRegistration } from '../../../store/auth/actions';

function RegistrationForm() {
    const [title, setTitle] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [mobile, setMobile] = useState('');
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');

    const awaitingEmailVerification = useSelector( state => state.auth.awaitingEmailVerification )
    if (awaitingEmailVerification) {
      setEmail('');
      setFirstName('');
      setLastName('');
      setMobile('');
      setPassword('');
      setPasswordRepeat('');
    }

    const registrationFormErrors = useSelector( state => state.auth.registrationFormErrors )
    var titleInputClass = classNames({
      '': registrationFormErrors.find((element) => element?.type == 'title') == null,
      'alert alert-danger regionError': registrationFormErrors.find((element) => element?.type == 'title') != null,
    });
    var txtFNameClass = classNames({
      'form-control': registrationFormErrors.find((element) => element?.type == 'firstName') == null,
      'form-control borderError': registrationFormErrors.find((element) => element?.type == 'firstName') != null,
    });
    var txtLNameClass = classNames({
      'form-control': registrationFormErrors.find((element) => element?.type == 'lastName') == null,
      'form-control borderError': registrationFormErrors.length > 0 && registrationFormErrors.find((element) => element?.type == 'lastName') != null,
    });

    const dispatch = useDispatch();
    
    return ( 
          <div class="row d-flex justify-content-center">
              <div>
                          <p class="w-lg-50">Sign up for a <strong>free</strong> Loanseller account on our specialised mortgage broker connectivity platform for secure fact find with your clients.</p>                          
                          <form method="post" >
                          <p class="w-lg-50">Upgrade at any time to one of our unbeatable packages for advanced productivity and sales generation</p>
                          <div class="mb-3">
                              <div className={titleInputClass} role="alert" ><span></span>
                              <div class="hstack gap-2 d-flex justify-content-center ">
                                  <div class="form-check"><input class="form-check-input" type="radio" id="formCheck-1" name="title" value="Mr" 
                                  checked={title === "Mr"} onChange={(e) => setTitle(e.target.value)} /><label class="form-check-label" for="formCheck-1" >Mr</label></div>
                                  <div class="form-check"><input class="form-check-input" type="radio" id="formCheck-2" name="title" value="Ms"
                                  checked={title === "Ms"} onChange={(e) => setTitle(e.target.value)} /><label class="form-check-label" for="formCheck-2" >Ms</label></div>
                                  <div class="form-check"><input class="form-check-input" type="radio" id="formCheck-3" name="title" value="Mrs"
                                  checked={title === "Mrs"} onChange={(e) => setTitle(e.target.value)}/><label class="form-check-label" for="formCheck-3" >Mrs</label></div>
                              </div>
                              </div>
                            </div>
                            <div class="mb-3"><input className={txtFNameClass} type="text" id="name-2" name="name" placeholder="First name" 
                                value={firstName} onChange={(e) => setFirstName(e.target.value)} /></div>
                            <div class="mb-3"><input className={txtLNameClass} type="text" id="name-1" name="name" placeholder="Last name" 
                                value={lastName} onChange={(e) => setLastName(e.target.value)} /></div>

                                {
                                  registrationFormErrors.find((element) => element?.type == 'mobile') == null ? 
                                  (        
                                    <div class="mb-3">
                                      <input className={'form-control'} type="text" id="registrationForm_mobile" name="registrationForm_mobile" placeholder="Mobile"  
                                        value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                    </div>
                                  ) : (
                                    <div class="mb-3">
                                        <div className={'alert alert-danger regionError'} role="alert" >
                                        <input className={'form-control borderError'} type="text" id="registrationForm_mobile" name="registrationForm_mobile" placeholder="Mobile" 
                                          value={mobile} onChange={(e) => setMobile(e.target.value)} />
                                        <span><strong>{registrationFormErrors.find((element) => element?.type == 'mobile').errorMessage}</strong><br/></span></div>
                                      </div>
                                  )
                                }

                                {
                                  registrationFormErrors.find((element) => element?.type == 'email') == null ? 
                                  ( 
                                    <div class="mb-3">
                                      <input className={'form-control'} type="email" id="registrationForm_email" name="registrationForm_email" placeholder="Email"  
                                        value={email} onChange={(e) => setEmail(e.target.value)} />
                                    </div>
                                  ) : (
                                    <div class="mb-3">
                                        <div className={'alert alert-danger regionError'} role="alert" >
                                        <input className={'form-control borderError'} type="email" id="registrationForm_email" name="registrationForm_email" placeholder="Email" 
                                          value={email} onChange={(e) => setEmail(e.target.value)} />
                                        <span><strong>{registrationFormErrors.find((element) => element?.type == 'email').errorMessage}</strong><br/></span></div>
                                      </div>
                                  )
                                }

                                {
                                  registrationFormErrors.find((element) => element?.type == 'password') == null ? 
                                    ( 
                                      <div class="mb-3">
                                        <input className={'form-control'} type="password" id={'registrationForm_password'} name={'registrationForm_password'} placeholder={'Enter a safe Password1!'}  
                                          value={password} onChange={(e) => setPassword(e.target.value)} />
                                      </div>
                                    ) : (
                                      <div class="mb-3">
                                          <div className={'alert alert-danger regionError'} role="alert" >
                                            <input className={'form-control borderError'} type="password" id={'registrationForm_password'} name={'registrationForm_password'} placeholder={'Enter a safe Password1!'}  
                                              value={password} onChange={(e) => setPassword(e.target.value)} />
                                          <span><strong>{registrationFormErrors.find((element) => element?.type == 'password').errorMessage}</strong><br/></span></div>
                                        </div>
                                    )
                                }

                                {
                                  registrationFormErrors.find((element) => element?.type == 'passwordRepeat') == null ? 
                                    ( 
                                      <div class="mb-3">
                                        <input className={'form-control'} type="password" id={'registrationForm_passwordRepeat'} name={'registrationForm_password'} placeholder={'Confirm password'}  
                                          value={passwordRepeat} onChange={(e) => setPasswordRepeat(e.target.value)} />
                                      </div>
                                    ) : (
                                      <div class="mb-3">
                                          <div className={'alert alert-danger regionError'} role="alert" >
                                            <input className={'form-control borderError'} type="password" id={'registrationForm_passwordRepeat'} name={'registrationForm_password'} placeholder={'Confirm password'}  
                                              value={passwordRepeat} onChange={(e) => setPasswordRepeat(e.target.value)} />
                                          <span><strong>{registrationFormErrors.find((element) => element?.type == 'passwordRepeat').errorMessage}</strong><br/></span></div>
                                        </div>
                                    )
                                }

                                <div><button class="btn btn-primary d-block w-100" onClick={(e) => {
                                    e.preventDefault();
                                    dispatch(submitRegistration(title, firstName, lastName, mobile, email, password, passwordRepeat));
                                }}
                                >Send</button></div>
                            </form>
                </div>
            </div>
     );
}

export default RegistrationForm;