import React from 'react';
import BorrowerChats from './BorrowerChats';

const Index = (props) => {

    return (
      <>
        <BorrowerChats />
      </>
  );
};

export default Index;