import _ from './actionType';

function translateProposalToState (proposal) {
  const state = true ?  
    {
      proposalInView : proposal,

      error: null,
      } : {};
  return state;
}

const initialState = { 
  selectedLoanTermInYrs: null,
  selectedLoanProducts:[] ,
  proposalInView : null,
  amortizationTable: null,
}

export default function sales(state = initialState, action) {
  switch (action.type) {

    case _.discardProposal:
      return {
        ...initialState,
      }      
    case _.setupFromProposal:
      return {
        ...state,
        ...translateProposalToState(action.payload), 
      }

    case _.fetchProduct.success:
      const loanProduct = []
      loanProduct[action.payload._id] = action.payload
      return {
        ...state,
        selectedLoanProducts: {...state.selectedLoanProducts, ...loanProduct},
      }
    case _.fetchProduct.fail:
      return {
        ...state,
        error: action.payload,
      }

    case _.runAmortization.success:
      var amortizationTable = []
      amortizationTable[action.payload.index] = {
        loanProductId : action.payload.loanProductId,
        instalmentAmount : action.payload.data.instalmentAmount.value,
        ...action.payload.data
      }
      amortizationTable["milestoneYears"] = action.payload.data.milestoneYears
      return {
          ...state,
          amortizationTable: {...state.amortizationTable, ...amortizationTable} ,
        }
    case _.runAmortization.fail:
      return {
        ...state,
        error: action.payload,
      }
      

    default:
      return state;
  }
}
