const setupFromProposal = 'sales/editLender/setupFromProposal'
const fetchProduct = {success: 'sales/fetchProduct/success', fail:'sales/fetchProduct/fail'};
const runAmortization = {success: 'sales/runAmortization/success', fail:'sales/runAmortization/fail'};
const discardProposal = 'sales/summary/discardProposal';
export default {
  setupFromProposal,
  fetchProduct,
  runAmortization,
  discardProposal,
};
