import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  } from '../../../../store/loanProducts/actions';
import Accordion from 'react-bootstrap/Accordion';

function Benefits(props) {
    return (
        <>
        {props.product.entitlements.benefit.map(function(each){
            return (
                <>
                <tr>
                    <td>{each}</td>
                </tr>
                </>
            );
        })}
        </>
    );
}

function Discounts(props) {
    return (
        <>
        {props.product.entitlements.discount.map(function(each){
            return (
                <>
                <tr>
                    <td>{each}</td>
                </tr>
                </>
            );
        })}
        </>
    );
}

class ProductDetailsModal extends Component {
    constructor(props) {
        super(props)
    }

    render() { 
        return (
        <>
        <div id="modal-loanproduct" class="modal fade" role="dialog" tabindex="-1">
            <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable modal-fullscreen" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div class="vstack">
                            <h4 class="modal-title">{this.props?.product?.productName}</h4>
                        </div>
                        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                    {this.props.product == null ? null : 

                        <div class="vstack gap-2">

                            <div class="row">

                                <div class="col-md-6">
                                    <div class="vstack gap-2">

                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                            <Accordion.Header><div class="hstack gap-2"><i class="fas fa-info-circle"></i><span>General information</span></div></Accordion.Header>
                                            <Accordion.Body>
                                            <div class="card shadow border-start-success py-2">
                                                <div class="card-body">
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>Interest Category</span></td>
                                                                    <td>{this.props.product.features.loanInterestCategory}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span>Rate Option</span></td>
                                                                    <td>{this.props.product.features.rateOption}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span>Loan purpose</span></td>
                                                                    <td>{this.props.product.features.residentialLoan === "true" ? "Residential" : "Investor" }</td>
                                                                </tr>
                                                                {this.props.product.variation == null ? null : 
                                                                <tr>
                                                                    <td><span>Variation</span></td>
                                                                    <td>{this.props.product.variation}</td>
                                                                </tr>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>

                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                            <Accordion.Header><div class="hstack gap-2"><i class="fas fa-calculator"></i><span>Other features</span></div></Accordion.Header>
                                            <Accordion.Body>
                                                <div class="card shadow border-start-success py-2">
                                                    <div class="card-body">
                                                        <div class="table-responsive">
                                                            <table class="table">
                                                                <tbody>
                                                                    {this.props.product.features?.additionalRepaymentSupport  ? 
                                                                    <tr>
                                                                        <td colspan="2"><span>Additional repayment support</span></td>
                                                                    </tr> : null }
                                                                    {this.props.product.features?.maxLoanTermInYears != null  ? 
                                                                    <tr>
                                                                        <td><span>Maximum loan term</span></td>
                                                                        <td><span>{this.props.product.features.maxLoanTermInYears} years</span></td>
                                                                    </tr> : null }
                                                                    {this.props.product.features?.maxFixedTermInMonths != null  ? 
                                                                    <tr>
                                                                        <td><span>Fixed term</span></td>
                                                                        <td><span>{this.props.product.features.maxFixedTermInMonths} months</span></td>
                                                                    </tr> : null }
                                                                    {this.props.product.features?.minLoanAmount != null  ? 
                                                                    <tr>
                                                                        <td><span>Minimum loan amount</span></td>
                                                                        <td><span>{
                                                                        this.props.product.features.minLoanAmount.value
                                                                            .toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
                                                                        }</span></td>
                                                                    </tr> : null }
                                                                    {this.props.product.features?.redraw ? 
                                                                    <tr>
                                                                        <td colspan="2"><span>Redraw support</span></td>
                                                                    </tr> : null }
                                                                    {this.props.product.features?.specialBorrowerCategory != null ? 
                                                                    <tr>
                                                                        <td colspan="2"><span>{this.props.product.features.specialBorrowerCategory}</span></td>
                                                                    </tr> : null }

                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>

                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="vstack gap-2">
                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                            <Accordion.Header><div class="hstack gap-2"><i class="fas fa-calculator"></i><span>Rates and fees</span></div></Accordion.Header>
                                            <Accordion.Body>
                                            <div class="card shadow border-start-success py-2">
                                                <div class="card-body">
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <tbody>
                                                                <tr>
                                                                    <td><span>Interest Rate</span></td>
                                                                    <td>{this.props.product.ratesAndFees.interestRate}%</td>
                                                                </tr>
                                                                {this.props.product.ratesAndFees.comparisonRate != null ? 
                                                                <tr>
                                                                    <td><span>Comparison Rate</span></td>
                                                                    <td><span>{this.props.product.ratesAndFees.comparisonRate}%</span></td>
                                                                </tr> : null }

                                                                {this.props.product.ratesAndFees.indexInterestRateIO != null ? 
                                                                <tr>
                                                                    <td><span>Index Interest Rate</span></td>
                                                                    <td><span>{this.props.product.ratesAndFees.indexInterestRateIO}%</span></td>
                                                                </tr> : null }
                                                                {this.props.product.ratesAndFees.indexInterestRatePI != null ? 
                                                                <tr>
                                                                    <td><span>Index Interest Rate</span></td>
                                                                    <td><span>{this.props.product.ratesAndFees.indexInterestRatePI}%</span></td>
                                                                </tr> : null }

                                                                {this.props.product.ratesAndFees.indexComparisonRateIO != null ? 
                                                                <tr>
                                                                    <td><span>Index Conparison Rate</span></td>
                                                                    <td><span>{this.props.product.ratesAndFees.indexComparisonRateIO}%</span></td>
                                                                </tr> : null }
                                                                {this.props.product.ratesAndFees.indexComparisonRatePI != null ? 
                                                                <tr>
                                                                    <td><span>Index Conparison Rate</span></td>
                                                                    <td><span>{this.props.product.ratesAndFees.indexComparisonRatePI}%</span></td>
                                                                </tr> : null }

                                                                {this.props.product.ratesAndFees.loanProductFees.map(function(each){
                                                                    return (
                                                                        <>
                                                                        {each.applicable ? 
                                                                        <tr>
                                                                            <td><span>{each?.label}</span></td>
                                                                            { ( each.amount ) ? <td><span>${each.amount?.value}</span></td> :
                                                                                each.applicable === true ? <td>{each.text}</td> : <td></td> }
                                                                        </tr> : null }
                                                                        </>
                                                                    );
                                                                })}
                                                                {this.props.product.ratesAndFees.feeStatement != null ? 
                                                                <tr>
                                                                    <td colspan="2"><span>{this.props.product.ratesAndFees.feeStatement}</span></td>
                                                                </tr> : null }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>

                                        <Accordion>
                                            <Accordion.Item eventKey="0">
                                            <Accordion.Header>
                                                <div class="hstack gap-2">
                                                <svg class="bi bi-award-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                                    <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z"></path>
                                                    <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"></path>
                                                </svg>
                                                <span>Benefits and discounts</span></div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                            <div class="card shadow border-start-success py-2">
                                                <div class="card-body">
                                                    <div class="table-responsive">
                                                        <table class="table">
                                                            <tbody>
                                                                    {this.props.product.entitlements.benefit != null  ? 
                                                                    <Benefits {...this.props} /> : null }
                                                                    {this.props.product.entitlements.discount != null  ? 
                                                                    <Discounts {...this.props} /> : null }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                            </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>

                                    </div>
                                </div>

                            </div>

                        </div>
                    
                    }
                    </div>
                    <div class="modal-footer"></div>
                </div>
            </div>
        </div> 
        </>
        );
    }
}
 
const mapStateToProps = ({ loanProducts }) => {
    return { 
        error: loanProducts.error,
        product: loanProducts.selectedProduct
      };
  };
  
  const mapDispatchToProps = (dispatch)=> {
      return {
      };
  };
  
  export default connect(
      mapStateToProps, 
      mapDispatchToProps
  )(ProductDetailsModal);