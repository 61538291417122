import { ADDRESS_ACTION_TYPES } from "./actionTypes";

export const initialState = {
    loading: false,
    autocompleteOptions: [],
    selectedAddress: null,
    error: null,
}

export default function autocompleteReducer(state = initialState, action) {
    switch (action.type) {
        case ADDRESS_ACTION_TYPES.FETCH_LOADING:
            return {
                ...state,
                loading: true
            }
        case ADDRESS_ACTION_TYPES.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                autocompleteOptions: action.payload,
                error: null,
            }
        case ADDRESS_ACTION_TYPES.FETCH_FAILURE:
            return {
                ...state,
                loading: false,
                autocompleteOptions: [], 
                error: "Failed to load addresses",
            }
        case ADDRESS_ACTION_TYPES.VALIDATION_ERROR:
            return {
                ...state,
                error: action.payload
            }
        case ADDRESS_ACTION_TYPES.SAVE_SUCCESS:
            return {
                ...state,
                selectedAddress: action.payload,
                error:null
            }
        default:
            return state
    }
}
  