import React from 'react';
import CaseHistory from './caseHistory';
import CaseNotes from './caseNotes';
import CaseProgress from './caseProgress';
import ClientInfo from './clientInfo';


function CasesTab() {
    return ( 
        <div class="tab-pane active" role="tabpanel" id="tab-2">
            <div class="vstack gap-2">
                <div class="row">
                    <div class="col d-flex flex-column">
                        <div class="vstack gap-2">
                            < CaseNotes />
                            < CaseHistory />
                        </div>
                    </div>
                    <div class="col-9">
                        <div class="vstack gap-2">
                            < ClientInfo />
                            < CaseProgress />
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}

export default CasesTab;