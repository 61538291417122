import React, {Component} from 'react';
import {connect} from 'react-redux';
import classNames from  'classnames';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import LoadMultiStepFormScript from '../../../common/loadMultiStepFormScript';
import ErrorModal from '../../../common/ErrorModal';
import LenderSelection from '../LenderSelection';
import ProductsSelection from '../ProductsSelection';
import DocumentSelection from './DocumentSelection';
import Finalise from '../Finalise';

import { clearErrors,  } from "../../../../store/sales/actions";

const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);

class EditProposalMultiStep extends Component {
    constructor(props) {
        super(props)
    }

    verifyLenderandLoanTerm(props) {
        return props?.selectedLender && props.selectedLender != null
            && props?.selectedLoanTermInYrs && props.selectedLoanTermInYrs != null
    }

    verifyLoanScenarios(props) {
        return props.loanScenarios.length > 0 && !props.loanScenarios.find(s => s.loanAmount < 20000)
    }

    render() {
        var step2Enabled = this.verifyLenderandLoanTerm(this.props);
        var step3Enabled = this.verifyLoanScenarios(this.props);
        var loanProductsLinkClass = classNames({
            'btn multisteps-form__progress-btn': step2Enabled ,
            'btn multisteps-form__progress-btn disabled': !(step2Enabled ),
            }); 
        var submissionDocumentsLinkClass = classNames({
            'btn multisteps-form__progress-btn': step2Enabled && step3Enabled,
            'btn multisteps-form__progress-btn disabled': !(step2Enabled && step3Enabled),
            }); 
        var finaliseLinkClass = classNames({
            'btn multisteps-form__progress-btn': step2Enabled && step3Enabled,
            'btn multisteps-form__progress-btn disabled': !(step2Enabled && step3Enabled),
            }); 
        return (
        <>
        <div class="vstack gap-2">
            <div id="multple-step-form-n" style={{"margin-top": "0px","margin-bottom": "10px","padding-bottom": "300px","padding-top": "57px"}}>
                <div id="progress-bar-button" class="multisteps-form">
                <div class="d-lg-flex justify-content-lg-center row" style={{"overflow": "auto"}}>
                        <div class="col-12 col-lg-8 ml-auto mr-auto mb-4">
                            <div class="multisteps-form__progress">
                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ locationtext: 'Select a lender' })} >
                                    <a class="btn multisteps-form__progress-btn js-active" role="button" >Lender</a>
                                </OverlayTrigger>
                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ locationtext: 'Select loan products' })} >
                                    <a class={loanProductsLinkClass} role="button" >Loan package</a>
                                </OverlayTrigger>
                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ locationtext: 'Select submission documents required from borrower' })} >
                                    <a class={submissionDocumentsLinkClass} role="button" >Submission documents</a>
                                </OverlayTrigger>
                                <OverlayTrigger placement="bottom" overlay={renderTooltip({ locationtext: 'Review and save scenario' })} >
                                    <a class={finaliseLinkClass} role="button" >Finalise</a>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="multistep-start-row" class="row" >
                    <div id="multistep-start-column" class="col-12 col-lg-8 m-auto" style={{"min-width": "100%"}}>
                        <form id="main-form" class="multisteps-form__form">
                            <LenderSelection nextEnabled={step2Enabled} permanent={true}  />
                            <ProductsSelection nextEnabled={step3Enabled} permanent={true} />
                            <DocumentSelection permanent={true} />
                            <Finalise permanent={true} />
                        </form>
                    </div>
                </div>
            </div>
        </div>
        {this.props.error ? 
            <ErrorModal {...this.props} onClose={() => {
                this.props.clearErrors()
            }} /> : null }

        <LoadMultiStepFormScript />
        </>
        )
    }
}


const mapStateToProps = state => {
    return {
        currentDisplay: state.sales.currentDisplay,
        selectedLender: state.sales.selectedLender,
        maxYears: state.sales.maxYears,
        selectedLoanTermInYrs: state.sales.selectedLoanTermInYrs,
        editableLoanAmount: state.sales.editableLoanAmount,
        loanProductRecords: state.sales.loanProductRecords,
        displayPage: state.sales.selectLoanProductsDisplayPage,
        recordsPerPage: state.sales.selectLoanProductsPageSize,
        filters: state.sales.selectLoanProductsFilters,
        sort: state.sales.selectLoanProductsSort,
        error: state.sales.error,
        loanAmount: state.sales.editableLoanAmount,
        loanScenarios: state.sales.loanScenarios,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        clearErrors: () => dispatch(clearErrors()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditProposalMultiStep);