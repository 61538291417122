import React, { Component } from 'react';
import { connect } from "react-redux";
import { useState } from 'react';
import classNames from  'classnames';
import { sendInvite, toggleAcknowledge } from '../../../store/clients/actions';
import ErrorContainer from "../../common/ErrorContainer";

function NameEntry(props) {
    var errorMsgClass = classNames({
      '': props.error == null,
      'alert alert-danger regionError': props.error != null,
    });
    var txtClass = classNames({
      'form-control': props.error == null,
      'form-control borderError': props.error != null,
    });  
  return props.error == null ? 
  (        
    <div class="mb-3">
      <input className={txtClass} type="text" id="inviteForm_name" name="inviteForm_name" placeholder="Name"  
        value={props.name} onChange={(e) => props.setName(e.target.value)} />
    </div>
  ) : (
    <div class="mb-3">
        <div className={errorMsgClass} role="alert" >
        <input className={txtClass} type="text" id="inviteForm_name" name="inviteForm_name" placeholder="Name" 
          value={props.name} onChange={(e) => props.setName(e.target.value)} />
        <span><strong>{props.error.errorMessage}</strong><br/></span></div>
      </div>
  );
}

function MobileEntry(props) {
    var errorMsgClass = classNames({
      '': props.error == null,
      'alert alert-danger regionError': props.error != null,
    });
    var txtClass = classNames({
      'form-control': props.error == null,
      'form-control borderError': props.error != null,
    });  
  return props.error == null ? 
  (        
    <div class="mb-3">
      <input className={txtClass} type="text" id="inviteForm_mobile" name="inviteForm_mobile" placeholder="Mobile"  
        value={props.mobile} onChange={(e) => props.setMobile(e.target.value)} />
    </div>
  ) : (
    <div class="mb-3">
        <div className={errorMsgClass} role="alert" >
        <input className={txtClass} type="text" id="inviteForm_mobile" name="inviteForm_mobile" placeholder="Mobile" 
          value={props.mobile} onChange={(e) => props.setMobile(e.target.value)} />
        <span><strong>{props.error.errorMessage}</strong><br/></span></div>
      </div>
  );
}

function EmailEntry(props) {
    var errorMsgClass = classNames({
      '': props.error == null,
      'alert alert-danger regionError': props.error != null,
    });
    var txtClass = classNames({
      'form-control': props.error == null,
      'form-control borderError': props.error != null,
    });  
  return props.error == null ? 
  ( 
    <div class="mb-3">
      <input className={txtClass} type="email" id="inviteForm_email" name="inviteForm_email" placeholder="Email"  
        value={props.email} onChange={(e) => props.setEmail(e.target.value)} />
    </div>
  ) : (
    <div class="mb-3">
        <div className={errorMsgClass} role="alert" >
        <input className={txtClass} type="email" id="inviteForm_email" name="inviteForm_email" placeholder="Email" 
          value={props.email} onChange={(e) => props.setEmail(e.target.value)} />
        <span><strong>{props.error.errorMessage}</strong><br/></span></div>
      </div>
  );
}

function ClientInvite(props) {

    const [inviteAsEmail, toggleEmailMobile] = useState(true)
    const [email, setEmail] = useState("")
    const [mobile, setMobile] = useState("")
    const [name, setName] = useState("")
    const [acknowledge, setAcknowledge] = useState(false)

    return ( 
        <div class="row d-flex justify-content-center">
            <div class="col-md-8 col-lg-6 col-xl-5 col-xxl-4">
                <div class="card mb-5">
                    <div class="card-header">
                        <div class="hstack d-lg-flex justify-content-lg-center">
                            <h2 class="text-center mb-4">Invite to connect</h2>
                        </div>
                    </div>
                    <div class="card-body p-sm-5">
                        <div class="mb-3">
                          <NameEntry name={name} setName={setName} error={props.inviteErrors.find((element) => element?.type == 'name')} /> 
                        </div>
                        <div class="mb-3">
                            <div class="vstack gap-1">
                                <div class="hstack gap-1 d-lg-flex justify-content-lg-center">
                                    <span>Email</span>
                                    <div class="form-check form-switch"
                                    onClick={() => toggleEmailMobile(!inviteAsEmail)}>
                                        <input class="form-check-input" type="checkbox" id="formCheck-1" />
                                        <label class="form-check-label" for="formCheck-1"></label>
                                    </div>
                                    <span>Phone</span>
                                </div>
                                { inviteAsEmail ? 
                                      <EmailEntry email={email} setEmail={setEmail} error={props.inviteErrors.find((element) => element?.type == 'email')} /> :
                                      <MobileEntry mobile={mobile} setMobile={setMobile} error={props.inviteErrors.find((element) => element?.type == 'mobile')} />
                                }
                            </div>
                        </div>
                        <div class="mb-3">
                          <ErrorContainer error={props.inviteErrors.find((element) => element?.type == 'acknowledge' && !element?.valid)}>
                            <div class="form-check">
                              <input id="formCheck-2" class="form-check-input" type="checkbox" 
                                onChange={() => {setAcknowledge(!acknowledge)}} checked={acknowledge} />
                              <label class="form-check-label" for="formCheck-2">I have discussed with the client that he/she will receive an email/SMS to connect via the LoanShopper app</label>
                            </div>
                          </ErrorContainer>
                        </div>
                        <div>
                            <button class="btn btn-primary d-block w-100" type="button"
                              onClick={(e) => {
                                  props.sendInvite(
                                      {
                                          name: name,
                                          phone: mobile, 
                                          email: email,
                                          acknowledge: acknowledge,
                                          inviteAsEmail: inviteAsEmail 
                                      }
                                  )
                                  }}
                                  >
                                Send 
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}

class ClientInviteForm extends Component {
	constructor(props) {
        super(props);
    }

    render () {
        return ( 
        <> 
        <ClientInvite {...this.props} /> </>
        );
    }
  }


const mapStateToProps = ({ clients }) => {
  return { 
    inviteErrors: clients.inviteErrors,
    };
};

const mapDispatchToProps = (dispatch)=> {
    return {
        sendInvite: (props) => dispatch(sendInvite(props)),
    };
};
export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(ClientInviteForm);