import { Col } from "react-bootstrap";
import { getProspectName } from "./prospectNameFormatting";
import { getReadableTs } from "./timestampFormatting";

export default function iterativeRender(prospectsList, numElements=3) {
    let counter = 0
    if(prospectsList.length > 0){
        return prospectsList.map(function(each){
            if(counter >= numElements) {
                return null
            }
            counter ++
            return (
                <>
                    <Col className="m-2">
                        <h4
                            style={{ fontSize: "15px" }}
                            className="small fw-bold"
                        >
                            {getProspectName(each.mainBorrower)} 
                            {each.mainBorrower?.currentAddress?.state  == null ? null :<span> from {each.mainBorrower?.currentAddress?.state} {each.mainBorrower?.currentAddress?.postCode}</span>}
                            <span> joined for a {each.loanRequest.loanPurpose} home loan</span>
                            
                             
                        </h4>
                        <p style={{ fontSize: "13px" }}>{getReadableTs(each.createdTs)}</p>
                    </Col>
                </>
                )
            })
      } 
}