import { ACTIVITIES_ACTION_TYPES } from "./actionTypes";

export const initialState = {
    activities:[],
    borrowers: [],
    currentPage: 1,
    maxDisplaySize: 4,
    hasMoreActivities: false,
}

export default function activitiesReducer(state = initialState, action) {
    switch (action.type) {
        case ACTIVITIES_ACTION_TYPES.FETCH_SUCCESS:
            return {...state, activities: action.payload.activities, borrowers : {}, currentPage: action.payload.page }
        case ACTIVITIES_ACTION_TYPES.FETCH_FAILURE:
            return {...state, error: action.payload}
        case ACTIVITIES_ACTION_TYPES.FETCH_BORROWER_SUCCESS:
            return {...state, borrowers : {...state.borrowers, ...action.payload}}
        case ACTIVITIES_ACTION_TYPES.FETCH_HAS_MORE_ACTIVITIES:
            return {...state, hasMoreActivities: action.payload.hasMoreActivities}
        default:
            return state
    }
}