import React, { Component, useState, useEffect } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import classNames from  'classnames';
import Tooltip from "react-bootstrap/Tooltip";

import { fetchBorrowerChatMessages, addBorrowerChatMessage, sawBorrowerChatMessage } from "../../../store/clients/actions";

function getChatTime(timestamp) {
    const givenTs = moment(timestamp)
    return givenTs.isBefore(moment().startOf('year')) ? givenTs.format("DD/MM/YYYY") : givenTs.isBefore(moment().startOf('day')) ? givenTs.format("DD/MM"): givenTs.format("hh:mm A")
}

function ChatWindow(props) {
    const [textMessage, setTextMessage] = useState(null)

    return (
        <div class="card shadow flex-fill border-start-warning py-2">
            <div class="card-body">
                <div class="row align-items-center no-gutters">
                    <div class="col me-2">
                        <div class="text-uppercase text-warning fw-bold text-xs mb-1"><span>Messages</span></div>
                    </div>
                    <div class="col-auto"><i class="fas fa-comments fa-2x text-gray-300"></i></div>
                </div>
                <div class="vstack gap-2">
                    <div class="hstack gap-2">
                        <div class="vstack">
                            <div class="input-group">
                                <textarea class="form-control" rows="6" value={textMessage} onChange={(e) => {setTextMessage(e.target.value)}} ></textarea>
                                <button class="btn btn-light" type="button" 
                                    onClick={() => {
                                        if (props.enabled)
                                            props.addBorrowerChatMessage(props.borrower._id, textMessage)
                                        setTextMessage('')
                                        }}>{props.enabled ? 'Send' : 'Upgrade for messaging'}</button>
                            </div>
                        </div>
                    </div>
                    <div class="vstack" style={{overflow: "auto",padding: "5%",maxHeight: "65%", "overflow": "scroll",}}>
                        {
                            props?.chatMessages ? 
                            props.chatMessages.map((message) => {
                                var buttonClass = classNames({
                                    'alert' : true,
                                    'alert-primary d-xxl-flex align-self-end justify-content-xxl-end': message.sender == 'agent',
                                    'alert-dark align-self-start': message.sender == 'borrower' && message.status == 'seen',
                                    'alert-success align-self-start': message.sender == 'borrower' && message.status == 'unseen' ,
                                });           
                                if (message.sender == 'borrower' && message.status == 'unseen') {
                                    setTimeout(() => {
                                        props.sawBorrowerChatMessage(message._id)
                                    }, 60000)
                                }
                                return (
                                    <div class={buttonClass} role="alert" style={{"max-width" : "80%"}}>
                                        <div class="vstack">
                                            <div class="vstack">
                                                <span><strong>{message.sender == 'agent' ? 'Me' : props.borrower.firstName}</strong> {message.text}</span>
                                            </div>
                                            <div class="vstack d-xxl-flex flex-row-reverse">
                                                <span style={{fontSize : "10px"}}>{getChatTime(message.createdTs)}</span>

                                                <div class="vstack">
                                                    {message.status == 'unseen' ? 
                                                    <svg class="bi bi-broadcast" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                                        <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"></path>
                                                    </svg> :
                                                    <svg class="bi bi-eye-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"></path>
                                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"></path>
                                                    </svg> }
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            }) :
                            null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

class ChatSection extends Component {
    componentDidMount() {
        this.messagePoller = setInterval(() => {
                    this.props.fetchBorrowerChatMessages(this.props.prospectInView.mainBorrower._id)
                }, 60000)
    }

    componentWillUnmount() {
        clearInterval(this.messagePoller)
    }

    render() {
        //console.log(this.props)
        const messagingEnabled = 
            this.props.accessRoles.filter(r => r.roleName === 'full_proposal').length > 0  ||
            this.props?.prospectInView?.clientConnectionId && this.props?.prospectInView?.connection.status === 'accepted'  
        return (
            <>
                <ChatWindow 
                    chatMessages={this.props?.borrowerMessages ? this.props?.borrowerMessages[this.props?.prospectInView?.mainBorrower?._id] : null} 
                    borrower={this.props?.prospectInView?.mainBorrower} 
                    enabled={messagingEnabled}
                    {...this.props} />
            </>
        );
    }
}

const mapStateToProps = ({ clients, brokerAgentProfile }) => {
    return {
        ...clients,
		accessRoles : brokerAgentProfile.accessRoles,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchBorrowerChatMessages: (borrowerId) => { dispatch(fetchBorrowerChatMessages(borrowerId)) },
        addBorrowerChatMessage : (borrowerId, textMessage) => { dispatch(addBorrowerChatMessage(borrowerId, textMessage))},
        sawBorrowerChatMessage: (textMessageId) => { dispatch(sawBorrowerChatMessage(textMessageId)) }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChatSection);