import { Provider } from "react-redux";

import store from "./store/store";

import "./assets/bootstrap/css/bootstrap.min.css";
import "./assets/fonts/fontawesome-all.min.css";
import "./assets/fonts/font-awesome.min.css";
import "./assets/fonts/material-icons.min.css";
import "./assets/fonts/simple-line-icons.min.css";
import "./assets/fonts/fontawesome5-overrides.min.css";
import "./assets/css/Features-Large-Icons.css";
import "./assets/css/Multi-step-form.css";
import "./assets/css/Pricing-Yearly--Monthly.css";
import "./styles/App.css";

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Amplify, { Auth } from 'aws-amplify';
import awsconfig from './aws/aws-exports';

Amplify.configure(awsconfig);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(//console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
