import React, { useState } from 'react';
import classNames from  'classnames';
import ProgressBar from './progressBar';
import BasicDetails from './basicDetails';
import AgencyDetails from './agencyDetails';
import ChangePasswordForm from '../changePasswordForm/changePasswordForm';

function GeneralInformationForm() {
    var profileFormClass = classNames("profileForm");
    return ( 
        <div>
            <div id="multple-step-form-n" className={profileFormClass} >
                <div class="vstack gap-4">
                    < ProgressBar />
                    <div id="multistep-start-row" class="row">
                        <div id="multistep-start-column" class="col-12 col-lg-8 m-auto">
                            <form id="main-form" class="multisteps-form__form">
                                < BasicDetails />                            
                                < AgencyDetails />
                                < ChangePasswordForm />
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}

export default GeneralInformationForm;
