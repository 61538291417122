import React, {  } from 'react';
import ProfileCloseButton from './buttons/profileCloseButton';
import GeneralInformationForm from './multistepForm/generalInformationForm';
import LoadMultiStepFormScript from '../../common/loadMultiStepFormScript';

function Profile(props) {
    return (
        <div class="container-fluid">
            <div class="card shadow">
                <div class="card-body">
                    <ProfileCloseButton />
                    <div class="vstack gap-2">
                        <div class="row">
                            <>
                                <GeneralInformationForm />
                                <LoadMultiStepFormScript /> 
                            </>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Profile;