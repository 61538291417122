import React from 'react';
import SaveSuccessfulModal from './saveSuccessful';
import CompanyDetails from './companyDetails';
import { useSelector } from 'react-redux';

function AgencyDetails() {

    const saveSuccessful = useSelector(state => state.brokerAgentProfile?.saveSuccessful)
    const saveSource = useSelector(state => state.brokerAgentProfile?.saveSource)

    
    return (<>
        <div id="single-form-next-prev" class="multisteps-form__panel shadow p-4 rounded bg-white" data-animation="scaleIn">
            <div id="form-content-1" class="multisteps-form__content">
            {saveSuccessful && saveSource == 'agency_details' ? (
                <SaveSuccessfulModal message='Agency details updated!' />
                ) : (
                    <>
                    <CompanyDetails/>
                    </>
            )}
            </div>
        </div> 
        </>
     );
}

export default AgencyDetails;