import actionType from "./actionType";
import _ from "./actionType";
import { getLatestPaidInvoice,getPaidInvoiceReferencesMetadata, getPaidInvoiceReferences, getInvoiceSearchByReference, getReceiptDocument } from "../request";
const uri = process.env.REACT_APP_API_baseuri;

export const fetchPaymentReference = (uniqueReference, accessCode) => async (dispatch) => {
  const res = 
    await fetch(uri + '/paymentreferences?' + new URLSearchParams({
            uniqueReference: uniqueReference,
            operationType: 'searchByUniqueReference'
          }),
          {
				    method: "GET",
            headers: { 'Content-Type': 'application/json', 'Authorization': accessCode }
          })
			    .then(response => {
            if (response.status >= 400 && response.status < 600) {
              dispatch({
                  type: _.fetchPayment.failed,
                  payload: { errorMessage: response.statusText	},
                });
            } else
                return  response.json();
            }
          )
			    .catch((error) => {
            dispatch({
                type: _.fetchPayment.failed,
                payload: { errorMessage: error },
              });
				    return null;	  						  	
          })          ;
      if (res != null) {
        dispatch({
          type: _.fetchPayment.success,
          payload: { ...res },
        });
      }
};


export const fetchMostRecentInvoice = () => async (dispatch) => {
    getLatestPaidInvoice()
        .then(res => {
            if (res != null && res.data)
                dispatch({
                  type: actionType.fetchInvoice.success,
                  payload: res.data
                });
        })
        .catch((error) => {
            return null;
        });
}

export const fetchPaidInvoicesMetadata = () => async (dispatch) => {
    getPaidInvoiceReferencesMetadata()
        .then(res => {
            if (res != null && res?.data?.success)
                dispatch({
                  type: actionType.fetchInvoicesMetadata.success,
                  payload: res?.data?.count || 0 
                });
              else
                dispatch({
                  type: actionType.fetchInvoicesMetadata.failed,
                  payload: 'Failed to get count of invoices'
                });
        })
        .catch((error) => {
                dispatch({
                  type: actionType.fetchInvoicesMetadata.failed,
                  payload: error
                });
        });
}

export const fetchPaidInvoices = (page, pageSize) => async (dispatch) => {
    getPaidInvoiceReferences(page, pageSize)
        .then(res => {
            if (res != null && res?.data)
                dispatch({
                  type: actionType.fetchInvoices.success,
                  payload: {page : page, records : res.data} 
                });
              else
                dispatch({
                  type: actionType.fetchInvoices.failed,
                  payload: 'Failed to get list of invoice references'
                });
        })
        .catch((error) => {
                dispatch({
                  type: actionType.fetchInvoices.failed,
                  payload: error
                });
        });
}

export const fetchInvoiceReference = (receiptReferenceId) => async (dispatch) => {
    getInvoiceSearchByReference(receiptReferenceId)
        .then(res => {
            if (res != null && res?.data)
                dispatch({
                  type: actionType.fetchInvoiceReference.success,
                  payload: res.data, 
                });
              else
                dispatch({
                  type: actionType.fetchInvoiceReference.failed,
                  payload: 'Failed to get invoice reference'
                });
        })
        .catch((error) => {
                dispatch({
                  type: actionType.fetchInvoiceReference.failed,
                  payload: error
                });
        });
}

export const downloadInvoice = (invoiceId) => async (dispatch) => {
    getReceiptDocument(invoiceId)
        .then( res => res.blob() )
        .then(blob => {
            //Build a URL from the file
            const fileURL = URL.createObjectURL(blob);
            //Open the URL on new Window
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
        })
        .catch((error) => {
            dispatch({
                type: actionType.fetchInvoiceDocument.failed,
                payload: { message: 'Failed to fetch document ' + invoiceId },
            });
        });
}

export const actions = { fetchPaidInvoices, fetchPaidInvoicesMetadata, fetchMostRecentInvoice, fetchPaymentReference };