import React, { Component } from 'react';
import { connect } from "react-redux";
import { hideUpgradeModal, showUpgradeModal, hideUpgradeWarning  } from "../../store/upgrades/actions";
import { Modal, Button } from 'react-bootstrap';
import UpgradePackages from "../pages/Dashboard/Upgrades/UpgradePackages";


function UpgradeModal(props) {
    return ( 
        <Modal show={props.showUpgradeWarning} centered onHide={props.hideUpgradeWarning}>
            <Modal.Header closeButton>
                <Modal.Title>LoanShopper</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="pb-2 pb-lg-1">
                    <p class="mb-0">This section is restricted for subscribers. Choose one of our unbeatable packages to avail these great features.</p>
                    <div class="hstack gap-5 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
                        <div class="my-2">
                        <Button
                            onClick={props.showUpgradeModal}
                            style={{ width: "210px", fontSize: "20px", padding: "8px" }}
                        >
                        Choose a package
                        </Button></div>
                    </div>
                    <UpgradePackages
                        show={props.modalShow}
                        onHide={props.hideUpgradeModal}
                        {...props}
                    />
                </div>
            </Modal.Body>
        </Modal>
     );
}



class UpgradeWarning extends Component {
	constructor(props) {
        super(props);
    }

    render () {
        return ( 
        <> 
        <UpgradeModal {...this.props} /> </>
        );
    }
  }


const mapStateToProps = ({ upgradePage }) => {
  return { 
      showUpgradeWarning: upgradePage.showUpgradeWarning,
      modalShow: upgradePage.upgradeModalVisible
    };
};

const mapDispatchToProps = (dispatch)=> {
    return {
        hideUpgradeModal: () => dispatch(hideUpgradeModal()),
        showUpgradeModal: () => dispatch(showUpgradeModal()),
        hideUpgradeWarning: () => dispatch(hideUpgradeWarning()),
    };
};
export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(UpgradeWarning);