import { fetchFailure, fetchLoading, fetchSuccess, saveSuccess, validationError } from "./actionTypes"
import { getAutocompleteResponse } from "./request"

export const fetchAutocompleteOptions = (lookup) => {
    return function(dispatch) {
        dispatch(fetchLoading()) 
        getAutocompleteResponse(lookup)
            .then(response => {
                const data = response.data
                dispatch(fetchSuccess(data))
            })
            .catch(error => {
                dispatch(fetchFailure(error))
            })
    }
}

export const checkAddress = (agency) => async (dispatch) => {
    const companyAddress = agency?.companyDetails?.companyAddress

    var error = { type:"companyAddress", 
            valid : companyAddress != null || companyAddress?.externalId != null,
            errorMessage : 'Please enter a valid company address',
        };

    if (!error.valid)
      dispatch(validationError(error));
}

export const saveSelectedAddress = (addressOption) => async (dispatch) => {
    dispatch(saveSuccess(addressOption))
}
