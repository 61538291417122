export const UPGRADE_ACTION_TYPES = {
    UPGRADE_MODAL_VISIBILITY: 'upgradeModalVisibilityChange',
    FETCH_SUCCESS: 'apiSuccess',
    FETCH_FAILURE: 'apiFailure',
    CHANGE_SUBSCRIPTION_PACKAGE : 'changeSubscriptionPackage',
    VALIDATE_CUSTOMER_DETAILS_PAGE: 'validateCustomerDetailsPage',
    CREATE_PAYMENT_REFERENCE_SUCCESS: 'createPaymentReferenceSuccess',
    CREATE_PAYMENT_REFERENCE_FAILED: 'createPaymentReferenceFailed',
    GET_PAYMENT_REFERENCE_SUCCESS: 'getPaymentReferenceSuccess',
    STORE_CARD_DIGITS: 'storeCardDigits',
    UPGRADE_SECTION_ENABLED : 'enableUpgrades',
    SHOW_UPGRADE_WARNING : 'showUpgradeWarning',
    HIDE_UPGRADE_WARNING : 'hideUpgradeWarning',
    GET_UUID : 'getUuid',
    TOGGLE_SALES_GENERATOR : 'toggleSalesGeneratorAddIn',
    FETCH_SUBSCRIPTION_PACKAGES_SUCCESS: 'fetchSubscriptionPackagesSuccess',
    FETCH_SUBSCRIPTION_PACKAGES_FAILED : 'fetchSubscriptionPackagesFailed',
}


const toggleUpgradeModalVisibility = status => {
    return {
        type: UPGRADE_ACTION_TYPES.UPGRADE_MODAL_VISIBILITY,
        payload: status
    }
}

const changeSubscriptionPackage = (subscriptionPackage) => {
    return {
        type: UPGRADE_ACTION_TYPES.CHANGE_SUBSCRIPTION_PACKAGE,
        payload:  { subscriptionPackage : subscriptionPackage }
    }
}

const createPaymentReferenceResult = (result) => {
    return {
        type: result.success ? 
            UPGRADE_ACTION_TYPES.CREATE_PAYMENT_REFERENCE_SUCCESS : 
            UPGRADE_ACTION_TYPES.CREATE_PAYMENT_REFERENCE_FAILED ,
        payload: result
    }
}
export default {UPGRADE_ACTION_TYPES, toggleUpgradeModalVisibility, changeSubscriptionPackage, createPaymentReferenceResult }