import React from 'react';
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { confirmChangePassword } from '../../../../store/auth/actions';

function ChangePassword(props) {
    const brokerEmail = useSelector(state => state.brokerAgentProfile?.brokerAgent?.contact?.primaryEmail);
    const dispatch = useDispatch();

    return (
        <div
        class="d-flex justify-content-center"
        style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
            type="submit"
            onClick={(e) => {
                e.preventDefault();
                dispatch(confirmChangePassword(brokerEmail, props.oldPassword, props.password, props.passwordRepeat));
            }}
            >
                Change Password
            </Button>
        </div>
     );
}

export default ChangePassword;