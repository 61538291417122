const setDocumentSelection = {
  primaryApplicantDriverLicence : 'sales/editDocuments/primaryApplicantDriverLicence',
  primaryApplicantPassport: 'sales/editDocuments/primaryApplicantPassport',
  primaryApplicantMedicare: 'sales/editDocuments/primaryApplicantMedicare',
  primaryApplicantBirthCertificate: 'sales/editDocuments/primaryApplicantBirthCertificate',
  primaryApplicantBankStatements: 'sales/editDocuments/primaryApplicantBankStatements',
  primaryApplicantCardStatements: 'sales/editDocuments/primaryApplicantCardStatements',
  primaryApplicantHomeLoanStatements: 'sales/editDocuments/primaryApplicantHomeLoanStatements',
  primaryApplicantPersonalLoanStatements: 'sales/editDocuments/primaryApplicantPersonalLoanStatements',
  primaryApplicantPayslips: 'sales/editDocuments/primaryApplicantPayslips',
  primaryApplicantNOA: 'sales/editDocuments/primaryApplicantNOA',
  primaryApplicantTaxReturns: 'sales/editDocuments/primaryApplicantTaxReturns',
  primaryApplicantBAS: 'sales/editDocuments/primaryApplicantBAS',

  secondaryApplicantDriverLicence: 'sales/editDocuments/secondaryApplicantDriverLicence',
  secondaryApplicantPassport: 'sales/editDocuments/secondaryApplicantPassport',
  secondaryApplicantMedicare: 'sales/editDocuments/secondaryApplicantMedicare',
  secondaryApplicantBirthCertificate: 'sales/editDocuments/secondaryApplicantBirthCertificate',
  secondaryApplicantBankStatements: 'sales/editDocuments/secondaryApplicantBankStatements',
  secondaryApplicantCardStatements: 'sales/editDocuments/secondaryApplicantCardStatements',
  secondaryApplicantHomeLoanStatements: 'sales/editDocuments/secondaryApplicantHomeLoanStatements',
  secondaryApplicantPersonalLoanStatements: 'sales/editDocuments/secondaryApplicantPersonalLoanStatements',
  secondaryApplicantPayslips: 'sales/editDocuments/secondaryApplicantPayslips',
  secondaryApplicantNOA: 'sales/editDocuments/secondaryApplicantNOA',
  secondaryApplicantTaxReturns: 'sales/editDocuments/secondaryApplicantTaxReturns',
  secondaryApplicantBAS: 'sales/editDocuments/secondaryApplicantBAS',

  rentalIncStmts: 'sales/editDocuments/rentalIncStmts',
  companyTaxReturns: 'sales/editDocuments/companyTaxReturns',
  carAndPersonalLoanStmt: 'sales/editDocuments/carAndPersonalLoanStmt',
  sharesInvestmentStmts: 'sales/editDocuments/sharesInvestmentStmts',
  ratesNotice: 'sales/editDocuments/ratesNotice',
  trustDeed: 'sales/editDocuments/trustDeed',
  leaseAgreement: 'sales/editDocuments/leaseAgreement',

  addRemoveDocument : 'sales/editDocuments/addRemoveDocument',
  selectIdDocs: 'sales/editDocuments/selectIdDocs',
  deselectIdDocs: 'sales/editDocuments/deselectIdDocs',
  selectBankStmts: 'sales/editDocuments/selectBankStmts',
  deselectBankStmts: 'sales/editDocuments/deselectBankStmts',
  selectIncomeDocs: 'sales/editDocuments/selectIncomeDocs',
  deselectIncomeDocs: 'sales/editDocuments/deselectIncomeDocs',
  selectOtherDocs: 'sales/editDocuments/selectOtherDocs',
  deselectOtherDocs: 'sales/editDocuments/deselectOtherDocs',
  resetRequiredDocs: 'sales/editDocuments/resetRequiredDocs',

};
const setupFromProspect = 'sales/editDocuments/setupFromProspect'
const loadAllDocumentTypes = {success: 'sales/editDocuments/loadAllDocumentTypes/success', failure: 'sales/editDocuments/loadAllDocumentTypes/failure'}

export default {
  loadAllDocumentTypes,
  setDocumentSelection,
  setupFromProspect,
};