import React, { useState, useEffect } from 'react';
import { AsyncTypeahead, Menu, MenuItem,  } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAutocompleteOptions, saveSelectedCompany, fetchABNSearchResults } from '../../../../store/companyNameLookup/actions';
import ErrorContainer from '../../../common/ErrorContainer';


export default function NameAutoFill(props) {
    var error = useSelector(state =>  state.companyNameAutocomplete?.error)
    const brokerAgency = useSelector(state => state?.brokerAgentProfile?.agency?.companyDetails)
    const dispatch = useDispatch()
    const [company, setCompany] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const options = useSelector(state => state.companyNameAutocomplete.autocompleteOptions)
    useEffect(() => {
        if (!company)
         setCompany(brokerAgency)
    }, []);
    const filterBy = () => true;
    return (
        <div>
            <div id="input-grp-single-6" class="form-row mt-4">
                <div class="col-12">
                    <ErrorContainer error={error}>
                        <div class="row">
                            <div class="col-4">
                                <div id="input-grp-single-1" class="form-row mt-4">
                                    <AsyncTypeahead
                                        filterBy={filterBy}
                                        id="async-abnlookup"
                                        isLoading={isLoading}
                                        placeholder="Search ABN"
                                        defaultInputValue={brokerAgency?.abn}
                                        labelKey={option => {
                                            dispatch(saveSelectedCompany(option));
                                            setCompany(option)
                                            return `${option.abn}` 
                                        }}
                                        minLength={2}
                                        onSearch={lookup => {
                                            dispatch(fetchABNSearchResults(lookup, setIsLoading))}}
                                        options={options}
                                        renderMenu={(results, menuProps) => (
                                            <Menu {...menuProps}>
                                            {results.map((result, index) => (
                                                <MenuItem option={result} position={index}>
                                                {result?.companyName && result?.name ? 
                                                    `ABN ${result.abn} ${result.companyName} trading as ${result.name}` : 
                                                    result?.name ? 
                                                    `ABN ${result.abn} ${result.name}` : 
                                                    `ABN ${result.abn} ${result.companyName}`
                                                    }
                                                </MenuItem>
                                            ))}
                                            </Menu>
                                        )}
                                    />                                
                                </div>
                            </div>
                            <div class="col">
                                <div id="input-grp-single-4" class="form-row mt-4">
                                    <div class="col-12">
                                        <input class="form-control multisteps-form__input" type="text" readOnly disabled
                                        placeholder="Business or Company Name" value={!company ? '' : 
                                            company?.companyName && company?.name ? `${company.companyName} trading as ${company.name}` : 
                                            company?.name ? `${company.name}` : `${company.companyName}`
                                                    } /></div>
                                </div>
                            </div>
                        </div>
                    </ErrorContainer>
                </div>
            </div>
        </div>
    );
}
