import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Nav, NavDropdown,  } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from  'classnames';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import Loanseller from "./LoanSeller_Light.png"
import { fetchBrokerAgent, fetchSubscription, } from "../../store/brokerAgent/actions";
import { fetchDocumentTypes } from "../../store/sales/editDocuments/actions";

const renderTooltip = props => (
  <Tooltip {...props}>{props?.locationtext}</Tooltip>
);

const TopBar = (props) => {
  const location = useLocation();
  const brokerFullName = useSelector(state => state.brokerAgentProfile?.brokerAgent?.fullName)
  const dispatch = useDispatch()
  const [refreshInterval, setRefreshInterval] = useState(15000);

  useEffect(() => {
    dispatch(fetchBrokerAgent())
  }, []);
  useEffect(() => {
    dispatch(fetchSubscription())
  }, []);
  useEffect(() => {
    dispatch(fetchDocumentTypes())
  }, []);
  useEffect(() => {
    if (refreshInterval && refreshInterval > 0) {
      const interval = setInterval(() => {
        dispatch(fetchBrokerAgent())
      }, refreshInterval);
      return () => clearInterval(interval);
    }
  }, [refreshInterval]);
  
  var isHomePage = location.pathname === "/" || 
    location.pathname === "/dashboard" || 
    location.pathname === "/dashboard/profile" || 
    location.pathname === "/dashboard/billing" || 
    location.pathname === "/dashboard/products" ;
  var dashboardNavLinkClass = classNames({
      'nav-link active loanshopperLightBlue': isHomePage,
      'nav-link': !isHomePage,
    });
  var clientsNavLinkClass = classNames({
      'nav-link active loanshopperLightBlue': location.pathname === "/clients",
      'nav-link': location.pathname != "/clients",
    });
  var salesNavLinkClass = classNames({
      'nav-link active loanshopperLightBlue': location.pathname === "/sales",
      'nav-link': location.pathname != "/sales",
    });
  var documentsNavLinkClass = classNames({
      'nav-link active loanshopperLightBlue': location.pathname === "/documents",
      'nav-link': location.pathname != "/documents",
    });
  var teamsNavLinkClass = classNames({
      'nav-link active loanshopperLightBlue': location.pathname === "/teams",
      'nav-link': location.pathname != "/teams",
    });
  var dashboardIconClass = classNames({
      'far fa-object-group fs-1 loanshopperLightBlue': isHomePage,
      'far fa-object-group fs-1': !isHomePage,
    });
  var clientsIconClass = classNames({
      'bi bi-file-earmark-person-fill fs-1 loanshopperLightBlue': location.pathname === "/clients",
      'bi bi-file-earmark-person-fill fs-1': location.pathname != "/clients",
    });
  var salesIconClass = classNames({
      'fas fa-store fs-1 loanshopperLightBlue': location.pathname === "/sales",
      'fas fa-store fs-1': location.pathname != "/sales",
    });
  var documentsIconClass = classNames({
      'far fa-file-pdf fs-1 loanshopperLightBlue': location.pathname === "/documents",
      'far fa-file-pdf fs-1': location.pathname != "/documents",
    });
  var chatIconClass = classNames({
      'far fa-comments fs-1 loanshopperLightBlue': location.pathname === "/chats",
      'far fa-comments fs-1': location.pathname != "/chats",
    });
  var teamsIconClass = classNames({
      'bi bi-kanban fs-1 loanshopperLightBlue': location.pathname === "/teams",
      'bi bi-kanban fs-1': location.pathname != "/teams",
    });

  return (
     <nav class="navbar navbar-light navbar-expand py-3">
        <div class="container-fluid">
            <a class="navbar-brand d-flex align-items-center" href="/"><img src={Loanseller} width="95" /></a>
            <div class="collapse navbar-collapse marginRight5percent" id="navcol-2">
              <Nav className="navbar-nav flex-nowrap ms-auto">
                <Nav.Item>
                  <OverlayTrigger placement="bottom" overlay={renderTooltip({...props,locationtext:'Dashboard'})} >
                    <Link className={dashboardNavLinkClass} to="/" >
                      <i className={dashboardIconClass} ></i>
                    </Link>
                  </OverlayTrigger>
                </Nav.Item>
                <Nav.Item>
                  <OverlayTrigger placement="bottom" overlay={renderTooltip({...props,locationtext:'Clients'})} >
                    <Link className={clientsNavLinkClass} to="clients" >
                      <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" 
                        className={clientsIconClass} data-bs-toggle="tooltip" data-bss-tooltip="" title="Dashboard">
                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0zm2 5.755V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-.245S4 12 8 12s5 1.755 5 1.755z"></path>
                      </svg>
                    </Link>
                  </OverlayTrigger>
                </Nav.Item>
                <Nav.Item>
                  <OverlayTrigger placement="bottom" overlay={renderTooltip({...props,locationtext:'Chat with clients'})} >
                    <Link className={documentsNavLinkClass} to="chats" >
                      <i className={chatIconClass} title="Messages"></i>
                    </Link>
                  </OverlayTrigger>
                </Nav.Item>
                <Nav.Item>
                  <OverlayTrigger placement="bottom" overlay={renderTooltip({...props,locationtext:'Sales'})} >
                    <Link className={salesNavLinkClass} to="sales" >
                      <i className={salesIconClass} ></i>
                    </Link>
                  </OverlayTrigger>
                </Nav.Item>
          		</Nav>
                <div class="dropdown">
                  <a class="dropdown-toggle" aria-expanded="true" data-bs-toggle="dropdown" >{brokerFullName}</a>
                    <div class="dropdown-menu">
                      <Link className={`dropdown-item`} to="/dashboard/profile" >
                        <i className={`fas fa-user fa-sm fa-fw me-2 text-gray-400`} title="Profile"></i>Profile
                      </Link>
                      <Link className={`dropdown-item`} to="/dashboard/products" >
                        <i className={`fas fa-percent fa-sm fa-fw me-2 text-gray-400`} title="LoanProducts"></i>Products
                      </Link>                      
                      <Link className={`dropdown-item`} to="/dashboard/billing" >
                        <i className={`fas fa-money-bill-wave-alt fa-sm fa-fw me-2 text-gray-400`} title="Billing"></i>Billing
                      </Link>
                      <div class="dropdown-divider"></div>
                      <Link className={`dropdown-item`} to="/dashboard" onClick={()=>  window.open("/","_self")} >
                        <i className={`fas fa-sign-out-alt fa-sm fa-fw me-2 text-gray-400`} title="Logout"></i>Logout
                      </Link>
                    </div>
                </div>
            </div>
        </div>
    </nav>
  );
};

export default TopBar;