import { OUTSTANDING_BALANCE_ACTION_TYPES } from "./actionTypes";

export const initialState = {
    loading: false,
    post: 'N/A',
    error: '',
    outstandingBalanceAmount: 'N/A'
}

function getDisplayableAmount(amount) {
    const displayableAmount = "$" + amount.toLocaleString(undefined, {maximumFractionDigits:0})
    return displayableAmount
}
  
export default function outstandingBalanceReducer(state = initialState, action) {
    switch (action.type) {
        case OUTSTANDING_BALANCE_ACTION_TYPES.FETCH_LOADING:
            return {
                ...state.dashboardOustandingBalance,
                loading: true
            }
        case OUTSTANDING_BALANCE_ACTION_TYPES.FETCH_SUCCESS:
            return {
                loading: false,
                post: action.payload,
                error: '',
                outstandingBalanceAmount: getDisplayableAmount(action.payload.totalOutstandingBalance.value) 
            }
        case OUTSTANDING_BALANCE_ACTION_TYPES.FETCH_FAILURE:
            return {
                loading: false,
                post: 'N/A',
                error: "Outstanding balance amount could not be retrieved.",
                outstandingBalanceAmount: 'N/A'
            }
        default:
            return state
    }
}
  