export const searchBilling = {
  pending: 'billing/searchBilling/pending',
  success: 'billing/searchBilling/success',
  fail: 'billing/searchBilling/fail'
};

export const selectBilling = 'billing/selectBilling';

export const selectPage = 'billing/selectPage';

export const requestUpdateBilling = {
  pending: 'billing/requestUpdateBilling/pending',
  success: 'billing/requestUpdateBilling/success',
  fail: 'billing/requestUpdateBilling/fail'
};

export const submitOTPOnUpdateBilling = {
  pending: 'billing/submitOTPOnUpdateBilling/pending',
  success: 'billing/submitOTPOnUpdateBilling/success',
  fail: 'billing/submitOTPOnUpdateBilling/fail'
};

export default {
  searchBilling,
  submitOTPOnUpdateBilling,
  requestUpdateBilling,
  selectBilling,
  selectPage
};
