import React, {Component} from 'react';
import {connect} from 'react-redux';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { editProposal,  } from "../../../../store/sales/actions";

const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);

class SubmissionDocuments extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
        <>

        {this.props?.proposalInView?.requiredDocs && this.props?.proposalInView.requiredDocs.length === 0 ? 
        <h2 class="fw-bold mb-2">No documents requested for this proposal. Click&nbsp;
            <a class="link-primary" href="#" onClick={(e) => {
                                        this.props.editProposal()
                                    }}>here</a>&nbsp;to edit proposal to add submission documents.</h2> :

        <div class="vstack">
            <div class="pb-2 pb-lg-1">
                <OverlayTrigger placement="top-start" overlay={renderTooltip({locationtext:'Documents required from borrower for further processing'})} >
                <h4 class="fw-bold d-xxl-flex justify-content-xxl-start mb-2">Submission documents</h4>
                </OverlayTrigger>
            </div>
            <div class="table-responsive" style={{overflow: "visible"}}>
                <table class="table table-striped">
                    <tbody>
                        <tr>
                            <td>
                                <ul class="list-group">
                                    {this.props?.proposalInView?.requiredDocs.map(d => {
                                        const supplied = this.props.documents.find(f => {return f.metadata.documentTypeId == d._id})
                                        
                                        return (
                                        <li class="list-group-item">
                                            <span>{d.description} </span> 
                                            {supplied ? 
                                                <OverlayTrigger placement="top-start" overlay={renderTooltip({locationtext:'Available to view'})} >
                                                    <svg class="bi bi-file-earmark-check-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0M9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1m1.354 4.354-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 9.793l2.646-2.647a.5.5 0 0 1 .708.708z"></path>
                                                    </svg>
                                                </OverlayTrigger>  : null}
                                        </li>
                                        )
                                    })}
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        }
        </>
        )
    }
}


const mapStateToProps = state => {
    return {
        prospectInView : state.clients.prospectInView,
        proposalInView : state.proposalSummary.proposalInView,
		accessRoles : state.brokerAgentProfile.accessRoles,
        documents: state.clients.borrowerDocuments,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        editProposal: () => dispatch(editProposal()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubmissionDocuments);