import axios from "axios";
import store from "./store"
const cacheHeaders ={'Cache-Control':'no-cache, no-store'}
export const toQueryString = (params) => {
    return (
        "?" +
        Object.entries(params)
        .map(
            ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&")
    );
}

export async function patchConfirmPassword(email,code,password) {
    const result = await fetch(process.env.REACT_APP_API_baseuri +  '/otps/confirmpassword' , {
        method: "PATCH",
        headers: { 'x-reset-password': password },
        body: JSON.stringify({ "email" : email, "code":code }) 
        })  
        .then(response => {
            if (response.status >= 400 && response.status < 600) {
                return response;
            } else {
                return response.json();
            }
        })
        .then((json) => {
            return json;
        })
        .catch((error) => {
            return error;
        })
    return result        
}

export async function getPasswordReset(email) {
    var qParams = Object.assign({ "email" : email });
    const result = await fetch(process.env.REACT_APP_API_baseuri +  '/otps/resetpassword' + toQueryString(qParams), {
        method: "GET",
        })  
        .then(response => {
            if (response.status >= 400 && response.status < 600) {
                return response;
            } else {
                return response.json();
            }
        })
        .then((json) => {
            return json;
        })
        .catch((error) => {
            return error;
        })
    return result        
}

export async function getAccessRoles() {
    const result =  await  axios.get(process.env.REACT_APP_API_baseuri +  '/accessroles', {
        headers: { 'Authorization': store.getState().auth.token,  },
    })
    //console.log(result)
    return result
}

export async function getRequiredDocuments() {
    var qParams = Object.assign({ "operationType" : "AllDocumentTypes" });
    const result =  await  axios.get(process.env.REACT_APP_API_baseuri +  '/documents' + toQueryString(qParams), {
        headers: { 'Authorization': store.getState().auth.token,  },
    })
    return result
}

export async function getInvoiceSearchByReference(receiptReferenceId) {
    var qParams = Object.assign({ "operationType" : "SearchInvoiceByReference", "receiptReferenceId": receiptReferenceId });
    const result =  await  axios.get(process.env.REACT_APP_API_baseuri +  '/invoices' + toQueryString(qParams), {
        headers: { 'Authorization': store.getState().auth.token,  },
    })
    return result
}

export async function getPaymentReference(uniqueReference) {
    var qParams = Object.assign({ "operationType" : "searchByUniqueReference", "uniqueReference": uniqueReference });
    const result =  await  axios.get(process.env.REACT_APP_API_baseuri +  '/paymentreferences' + toQueryString(qParams), {
        headers: { 'Authorization': store.getState().auth.token,  },
    })
    return result
}

export async function postPaymentReference(paymentReference) {
    const result = await fetch(process.env.REACT_APP_API_baseuri +  '/paymentreferences', {
        method: "POST",
        headers: { 'Content-Type': 'application/json', 
            'Authorization': store.getState().auth.token, 
            'target': `${window.location.origin.toString()}` },
        body: JSON.stringify(paymentReference) 
        })  
        .then(response => {
            if (response.status >= 400 && response.status < 600) {
                return response;
            } else {
                return response.json();
            }
        })
        .then((json) => {
            return json;
        })
        .catch((error) => {
            return error;
        })
    return result
}

export async function getSubscriptionPackages() {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + '/subscriptionpackages', 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function postProposal(agentId, loanRequestId, prospectId, lenderId, selectedLoanTermInYrs, loanProducts, requiredDocs, productName, interestRate, brokerDiscounts) {
    //console.log(loanProducts)
    var proposal = JSON.stringify({
            agentId: agentId, 
            loanRequestId: loanRequestId,
            prospectId: prospectId,
            loanPackage : {
                lenderId:lenderId,
                loanProducts : loanProducts.map(l => {
                    return {
                        loanProduct : { 
                            reference :l.loanProduct?.reference, 
                            externalReference: l.loanProduct?.externalReference, 
                            _id: l.loanProduct?._id } , 
                        loanAmount: l.loanAmount
                    }
                }),
                loanTermInYears : selectedLoanTermInYrs,
            },
            requiredDocs: requiredDocs,
            loanPackageSummary: {
                label: productName,
                interestRate : interestRate,
            },
            proposalDiscounts: brokerDiscounts,
            status: 'proposed'
        })
    //console.log(proposal)
    const response = await fetch(process.env.REACT_APP_API_baseuri + `/proposals`, {
				method: "POST",
                headers: { 'Content-Type': 'application/json', 'Authorization': store.getState().auth.token },
                body: proposal
			})
			.then(response => {
				if (response.status >= 400 && response.status < 600) {
					return response;
				} else {
					return response.json();
                }
			})
			.then((json) => {
				return json;
			})
			.catch((error) => {
				return error;
			})
    return response
}

export async function patchProposal(proposalId, lenderId, selectedLoanTermInYrs, loanProducts, requiredDocs, productName, interestRate, brokerDiscounts) {
    var proposal = JSON.stringify({
            _id: proposalId,
            loanPackage : {
                lenderId:lenderId,
                loanProducts : loanProducts.map(l => {
                    return {
                        loanProduct : { _id : l.loanProduct._id } , 
                        loanAmount: l.loanAmount
                    }
                }),
                loanTermInYears : selectedLoanTermInYrs,
            },
            requiredDocs: requiredDocs,
            loanPackageSummary: {
                label: productName,
                interestRate : interestRate,
            },
            proposalDiscounts: brokerDiscounts,
            status: 'proposed'
        })
    const response = await fetch(process.env.REACT_APP_API_baseuri + `/proposals/` + proposalId, {
				method: "PATCH",
                headers: { 'Content-Type': 'application/json', 'Authorization': store.getState().auth.token },
                body: proposal
			})
			.then(response => {
                //console.log(proposal)
                //console.log(response)
				if (response.status >= 400 && response.status < 600) {
					return response;
				} else {
					return null;
                }
			})
			.then((json) => {
				return json;
			})
			.catch((error) => {
				return error;
            })
    return response
}

export async function getAmortization(loanAmount, loanTermInYrs, interestRate, waypoints) {
    var qParams = Object.assign({
        "termInYears" : loanTermInYrs, 
        "borrowingAmount": loanAmount,
        "interestRate" : interestRate,
        "waypoints" : waypoints ? waypoints : 5
    });
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/brokeragents/getAmortizationTable`  + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getProspectsMetadataForSearchType(searchType) {
    var qParams = Object.assign({
        "operationType" : "IncludeBorrowerSummaries", 
        "searchType": searchType});
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/prospects/metadata`  + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getProspectsForSearchType(page, pagesize, searchType) {
    var qParams = Object.assign({
        "operationType" : "IncludeBorrowerSummaries", 
        "page": page, 
        "pagesize": pagesize == 0 ? 1 : pagesize,
        "searchType": searchType});
    //console.log(qParams)
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/prospects`  + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getProspectById(prospectId) {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/prospects/`  + prospectId, 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getAllProspects(page, pagesize) {
    var qParams = Object.assign({
        "operationType" : "IncludeBorrowerSummaries", 
        "page": page, 
        "pagesize": pagesize == 0 ? 1 : pagesize});
    //console.log(qParams)
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/prospects`  + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getAllProspectsMetadata() {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/prospects/metadata?operationType=IncludeBorrowerSummaries`, 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getAutocompleteResponse(lookup) {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/addresses?match=${lookup}`, 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getLatestPaidInvoice() {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/invoicereferences?operationType=FindLatestPaidInvoice`, 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getPaidInvoiceReferencesMetadata() {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/invoicereferences/metadata?operationType=GetAllPaidInvoices`, 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getPaidInvoiceReferences(page, pagesize) {
    var qParams = Object.assign({"operationType" : "GetAllPaidInvoices", "page": page, "pagesize": pagesize == 0 ? 1 : pagesize});
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/invoicereferences` + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getBrokerUnseenMessages() {
    var qParams = Object.assign({"operationType" : "SearchForUnseenMessages", pagesize:500});
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/messages` + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getProspect(borrowerId) {
    var qParams = Object.assign({"operationType" : "MatchBrokerAndBorrower", borrowerId: borrowerId});
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/prospects` + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getChatMessages(borrowerId) {
    var qParams = Object.assign(
        {"operationType" : "SearchForBorrowerMessages,IncludeSentMessages", 
        "borrowerId" : borrowerId, "pagesize": 100});
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/messages` + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
            //console.log(response)
    return response
}

export async function patchChatMessage(textMessageId) {
    var message = JSON.stringify({status: 'seen', _id: textMessageId})
    const response = await fetch(process.env.REACT_APP_API_baseuri + `/messages/` + textMessageId, {
				method: "PATCH",
                headers: { 'Content-Type': 'application/json', 'Authorization': store.getState().auth.token },
                body: message
			})
			.then(response => {
				if (response.status >= 400 && response.status < 600) {
					return response;
				} else {
					return null;
                }
			})
			.then((json) => {
				return json;
			})
			.catch((error) => {
				return error;
            })
    return response
}

export async function postChatMessage(borrowerId, textMessage) {
    var message = JSON.stringify({text: textMessage, borrowerId: borrowerId})
    const response = await fetch(process.env.REACT_APP_API_baseuri + `/messages`, {
				method: "POST",
                headers: { 'Content-Type': 'application/json', 'Authorization': store.getState().auth.token },
                body: message
			})
			.then(response => {
				if (response.status >= 400 && response.status < 600) {
					return response;
				} else {
					return response.json();
                }
			})
			.then((json) => {
				return json;
			})
			.catch((error) => {
				return error;
			})
    return response
}

export async function getReceiptDocument(invoiceId) {
    var qParams = Object.assign({"operationType" : "ShowReceipt"});
    const response = await fetch(process.env.REACT_APP_API_baseuri + `/invoices/` + invoiceId + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getDocument(documentId) {
    var qParams = Object.assign({"operationType" : "BorrowerDocument"});
    const response = await fetch(process.env.REACT_APP_API_baseuri + `/documents/` + documentId + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getBorrowerDocuments(borrowerId) {
    var qParams = Object.assign({"borrowerId" : borrowerId, "operationType" : "SearchForBorrowerId"});
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/documents`  + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getClientBorrowingEstimates(mainApplicantIncome, jointApplicantIncome, numDependants) {
    var qParams = Object.assign({"mainApplicantIncome" : mainApplicantIncome});
    qParams = jointApplicantIncome == null ? qParams : {...qParams, ...Object.assign({"jointApplicantIncome" : jointApplicantIncome}) }
    qParams = numDependants == null ? qParams : {...qParams, ...Object.assign({"numDependants" : numDependants}) }
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/brokeragents/getClientBorrowingEstimate`  + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getActiveConnections(page, pagesize, borrowerLookupKey) {
    var qParams = Object.assign({
        "operationType" : "ActiveConnections", 
        "page": page, 
        "pagesize": pagesize == 0 ? 1 : pagesize});
    
    qParams = borrowerLookupKey ? {...qParams, "borrowerLookupKey" : borrowerLookupKey} : qParams
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/clientconnections`  + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getNewConnections(page, pagesize) {
    var qParams = Object.assign({"operationType" : "NewConnections", "page": page, 
        "pagesize": pagesize == 0 ? 1 : pagesize});
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/clientconnections`  + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getNewConnectionCount() {
    var qParams = Object.assign({"operationType" : "NewConnections"});
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/clientconnections/metadata`  + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getActiveConnectionCount(borrowerLookupKey) {
    var qParams = Object.assign({"operationType" : "ActiveConnections"});
    qParams = borrowerLookupKey ? {...qParams, "borrowerLookupKey" : borrowerLookupKey} : qParams
    
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/clientconnections/metadata`  + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getActivities(page, pagesize) {
    var qParams = toQueryString(Object.assign({
        "page" : page,
        "pagesize" : pagesize
    }))

    const response = await axios.get(process.env.REACT_APP_API_baseuri + '/activities' + qParams, 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getBorrower(borrowerId) {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/borrowers/` + borrowerId, 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getProspectForBrokerAndBorrower(borrowerId) {
    var qParams = toQueryString(Object.assign({
        "operationType" : "MatchBrokerAndBorrower",
        "borrowerId" : borrowerId
    }))
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/prospects/` + qParams, 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
            //console.log(response)
    return response
}
  
export async function getLenderProfilesResponse() {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + '/lenderprofiles', 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getMessagesResponse() {
    var qParams = Object.assign({"operationType" : "SearchForUnseenMessages"});
    const response = await axios.get(process.env.REACT_APP_API_baseuri + '/messages/metadata' + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    const messageJSON = await response.data
    return messageJSON
}

export async function getOutstandingBalanceResponse() {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + '/subscriptions', 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export const getBrokerAgent = () => async (dispatch) =>  {
    //console.log('Token ' + store.getState().auth.token)
    const response = await axios.get(process.env.REACT_APP_API_baseuri + '/brokeragents', 
            { headers: {
                ...cacheHeaders,
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getActiveSubscription () {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + '/subscriptions', 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}


export async function getExtras () {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + '/subscriptions?operationType=SearchExtras', 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getProposalsMetadata(namePart, lenderId) {
    var qParams = Object.assign({});
    qParams = namePart ? {...qParams, "match" : namePart} : qParams
    qParams = lenderId ? {...qParams, "lender" : lenderId} : qParams
    const response = await axios.get(process.env.REACT_APP_API_baseuri + '/proposals/metadata' + toQueryString(qParams) , 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getProposals(page, pagesize, namePart, lenderId) {
    var qParams = Object.assign({
        "page" : page,
        "pagesize": pagesize,
    });
    qParams = namePart ? {...qParams, "match" : namePart} : qParams
    qParams = lenderId ? {...qParams, "lender" : lenderId} : qParams
    const response = await axios.get(process.env.REACT_APP_API_baseuri + '/proposals' + toQueryString(qParams) , 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}


export async function getBorrowerProposal(borrowerId) {
    var qParams = Object.assign({
        "borrowerId" : borrowerId,
        "searchType" : 5,
    });
    const response = await axios.get(process.env.REACT_APP_API_baseuri + '/proposals' + toQueryString(qParams) , 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getProposal(proposalId) {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + '/proposals' + proposalId , 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getProspectsMetadata() {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + '/prospects/metadata', 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}
 
export async function getProspectsResponse() {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + '/prospects?operationType=RecentProspectsAndConnections', 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getLenders() {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/lenders`, 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getProducts(lenderName, filters, sort, page, pagesize) {
    var qParams = Object.assign({"lender" : lenderName, "page": page, "pagesize": pagesize});

    if (filters && filters?.loanPurpose && filters.loanPurpose != "any")
        qParams = {...qParams, loanPurpose: filters.loanPurpose}
    if (filters && filters?.interestCategory && filters.interestCategory != "any")
        qParams = {...qParams, interestCategory: filters.interestCategory}
    if (filters && filters?.rateOption && filters.rateOption != "any")
        qParams = {...qParams, rateOption: filters.rateOption}

    if (sort && sort !== 'default') {
            qParams = {...qParams, sort: 
                (sort.indexOf('-up') !== -1 ? 
                    sort.substring(0, sort.indexOf('-up')) : "-" + sort.substring(0, sort.indexOf('-down')) ) };
    }
    

    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/loanproducts` + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}


export async function getProduct(productId) {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/loanproducts/` + productId , 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getProductsMetadata(lenderName, filters) {
    var qParams = Object.assign({"lender" : lenderName});

    if (filters && filters?.loanPurpose && filters.loanPurpose != "any")
        qParams = {...qParams, loanPurpose: filters.loanPurpose}
    if (filters && filters?.interestCategory && filters.interestCategory != "any")
        qParams = {...qParams, interestCategory: filters.interestCategory}
    if (filters && filters?.rateOption && filters.rateOption != "any")
        qParams = {...qParams, rateOption: filters.rateOption}

    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/loanproducts/metadata` + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}
