import React, {Component} from 'react';
import {connect} from 'react-redux';

import Tooltip from "react-bootstrap/Tooltip";
import Amortization from './Amortization';
import LoanPackage from './LoanPackage';
import SubmissionDocuments from './SubmissionDocuments';

import { storeProduct, redrawAmortisation } from "../../../../store/sales/actions";


const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);

class ProposalReview extends Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const getProductDetails = this.props.storeProduct
        const loanProducts = this.props.selectedLoanProducts
        this.props?.loanScenarios.map(pl => {
            if (!loanProducts[pl.loanProduct._id])
                getProductDetails(pl.loanProduct._id)
        })  

        this.props.redrawAmortisation(this.props.amortizationTable, this.props.selectedLoanProducts, 
            this.props?.proposalInView?.loanPackage?.loanTermInYears, this.props?.loanScenarios, true)
    }


    render() {
        //console.log(this.props)
        var applicantName = 
            this.props.prospectInView.mainBorrower.title + ' ' + this.props.prospectInView.mainBorrower.firstName + ' ' + this.props.prospectInView.mainBorrower.lastName
        applicantName  = this.props.prospectInView?.loanRequest?.borrowers?.jointApplicant ? 
                applicantName  + ' and ' + this.props.prospectInView?.loanRequest?.borrowers?.jointApplicant?.title + ' ' + this.props.prospectInView?.loanRequest?.borrowers?.jointApplicant?.firstName + ' ' + this.props.prospectInView?.loanRequest?.borrowers?.jointApplicant?.lastName :
                applicantName 
        return (
        <>
            <div class="hstack gap-3" >
                <div class="vstack">
                    <div class="card shadow flex-grow-1 mb-4">
                        <div class="card-body">
                            <LoanPackage />
                        </div>
                    </div>
                    <div class="hstack gap-2" >
                        {this.props?.requiredDocuments && this.props?.requiredDocuments.length === 0 ? null :
                            <div class="card shadow flex-grow-1 mb-4">
                                <div class="card-body">
                                    <SubmissionDocuments />
                                </div>
                            </div>
                        }
                        {this.props.amortizationTable ? <Amortization  editMode={true} /> : null }
                    </div>                    
            </div>
            </div>

        </>
        )
    }
}

const mapStateToProps = state => {
    return {
        oldProposal : state.proposalSummary.proposalInView,
        prospectInView : state.clients.prospectInView,
        proposalInView : state.sales.proposalInView,
        selectedLoanProducts : state.sales.selectedLoanProducts,
        selectedLoanTermInYrs: state.sales.selectedLoanTermInYrs,
        loanScenarios: state.sales.loanScenarios,
        amortizationTable: state.sales.editModeAmortizationTable,
        loanDocs: state.sales.loanDocs,
        sales: state.sales,
		accessRoles : state.brokerAgentProfile.accessRoles,
		requiredDocuments : state.editDocuments.requiredDocuments,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        storeProduct: (productId) => dispatch(storeProduct(productId)),
        redrawAmortisation: 
            (amortization, availableProducts, loanTermInYears, products, editMode) =>
            dispatch(
                redrawAmortisation(amortization, availableProducts, loanTermInYears, products, editMode)
            )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProposalReview);