import React from 'react';
import { IoChatbubblesSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { fetchMessageCount } from '../../../store/dashboardMessages/actions';
import { useDispatch, useSelector } from 'react-redux'

function Messages() {
    const messageCount = useSelector( state => state.dashboardMessages.post.count )
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(fetchMessageCount())
    }, [])
    return ( 
        <Link to="chats" >
            <div class="card shadow border-start-warning py-2">
                <div class="card-body">
                    <div class="row align-items-center no-gutters">
                        <div class="col me-2">
                            <div class="text-uppercase text-warning fw-bold text-xs mb-1"><span>My messages</span></div>
                            <div class="text-dark fw-bold h5 mb-0"><span>{messageCount}</span></div>
                        </div>
                        <div class="col-auto"><IoChatbubblesSharp style={{ color: "#dbdbdb" }} size="30" /></div>
                    </div>
                </div>
            </div>
        </Link>
     );
}

export default Messages;