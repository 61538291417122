import React, {Component, useState, useEffect } from 'react';
import {connect} from 'react-redux';
import CurrencyFormat from "react-currency-format";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { removeLoanProduct, storeProduct, addBrokersDiscount, toggleAddMode } from "../../../store/sales/actions";
import Moment from 'react-moment';

const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);

function BrokerDiscount(props) {
    const [amount,setAmount] = useState()
    const [warning,setWarning] = useState()
    const [formatted,setFormatted] = useState(false)
    useEffect(() => {
        //console.log(props?.brokersDiscount)
        if (props?.brokersDiscount && props?.brokersDiscount > 0){
            setAmount(props.brokersDiscount)
            setFormatted(true)
        }
    },props)
    return (
        <>
        <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:warning })} >
            {formatted ? 
            <CurrencyFormat class="form-control" thousandSeparator={false} 
                    prefix={ 'Broker\'s discount of -' } 
                    suffix={ '%' }
                    allowNegative={false} 
                    value={amount} 
                    style={{color:"green"}}
                    decimalScale={2} fixedDecimalScale={true} 
                    onFocus={e => { setFormatted(false) }}
                    onBlur={e => { setFormatted(false) }}
                    onClick={e => { setFormatted(false) }}
                    onChange={e => { setFormatted(false) }}  /> : 
            <input class="form-control" type="text" 
                value={amount}
                style={warning  ? {color:"red"} : {color:"blue"}}
                placeholder={"Enter broker's discount on interest rate"} 
                onChange={e => {
                    const value = e.target.value
                    //console.log(value)
                    setAmount(value)
                    setWarning(undefined)  
                    if (!value) {
                        setAmount(undefined)
                        props.onChange(props._id, 0)
                    } else if (!isNaN(value)){
                        const discount = parseFloat(value)
                        if (discount > props.maxDiscount) {
                            setWarning('Discount must not be greater than ' + props.maxDiscount + '%')
                        } else {
                            props.onChange(props._id, discount)
                        }
                        setAmount(value)
                    } else {
                        setWarning('Invalid discount value - ' + value)
                    }
                }}
                onBlur={e => { 
                    if (!warning) setFormatted(true) }}
                /> 
                }
        </OverlayTrigger>
        </>    );
}

function Tabs(props) {
    return (
    <>
        <ul class="nav nav-tabs" role="tablist">
        {
            props.loanProducts.map(s => {
                var id = "#tab-" + s._id
                var active = props.focus.loanProduct._id === s._id
                return (
                    <li class="nav-item" role="presentation" key={id}>
                        <a class={active ? "nav-link active" : "nav-link"} role="tab" data-bs-toggle="tab" href={id}>{s.productName}</a></li>
                );
            })
        }
        {props.addMode ? null :
        <li class="nav-item" role="presentation" key={new Moment().valueOf()} >
            <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Add more products'})} >
            <svg class="bi bi-plus-circle-fill fs-2 text-success d-xl-flex d-xxl-flex" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16"
                onClick={() => {props.addNewProduct()}}>
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3v-3z"></path>
            </svg></OverlayTrigger>
            </li>}
        </ul>
    </>);
}

function GeneralInformation(props) {
    return (
        <>
            <table class="table">
                <thead>
                    <tr>
                        <th><span>General information</span></th>
                        <th><i class="fas fa-info-circle"></i></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><span>Interest Category</span></td>
                        <td>{props?.features?.loanInterestCategory}</td>
                    </tr>                                                
                    <tr>
                        <td><span>Rate Option</span></td>
                        <td>{props?.features?.rateOption}</td>
                    </tr> 
                    <tr>
                        <td><span>Loan purpose</span></td>
                        <td>{props?.features?.residentialLoan === "true" ? "Residential" : "Investor" }</td>
                    </tr> 
                    <tr>
                        <td><span>Variation</span></td>
                        <td>{props?.variation}</td>
                    </tr> 
                </tbody>
            </table>
        </>
    );
}

function OtherDetails(props) {
    return (
        <>
        <table class="table">
            <thead>
                <tr>
                    <th><span>Other features</span></th>
                    <th><i class="fas fa-calculator"></i></th>
                </tr>
            </thead>
            <tbody>
                {props?.features?.maxLoanTermInYears ? <tr>
                    <td><span>Maximum loan term</span></td>
                    <td><span>{props?.features?.maxLoanTermInYears} years</span></td>
                </tr> : null}
                {props?.features?.maxFixedTermInMonths ? <tr>
                    <td><span>Fixed term</span></td>
                    <td><span>{props?.features?.maxFixedTermInMonths} months</span></td>
                </tr> : null}
                {props?.features?.minLoanAmount?.value ? <tr>
                    <td><span>Minimum loan amount</span></td>
                    <td><span>{
                    props?.features?.minLoanAmount?.value
                        .toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })
                    }</span></td>
                </tr> : null}
            </tbody>
        </table>
        </>
    );
}

function BenefitsAndDiscounts(props) {
    return (
        <>
        <table class="table">
            <thead>
                <tr>
                    <th><span>Benefits and discounts</span></th>
                    <th>
                    <svg class="bi bi-award-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                            <path d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864 8 0z"></path>
                            <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1 4 11.794z"></path>
                        </svg></th>
                </tr>
            </thead>
            <tbody>
                {props?.loanProduct?.entitlements?.benefit != null  ? 
                <Benefits {...props.loanProduct} /> : null }
                {props?.loanProduct?.entitlements?.discount != null || props?.brokersDiscount ? 
                <Discounts {...props} /> : null }
            </tbody>
        </table>
        </>
    );
}

function Benefits(props) {
    return (
        <>
        {props?.entitlements?.benefit.map(function(each){
            return (
                <>
                <tr>
                    <td>{each}</td>
                </tr>
                </>
            );
        })}
        </>
    );
}

function Discounts(props) {
    const discounts = props?.loanProduct?.entitlements?.discount ? props?.loanProduct?.entitlements?.discount : [] 
    return (
        <>
        {discounts.map(function(each){
            return (
                <>
                <tr>
                    <td>{each}</td>
                </tr>
                </>
            );
        })}
        {props?.brokersDiscount && props?.brokersDiscount > 0 ? <tr>
            <td><span>Proposal includes discount of&nbsp;</span>{props?.brokersDiscount}%<span>&nbsp;on lender's base interest rate &nbsp;</span></td>
        </tr> : null}
        </>
    );
}

function RatesAndFees(props) {
    return (
        <>
        <table class="table">
            <thead>
                <tr>
                    <th><span>Rates and Fees</span></th>
                    <th><i class="fas fa-calculator"></i></th>
                </tr>
            </thead>
            <tbody>
                {props?.brokersDiscount && props?.brokersDiscount > 0 ? <tr>
                    <td><span>Offered Rate</span></td>
                    <td>{props?.offeredRate ? props.offeredRate : props.currentDiscount}%</td>
                </tr> : null}
                <tr>
                    <td><span>Interest Rate</span></td>
                    <td>{props?.loanProduct?.ratesAndFees?.interestRate}%</td>
                </tr>
                {props?.loanProduct?.ratesAndFees?.comparisonRate != null ? 
                <tr>
                    <td><span>Comparison Rate</span></td>
                    <td><span>{props.loanProduct?.ratesAndFees.comparisonRate}%</span></td>
                </tr> : null }

                {props?.loanProduct?.ratesAndFees?.indexInterestRateIO != null ? 
                <tr>
                    <td><span>Index Interest Rate</span></td>
                    <td><span>{props?.loanProduct?.ratesAndFees?.indexInterestRateIO}%</span></td>
                </tr> : null }
                {props?.loanProduct?.ratesAndFees?.indexInterestRatePI != null ? 
                <tr>
                    <td><span>Index Interest Rate</span></td>
                    <td><span>{props.loanProduct?.ratesAndFees.indexInterestRatePI}%</span></td>
                </tr> : null }

                {props?.loanProduct?.ratesAndFees?.indexComparisonRateIO != null ? 
                <tr>
                    <td><span>Index Comparison Rate</span></td>
                    <td><span>{props.loanProduct?.ratesAndFees.indexComparisonRateIO}%</span></td>
                </tr> : null }
                {props?.loanProduct?.ratesAndFees?.indexComparisonRatePI != null ? 
                <tr>
                    <td><span>Index Comparison Rate</span></td>
                    <td><span>{props.loanProduct?.ratesAndFees.indexComparisonRatePI}%</span></td>
                </tr> : null }

                {props?.loanProduct?.ratesAndFees?.loanProductFees?.map(function(each){
                    return (
                        <>
                        {each?.applicable ? 
                        <tr>
                            <td><span>{each?.label}</span></td>
                            { ( each?.amount ) ? <td><span>${each.amount?.value}</span></td> :
                                each.applicable === true ? <td>{each.text}</td> : <td></td> }
                        </tr> : null }
                        </>
                    );
                })}
                {props?.loanProduct?.ratesAndFees?.feeStatement != null ? 
                <tr>
                    <td colspan="2"><span>{props.loanProduct?.ratesAndFees.feeStatement}</span></td>
                </tr> : null }
            </tbody>
        </table>
        </>
    );
}

function LoanProduct(props) {
    const product = props.selectedLoanProducts[props.scenario.loanProduct._id] ? 
        props.selectedLoanProducts[props.scenario.loanProduct._id] :
        props.scenario.loanProduct
    const discounts = props?.proposalInView?.proposalDiscounts ? props?.proposalInView?.proposalDiscounts : []
    const discount = discounts.find(d => {
        return d.loanProductId == props.scenario.loanProduct._id 
    }) 
    const offeredRate = discount ? 
        Math.round((product?.ratesAndFees?.interestRate - discount.discount.homeLoanInterestRateDiscount)*100)/100 :
        undefined
    
    return (
    <>
    <div class="card shadow border-start-success py-2" style={{minWidth:"850px", maxHeight:"650px", overflow:"auto"}}>
        <div class="card-body">
            <div class="hstack gap-2 d-xxl-flex justify-content-xxl-end">
                <div class="hstack gap-2 d-flex flex-row flex-grow-1 justify-content-evenly align-self-stretch align-items-xl-start align-items-xxl-start">
                    <div class="vstack gap-2">
                        <div class="hstack d-xxl-flex flex-row flex-grow-1 flex-fill justify-content-between">
                            <h2 class="fw-bold d-xxl-flex mb-2">
                                <CurrencyFormat thousandSeparator={true} prefix={'$'} displayType={'text'} value={props.scenario.loanAmount.value}
                                    decimalScale={2} fixedDecimalScale={true}  />
                                </h2>
                            <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Remove product'})} >
                            <svg class="bi bi-trash fs-2 text-danger d-xxl-flex" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" 
                                onClick={() => {
                                    props.removeLoanProduct(props.scenario.loanProduct)}}>
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"></path>
                                <path fill-rule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"></path>
                            </svg>
                            </OverlayTrigger>
                        </div>
                        <BrokerDiscount  onChange={props.addBrokersDiscount} {...props.scenario} maxDiscount={product?.ratesAndFees?.interestRate} />                         
                        <div class="table-responsive">
                            <div class="card shadow border-start-success py-2">
                                <div class="card-body">
                                    <div class="hstack d-xxl-flex align-items-xxl-start">
                                        <GeneralInformation {...product} />
                                        <RatesAndFees {...props.scenario} currentDiscount={offeredRate} />
                                    </div>                                    
                                </div>
                            </div>
                            <p/>
                            <div class="card shadow border-start-success py-2">
                                <div class="card-body">
                                    <div class="table-responsive"><BenefitsAndDiscounts {...props.scenario} /></div>
                                </div>
                            </div>
                            <p/>
                            <div class="card shadow border-start-success py-2">
                                <div class="card-body">
                                    <div class="table-responsive"><OtherDetails {...product} /></div>
                                </div>
                            </div>
                        </div>

                    
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>);
}

class SelectedProductsPanel extends Component {

    componentDidUpdate() {
        const products = this.props.scenarios.map(s => s.loanProduct)
        const availableProducts = this.props.selectedLoanProducts
        const loadProduct = this.props.storeProduct
        products.map(product => {
            if (!availableProducts[product._id]) {
                loadProduct(product._id)
            }
        });
    }

    render() {
        const availableProducts = this.props.selectedLoanProducts
        const loadProduct = this.props.storeProduct
        const loanProducts = 
            this.props.scenarios.map(s => {
                const product = availableProducts[s.loanProduct._id]
                if (!product)
                    loadProduct(s.loanProduct._id)
                return product
            }).filter(l => l !== undefined)
        
        var last = this.props.scenarios.length > 0 ?  this.props.scenarios[this.props.scenarios.length - 1] : null
        //console.log(this.props)

        return (
        <>
        {!availableProducts || loanProducts.length === 0 ? null :
            <div>
                <Tabs focus={last} loanProducts={loanProducts} addMode={this.props.addMode} addNewProduct={this.props.toggleAddMode} />

                <div class="tab-content">
                {this.props.scenarios.map(s => {
                    var id = "tab-" + s.loanProduct._id
                    var active = last.loanProduct._id === s.loanProduct._id
                    return (
                    <>
                        <div id={id} class={active ? "tab-pane fade show active" : "tab-pane fade"} role="tabpanel" key={id}>
                            <LoanProduct scenario={s}  key={"lp-" + id} {...this.props} />
                        </div>
                    </>
                    );
                })}

                </div>
            </div>
        }

        </>
        )
    }
}

const mapStateToProps = state => {
    return {
        oldProposal : state.proposalSummary.proposalInView,
        prospectInView : state.clients.prospectInView,
        proposalInView : state.sales.proposalInView,
        scenarios: state.sales.loanScenarios,
        selectedLoanProducts: state.sales.selectedLoanProducts,
        addMode: state.sales.addMode,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        removeLoanProduct: (product) => dispatch(removeLoanProduct(product)),
        storeProduct: (productId) => dispatch(storeProduct(productId)),
        addBrokersDiscount: (scenarioId, brokerDiscount) => dispatch(addBrokersDiscount(scenarioId, brokerDiscount)),
        toggleAddMode: () => dispatch(toggleAddMode()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SelectedProductsPanel);