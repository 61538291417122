import React, { useState } from 'react';
import classNames from  'classnames';
import { useSelector } from "react-redux";
import SaveAgentButton from '../buttons/saveAgent';
import SaveSuccessfulModal from './saveSuccessful';
import ErrorContainer from '../../../common/ErrorContainer';

function getDisplayableNumber(e164Number) {
    if (e164Number == null) return null;
    const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
    const backNum = phoneUtil.parseAndKeepRawInput(e164Number, "AU")
    const nationalNumber = backNum.getNationalNumber()
    const australianNumber = '0' + nationalNumber
    return australianNumber
}

function BasicDetails() {
    const brokerFullName = useSelector(state => state.brokerAgentProfile?.brokerAgent?.fullName)
    const brokerEmail = useSelector(state => state.brokerAgentProfile?.brokerAgent?.contact?.primaryEmail)
    const brokerPhone = getDisplayableNumber(useSelector(state => state.brokerAgentProfile?.brokerAgent?.contact?.primaryPhone))
    const [mobile, setMobile] = useState(brokerPhone)
    const brokerAgent = useSelector(state => state.brokerAgentProfile?.brokerAgent)
    const saveSuccessful = useSelector(state => state.brokerAgentProfile?.saveSuccessful)
    const saveSource = useSelector(state => state.brokerAgentProfile?.saveSource)
    const authToken = useSelector(state => state.auth.token)
    const mobileUpdateErrors = useSelector(state => state.brokerAgentProfile?.mobileNumberUpdateErrors)

    var txtClass = classNames({
      'form-control': mobileUpdateErrors == null || mobileUpdateErrors.length == 0,
      'form-control borderError': mobileUpdateErrors != null && mobileUpdateErrors.length > 0,
    });          
    return (
      <>
        <div id="single-form-next" class="multisteps-form__panel shadow p-4 rounded bg-white js-active" data-animation="scaleIn">
            <div id="form-content" class="multisteps-form__content">
            {saveSuccessful && saveSource == 'basic_details' ? (
                < SaveSuccessfulModal message='Mobile number updated!'/>
                ) : (
                <div>
                <div id="input-grp-single-2" class="form-row mt-4">
                    <div class="vstack">
                        <div class="col-12"><input class="form-control multisteps-form__input" type="text" placeholder={brokerFullName} readonly="" disabled=""/></div>
                    </div>
                </div>
                <div id="input-grp-single-3" class="form-row mt-4">
                    <div class="col-12"><input class="form-control multisteps-form__input" type="text" placeholder={brokerEmail} disabled="" readonly=""/></div>
                </div>
                <div id="input-grp-single-5" class="form-row mt-4">
                    <div class="col-12">
                        <ErrorContainer error={mobileUpdateErrors}>
                            <input className={txtClass} type="text" id="registrationForm_mobile" name="registrationForm_mobile" 
                            placeholder="Mobile number" 
                            value={mobile} onChange={(e) => setMobile(e.target.value)} />
                        </ErrorContainer>
                    </div>
                </div>
                <SaveAgentButton brokerAgent={ {...brokerAgent, contact: { primaryPhone : mobile }} }  authToken={authToken} />
                </div>
                )}


            </div>
        </div>
      </>
        
     );
}

export default BasicDetails;