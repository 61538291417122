import React, { Component, useState, } from 'react';
import { connect } from "react-redux";
import moment from 'moment';
import ConnectionsPageSelector from "./ConnectionsPagination";
import { setClientInDetailView, fetchActiveConnections, fetchBorrowerChatMessages } from '../../../store/clients/actions';

function ActiveClientSearch(props) {
    const [borrowerLookup,setBorrowerLookup] = useState(props.activeClientSearch)
    return (
        <div id="accordion-clientSearch" class="accordion" role="tablist">
            <div class="accordion-item">
                <h2 class="accordion-header" role="tab"><button class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#accordion-clientSearch .item-1" aria-expanded="false" aria-controls="accordion-clientSearch">Search filters</button></h2>
                <div class="accordion-collapse collapse item-1" role="tabpanel" data-bs-parent="#accordion-clientSearch">
                    <div class="accordion-body">
                        <div class="vstack gap-2">
                            <div class="input-group input-group-sm" style={{"border-radius": "100","border-width": "1px"}}>
                                <input class="bg-light form-control border-0 small" type="text" placeholder="Name / phone / email"
                                    value={borrowerLookup} onChange={e => {
                                        setBorrowerLookup(e.target.value)
                                        if (!e.target.value) {
                                            props.fetchActiveConnections(1, props.connectionsPageSize)
                                        }
                                    }}
                                        />
                                <button class="btn btn-primary py-0" type="button" 
                                    onClick={() => props.fetchActiveConnections(props.connectionsDisplayPage, props.connectionsPageSize, borrowerLookup)
                                } ><i class="fas fa-search"></i></button>
                            </div>
                            <button class="btn btn-primary" type="button" 
                                onClick={() => {
                                    setBorrowerLookup('')
                                    props.fetchActiveConnections(1, props.connectionsPageSize)
                                }}>Clear filters</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    );
}


function ClientConnection(props) {
    return (<>
        <ul class="nav nav-tabs flex-column">
            <li class="nav-item">
                <div class="card text-secondary bg-white">
                    <div class="card-body"  style={{background: "#536d85",color: "var(--bs-light)"}}>
                        <div class="row">
                            <div class="col-7">
                                <h4>{props?.prospect?.mainBorrower?.firstName}</h4>
                            </div>
                            <div class="col d-lg-flex justify-content-lg-end">
                                {props?.liked != null && props.prospect.liked ? 
                                <i class="fas fa-bookmark d-xl-flex justify-content-xl-end" style={{"font-size": "16px"}}></i> : 
                                <i class="far fa-bookmark d-xl-flex justify-content-xl-end" style={{"font-size": "16px"}}></i> }                               
                            </div>
                        </div>
                        {props?.prospect?.loanRequest?.loanAmount == null || props?.prospect?.loanRequest?.loanAmount.value === 0 ? null : 
                        <div class="row">
                            <div class="col"><small>Loan requirement</small></div>
                            <div class="col"><strong>{props.prospect.loanRequest.loanAmount.value
                                                                            .toLocaleString('en-AU', { style: 'currency', currency: 'AUD' })}</strong></div>
                        </div>}
                        {props?.prospect?.mainBorrower?.currentAddress?.state == null ? null : 
                        <div class="row">
                            <div class="col"><small>Location</small></div>
                            <div class="col"><strong>{props?.prospect?.mainBorrower?.currentAddress?.state}</strong></div>
                        </div>}
                        {props?.prospect?.mainBorrower?.dateOfBirth == null ? null : 
                        <div class="row">
                            <div class="col"><small>Age</small></div>
                            <div class="col"><strong>{moment().diff(moment(props.prospect.mainBorrower.dateOfBirth),'years')}</strong></div>
                        </div>}
                        {props?.prospect?.mainBorrower?.primaryProfession == null ? null : 
                        <div class="row">
                            <div class="col"><small>Profession</small></div>
                            <div class="col"><strong>{props?.prospect?.mainBorrower?.primaryProfession}</strong></div>
                        </div>}
                        <a class="active link-primary" data-bs-toggle="modal" data-bs-target="#modal-client"><svg xmlns="http://www.w3.org/2000/svg" viewBox="-32 0 512 512" width="1em" height="1em" fill="currentColor" style={{"color": "#ffffff"}}
                            onClick={() => {props.setProspect(props?.prospect)}}>
                            <path d="M447.1 319.1v135.1c0 13.26-10.75 23.1-23.1 23.1h-135.1c-12.94 0-24.61-7.781-29.56-19.75c-4.906-11.1-2.203-25.72 6.937-34.87l30.06-30.06L224 323.9l-71.43 71.44l30.06 30.06c9.156 9.156 11.91 22.91 6.937 34.87C184.6 472.2 172.9 479.1 160 479.1H24c-13.25 0-23.1-10.74-23.1-23.1v-135.1c0-12.94 7.781-24.61 19.75-29.56C23.72 288.8 27.88 288 32 288c8.312 0 16.5 3.242 22.63 9.367l30.06 30.06l71.44-71.44L84.69 184.6L54.63 214.6c-9.156 9.156-22.91 11.91-34.87 6.937C7.798 216.6 .0013 204.9 .0013 191.1v-135.1c0-13.26 10.75-23.1 23.1-23.1h135.1c12.94 0 24.61 7.781 29.56 19.75C191.2 55.72 191.1 59.87 191.1 63.1c0 8.312-3.237 16.5-9.362 22.63L152.6 116.7l71.44 71.44l71.43-71.44l-30.06-30.06c-9.156-9.156-11.91-22.91-6.937-34.87c4.937-11.95 16.62-19.75 29.56-19.75h135.1c13.26 0 23.1 10.75 23.1 23.1v135.1c0 12.94-7.781 24.61-19.75 29.56c-11.1 4.906-25.72 2.203-34.87-6.937l-30.06-30.06l-71.43 71.43l71.44 71.44l30.06-30.06c9.156-9.156 22.91-11.91 34.87-6.937C440.2 295.4 447.1 307.1 447.1 319.1z"></path>
                        </svg></a>
                    </div>
                </div>
            </li>
        </ul>    
    </>);
}

class ActiveClientsPanel extends Component {
	constructor(props) {
        super(props);
    }

    render () {
        var setClientInDetailView = this.props.setClientInDetailView
        //console.log(this.props)
        return ( 
        <>
        <div class="vstack gap-2">
            <div class="hstack gap-2 d-flex d-md-flex justify-content-between align-items-xxl-start">
                <div class="hstack gap-2">
                    {
                        this.props.prospects.map(function(each){
                            return (
                                <div class="hstack gap-2 d-md-flex flex-grow-1 flex-fill justify-content-between align-items-stretch justify-content-md-start align-items-md-start">
                                <ClientConnection prospect={each} setProspect={setClientInDetailView} /></div>);
                    })}
                </div>
                <ActiveClientSearch {...this.props} />
            </div>

            <div class="hstack gap-2 justify-content-end">
                {this.props.activeClientSearch ? null : <ConnectionsPageSelector  />}
            </div>
        </div> 
        </>
     )}
}


const mapStateToProps = ({ clients }) => {
  return { 
      ...clients
    };
};

const mapDispatchToProps = (dispatch)=> {
    return {
        setClientInDetailView : (prospect) => dispatch(setClientInDetailView(prospect)),
        fetchActiveConnections: (page, pagesize, borrowerLookupKey) => dispatch(fetchActiveConnections(page, pagesize, borrowerLookupKey)),
        fetchBorrowerChatMessages: (borrowerId) => dispatch(fetchBorrowerChatMessages(borrowerId)),
    };
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(ActiveClientsPanel);