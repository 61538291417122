import React, { useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { confirmPasswordReset } from '../../../store/auth/actions';

import ErrorModal from '../../common/ErrorModal';
import Loader from '../../common/Loader';
import Loanseller from "../../layout/LoanSeller_Light.png";

const ResetPasswordForm = (props) => {
    //console.log(props)
    const [showPasswordModal, setShowPasswordModal] = useState(true)
    const [complete, setComplete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState(props?.email);
    const [code, setCode] = useState(props?.code);
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const { resetPasswordError,passwordError,passwordRepeatError } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const onSuccess = () => {
        setShowPasswordModal(false)
        setLoading(false)
        setComplete(true)
    }
    const onFailure = (repeat) => {
        setShowPasswordModal(repeat)
        setLoading(false)
    }

    return (
    <>
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div>
                    <Modal show={showPasswordModal} 
                        onHide={() => {setShowPasswordModal(false) }}>
                        <Modal.Header>
                        <Modal.Title>
                            <img src={Loanseller} width="95" />
                            Set new password</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        {
                            passwordError ? 
                            ( 
                                <div class="mb-3">
                                    <div className={'alert alert-danger regionError'} role="alert" >
                                    <input className={'form-control borderError'} type="password" 
                                        id={'resetPasswordForm_password'} 
                                        name={'resetPasswordForm_password'} 
                                        placeholder={'Enter a safe Password1!'}  
                                        value={password} 
                                        onChange={(e) => setPassword(e.target.value)} />
                                    <span><strong>{passwordError.errorMessage}</strong><br/></span></div>
                                </div>
                            ) : (
                                <div class="mb-3">
                                <input className={'form-control'} type="password" id={'resetPasswordForm_password'} 
                                    name={'resetPasswordForm_password'} 
                                    placeholder={'Set a new Password1!'}  
                                    value={password} 
                                    onChange={(e) => setPassword(e.target.value)} />
                                </div>
                            )
                        }
                        {
                            passwordRepeatError ? 
                            ( 
                                <div class="mb-3">
                                    <div className={'alert alert-danger regionError'} role="alert" >
                                    <input className={'form-control borderError'} 
                                        type="password" 
                                        id={'resetPasswordForm_passwordRepeat'} 
                                        name={'resetPasswordForm_password'} 
                                        placeholder={'Confirm password'}  
                                        value={passwordRepeat} 
                                        onChange={(e) => setPasswordRepeat(e.target.value)} />
                                    <span><strong>{passwordRepeatError.errorMessage}
                                        </strong><br/></span></div>
                                </div>
                            ) : (
                                <div class="mb-3">
                                <input className={'form-control'} type="password" 
                                    id={'resetPasswordForm_passwordRepeat'} 
                                    name={'resetPasswordForm_password'} 
                                    placeholder={'Confirm password'}  
                                    value={passwordRepeat} 
                                    onChange={(e) => setPasswordRepeat(e.target.value)} />
                                </div>
                            )
                        }
                        </Modal.Body>
                        <Modal.Footer>
                        <Button variant="primary" 
                            disabled={!password || !passwordRepeat}
                            onClick={() => {
                                        setLoading(true)
                                        setShowPasswordModal(false)
                                        dispatch(confirmPasswordReset(
                                            email, code, password, passwordRepeat, 
                                            onSuccess, onFailure));
                                    }} >
                            Submit
                        </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={complete} onHide={() => {window.open("/","_self")}}>
                        <Modal.Header closeButton>
                        <Modal.Title>Great!</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Password updated!</p>
                        </Modal.Body>
                    </Modal>

                    {loading ? <Loader /> : null}

                    { 
                    resetPasswordError?.status == undefined ? null : 
                    <ErrorModal error={resetPasswordError} /> }
                </div>
            </div>
        </div>
    </div>
    </>
    );
}

export default ResetPasswordForm;