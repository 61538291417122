const getBilling = {
  pending: 'updateBilling/getBilling/pending',
  success: 'updateBilling/getBilling/success',
  fail: 'updateBilling/getBilling/fail'
};
const update = {
  pending: 'updateBilling/update/pending',
  success: 'updateBilling/update/success',
  fail: 'updateBilling/update/fail'
};
const createNewBilling = {
  pending: 'updateBilling/createNewBilling/pending',
  success: 'updateBilling/createNewBilling/success',
  fail: 'updateBilling/createNewBilling/fail'
};

const updateFrom = 'updateBilling/updateFrom';
const selectBilling = 'updateBilling/selectBilling';
const removeSelectedBilling = 'updateBilling/removeSelectedBilling';

export default {
  getBilling,
  update,
  createNewBilling,
  selectBilling,
  updateFrom,
  removeSelectedBilling
};
