import types from './actionType';
import { getRequiredDocuments } from "../../request";
import * as constants from '../constants';

export const setUp = (proposal) => {
    return {
      type: types.setupFromProspect,
      payload : proposal
    }
}

export const fetchDocumentTypes = () => async (dispatch) => {
  getRequiredDocuments()
    .then(res => {
      if (res != null && res.data) {
        dispatch({
          type: types.loadAllDocumentTypes.success,
          payload: res.data,
          });
      } else
        dispatch({
          type: types.loadAllDocumentTypes.failure,
          payload: 'Failed to get all required document types' ,
        });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: types.loadAllDocumentTypes.failure,
        payload: error ,
      });
      return null;
    });
};


export const clearAllDocs = () => {
  return {
    type: types.setDocumentSelection.resetRequiredDocs,
  }
}

export const toggleDocumentRequired = (documentType, toggleOnOff) => {
  return {
    type: types.setDocumentSelection.addRemoveDocument,
    payload : { documentType : documentType, toggleOnOff : toggleOnOff }
  }
}

export const toggleAllDocs = (toggleType, toggleOnOff) =>  {
  switch (toggleType) {
    case constants.ID_DOCS :
      return {
        type: toggleOnOff ? types.setDocumentSelection.selectIdDocs : types.setDocumentSelection.deselectIdDocs,
      }
    case constants.BANK_DOCS :
      return {
        type: toggleOnOff ? types.setDocumentSelection.selectBankStmts : types.setDocumentSelection.deselectBankStmts,
      }
    case constants.INC_PROOF :
      return {
        type: toggleOnOff ? types.setDocumentSelection.selectIncomeDocs : types.setDocumentSelection.deselectIncomeDocs,
      }
    case constants.OTHER_DOCS :
      return {
        type: toggleOnOff ? types.setDocumentSelection.selectOtherDocs : types.setDocumentSelection.deselectOtherDocs,
      }
  }
}