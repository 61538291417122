import _ from './actionType';

const initialState = {
  selectedBilling: {},
  getBillingLoading: false,

  form: {},

  updateSubscriberLoading: false,

  createNew: true,
  errorMessage: null
};

export default function updateSubscribers(state = initialState, action) {
  if (!action.payload) return state;
  const { selectedBillingData } = action.payload;
  switch (action.type) {
    // case _.selectBilling:
    //   return {
    //     ...state,
    //     createNew: false,
    //     selectedBilling: selectedBillingData
    //   };

    case _.getBilling.pending:
      return {
        ...state,
        getBillingLoading: true
      };
    case _.getBilling.success:
      return {
        ...state,
        getBillingLoading: true,
        selectedBilling: { ...action.payload.data },
        form: { ...action.payload.data }
      };
    case _.getBilling.fail:
      return {
        ...state,
        getBillingLoading: false,
        errorMessage: action.payload.errorMessage
      };

    case _.update.pending:
      return {
        ...state,
        loading: true
      };
    case _.update.success:
      return {
        ...state,
        loading: true,
        selectedBilling: { ...action.payload.updatedBilling },
        form: { ...action.payload.updatedBilling }
      };
    case _.update.fail:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage
      };

    case _.createNewBilling.pending:
      return {
        ...state,
        loading: true
      };
    case _.createNewBilling.success:
      return {
        ...state,
        loading: true,
        selectedBilling: { ...action.payload.data }
      };
    case _.createNewBilling.fail:
      return {
        ...state,
        loading: false,
        errorMessage: action.payload.errorMessage
      };
    case _.updateFrom:
      return {
        ...state,
        form: { ...state.form, ...action.payload.newData }
      };

    default:
      return state;
  }
}
