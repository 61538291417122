import { fetchFailure, fetchLoading, fetchSuccess, saveSuccess, validationError } from "./actionTypes"
import { getAutocompleteResponse, findByAbn } from "./request"
import { fetchBrokerAgent, } from "../../store/brokerAgent/actions";

export const fetchAutocompleteOptions = (lookup, setIsLoading) => {
    return function(dispatch) {
        setIsLoading(true);
        dispatch(fetchLoading()) 
        getAutocompleteResponse(lookup)
            .then(response => {
                setIsLoading(false)
                const results = response?.data ? response.data : []
                results.sort((a,b) => {
                    return a.companyName < b.companyName ? -1 : a.companyName > b.companyName ? 1 : 0
                })
                dispatch(fetchSuccess(results))
            })
            .catch(error => {
                setIsLoading(false)
                dispatch(fetchFailure(error))
            })
    }
}

export const fetchABNSearchResults = (lookup, setIsLoading) => {
    return function(dispatch) {
        setIsLoading(true);
        dispatch(fetchLoading()) 
        findByAbn(lookup)
            .then(response => {
                const post = response.data
                setIsLoading(false)
                dispatch(fetchSuccess(post))
                dispatch(fetchBrokerAgent())

            })
            .catch(error => {
                setIsLoading(false)
                dispatch(fetchFailure(error))
            })
    }
}
export const checkCompanyABNAndName = (agency) => async (dispatch) => {
    const abn = agency?.companyDetails?.abn
    const companyName = agency?.companyDetails?.companyName
    var errors = [
        {type:"asicDetails", 
            valid : abn != null && companyName != null,
            errorMessage : 'Please select your company using valid ASIC details',
        },
    ]
    .filter((element) => {return !element?.valid;});

    if (errors.length > 0) 
      dispatch(validationError(errors));

}

export const saveSelectedCompany = (companyOption) => {
    return function(dispatch) {
        dispatch(saveSuccess(companyOption))
    }
}