import React, {Component, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from "react-redux";

import moment from 'moment';

import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import ActiveSalesPageSelector from './ActiveSalesPagination';
import HiddenText from '../../../common/HiddenText'
import { loanProposalMenuAction, fetchProposalsCount, fetchProposals, fetchProspect, showProposal, reviewProposal, fetchProductCount, fetchProducts  } from "../../../../store/sales/actions";
import { setClientInDetailView, fetchBorrowerChatMessages } from '../../../../store/clients/actions';
import { setUp as setUpDocuments } from "../../../../store/sales/editDocuments/actions";
import { setUp as setUpProposalSummary } from "../../../../store/sales/proposalSummary/actions";
import { LOAN_PACKAGE_VIEW } from '../../../../store/sales/constants';

const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);


class SalesInProgress extends Component {
	constructor(props) {
        super(props);
    }


    reload() {
        const availableProducts = this.props.loanProductRecords
        const fetchProducts = this.props.fetchProducts
        const fetchProductCount = this.props.fetchProductCount    

        this.props.fetchCount(this.props.activeSalesLookup, this.props.activeSalesLenderFilter)
        this.props.fetchProposals(this.props.activeSalesDisplayPage, this.props.activeSalesPageSize, 
            this.props.activeSalesLookup, this.props.activeSalesLenderFilter)
        this.props.activeSales.map(prospect => {
            if (availableProducts && !availableProducts.find(product => prospect?.lender?._id === product?.lender?._id )) {
                fetchProducts(prospect.lender.name, null, null, 1, 4);
                fetchProductCount(prospect.lender.name, null);
            }
        })
    }


    viewProposal(props) {
        if (props) {
            //console.log(props)
            props.fetchProspect(props.proposal.prospect._id, props.setClientInDetailView)
            props.reviewProposal(props.proposal)
            props.setUpProposalSummary(props.proposal)
            props.setUpDocuments(props.proposal)
            props.fetchBorrowerChatMessages(props.proposal.prospect.mainBorrower._id)
            props.loanProposalMenuAction(LOAN_PACKAGE_VIEW)
            props.showProposal()
        }
    }

	componentDidMount() {
        this.reload()
    }


    render() {
        const viewProposal = this.viewProposal
        const properties = this.props
		const accessRoles = this.props.accessRoles
        return (
        <>
            <div class="card shadow flex-fill border-start-warning py-2">
                <div class="card-body">
                    <div class="row align-items-center no-gutters">
                        <div class="col me-2">
                            <div class="text-uppercase text-success fw-bold text-xs mb-1">
                                <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'In progress sales proposals that you sent to clients and LoanShoper prospects'})} >
                                <span style={{"color":"var(--bs-primary)"}}>Loan Proposals</span>
                                </OverlayTrigger>
                            </div>
                            <div class="text-dark d-xxl-flex justify-content-xxl-end fw-bold h5 mb-0"><span></span></div>
                        </div>
                        <div class="col-auto"><svg class="bi bi-briefcase-fill fa-2x text-gray-300" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v1.384l7.614 2.03a1.5 1.5 0 0 0 .772 0L16 5.884V4.5A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5z"></path>
                                <path d="M0 12.5A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5V6.85L8.129 8.947a.5.5 0 0 1-.258 0L0 6.85v5.65z"></path>
                            </svg></div>
                    </div>
                    {this.props.activeSales.length == 0 ? null :
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Start date</th>
                                    <th>Status</th>
                                    <th>Borrower</th>
                                    <th>Lender</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.activeSales.map(function(proposal){
                                    const showPersonalDetails = 
                                        // Restricted prospect details for BYO licences
                                        proposal?.status === 'accepted'  
                                        || accessRoles.filter(r => r.roleName === 'partial_prospect').length == 0
                                    return (
                                        <tr id={new Date().getMilliseconds()}>
                                            <td>
                                                <a onClick={() => {
                                                    viewProposal({...properties, proposal : proposal})
                                                    }}>
                                                <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Show proposal'})} >
                                                <svg class="bi bi-arrows-fullscreen" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" data-bs-toggle="modal" data-bs-target="#modal-loanproposal">
                                                    <path fill-rule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344 0a.5.5 0 0 1 .707 0l4.096 4.096V11.5a.5.5 0 1 1 1 0v3.975a.5.5 0 0 1-.5.5H11.5a.5.5 0 0 1 0-1h2.768l-4.096-4.096a.5.5 0 0 1 0-.707zm0-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707zm-4.344 0a.5.5 0 0 1-.707 0L1.025 1.732V4.5a.5.5 0 0 1-1 0V.525a.5.5 0 0 1 .5-.5H4.5a.5.5 0 0 1 0 1H1.732l4.096 4.096a.5.5 0 0 1 0 .707z"></path>
                                                </svg>
                                                </OverlayTrigger>
                                                </a></td>
                                            <td>{moment(proposal.createdTs).format("DD MMM YY")}</td>
                                            <td>
                                            <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:
                                                proposal.status === 'proposed' ? 'Loan proposal sent to borrower' : 
                                                proposal.status === 'deleted' ? 'Loan proposal declined by borrower' : 
                                                proposal.status === 'accepted' ? 'Loan proposal accepted by borrower for further processing' : ''})} >
                                                <span>{proposal.status.toUpperCase()}</span>
                                            </OverlayTrigger>
                                                </td>
                                            <td><HiddenText unmasked={showPersonalDetails} >{proposal?.mainBorrower?.title} {proposal?.mainBorrower?.firstName} {proposal?.mainBorrower?.lastName}</HiddenText></td>
                                            <td>{proposal?.lender?.name}</td>
                                        </tr>);
                                })}
                            </tbody>
                        </table>
                    </div>
                    }

                    <ActiveSalesPageSelector />
                    
                </div>
            </div>
            </>
        )
    }
}

const mapStateToProps = state => {
    return {
        activeSales: state.sales.activeSales,
        activeSalesDisplayPage : state.sales.activeSalesDisplayPage,
        activeSalesPageSize: state.sales.activeSalesPageSize,
        activeSalesLookup: state.sales.activeSalesLookup, 
        activeSalesLenderFilter: state.sales.activeSalesLenderFilter,
        loanProductRecords: state.sales.loanProductRecords,

        prospectInView : state.clients.prospectInView,
        prosposalInView : state.sales.proposalInView,
        oldProsposal : state.proposalSummary.proposalInView,

		accessRoles : state.brokerAgentProfile.accessRoles,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchCount: (name,lenderId) => dispatch(fetchProposalsCount(name,lenderId)),
        fetchProposals: (page, pagesize, name,lenderId) => dispatch(fetchProposals(page, pagesize, name,lenderId)),
        fetchProspect: (prospectId, onsuccess) => dispatch(fetchProspect(prospectId, onsuccess)),
		setClientInDetailView: (prospect) => dispatch(setClientInDetailView(prospect)),
		showProposal: () => dispatch(showProposal()),
		reviewProposal: (proposal) => dispatch(reviewProposal(proposal)),
		setUpDocuments: (proposal) => dispatch(setUpDocuments(proposal)),
		setUpProposalSummary: (proposal) => dispatch(setUpProposalSummary(proposal)),
        fetchProducts: (lenderName, filters, sort, page, pagesize) => dispatch(fetchProducts(lenderName, filters, sort, page, pagesize)),
        fetchProductCount: (lenderName, filters) => dispatch(fetchProductCount(lenderName, filters)),
        fetchBorrowerChatMessages: (borrowerId) => { dispatch(fetchBorrowerChatMessages(borrowerId)) },
        loanProposalMenuAction: (view) => dispatch(loanProposalMenuAction(view)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SalesInProgress);