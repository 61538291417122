export const OUTSTANDING_BALANCE_ACTION_TYPES = {
    FETCH_LOADING: 'outstandingBalanceFetchLoading',
    FETCH_SUCCESS: 'outstandingBalanceFetchSuccess',
    FETCH_FAILURE: 'outstandingFetchFailure'
}

export default function getActionType(success) {
    const type = success? OUTSTANDING_BALANCE_ACTION_TYPES.FETCH_SUCCESS : OUTSTANDING_BALANCE_ACTION_TYPES.FETCH_ERROR
    return type
}

export const fetchLoading = () => {
    return {
        type: OUTSTANDING_BALANCE_ACTION_TYPES.FETCH_LOADING
    }
}

export const fetchSuccess = proposalsPost => {
    return {
        type: OUTSTANDING_BALANCE_ACTION_TYPES.FETCH_SUCCESS,
        payload: proposalsPost
    }
}

export const fetchFailure = error => {
    return {
        type: OUTSTANDING_BALANCE_ACTION_TYPES.FETCH_FAILURE,
        payload: error
    }
}