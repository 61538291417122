import { UPGRADE_ACTION_TYPES } from "./actionTypes"

export const initialState = {
    loading: false,
    error: null,
    upgradesEnabled:false,
    createSubscriptionPageVisible: false,
    upgradeModalVisible: false,
    showUpgradeWarning:false,
    trialAccountMode:false,
    subscriptionPackage: null,
    optionalExtras: [],
    formValiationErrors: null,
    paymentAmount: null,
    createPaymentReferenceResponse: null,
    paymentReference: null,
    uniqueReference: null,
    salesGeneratorAddIn: true,

    basicAnnualRate: 0,
    basicQuartelyRate: 0,
    teamsRate: 0,
    fixedSetUpRate: 0,
    commissionRate: 0,
}
  
export default function upgradeReducer(state = initialState, action) {
    switch (action.type) {
        case UPGRADE_ACTION_TYPES.FETCH_SUBSCRIPTION_PACKAGES_SUCCESS:
            var basicAnnualRate = action?.payload ? 
                action.payload.find(
                    subscription => subscription.name === 'Basic' && subscription.type === 'Annual' && subscription.status === 'active')
                    ?.rate?.value  :
                0
            var basicQuartelyRate = action?.payload ? 
                action.payload.find(
                    subscription => subscription.name === 'Basic' && subscription.type === 'Quarterly' && subscription.status === 'active')
                    ?.rate?.value  :
                0
            var teamsRate = action?.payload ? 
                action.payload.find(
                    subscription => subscription.name === 'Teams' && subscription.status === 'active')
                    ?.rate?.value  :
                0
            var fixedSetUpRate = action?.payload ? 
                action.payload.find(
                    subscription => subscription.type === 'Set-up' && subscription.status === 'active')
                    ?.rate?.value  :
                0
            var commissionRate = action?.payload ? 
                action.payload.find(
                    subscription => subscription.name === 'Sales generator' && subscription.status === 'active')
                    ?.commission  :
                0

            return {
                ...state,
                subscriptionPackages : action.payload,
                basicAnnualRate: basicAnnualRate,
                basicQuartelyRate: basicQuartelyRate,
                teamsRate: teamsRate,
                fixedSetUpRate: fixedSetUpRate,
                commissionRate: commissionRate,
                optionalExtras : action.payload.filter(
                    subscription => subscription.name === 'Sales generator' && subscription.status === 'active')
            }
        case UPGRADE_ACTION_TYPES.FETCH_SUBSCRIPTION_PACKAGES_FAILED:
            return {
                ...state,
                error: action.payload ,
            }

        case UPGRADE_ACTION_TYPES.TOGGLE_SALES_GENERATOR:
            var extras = [].concat(
                state.optionalExtras.filter(subscription => subscription.name !== 'Sales generator'))
            const addSalesGenerator = !state.salesGeneratorAddIn
            return {
                ...state,
                salesGeneratorAddIn: addSalesGenerator,
                optionalExtras : addSalesGenerator ? 
                    extras.concat(state.subscriptionPackages.find(subscription => subscription.name === 'Sales generator')):
                    extras ,
            }
        case UPGRADE_ACTION_TYPES.UPGRADE_MODAL_VISIBILITY:
            return {
                ...state,
                loading: false,
                upgradeModalVisible: action.payload
            }
        case UPGRADE_ACTION_TYPES.SHOW_UPGRADE_WARNING: 
            return {
                ...state,
                showUpgradeWarning: true,
            }
        case UPGRADE_ACTION_TYPES.HIDE_UPGRADE_WARNING: 
            return {
                ...state,
                showUpgradeWarning: false,
            }
        case UPGRADE_ACTION_TYPES.CHANGE_SUBSCRIPTION_PACKAGE:
            const subscriptionPackage = action.payload.subscriptionPackage
            return {
                ...state,
                error: null,
                createPaymentReferenceResponse: null,
                subscriptionPackage : subscriptionPackage,
                paymentAmount: subscriptionPackage == null ? null : 
                    {
                        value: 
                            subscriptionPackage.packageName === 'Basic' ? subscriptionPackage.subscriptionRate :
                            subscriptionPackage.packageName === 'Teams' ? subscriptionPackage.setupCharge : 
                            null ,
                        currency: 'AUD'
                    }    
            }
        case UPGRADE_ACTION_TYPES.CREATE_PAYMENT_REFERENCE_FAILED:
            return {
                ...state,
                error: action.payload ,
                createPaymentReferenceResponse: null
            }
        case UPGRADE_ACTION_TYPES.CREATE_PAYMENT_REFERENCE_SUCCESS:
            return {
                ...state,
                error: null,
                createPaymentReferenceResponse: action.payload
            }
        case UPGRADE_ACTION_TYPES.GET_PAYMENT_REFERENCE_SUCCESS:
            return {
                ...state,
                error: null,
                paymentReference: action.payload,
            }
        case UPGRADE_ACTION_TYPES.UPGRADE_SECTION_ENABLED:
            return {
                ...state,
                upgradesEnabled: true, trialAccountMode: true
            }
        case UPGRADE_ACTION_TYPES.GET_UUID:
            return {
                ...state,
                uniqueReference: action.payload
            }
        default:
            return state
    }
} 