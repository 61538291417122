import React, {Component} from 'react';
import {connect} from 'react-redux';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import { showClient, loanProposalMenuAction } from "../../../store/sales/actions";
import { SUMMARY_VIEW } from '../../../store/sales/constants';


const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);

class CloseButton extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
        <>
            <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Back to client details'})} >
                <button class="btn btn-primary" type="button"  
                    onClick={(e) => {
                        this.props.showClient()
                        this.props.loanProposalMenuAction(SUMMARY_VIEW)
                        if (this.props.onClose)
                            this.props.onClose()
                    }}>Close</button>
                    </OverlayTrigger>
        </>
        )
    }
}


const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        loanProposalMenuAction: (view) => dispatch(loanProposalMenuAction(view)),
        showClient: () => dispatch(showClient()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CloseButton);