import React, {  } from 'react';
import { AsyncTypeahead, Menu, MenuItem } from "react-bootstrap-typeahead";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAutocompleteOptions, saveSelectedAddress } from '../../../../store/addressLookup/actions';
import ErrorContainer from '../../../common/ErrorContainer';


export default function AddressAutoFill(props) {
    var error = useSelector(state =>  state.companyAddressAutocomplete?.error)
    const displayCompanyAddress = useSelector(state => state?.brokerAgentProfile?.agency?.companyDetails?.companyAddress)

    const dispatch = useDispatch()
    const isLoading = useSelector(state => state.companyAddressAutocomplete.loading)
    const options = useSelector(state => state.companyAddressAutocomplete.autocompleteOptions)
    const filterBy = () => true;

    return (
        <div>
            <div id="input-grp-single-6" class="form-row mt-4">
                <div class="col-12">
                  <ErrorContainer error={error}>
                    <AsyncTypeahead
                      filterBy={filterBy}
                      disabled={props?.readOnly}
                      id="async-companyAddress"
                      isLoading={isLoading}
                      labelKey={option => {
                          dispatch(saveSelectedAddress(option));
                          return `${option.fullAddress}`;
                      }}
                      minLength={2}
                      onSearch={lookup => {dispatch(fetchAutocompleteOptions(lookup))}}
                      options={options}
                      defaultInputValue={displayCompanyAddress != null ? displayCompanyAddress.fullAddress : ''}
                      placeholder="Company Address"
                      renderMenu={(results, menuProps) => (
                          <Menu {...menuProps}>
                          {results.map((result, index) => (
                              <MenuItem option={result} position={index}>
                              {result.fullAddress}
                              </MenuItem>
                          ))}
                          </Menu>
                      )}
                      />
                  </ErrorContainer>
                </div>
            </div>
        </div>
    );
}