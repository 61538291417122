import _ from "./actionType";

const initialState = {
  error: null,
  lenderOptions: [],
  selectedLender: null,
  lenderProducts: [],
  selectedProduct: null,

  // Filters
  filters : Object.assign({  
    "loanPurpose": "any",
    "interestCategory": "any",
    "rateOption": "any",
    "favorites": false,
    "offSale": false,
  }),
  sort:"default",

  // Pagination
  productTablePage: 1,
  numProducts: 0,
  pageSize:6,
  displayPage: 0,

};

export default function productsReducer(state = initialState, action) {
  switch (action.type) {
    case _.fetchLenderOptions.success:
      //console.log(action.payload)
      return {
        ...state,
        lenderOptions: !action?.payload ? [] :  action.payload.sort(function (a, b) {
          if (a.name.toUpperCase() < b.name.toUpperCase()) {
            return -1;
          }
          if (a.name.toUpperCase() > b.name.toUpperCase()) {
            return 1;
          }
          return 0;
        })
        ,}
    case _.fetchLenderOptions.failed:
      return {
        ...state,
        lenderOptions: []
      }
    case _.saveOptions.saveLender:
      return {
        ...state,
        selectedLender: action.payload,
        productTablePage: 1
      }
    case _.fetchProductsActions.success:
      return {
        ...state,
        lenderProducts: action.payload.records,
        displayPage: action.payload.page
      }
    case _.fetchProductsActions.failed:
      return {
        ...state,
        lenderProducts:[],
      }
    case _.fetchProductsActions.countsuccess:
      return {
        ...state,
        numProducts: action.payload.count,
      }
    case _.fetchProductsActions.countfailed:
      return {
        ...state,
        lenderProducts:[],
      }
    case _.fetchProductsActions.applyfilters:
      return {
        ...state,
        filters:action.payload,
      }
    case _.fetchProductsActions.applysortorder:
      return {
        ...state,
        sort:action.payload,
      }
    case _.fetchProductsActions.changepagesize:
      return {
        ...state,
        pageSize: action.payload,
      }
    case _.productActions.show:
      return {
        ...state,
        selectedProduct: action.payload,
      }

    default:
      return state;
  }
}
