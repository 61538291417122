export const getPaymentReference = "getPaymentReference";
export const fetchPayment = {
  success: "fetch/success",
  failed: "fetch/fail",
};
export const fetchInvoice = {
  success: "fetchInvoice/success",
  failed: "fetchInvoice/fail",
}
export const fetchInvoicesMetadata = {
  success: "fetchInvoicesMetadata/success",
  failed: "fetchInvoicesMetadata/fail",
}
export const fetchInvoices = {
  success: "fetchInvoices/success",
  failed: "fetchInvoices/fail",
}
export const fetchInvoiceReference = {
  success: "fetchInvoiceReference/success",
  failed: "fetchInvoiceReference/fail",
}
export const fetchInvoiceDocument = {
  failed: "fetchInvoiceDocument/fail",
}

export default { fetchPayment, getPaymentReference, fetchInvoice, fetchInvoicesMetadata, fetchInvoices, fetchInvoiceReference, fetchInvoiceDocument };