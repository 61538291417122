import { BROKER_ACTION_TYPES } from "./actionTypes";

export const initialState = {
    loading: false,
    error: null,
    saveSuccessful: false,
    mobileNumberUpdateErrors: [],
    brokerAgent:null,
    agency: null,
    agencyDetailsSaved: false,
    agencySaveErrors: [],
    saveSource: null,
    subscription: null,
    accessRoles: [],
}

export default function brokerAgentProfileReducer(state = initialState, action) {
    switch (action.type) {
        case BROKER_ACTION_TYPES.FETCH_LOADING:
            return {
                ...state,
                loading: true
            }
        case BROKER_ACTION_TYPES.FETCH_SUCCESS:
            return {
                ...state,
                loading: false,
                brokerAgent:action.payload,
                agency:action.payload.brokerAgency,
                accessRoles: action.payload.roles,
                error: null
            }
        case BROKER_ACTION_TYPES.ACCESS_ROLES_LOADING:
            return {
                ...state,
                loading: true
            }
        case BROKER_ACTION_TYPES.ACCESS_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                accessRoles: action?.payload ? action.payload : []
            }
        case BROKER_ACTION_TYPES.ACCESS_ROLES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case BROKER_ACTION_TYPES.SUBSCRIPTION_LOADING:
            return {
                ...state,
                loading: true
            }
        case BROKER_ACTION_TYPES.SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                loading: false,
                subscription: action?.payload?.length > 0 ? action.payload[0] : null,
                error: null
            }
        case BROKER_ACTION_TYPES.SUBSCRIPTION_FAILURE:
            return {
                ...state,
                loading: false,
                subscription: null,
                error: "Subscription could not be retrieved." 
            }
            
        case BROKER_ACTION_TYPES.EXTRAS_LOADING:
            return {
                ...state,
                loading: true
            }
        case BROKER_ACTION_TYPES.EXTRAS_SUCCESS:
            return {
                ...state,
                loading: false,
                extras: action?.payload?.length > 0 ? action.payload : null,
                error: null
            }
        case BROKER_ACTION_TYPES.EXTRAS_FAILURE:
            return {
                ...state,
                loading: false,
                extras: null,
                error: "Extras could not be retrieved." 
            }

        case BROKER_ACTION_TYPES.MOBILE_UPDATE_VALIDATION_FAIL:
            return {
                ...state,
                mobileNumberUpdateErrors: action.payload.errors
            }
        case BROKER_ACTION_TYPES.SAVE_SUCCESS:
            return {
                ...state,
                saveSuccessful: true,
                saveSource:action.payload,
                mobileNumberUpdateErrors: []
            }
        case BROKER_ACTION_TYPES.SAVE_OKAY_CLOSE:
            return {
                ...state,
                loading: false,
                saveSource:null,
                saveSuccessful: false
            }
        case BROKER_ACTION_TYPES.AGENCY_DETAILS_VALIDATION_FAIL:
            return {
                ...state,
                agencySaveErrors: action.payload.errors
            }
        case BROKER_ACTION_TYPES.AGENCY_DETAILS_SAVE_SUCCESS:
            return {
                ...state,
                agencyDetailsSaved: true,
                agencySaveErrors: []
            }
        case BROKER_ACTION_TYPES.AGENCY_DETAILS_CLOSE:
            return {
                ...state,
                loading: false,
                agencyDetailsSaved: false
            }
        case BROKER_ACTION_TYPES.AGENCY_DETAILS_UPDATED:
            //console.log(action.payload)
            return {
                ...state,
                loading: false,
                agency: action.payload
            }
        default:
            return state
    }
}
  