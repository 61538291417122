export const DRIVER_LICENCE = 'DRIVER_LICENCE'
export const PASSPORT = 'PASSPORT'
export const BIRTH_CERTIFICATE = 'BIRTH_CERTIFICATE'
export const MEDICARE = 'MEDICARE'
export const BANK_ACCOUNT_STMT = 'BANK_ACCOUNT_STMT'
export const CREDIT_CARD_STMT = 'CREDIT_CARD_STMT'
export const HOME_LOAN_STMT = 'HOME_LOAN_ACC_STMT'
export const PERSONAL_LOAN_STMT = 'PERSONAL_LOAN_ACC_STMT'
export const PAYSLIPS = 'PAYSLIP'
export const NOA = 'NOA'
export const PERSONAL_TAX_RETURNS = 'PERSONAL_TAX_RETURNS'
export const BAS  = 'BAS'
export const RENTAL_INCOME_STMT = 'RENTAL_INCOME_STMT'
export const SHARES_ACC_STMT = 'SHARES_ACC_STMT'
export const COMPANY_TAX_RETURNS = 'COMPANY_TAX_RETURNS'
export const PERSONAL_CAR_LOAN_ACC_STMT = 'PERSONAL_CAR_LOAN_ACC_STMT'
export const COUNCIL_RATES_NOTICES = 'COUNCIL_RATES_NOTICES'
export const TRUST_DEED = 'TRUST_DEED'
export const RENTAL_LEASE_AGREEMENT = 'RENTAL_LEASE_AGREEMENT'

export const DRIVER_LICENCE_COPY_TEXT = 'Driver licence copy'
export const PASSPORT_COPY_TEXT = 'Passport copy'
export const BIRTH_CERTIFICATE_TEXT = 'Birth certificate copy'
export const MEDICARE_TEXT = 'Medicare copy'
export const BANK_AC_3_MNTHS_STMT_TEXT = 'Last 3 months bank account statements'
export const CREDIT_CARD_3_MNTHS_STMT_TEXT = 'Last 3 months credit card statements'
export const HOME_LOAN_6_MNTHS_STMT_TEXT = 'Last 6 months home loan statements'
export const PERSONAL_LOAN_3_MNTHS_STMT_TEXT = 'Last 3 months personal loan statements'
export const PAYSLIPS_TEXT = 'Latest 3 payslips'
export const NOA_TEXT = 'Last 2 Notice of Assessments'
export const TAX_RETURNS_TEXT = 'Individual tax returns for 2 financial years'
export const BAS_TEXT = 'BAS for current financial year'
export const RENTAL_INC_STMT_TEXT = 'Latest rental income statements'
export const SHARES_ACC_STMT_TEXT = 'Latest shares account statements'
export const CO_TAX_RET_TEXT = 'Company Tax Returns for past 2 financial years'
export const PERSONAL_CAR_LOAN_STMT_TEXT = 'Latest personl or car loan account statements'
export const COUNCIL_RATES_TEXT = 'Latest council rates notice for all owned properties'
export const TRUST_DEED_TEXT = 'Copy of Trust deed (if propery will be owned bu a family trust)'
export const LEASE_AGREEMENT_TEXT = 'Lease agreement (if currently renting)'

export const OTHER_DOCS = 'other_docs'
export const ID_DOCS = 'id_proof'
export const BANK_DOCS = 'bank_statement'
export const INC_PROOF = 'inc_proof'

export const SUMMARY_VIEW = 'summary'
export const MESSAGES_VIEW = 'messages'
export const DOCUMENTS_VIEW = 'documents'
export const REQUIREMENTS_VIEW = 'requirements'
export const ASSETS_AND_LIABILITIES_VIEW = 'assets_and_liabilities'
export const EXPENSES_VIEW = 'expenses'
export const LOAN_PACKAGE_VIEW = 'loan_package'
export const REQUIRED_DOCUMENTS_VIEW = 'required_documents'
export const AMORTIZATION_VIEW = 'amortization'
export const LOAN_PROPOSALS_VIEW = 'loan_proposals'

export const NEW_SALE = 'new_sale'
