import React, { Component } from "react";
import { connect } from 'react-redux';
import { addMultiStepFormListeners } from './Multistepform';

class LoadMultiStepFormScript extends Component {


    componentDidMount( ) {
        addMultiStepFormListeners();
    }

    render () {
    	return (
        <></>
        )
    }
}

export default connect()(LoadMultiStepFormScript);