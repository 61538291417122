export const MESSAGE_ACTION_TYPES = {
    FETCH_LOADING: 'messageFetchLoading',
    FETCH_SUCCESS: 'messageFetchSuccess',
    FETCH_FAILURE: 'messageFetchFailure'
}

export default function getActionType(success) {
    const type = success? MESSAGE_ACTION_TYPES.FETCH_SUCCESS : MESSAGE_ACTION_TYPES.FETCH_ERROR
    return type
}

export const fetchLoading = () => {
    return {
        type: MESSAGE_ACTION_TYPES.FETCH_LOADING
    }
}

export const fetchSuccess = messagesPost => {
    return {
        type: MESSAGE_ACTION_TYPES.FETCH_SUCCESS,
        payload: messagesPost
    }
}

export const fetchFailure = error => {
    return {
        type: MESSAGE_ACTION_TYPES.FETCH_FAILURE,
        payload: error
    }
}