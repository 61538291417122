import React from 'react';

function MembersTab() {
    return ( 
            <div class="tab-pane" role="tabpanel" id="tab-1">
                <div class="card shadow border-start-success py-2">
                    <div class="card-body">
                        <div class="table-responsive d-flex">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Username</th>
                                        <th>Full name</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>julia.roberts@bestbroker.com.au</td>
                                        <td>Ms Julia Roberts</td>
                                        <td>
                                            <div class="dropdown"><button class="btn btn-primary dropdown-toggle" aria-expanded="false" data-bs-toggle="dropdown" type="button">Select status</button>
                                                <div class="dropdown-menu"><a class="dropdown-item" href="#">Active</a><a class="dropdown-item" href="#">Inactive</a><a class="dropdown-item" href="#">On hold</a></div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td><i class="fas fa-plus-circle"></i></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
     );
}

export default MembersTab;