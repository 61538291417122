import moment from 'moment';

export function sortByTimestamp(prospectsList){
const prospectsListCopy = [...prospectsList]
prospectsListCopy.sort(function(x, y){
    return x.createdTs - y.createdTs;
})
return prospectsListCopy
}

export function getReadableTs(prospectObject) {
    const givenTs = moment(prospectObject.createdTs)
    
    if(givenTs == moment()) {
        givenTs.utcOffset(10)
        return givenTs.format("hh:mm A")
    }
    else {
        givenTs.utcOffset(10)
        return givenTs.format("DD/MM") 
    }
}


// sort the list based on epoch
// get top 3 slice
// for each in top 3: timestamp format
// if today == day => time in
