import store from "../store"
import axios from "axios";

export const toQueryString = (params) => {
    return (
        "?" +
        Object.entries(params)
        .map(
            ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&")
    );
}

export async function getActivities(page, pagesize) {
    var qParams = toQueryString(Object.assign({
        "page" : page,
        "pagesize" : pagesize
    }))

    const response = await axios.get(process.env.REACT_APP_API_baseuri + '/activities' + qParams, 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getBorrower(borrowerId) {
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/borrowers/` + borrowerId, 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    return response
}

export async function getProspectForBrokerAndBorrower(borrowerId) {
    var qParams = toQueryString(Object.assign({
        "operationType" : "MatchBrokerAndBorrower",
        "borrowerId" : borrowerId
    }))
    const response = await axios.get(process.env.REACT_APP_API_baseuri + `/prospects/` + qParams, 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
            //console.log(response)
    return response
}