import _ from "./actionType";
import { Auth } from 'aws-amplify';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { scope } from './settings';
import { getBrokerAgent, getPasswordReset, patchConfirmPassword } from '../request'
const uri = process.env.REACT_APP_API_baseuri;
const phoneUtil = PhoneNumberUtil.getInstance();

const passwordPattern = [
  '^.{8,}$', // min 8 chars
  '(?=.*[0-9])', // number required
  '(?=.*[a-z])', // lower case letter
  '(?=.*[A-Z])', // uppercase letter
  '(?=.*[-!@#%&/,><\’:;|_~`])' // atleast one special character required
];

export const toQueryString = (params) => {
    return (
        "?" +
        Object.entries(params)
        .map(
            ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&")
    );
}

export const showLoginPage = (authorizationUrl, responseType, clientId, redirectUri, oauthState) =>  {
  window.open(
      authorizationUrl 
      + toQueryString(Object.assign({
        "response_type": responseType,
        "client_id": clientId,
        "redirect_uri": redirectUri,
        "state": oauthState,
        "scope": scope,
      })) , 
      "_self");
}

export const authenticate = (accessCode) => async (dispatch) => {
  dispatch({
    type: _.authenticate,
    payload: {
      token: accessCode,
    },
  })
  dispatch(getBrokerAgent())
      .then(response => {
          dispatch({
            type: _.login.success,
            payload: {
              user: response?.data,
              token: accessCode,
            },
          });
      })
      .catch(error => {
        dispatch({
            type: _.login.fail,
            payload: { errorMessage: error },
          });

      })
}

export const confirmPasswordReset = (email,code,password, passwordRepeat, 
  onSuccess, onError) => async (dispatch) => {
  const conditions = passwordPattern.map(rule => new RegExp(rule, 'g'));
  var errors = [
      {type:"password", 
        valid:password != '' && password != null && conditions.map(condition => condition.test(password)).every((result) => {return result;}),
        errorMessage : 'Password must be minimum 8 characters and having atleast one upper case letter, one lower case letter a number and a special character',
      },
      {type:"passwordRepeat", 
        valid:passwordRepeat!= null && passwordRepeat!= '' && password == passwordRepeat, 
        errorMessage: password == passwordRepeat ? '' : 'Passwords do not match'}
  ]
  .filter((element) => {return !element?.valid;});
  //console.log(errors)
  if (errors.length > 0) {
    onError(true)
    dispatch({
      type: _.resetPassword.validation_fail,
      payload: {status: 'reset_password_validation_failed',errors:errors},
    });
  } else
    patchConfirmPassword(email,code,password)
      .then(response => {
          if (response?.success == true) {
            onSuccess()
            dispatch({
              type: _.resetPassword.success
            })
          } else if (response?.success == false) {
            onError()
            dispatch({
              type: _.resetPassword.fail,
              payload: {status: 'reset_password_failed',displayMessage: 'Failed password reset for ' + email },
            })
          } else {
            onError()
            dispatch({
              type: _.resetPassword.fail,
              payload: {status: 'reset_password_failed', statusText : response?.statusText,errorMessage: 'Error occured during password reset operation' },
            })
          }
            ;
      })
      .catch(error => {
        onError()
        dispatch({
            type: _.resetPassword.fail,
            payload: { status: 'reset_password_failed',errorMessage: error },
          });
      })
}

export const submitPasswordReset = (email, onSuccess, onError) => async (dispatch) => {
  getPasswordReset(email)
      .then(response => {
          if (response?.success == true) {
            onSuccess()
            dispatch({
              type: _.resetPassword.pending,
              payload: email
            })
          } else if (response?.success == false) {
            onError()
            dispatch({
              type: _.resetPassword.error,
              payload: {status: 'incorrect_broker_email',displayMessage: 'No account found for ' + email },
            })
          } else {
            onError()
            dispatch({
              type: _.resetPassword.error,
              payload: {status: response?.status, statusText : response?.statusText,errorMessage: 'Error occured during password reset operation' },
            })
          }
            ;
      })
      .catch(error => {
        dispatch({
            type: _.resetPassword.error,
            payload: { errorMessage: error },
          });

      })
}

export const submitRegistration = (title, firstName, lastName, mobile, email, password, passwordRepeat) => async (dispatch) => {
    // This pattern matcher validates email address formats
	var regexEmail = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
    // This pattern matcher validates all phone numbers must be AU standard
	var regexMobile = /^04[0-9]{8}$/;
    // This pattern matcher validates per AWS Cognito setup

  const conditions = passwordPattern.map(rule => new RegExp(rule, 'g'));
  var errors = [
      {type:"title", valid:title != '' && title != null, },
      {type:"firstName", valid:firstName != '' && firstName != null},
      {type:"lastName", valid:lastName != '' && lastName != null},
      {type:"mobile", 
        valid:mobile != null && regexMobile.test(mobile),
        errorMessage : 'Enter a valid mobile phone number',
      },
      {type:"email", 
        valid:email != null && regexEmail.test(email),
        errorMessage : 'Enter a valid email address',
       },
      {type:"password", 
        valid:password != '' && password != null && conditions.map(condition => condition.test(password)).every((result) => {return result;}),
        errorMessage : 'Password must be minimum 8 characters and having atleast one upper case letter, one lower case letter a number and a special character',
      },
      {type:"passwordRepeat", 
        valid:passwordRepeat!= null && passwordRepeat!= '' && password == passwordRepeat, 
        errorMessage: password == passwordRepeat ? '' : 'Passwords do not match'}
  ]
  .filter((element) => {return !element?.valid;});
  if (errors.length > 0)
    dispatch({
      type: _.register.validation_fail,
      payload: {errors:errors},
    });
  else {
    var username = email;
    const number = phoneUtil.parseAndKeepRawInput(mobile, 'AU');
    var phone_number = phoneUtil.format(number, PhoneNumberFormat.E164);
    var given_name = firstName;
    var family_name = lastName;
    var gender = title == 'Mr' ? 'm' : 'f';
    await Auth.signUp({
      username,
      password,
      attributes: {
        email,          
        phone_number,   
        given_name, 
        family_name, 
        gender ,
        "custom:title" : title
      }
    }).then((CognitoUser) => {
      dispatch({
        type: _.register.signup,
        payload: {
          username: CognitoUser?.user?.username,
        },
      });
    }).catch((error) => {
      //console.log(error);
      if (new RegExp("UsernameExistsException","g","i").test(error)) {
        dispatch({
          type: _.register.signup_error,
          payload: {errors:[{type:"email", valid:false, errorMessage : 'Email ' + email + ' already in use'}]},
        });
      }
    });

  }
}

export const submitVerificationCode = (username, code) => async (dispatch) => {
  //console.log('Verify ' + code + " for " + username);
  await Auth.confirmSignUp(username, code)
    .then(() => {
      dispatch({
        type: _.register.verify_code,
        payload: {},
      });
    }).catch((error) => {
        //console.log(error);
        dispatch({
          type: _.register.verification_fail,
          payload: {errors:[{type:"registrationCode", valid:false, errorMessage : 'Verification failed for user ' + username}]},
        });
    });
}

export const confirmChangePassword = (email, oldPassword, password, passwordRepeat) => async (dispatch) => {
  
  const conditions = passwordPattern.map(rule => new RegExp(rule, 'g'));
  var errors = [
      {type:"password", 
        valid:password != '' && password != null && conditions.map(condition => condition.test(password)).every((result) => {return result;}),
        errorMessage : 'Password must be minimum 8 characters and having atleast one upper case letter, one lower case letter a number and a special character',
      },
      {type:"passwordRepeat", 
        valid:passwordRepeat!= null && passwordRepeat!= '' && password == passwordRepeat, 
        errorMessage: password == passwordRepeat ? '' : 'Passwords do not match'}
  ]
  .filter((element) => {return !element?.valid;});
  if (errors.length > 0)
    dispatch({
      type: _.changePassword.validation_fail,
      payload: {errors:errors},
    });
  else {
    const user = await Auth.signIn(email, oldPassword)
    .catch((error) => {
      //console.log("catch working: ", error);
      dispatch(
        {
          type: _.changePassword.validation_fail,
          payload: {
                    errors: [{
                      type:"currentPassword", 
                      errorMessage: "Current password is invalid"
                    }]
                  },
        } 
      )
    })
    await Auth.changePassword(
      user, 
      oldPassword, 
      password
      )
      .then((CognitoUser) => {
        //console.log(CognitoUser);
        //console.log(CognitoUser?.user?.username);
        dispatch({
          type: _.changePassword.submit,
          payload: {
            username: CognitoUser?.user?.username,
          },
        });
      })
        
  }

}


export const actions = { toQueryString, authenticate, showLoginPage, submitRegistration, submitVerificationCode, confirmChangePassword };