import React, {Component} from 'react';
import {connect} from 'react-redux';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { countDisplay, buttonClass, fetchFirstHomeCount, fetchProspects, toggleProspectDisplay, searchTypeFromFilter } from "../../../store/sales/actions";

const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);

const _filter = 4

class FirstHomeButton extends Component {
    constructor(props) {
        super(props)
    }

	componentDidMount() {
		this.props.fetchCount()
	}

    render() {
        return (
        <>
        <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'First home buyers'})} >
        <div class="text-center d-flex flex-column justify-content-center align-items-center py-3">
            <div class={ this.props.buttonClass(this.props.prospectsFilter === _filter) }
                onClick={() => {
                        this.props.fetchCount()
                        this.props.fetchProspects(1, this.props.prospectsPageSize,searchTypeFromFilter[_filter])
                        this.props.toggleProspectDisplay(_filter)
                    }}>

                <i class="fas fa-home"></i></div>
                <div class="px-3">
                <h4 class="fw-bold mb-0">{this.props.countDisplay(this.props.firstHomeCount)}</h4>
                </div>
        </div>
        </OverlayTrigger>
        </>
        )
    }
}


const mapStateToProps = state => {
    return {
        ...state.sales,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        countDisplay: (count) => countDisplay(count),
        buttonClass: (dark) => buttonClass(dark),
        fetchCount: () => dispatch(fetchFirstHomeCount()),
        fetchProspects: (page, pagesize, searchType) => dispatch(fetchProspects(page, pagesize, searchType)),
        toggleProspectDisplay: (filter) => dispatch(toggleProspectDisplay(filter)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FirstHomeButton);