import React, { Component } from 'react';
import { connect } from 'react-redux';


class BillingNonSub extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        return ( 
        <>
        <div class="container-fluid">
            <section class="py-4 py-xl-5">
                <div class="container">
                    <div class="bg-light border rounded border-0 border-light d-flex flex-column justify-content-between flex-lg-row p-4 p-md-5">
                        <div class="pb-2 pb-lg-1">
                            <h2 class="fw-bold mb-2">You have no billing history</h2>
                            <p class="mb-0"></p>
                        </div>
                        <div class="my-2"></div>
                    </div>
                </div>
            </section>
        </div>
        </>);
    }
}

export default connect (
    null, null
)(BillingNonSub)