import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { hideModal } from '../../../../store/brokerAgent/actions';


function SaveSuccessfulModal(props) {
    const dispatch = useDispatch()
    const showModal = useSelector(state => state.brokerAgentProfile?.saveSuccessful)
    return (
        <>
        <Modal.Dialog show={showModal ? "true" : "false"}>
            <Modal.Header>
            </Modal.Header>
            <Modal.Body>
                <p>{props.message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    onClick={() => {
                            dispatch(hideModal())
                        }} 
                        >Okay</Button>
            </Modal.Footer>
        </Modal.Dialog>
        </>
     );
}

export default SaveSuccessfulModal;