export const CHATS_ACTION_TYPES = {
    GET_UNREAD_CHATS_METADATA: 'getUnreadChatMessagesMetadata',
    GET_UNREAD_CHATS: 'getUnreadChatMessages',
    GET_CHATS: 'getChatMessages',
    GET_BORROWERS_WITH_CHATS: 'getBorrowersWithChatMessages',
    GET_BORROWER_DETAILS: 'getBorrowerDetails',
    GET_BORROWER_CHATS: 'getBorrowerChatMessages',
    SET_BORROWER_IN_VIEW: 'setBorrowerInView',
    POST_CHAT_MESSAGE: 'postChatMessage',
    ERROR: 'error',
}