import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import ModalLoanProposal from './ModalLoanProposal';
import SalesProspects from './Prospects';
import SalesInProgress from './ActiveSales/SalesInProgess';

const Index = (props) => {
  const [tab,setTab] = useState(1)
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search)  
  return (
    <>
      < ModalLoanProposal />

      <div class="card">
        <div class="card-body">
          <div>
              <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item" role="presentation">
                    <a class="nav-link active" role="tab" data-bs-toggle="tab" href="#sales-prospects">Prospective customers</a></li>
                  <li class="nav-item" role="presentation">
                    <a class="nav-link" role="tab" data-bs-toggle="tab" href="#sales-proposals">Loan proposals in progress</a></li>
              </ul>
              <div class="tab-content">
                  <div id="sales-prospects" class="tab-pane fade show active" role="tabpanel">
                      < SalesProspects />
                  </div>
                  <div id="sales-proposals" class="tab-pane fade" role="tabpanel">
                      < SalesInProgress />
                  </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;