import React, { useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal } from 'react-bootstrap';
import { submitPasswordReset, confirmPasswordReset } from '../../../store/auth/actions';

import ErrorModal from '../../common/ErrorModal';
import Loader from '../../common/Loader';

const ForgotPasswordModal = () => {
    const [showResetModal, setShowResetModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState(false)
    const [complete, setComplete] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState();
    const [code, setCode] = useState();
    const [password, setPassword] = useState('');
    const [passwordRepeat, setPasswordRepeat] = useState('');
    const { resetPasswordError,passwordError,passwordRepeatError } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const onStart = () => {
        setShowResetModal(false)
        setShowPasswordModal(true)
        setLoading(false)
    }
    const onStartError = () => {
        setShowResetModal(false)
        setLoading(false)
    }
    const onSuccess = () => {
        setShowPasswordModal(false)
        setLoading(false)
        setComplete(true)
    }
    const onFailure = (repeat) => {
        setShowPasswordModal(repeat)
        setLoading(false)
    }
    return (
        <div>
            <Button hidden={!showResetModal}
                    className="btn btn-primary d-block w-100"
                    onClick={() => {
                        setShowResetModal(true)
                    }}
                    >Forgot password</Button>

            {loading ? <Loader /> : null}

            <Modal show={showResetModal} 
                onHide={() => {setShowResetModal(false)}}>
                <Modal.Header closeButton>
                <Modal.Title>Forgot your password?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Enter your Email below and we will send a message to reset your password</p>
                    <input class="form-control" type="email" name="resetPassword" placeholder="Email" inputmode="email" autocomplete="on" autofocus 
                        value={email} onChange={(e) => {setEmail(e.target.value)}}  />
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" 
                    disabled={!email}
                    onClick={() => {
                                setLoading(true)
                                dispatch(submitPasswordReset(
                                    email, onStart, onStartError));
                            }} >Submit</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showPasswordModal} 
                onHide={() => {setShowPasswordModal(false) }}>
                <Modal.Header>
                <Modal.Title>Set new password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="mb-3">
                    <input className={'form-control'} type="code" id={'resetPasswordForm_code'} 
                        name={'resetPasswordForm_code'} 
                        placeholder={'Enter verification code'}  
                        value={code} 
                        onChange={(e) => setCode(e.target.value)} />
                    </div>
                {
                    passwordError ? 
                    ( 
                        <div class="mb-3">
                            <div className={'alert alert-danger regionError'} role="alert" >
                            <input className={'form-control borderError'} type="password" 
                                id={'resetPasswordForm_password'} 
                                name={'resetPasswordForm_password'} 
                                placeholder={'Enter a safe Password1!'}  
                                value={password} 
                                onChange={(e) => setPassword(e.target.value)} />
                            <span><strong>{passwordError.errorMessage}</strong><br/></span></div>
                        </div>
                    ) : (
                        <div class="mb-3">
                        <input className={'form-control'} type="password" id={'resetPasswordForm_password'} 
                            name={'resetPasswordForm_password'} 
                            placeholder={'Set a new Password1!'}  
                            value={password} 
                            onChange={(e) => setPassword(e.target.value)} />
                        </div>
                    )
                }
                {
                    passwordRepeatError ? 
                    ( 
                        <div class="mb-3">
                            <div className={'alert alert-danger regionError'} role="alert" >
                            <input className={'form-control borderError'} 
                                type="password" 
                                id={'resetPasswordForm_passwordRepeat'} 
                                name={'resetPasswordForm_password'} 
                                placeholder={'Confirm password'}  
                                value={passwordRepeat} 
                                onChange={(e) => setPasswordRepeat(e.target.value)} />
                            <span><strong>{passwordRepeatError.errorMessage}
                                </strong><br/></span></div>
                        </div>
                    ) : (
                        <div class="mb-3">
                        <input className={'form-control'} type="password" 
                            id={'resetPasswordForm_passwordRepeat'} 
                            name={'resetPasswordForm_password'} 
                            placeholder={'Confirm password'}  
                            value={passwordRepeat} 
                            onChange={(e) => setPasswordRepeat(e.target.value)} />
                        </div>
                    )
                }
                </Modal.Body>
                <Modal.Footer>
                <Button variant="primary" 
                    disabled={!email}
                    onClick={() => {
                                setLoading(true)
                                setShowPasswordModal(false)
                                dispatch(confirmPasswordReset(
                                    email, code, password, passwordRepeat, 
                                    onSuccess, onFailure));
                            }} >
                    Submit
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={complete} 
                onHide={() => {window.open("/","_self")}}>
                <Modal.Header closeButton>
                <Modal.Title>Great!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Password updated!</p>
                </Modal.Body>
            </Modal>

            { 
            resetPasswordError?.status == undefined ? null : 
            <ErrorModal error={resetPasswordError} /> }
        </div>
    );
}


export default ForgotPasswordModal;