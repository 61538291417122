import React, { useState, useRef, Component } from 'react';
import { connect } from "react-redux";

import '../../../styles/App.css';
import PaymentResult from "./PaymentResult";
import BillingSubscriber from "./billingSubscriber";
import BillingNonSub from "./billingNonSub";
import CloseButton from '../../common/CloseButton';
import { fetchPaymentReference } from '../../../store/billing/actions';

class Billing extends Component {

	constructor(props) {
        super(props);
    }

    componentDidMount() {
        if (this.props.showPaymentResult) {
            this.props.fetchPaymentReference(this.props.uniqueReference, this.props.accessCode);
        }
    }

    render () {
        return ( 
        <> 
        (
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col">
                            <h5 class="mb-0">Billing</h5>
                        </div>
                        <div class="col">
                            <CloseButton />
                        </div>
                    </div>
                </div>
                <div class="card-body">
                  <div>
                    {this.props.showPaymentResult ? 
                    <PaymentResult  /> : 
                    this.props?.subscription ?
                    <section class="py-4 py-xl-5">
                        < BillingSubscriber />
                    </section> :
                    < BillingNonSub />
                    }
                  </div>
                </div>
            </div>
        </div>
        )
        </>
     );    
    }
}

const mapStateToProps = state => {
    return {
        ...state.billingReducer,
        subscription : state.brokerAgentProfile.subscription,
    }
}

export default connect(mapStateToProps, { fetchPaymentReference })(Billing);