import types from './actionType';
import { getLenders, getProducts, getProductsMetadata, 
  getAllProspects, getProspectsForSearchType,
  getAllProspectsMetadata, getProspectsMetadataForSearchType,
  getAmortization, postProposal, patchProposal, 
  getProposalsMetadata, getProposals, 
  getProspectById, getProduct, getProposal, getBorrowerProposal } from "../request";

  
export const getProposalAmortizationLineChartData = (amortization, loanPackage, lenderProducts) => {
    const chartLabels = amortization?.milestoneYears ? amortization.milestoneYears : []
    const conversion =  (balances) => {
        return balances ? balances.map(b => {
            return b.value
        }) : []
    }
    //console.log(loanPackage)
    //console.log(lenderProducts)
    //console.log(amortization)
    //console.log(Object.keys(amortization))
    const keys = Object.keys(amortization).filter(k => k.startsWith('loanproducts-'))
    //console.log(keys)
    const graphs = keys ?  keys.map(k => {
      const amortizationData = amortization[k]
      const loanProposal = loanPackage.find(lp => lp.loanProduct._id == amortizationData.loanProductId)
      const loanProduct = loanProposal?.loanProduct ? loanProposal.loanProduct : loanProposal
      return loanProduct && lenderProducts[loanProduct._id]?.features?.loanInterestCategory === 'Interest Only' ? {} : { 
        label: lenderProducts[loanProduct._id]?.productName + ' at ' + amortizationData.interestRate + '% for $' + amortizationData.principalAmount.value ,
        data: conversion(amortizationData.reducingBalances), 
        borderColor: "#" + (Math.random() * 0xFFFFFF << 0).toString(16),
        backgroundColor: "#" + (Math.random() * 0xFFFFFF << 0).toString(16), 
      } 
    }) : []
    const data = { labels : chartLabels, datasets: [...graphs], }
    return data
}

export const exit = () => {
  return {
    type: types.close
  }
}

export const searchTypeFromFilter = { 1:6,2:4,3:3,4:2,5:8 }

export const buttonClass = (dark) => {
		return dark ?
            'bs-icon-md bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg': 
            'bs-icon-md bs-icon-circle bs-icon-primary-light d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg'
}

export const	countDisplay = (count) => {
		return count <=10 ? count : 
			count <= 100 ? (Math.floor(count / 10) * Math.min((count / 1),10)*1) + '+' :
			count <= 1000 ? (Math.floor(count / 100) * Math.min((count / 10),10)*10) + '+' :
			count <= 10000 ? (Math.floor(count / 1000) * Math.min((count / 100),10)*100) + '+' :
			count <= 100000 ? (Math.floor(count / 10000) * Math.min((count / 1000),10)*1000) + '+' :
			count <= 1000000 ? (Math.floor(count / 100000) * Math.min((count / 10000),10)*10000) + '+' : 
			count <= 10000000 ? (Math.floor(count / 1000000) * Math.min((count / 100000),10)*100000) + '+' : 
			count;
}

export const toggleProspectDisplay = (display) => {
  if (display)
    switch (display) {
      case 1 :
        return {
          type: types.setProspectDisplay.selfEmployed
        }
      case 2 :
        return {
          type: types.setProspectDisplay.refinance
        }
      case 3 :
        return {
          type: types.setProspectDisplay.investor
        }
      case 4 :
        return {
          type: types.setProspectDisplay.firstHome
        }
      case 5 :
        return {
          type: types.setProspectDisplay.noProposals
        }
      }
  else
        return {
          type: types.setProspectDisplay.all
        }
  }

export const fetchBorrowerProposal = (borrowerId) => async (dispatch) => {
    getBorrowerProposal(borrowerId)
        .then(res => {
          //console.log(res)
            if (res != null && res.data)
                dispatch({
                    type: types.fetchBorrowerProposal.success,
                    payload: { data: res.data },
                });
            else 
                dispatch({
                    type: types.fetchBorrowerProposal.fail,
                });
        })
        .catch((error) => {
            dispatch({
                type: types.fetchBorrowerProposal.fail,
                payload: { message: 'Failed to fetch proposal ' + borrowerId },
            });
            return null;
        });
};

export const fetchProposal = (proposalId) => async (dispatch) => {
    getProposal(proposalId)
        .then(res => {
            if (res != null && res.data)
                dispatch({
                    type: types.loadProposal.success,
                    payload: { data: res.data },
                });
            else 
                dispatch({
                    type: types.loadProposal.fail,
                });
        })
        .catch((error) => {
            dispatch({
                type: types.loadProposal.fail,
                payload: { message: 'Failed to fetch proposal ' + proposalId },
            });
            return null;
        });
};

export const updateProposal = (proposalId, agentId, loanRequestId, prospectId, lenderId, selectedLoanTermInYrs, loanProducts, requiredDocs,productName, interestRate, onSuccess, params) => async (dispatch) => {
  patchProposal(proposalId, agentId, loanRequestId, prospectId, lenderId, selectedLoanTermInYrs, loanProducts, requiredDocs,productName, interestRate)  
        .then(res => {
              dispatch({
                type: types.postProposal.success
              });
              //onSuccess(params)
        })
        .catch((error) => {
            dispatch({
                  type: types.postProposal.fail,
                payload: { message: 'Failed to update proposal' },
            });
        });
}

export const createProposal = (agentId, loanRequestId, prospectId, lenderId, selectedLoanTermInYrs, loanProducts, requiredDocs,productName, interestRate, brokerDiscounts, onSuccess) => async (dispatch) => {
  postProposal(agentId, loanRequestId, prospectId, lenderId, selectedLoanTermInYrs, loanProducts, requiredDocs, productName, interestRate, brokerDiscounts)  
        .then(res => {
            if (res?.success) {
              if (onSuccess)
                onSuccess()
              dispatch({
                type: types.postProposal.success
              });
            } else
                dispatch({
                  type: types.postProposal.fail,
                    payload: { message: 'Failed to add proposal' },
                });
        })
        .catch((error) => {
            dispatch({
                  type: types.postProposal.fail,
                payload: { message: 'Failed to add chat message' },
            });
        });
}

export const fetchEstimatedRepayment = (scenarioIdx, loanAmount, loanTermInYrs, interestRate) => async (dispatch) => {
    getAmortization(loanAmount, loanTermInYrs, interestRate)
        .then(res => {
            if (res != null && res.data)
                dispatch({
                    type: types.fetchAmortization.success,
                    payload: { data: res.data, scenarioIdx: scenarioIdx },
                });
            else 
                dispatch({
                    type: types.fetchAmortization.fail,
                });
        })
        .catch((error) => {
            dispatch({
                type: types.fetchAmortization.fail,
                payload: { message: 'Failed to fetch amortization table' },
            });
            return null;
        });
};

export const fetchAmortizationTable = (index, loanProductId, loanAmount, loanTermInYrs, interestRate, editMode) => async (dispatch) => {
  getAmortization(loanAmount , loanTermInYrs, interestRate)
        .then(res => {
            if (res != null && res.data)
                dispatch({
                    type: types.runAmortization.success,
                    payload: { data: res.data, index : index, 
                      loanProductId: loanProductId, interestRate: interestRate, 
                      editMode: editMode},
                });
            else 
                dispatch({
                    type: types.runAmortization.fail,
                });
        })
        .catch((error) => {
            dispatch({
                type: types.runAmortization.fail,
                payload: { message: 'Failed to fetch amortization table' },
            });
            return null;
        });
};

export const redrawAmortisation = (amortization, availableProducts, loanTermInYears, products,  editMode) => async (dispatch) => {
  if (availableProducts && products)
        products.map(pl => {
            const product = availableProducts[pl.loanProduct._id]
            if (product && product?.features?.loanInterestCategory !== 'Interest Only') {
                const interestRate = pl?.brokersDiscount ? 
                    Math.round((product?.ratesAndFees?.interestRate - pl.brokersDiscount)*100)/100 :
                    product.ratesAndFees.interestRate
                const idx = product._id + '-' + pl.loanAmount.value + '-' + interestRate
                const idxBaseRate = product._id + '-' + pl.loanAmount.value + '-' + product?.ratesAndFees?.interestRate
                const previousAmortisedDuration = 
                  !amortization?.milestoneYears ? 0 :
                      amortization.milestoneYears[amortization.milestoneYears.length-1] - 
                          amortization.milestoneYears[0] 
                if ( !amortization  || 
                    !amortization[idx] || 
                    (amortization[idx] && Number(loanTermInYears) !== Number(previousAmortisedDuration) )) {
                  dispatch(fetchAmortizationTable(idx, product._id, pl.loanAmount.value, loanTermInYears, interestRate, editMode))
                }
                if (pl?.brokersDiscount && !amortization[idxBaseRate] || 
                    (amortization[idxBaseRate] && Number(loanTermInYears) !== Number(previousAmortisedDuration) )) {
                  dispatch(fetchAmortizationTable(idxBaseRate, product._id, pl.loanAmount.value, loanTermInYears, product?.ratesAndFees?.interestRate, editMode))
                }

            }
        })

}

export const fetchFirstHomeCount = () => async (dispatch) => {
  getProspectsMetadataForSearchType(2)
      .then(res => {
          if (res != null && res.data.success)
              dispatch({
                  type: types.fetchProspectBySearchTypeCount.firstHomeSuccess,
                  payload: { data: res.data, },
              });
          else if (res == null || !res.data.success)
              dispatch({
                  type: types.fetchProspectBySearchTypeCount.firstHomeFail,
                  payload: res.data.error,
              });
      })
      .catch((error) => {
          dispatch({
              type: types.fetchProspectBySearchTypeCount.firstHomeFail,
              payload: { message: 'Failed to fetch first home buyer count' },
          });
          return null;
      });
};

export const fetchInvestorCount = () => async (dispatch) => {
  getProspectsMetadataForSearchType(3)
      .then(res => {
          if (res != null && res.data.success)
              dispatch({
                  type: types.fetchProspectBySearchTypeCount.investorSuccess,
                  payload: { data: res.data, },
              });
          else if (res == null || !res.data.success)
              dispatch({
                  type: types.fetchProspectBySearchTypeCount.investorFail,
                  payload: res.data.error,
              });
      })
      .catch((error) => {
          dispatch({
              type: types.fetchProspectBySearchTypeCount.investorFail,
              payload: { message: 'Failed to fetch investor count' },
          });
          return null;
      });
};

export const fetchRefinanceCount = () => async (dispatch) => {
    getProspectsMetadataForSearchType(4)
        .then(res => {
            if (res != null && res.data.success)
                dispatch({
                    type: types.fetchProspectBySearchTypeCount.refinanceSuccess,
                    payload: { data: res.data, },
                });
            else if (res == null || !res.data.success)
                dispatch({
                    type: types.fetchProspectBySearchTypeCount.refinanceFail,
                    payload: res.data.error,
                });
        })
        .catch((error) => {
            dispatch({
                type: types.fetchProspectBySearchTypeCount.refinanceFail,
                payload: { message: 'Failed to fetch refinance count' },
            });
            return null;
        });
};

export const fetchSelfEmployedCount = () => async (dispatch) => {
    getProspectsMetadataForSearchType(6)
        .then(res => {
            if (res != null && res.data.success)
                dispatch({
                    type: types.fetchProspectBySearchTypeCount.selfEmployedSuccess,
                    payload: { data: res.data, },
                });
            else if (res == null || !res.data.success)
                dispatch({
                    type: types.fetchProspectBySearchTypeCount.selfEmployedFail,
                    payload: res.data.error,
                });
        })
        .catch((error) => {
            dispatch({
                type: types.fetchProspectBySearchTypeCount.selfEmployedFail,
                payload: { message: 'Failed to fetch self employed count' },
            });
            return null;
        });
};


export const fetchNoProposalsCount = () => async (dispatch) => {
    getProspectsMetadataForSearchType(8)
        .then(res => {
            if (res != null && res.data.success)
                dispatch({
                    type: types.fetchProspectBySearchTypeCount.noProposalsSuccess,
                    payload: { data: res.data, },
                });
            else if (res == null || !res.data.success)
                dispatch({
                    type: types.fetchProspectBySearchTypeCount.noProposalsFail,
                    payload: res.data.error,
                });
        })
        .catch((error) => {
            dispatch({
                type: types.fetchProspectBySearchTypeCount.noProposalsFail,
                payload: { message: 'Failed to fetch fresh prospect count' },
            });
            return null;
        });
};

export const fetchProposalsCount = (namePart, lenderId) => async (dispatch) => {
    getProposalsMetadata(namePart, lenderId)
        .then(res => {
            if (res != null && res.data.success)
                dispatch({
                    type: types.fetchProposalsCount.success,
                    payload: { data: res.data, },
                });
            else if (res == null || !res.data.success) {
                dispatch({
                    type: types.fetchProposalsCount.fail,
                    payload: res.data.error,
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: types.fetchProposalsCount.fail,
                payload: { message: 'Failed to fetch count of active proposals' },
            });
            return null;
        });
};

export const fetchProposals = (page, pagesize, namePart, lenderId) => async (dispatch) => {
    getProposals(page, pagesize, namePart, lenderId)
        .then(res => {
              if (res != null && res.data.success == null)
                  dispatch({
                      type: types.fetchProposals.success,
                      payload: { data: res.data, page: page, pagesize: pagesize, },
                  });
              else if (res == null || !res.data.success)
                  dispatch({
                      type: types.fetchProposals.fail,
                      payload: res.data.error,
                  });
        })
        .catch((error) => {
            dispatch({
                type: types.fetchProposals.fail,
                payload: { message: 'Failed to fetch active proposals' },
            });
            return null;
        });
};

export const fetchProspectCount = (page, pagesize, prospectFilter) => async (dispatch) => {
    getAllProspectsMetadata()
        .then(res => {
            //console.log('Prospect metadata page #' + page + ' for ' + prospectFilter)
            //console.log(res)
            if (res != null && res.data.success) {
              dispatch({
                    type: types.fetchProspectsCount.success,
                    payload: { data: res.data, },
                });
              dispatch(fetchProspects (page, pagesize, prospectFilter))    
            } else if (res == null || !res.data.success)
                dispatch({
                    type: types.fetchProspectsCount.fail,
                    payload: res.data.error,
                });
        })
        .catch((error) => {
            dispatch({
                type: types.fetchProspectsCount.fail,
                payload: { message: 'Failed to fetch active prospects count' },
            });
            return null;
        });
};

export const fetchProspect = (prospectId, onsuccess) => async (dispatch) => {
    getProspectById(prospectId)
        .then(res => {
            if (res != null && res.data) {
                dispatch({
                    type: types.fetchProspect.success,
                    payload: { data: res.data, },
                });
                if(onsuccess) 
                  onsuccess(res.data) 

            } else if (res == null || !res.data.success)
                dispatch({
                    type: types.fetchProspect.fail,
                    payload: res.data.error,
                });
        })
        .catch((error) => {
            dispatch({
                type: types.fetchProspect.fail,
                payload: { message: 'Failed to fetch prospect ' + prospectId },
            });
            return null;
        });
};

export const fetchProspects = (page, pagesize, prospectFilter) => async (dispatch) => {
    if (prospectFilter) 
      getProspectsForSearchType(page, pagesize, prospectFilter)
          .then(res => {
            //console.log('Prospects page #' + page + ' for ' + prospectFilter)
            //console.log(res)
              if (res != null && res.data.success == null)
                  dispatch({
                      type: types.fetchProspects.success,
                      payload: { data: res.data, page: page, pagesize: pagesize, },
                  });
              else if (res == null || !res.data.success)
                  dispatch({
                      type: types.fetchProspects.fail,
                      payload: res.data.error,
                  });
          })
          .catch((error) => {
              dispatch({
                  type: types.fetchProspects.fail,
                  payload: { message: 'Failed to fetch active prospects' },
              });
              return null;
          });
    else
      getAllProspects(page, pagesize)
          .then(res => {
              if (res != null && res.data.success == null)
                  dispatch({
                      type: types.fetchProspects.success,
                      payload: { data: res.data, page: page, pagesize: pagesize, },
                  });
              else if (res == null || !res.data.success)
                  dispatch({
                      type: types.fetchProspects.fail,
                      payload: res.data.error,
                  });
          })
          .catch((error) => {
              dispatch({
                  type: types.fetchProspects.fail,
                  payload: { message: 'Failed to fetch active prospects' },
              });
              return null;
          });

};

export const toggleDocumentRequired = (documentType) => {
  switch (documentType) {
    case "primaryApplicantDriverLicence" :
      return {
        type: types.setDocumentSelection.primaryApplicantDriverLicence
      }
    case "primaryApplicantPassport" :
      return {
        type: types.setDocumentSelection.primaryApplicantPassport
      }
    case "primaryApplicantMedicare" :
      return {
        type: types.setDocumentSelection.primaryApplicantMedicare
      }
    case "primaryApplicantBirthCertificate" :
      return {
        type: types.setDocumentSelection.primaryApplicantBirthCertificate
      }
    case "secondaryApplicantDriverLicence" :
      return {
        type: types.setDocumentSelection.secondaryApplicantDriverLicence
      }
    case "secondaryApplicantPassport" :
      return {
        type: types.setDocumentSelection.secondaryApplicantPassport
      }
    case "secondaryApplicantMedicare" :
      return {
        type: types.setDocumentSelection.secondaryApplicantMedicare
      }
    case "secondaryApplicantBirthCertificate" :
      return {
        type: types.setDocumentSelection.secondaryApplicantBirthCertificate
      }
    case "primaryApplicantBankStatements" :
      return {
        type: types.setDocumentSelection.primaryApplicantBankStatements
      }
    case "primaryApplicantCardStatements" :
      return {
        type: types.setDocumentSelection.primaryApplicantCardStatements
      }
    case "primaryApplicantHomeLoanStatements" :
      return {
        type: types.setDocumentSelection.primaryApplicantHomeLoanStatements
      }
    case "primaryApplicantPersonalLoanStatements" :
      return {
        type: types.setDocumentSelection.primaryApplicantPersonalLoanStatements
      }
    case "secondaryApplicantBankStatements" :
      return {
        type: types.setDocumentSelection.secondaryApplicantBankStatements
      }
    case "secondaryApplicantCardStatements" :
      return {
        type: types.setDocumentSelection.secondaryApplicantCardStatements
      }
    case "secondaryApplicantHomeLoanStatements" :
      return {
        type: types.setDocumentSelection.secondaryApplicantHomeLoanStatements
      }
    case "secondaryApplicantPersonalLoanStatements" :
      return {
        type: types.setDocumentSelection.secondaryApplicantPersonalLoanStatements
      }
    case "primaryApplicantPayslips" :
      return {
        type: types.setDocumentSelection.primaryApplicantPayslips
      }
    case "primaryApplicantNOA" :
      return {
        type: types.setDocumentSelection.primaryApplicantNOA
      }
    case "primaryApplicantTaxReturns" :
      return {
        type: types.setDocumentSelection.primaryApplicantTaxReturns
      }
    case "primaryApplicantBAS" :
      return {
        type: types.setDocumentSelection.primaryApplicantBAS
      }
    case "secondaryApplicantPayslips" :
      return {
        type: types.setDocumentSelection.secondaryApplicantPayslips
      }
    case "secondaryApplicantNOA" :
      return {
        type: types.setDocumentSelection.secondaryApplicantNOA
      }
    case "secondaryApplicantTaxReturns" :
      return {
        type: types.setDocumentSelection.secondaryApplicantTaxReturns
      }
    case "secondaryApplicantBAS" :
      return {
        type: types.setDocumentSelection.secondaryApplicantBAS
      }
    case "rentalIncStmts" :
      return {
        type: types.setDocumentSelection.rentalIncStmts
      }
    case "companyTaxReturns" :
      return {
        type: types.setDocumentSelection.companyTaxReturns
      }
    case "carAndPersonalLoanStmt" :
      return {
        type: types.setDocumentSelection.carAndPersonalLoanStmt
      }
    case "sharesInvestmentStmts" :
      return {
        type: types.setDocumentSelection.sharesInvestmentStmts
      }
    case "ratesNotice" :
      return {
        type: types.setDocumentSelection.ratesNotice
      }
    case "trustDeed" :
      return {
        type: types.setDocumentSelection.trustDeed
      }
    case "leaseAgreement" :
      return {
        type: types.setDocumentSelection.leaseAgreement
      }
  }
}

export const toggleAllDocs = (toggleType) =>  {
  switch (toggleType) {
    case "selectIdDocs" :
      return {
        type: types.setDocumentSelection.selectIdDocs
      }
    case "deselectIdDocs" :
      return {
        type: types.setDocumentSelection.deselectIdDocs
      }
    case "selectBankStmts" :
      return {
        type: types.setDocumentSelection.selectBankStmts
      }
    case "deselectBankStmts" :
      return {
        type: types.setDocumentSelection.deselectBankStmts
      }
    case "selectIncomeDocs" :
      return {
        type: types.setDocumentSelection.selectIncomeDocs
      }
    case "deselectIncomeDocs" :
      return {
        type: types.setDocumentSelection.deselectIncomeDocs
      }
    case "selectOtherDocs" :
      return {
        type: types.setDocumentSelection.selectOtherDocs
      }
    case "deselectOtherDocs" :
      return {
        type: types.setDocumentSelection.deselectOtherDocs
      }
  }
}

export const applySort = (sort) =>  {
  return {
      type: types.setProductTableFilter.applySort,
      payload: sort,
    };
}

export const applyFilters = (filters) =>  {
  return {
      type: types.setProductTableFilter.applyFilter,
      payload: filters,
    };
}

export const clearErrors = () =>  {
  return {
      type: types.clearErrors,
    };
}

export const fetchProductCount = (lenderName, filters) => async (dispatch) => {
  getProductsMetadata(lenderName, filters)
    .then(res => {
      if (res != null) {
        dispatch({
          type: types.fetchProductCount.success,
          payload: res?.data?.count,
          });
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: types.fetchProductCount.fail,
        payload: error ,
      });
      return null;
    });
};

export const fetchProducts = (lenderName, filters, sort, page, pagesize) => async (dispatch) => {
  getProducts(lenderName, filters, sort, page, pagesize)
    .then(res => {
      if (res != null) {
        dispatch({
          type: types.fetchProducts.success,
          payload: {records: res.data, page: page, filters: filters, sort: sort},
          });
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: types.fetchProducts.fail,
        payload: error ,
      });
      return null;
    });
};

export const storeProduct = (productId) => async (dispatch) => {
  getProduct(productId)
    .then(res => {
      if (res != null && res.data) {
        dispatch({
          type: types.fetchProduct.success,
          payload: res.data,
          });
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: types.fetchProduct.fail,
        payload: error ,
      });
      return null;
    });
};


export const reviewProposal = (proposal) => async (dispatch) => {
  dispatch({
    type: types.renderProposal,
    payload: proposal,
    });
};

export const toggleAddMode = () =>  {
  return {
      type: types.setAddMode,
    };
}


export const removeLoanProduct = (loanProduct) =>  {
  return {
      type: types.setLoanProduct,
      payload: {product: loanProduct, remove:true}
    };
}

export const addLoanProduct = (loanProduct, amount) =>  {
  return {
      type: types.setLoanProduct,
      payload: {product: loanProduct, amount: amount}
    };
}

export const addLoanAmount = (loanAmount) =>  {
  return {
      type: types.setLoanAmount,
      payload: loanAmount
    };
}

export const addBrokersDiscount = (scenarioId, brokersDiscount) =>  {
  return {
      type: types.setBrokersDiscount,
      payload: {_id: scenarioId, brokersDiscount: brokersDiscount}
    };
}
export const chooseLoanTerm = (loanTerm) =>  {
  return {
      type: types.chooseLoanTerm,
      payload: loanTerm
    };
}

export const chooseLender = (lenderOption) =>  {
  return {
      type: types.chooseLender,
      payload: lenderOption
    };
}

export const fetchLenders = () => async (dispatch) => {
  getLenders()
    .then(res => {
      if (res != null) {
        dispatch({
          type: types.getLenders.success,
          payload: res?.data,
          });
        }
    })
    .catch((error) => {
      dispatch({
        type: types.getLenders.fail,
        payload: error ,
      });
    });
};

export const showMultiStep = () => (dispatch) => {
  dispatch({
    type: types.changeModalDisplay,
    payload: "multiStep"
  })
}

export const showEditMultiStep = () => (dispatch) => {
  dispatch({
    type: types.changeModalDisplay,
    payload: "editMultiStep"
  })
}

export const showClient = () => (dispatch) => {
  dispatch({
    type: types.changeModalDisplay,
    payload: "client"
  })
}

export const showProposal = () => (dispatch) => {
  dispatch({
    type: types.changeModalDisplay,
    payload: "proposalView"
  })
}

export const showLanding = () => (dispatch) => {
  dispatch({
    type: types.changeModalDisplay,
    payload: "landingPage"
  })
}

export const editProposal = () => (dispatch) => {
  dispatch({
    type: types.changeModalDisplay,
    payload: 'proposalEdit'
  })
}

export const discardProposalEdits = () => (dispatch) => {
  dispatch({
    type: types.discardUpdatedProposal,
  })
}

export const discardProposal = () => (dispatch) => {
  dispatch({
    type: types.discardProposalInView,
  })
}


export const loanProposalMenuAction = (view) => (dispatch) => {
  dispatch({
    type: types.changeLoanProposalMenu,
    payload: view
  })
}

export default {
  showMultiStep,
  showLanding
};
