import _ from "./actionType";
import { authorizationUrl, clientId, redirectUri, scope, state, responseType } from "./settings";

const initialState = {
  loading: false,
  token: null,
  user: null,
  email:undefined,
  isAuthenticated: false,
  errorMessage: null,
  authorizationUrl: authorizationUrl,
  clientId: clientId,
  redirectUri: redirectUri,
  scope: scope,
  oauthState: state,
  responseType: responseType,
  registrationFormErrors: [],
  username:null,
  awaitingEmailVerification: false,
  failedVerificationCode:false,
  showVerificationSuccess:false,
  showChangePasswordSuccess: false,
  changePasswordErrors: [],
  resetPasswordError: undefined,
  passwordError: undefined,
  passwordRepeatError: undefined,
};

export default function auth(state = initialState, action) {
  //console.log(action)
  switch (action.type) {
    case _.authenticate:
      return {
        ...state,
        ...action.payload,
        loading: true,
        isAuthenticated: false,
      };
    case _.login.pending:
      return {
        ...state,
        loading: true,
        token: null,
        user: null,
        isAuthenticated: false,
      };
    case _.login.fail:
      return {
        ...state,
        loading: false,
        token: null,
        isAuthenticated: false,
        errorMessage: action.payload.errorMessage,
      };
    case _.login.success:
      return {
        ...state,
        loading: false,
        token: action.payload.token,
        user: action.payload.user,
        isAuthenticated: true,
      };
    case _.logout:
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
      };
    case _.register.validation_fail:
    case _.register.signup_error:
      return {
        ...state,
        ...action.payload,
        registrationFormErrors: action.payload.errors,
      };
    case _.register.signup:
      return {
        ...state,
        username:action.payload.username,
        registrationFormErrors: [],
        awaitingEmailVerification: true,
      };
    case _.register.verify_code :
      return {
        ...state,
        awaitingEmailVerification: false,
        showVerificationSuccess: true,
      };
    case _.register.verification_fail :
      return {
        ...state,
        registrationFormErrors: action.payload.errors,
      };
    case _.changePassword.validation_fail :
      return {
        ...state,
        ...action.payload,
        changePasswordErrors: action.payload.errors
      };
    case _.changePassword.submit :
      return {
        ...state,
        ...action.payload,
        changePasswordErrors: [], 
        showChangePasswordSuccess: true
      }
    case _.resetPassword.validation_fail :
      return {
        ...state,
        ...action.payload,
        passwordError: action.payload.errors.find((element) => element?.type == 'password'),
        passwordRepeatError: action.payload.errors.find((element) => element?.type == 'passwordRepeat')
      };
    case _.resetPassword.fail :
      return {
        ...state,
        email: undefined,
        resetPasswordError: action.payload,
        passwordError: undefined,
        passwordRepeatError: undefined,
      };
    case _.resetPassword.error :
      return {
        ...state,
        email: undefined,
        resetPasswordError: action.payload,
        passwordError: undefined,
        passwordRepeatError: undefined,
      };
    case _.resetPassword.pending :
      return {
        ...state,
        email: action.payload,
        resetPasswordError: undefined,
        passwordError: undefined,
        passwordRepeatError: undefined,
      };
    case _.resetPassword.success :
      return {
        ...state,
        email: undefined,
        resetPasswordError: undefined,
        passwordError: undefined,
        passwordRepeatError: undefined
      };
    default:
      return state;
  }
}
