import React from 'react';
import { Link } from "react-router-dom";

function CloseButton(props) {
    return ( 
        <div class="d-flex justify-content-end">
            <Link class="btn btn-primary" to="/" onClick={(e) => {
                return props.onClick == null ? true : props.onClick()
            }} >
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" class="bi bi-x-circle-fill">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                </svg>
            </Link>
        </div>
     );
}

export default CloseButton;