import { CLIENTS_ACTION_TYPES } from "./actionTypes";
import store from "../store"
import {
    getNewConnectionCount, getActiveConnectionCount, getNewConnections, getActiveConnections,
    getClientBorrowingEstimates, getBorrowerDocuments, getDocument, 
    getChatMessages, postChatMessage, patchChatMessage, getProspect
} from "../request";

export const clientMenuAction = (view) => async (dispatch) => {
  dispatch({
    type: CLIENTS_ACTION_TYPES.CHANGE_MENU,
    payload: view
  })
}

export const addProspect = (borrowerId) => async (dispatch) => {
    getProspect(borrowerId)
        .then(res => {
             dispatch({
                    type: CLIENTS_ACTION_TYPES.ADD_PROSPECT_TO_LIST,
                    payload : {data: res.data} 
                });
        })
        .catch((error) => {
            dispatch({
                type: CLIENTS_ACTION_TYPES.CLIENT_BOOK_ERRORS,
                payload: { message: 'Failed to add prospect' },
            });
        });    
}

export const sawBorrowerChatMessage = (textMessageId) => async (dispatch) => {
    patchChatMessage(textMessageId)
        .then(res => {
             dispatch({
                    type: CLIENTS_ACTION_TYPES.SAW_CHAT_MESSAGE,
                });
        })
        .catch((error) => {
            dispatch({
                type: CLIENTS_ACTION_TYPES.CLIENT_BOOK_ERRORS,
                payload: { message: 'Failed to add chat message' },
            });
        });    
}

export const addBorrowerChatMessage = (borrowerId, textMessage) => async (dispatch) => {
    postChatMessage(borrowerId, textMessage)
        .then(res => {
            dispatch(fetchBorrowerChatMessages(borrowerId))
            if (res?.success) 
                dispatch({
                    type: CLIENTS_ACTION_TYPES.POST_CHAT_MESSAGE,
                });
            else
                dispatch({
                    type: CLIENTS_ACTION_TYPES.CLIENT_BOOK_ERRORS,
                    payload: { message: 'Failed to add chat message' },
                });
        })
        .catch((error) => {
            dispatch({
                type: CLIENTS_ACTION_TYPES.CLIENT_BOOK_ERRORS,
                payload: { message: 'Failed to add chat message' },
            });
        });
}

export const fetchBorrowerChatMessages = (borrowerId) => async (dispatch) => {
    getChatMessages(borrowerId)
        .then(res => {
            //console.log(res)
            if (res != null && res.data.success == null) {
                var borrowerMessages = JSON.parse("{\"" + borrowerId + "\":" + JSON.stringify(res.data) + "}")
                dispatch({
                    type: CLIENTS_ACTION_TYPES.GET_CHAT_MESSAGES,
                    payload: {data: borrowerMessages},
                });
            }
            else if (res == null || !res.data.success)
                dispatch({
                    type: CLIENTS_ACTION_TYPES.CLIENT_BOOK_ERRORS,
                    payload: res.data.error,
                });
        })
        .catch((error) => {
            dispatch({
                type: CLIENTS_ACTION_TYPES.CLIENT_BOOK_ERRORS,
                payload: { message: 'Failed to fetch chat messages' },
            });
        });
}

export const downloadDocument = (documentId) => async (dispatch) => {
    getDocument(documentId)
        .then( res => res.blob() )
        .then(blob => {
            //Build a URL from the file
            const fileURL = URL.createObjectURL(blob);
            //Open the URL on new Window
            const pdfWindow = window.open();
            pdfWindow.location.href = fileURL;
        })
        .catch((error) => {
            dispatch({
                type: CLIENTS_ACTION_TYPES.CLIENT_BOOK_ERRORS,
                payload: { message: 'Failed to fetch document ' + documentId },
            });
        });
}

export const setClientInDetailView = (prospect) => async (dispatch) => {
    getClientBorrowingEstimates(
        prospect.loanRequest?.financials?.annualIncome?.value,
        prospect.loanRequest?.financials?.annualIncomeJointApplicant?.value,
        prospect.mainBorrower?.numDependants)
        .then(res => {
            if (res != null && res.data.success == null)
                dispatch({
                    type: CLIENTS_ACTION_TYPES.GET_CLIENT_BORROWING_ESTIMATES,
                    payload: { data: res.data },
                });
            else if (res == null || !res.data.success)
                dispatch({
                    type: CLIENTS_ACTION_TYPES.CLIENT_BOOK_ERRORS,
                    payload: res.data.error,
                });
        })
        .catch((error) => {
            dispatch({
                type: CLIENTS_ACTION_TYPES.CLIENT_BOOK_ERRORS,
                payload: { message: 'Failed to fetch client borrowing estimates' },
            });
        });

    getBorrowerDocuments(prospect.mainBorrower._id)
        .then(res => {
            //console.log(res)
            if (res != null && res.data.success == null)
                dispatch({
                    type: CLIENTS_ACTION_TYPES.GET_BORROWER_DOCUMENTS,
                    payload: { data: res.data },
                });
            else if (res == null || !res.data.success)
                dispatch({
                    type: CLIENTS_ACTION_TYPES.CLIENT_BOOK_ERRORS,
                    payload: res.data.error,
                });
        })
        .catch((error) => {
            //console.log(error)
            dispatch({
                type: CLIENTS_ACTION_TYPES.CLIENT_BOOK_ERRORS,
                payload: { message: 'Failed to fetch documents for ' + prospect.mainBorrower._id },
            });
        });
    dispatch({
        type: CLIENTS_ACTION_TYPES.CLIENT_IN_DETAIL_VIEW,
        payload: prospect
    });
}

export const clearErrors = () => {
    return {
        type: CLIENTS_ACTION_TYPES.CLEAR_CLIENT_BOOK_ERRORS,
    }
}

export const fetchActiveConnections = (page, pagesize, borrowerLookupKey) => async (dispatch) => {
    getActiveConnections(page, pagesize, borrowerLookupKey)
        .then(res => {
            if (res != null && res.data.success == null)
                dispatch({
                    type: CLIENTS_ACTION_TYPES.GET_ACTIVE_CONNECTIONS,
                    payload: { data: res.data, page: page, pagesize: pagesize, activeClientSearch: borrowerLookupKey },
                });
            else if (res == null || !res.data.success)
                dispatch({
                    type: CLIENTS_ACTION_TYPES.CLIENT_BOOK_ERRORS,
                    payload: res.data.error,
                });
        })
        .catch((error) => {
            dispatch({
                type: CLIENTS_ACTION_TYPES.CLIENT_BOOK_ERRORS,
                payload: { message: 'Failed to fetch active connection details' },
            });
            return null;
        });
};

export const fetchNewConnections = (page, pagesize) => async (dispatch) => {
    getNewConnections(page, pagesize)
        .then(res => {
            if (res != null && res.data.success == null)
                dispatch({
                    type: CLIENTS_ACTION_TYPES.GET_NEW_CONNECTIONS,
                    payload: { data: res.data, page: page, pagesize: pagesize },
                });
            else if (res == null || !res.data.success)
                dispatch({
                    type: CLIENTS_ACTION_TYPES.CLIENT_BOOK_ERRORS,
                    payload: res.data.error,
                });
        })
        .catch((error) => {
            dispatch({
                type: CLIENTS_ACTION_TYPES.CLIENT_BOOK_ERRORS,
                payload: { message: 'Failed to fetch new connection details' },
            });
            return null;
        });
};

export const switchBookView = (active) => {
    return {
        type: CLIENTS_ACTION_TYPES.SWITCH_BOOK,
        payload: active
    }
}

export const fetchActiveConnectionCount = () => async (dispatch) => {
    getActiveConnectionCount()
        .then(res => {
            //console.log(res)
            if (res != null) {
                dispatch({
                    type: CLIENTS_ACTION_TYPES.GET_ACTIVE_CONNECTION_COUNT,
                    payload: res.data,
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: CLIENTS_ACTION_TYPES.CLIENT_BOOK_ERRORS,
                payload: error,
            });
            return null;
        });
};

export const fetchNewConnectionCount = () => async (dispatch) => {
    getNewConnectionCount()
        .then(res => {
            if (res != null) {
                dispatch({
                    type: CLIENTS_ACTION_TYPES.GET_NEW_CONNECTION_COUNT,
                    payload: res.data,
                });
            }
        })
        .catch((error) => {
            dispatch({
                type: CLIENTS_ACTION_TYPES.CLIENT_BOOK_ERRORS,
                payload: error,
            });
            return null;
        });
};

export const showInvitePage = () => {
    return {
        type: CLIENTS_ACTION_TYPES.CHANGE_DISPLAY,
        payload: "inviteNewClient"
    }
}

export const showLandingPage = () => {
    return {
        type: CLIENTS_ACTION_TYPES.CHANGE_DISPLAY,
        payload: "landing"
    }
}

export const showProspectsPage = () => {
    return {
        type: CLIENTS_ACTION_TYPES.CHANGE_DISPLAY,
        payload: "prospects"
    }
}

export const showClientsBookPage = () => {
    return {
        type: CLIENTS_ACTION_TYPES.CHANGE_DISPLAY,
        payload: "clientsBook"
    }
}

export const sendInvite = (props) => async (dispatch) => {
    var regexEmail = /^(([^<>()\[\]\\.,;:\s@”]+(\.[^<>()\[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/;
    // This pattern matcher validates all phone numbers must be AU standard
    var regexPhone = /^(04|02|03|07|08)[0-9]{8}$/;

    const name = props?.name
    const phone = props?.phone
    const email = props?.email
    const acknowledge = props?.acknowledge
    const inviteAsEmail = props?.inviteAsEmail
    var errors = [];

    errors.push(
        {
            type: "name",
            valid: name != null && name != '',
            errorMessage: 'Please enter client\'s full name'
        })

    if (inviteAsEmail) {
        errors.push(
            {
                type: "email",
                valid: email != null && regexEmail.test(email),
                errorMessage: 'Enter a valid email address'
            })
    } else {
        errors.push(
            {
                type: "mobile",
                valid: phone != null && regexPhone.test(phone),
                errorMessage: 'Enter a valid mobile phone number'
            })
    }
    errors.push({ type: "acknowledge", valid: acknowledge, })

    errors = errors.filter((element) => { return !element?.valid; });

    if (errors.length > 0) {
        dispatch({
            type: CLIENTS_ACTION_TYPES.INVITE_ERRORS,
            payload: errors
        });
    } else {
        const response = await fetch(process.env.REACT_APP_API_baseuri + '/clientconnections', {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': store.getState().auth.token,
            },
            body: JSON.stringify(
                inviteAsEmail ?
                    { "email": props.email, "personName": props.name } :
                    { "mobileNumber": props.phone, "personName": props.name })
        })
            .then(response => {
                if (response.status >= 400 && response.status < 600) {
                    dispatch({
                        type: CLIENTS_ACTION_TYPES.SHOW_RESULT_MODAL,
                        payload: { ...response, success: false, errorMessage: 'We are currently experiencing issues. Sorry for the inconvenience.' }
                    });
                } else {
                    return response.json()
                }
            }).catch((error) => {
                dispatch({
                    type: CLIENTS_ACTION_TYPES.SHOW_RESULT_MODAL,
                    payload: { ...error, success: false, errorMessage: 'We are currently experiencing issues. Sorry for the inconvenience.' }
                });
            });

        if (response != null && response.success)
            dispatch({
                type: CLIENTS_ACTION_TYPES.SHOW_RESULT_MODAL,
                payload: { success: true, responseMessage: 'Invitation sent to ' + name + '. You will be notified on further updates.' }
            });
        else
            dispatch({
                type: CLIENTS_ACTION_TYPES.SHOW_RESULT_MODAL,
                payload: { success: false, errorMessage: 'We are currently experiencing issues. Sorry for the inconvenience.' }
            });
    }
}

export const hideResultModal = () => {
    return {
        type: CLIENTS_ACTION_TYPES.HIDE_RESULT_MODAL,
    }
}