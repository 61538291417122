import React, { Component } from 'react';
import { connect } from "react-redux";
import Pagination from 'react-bootstrap/Pagination';
import { fetchProspects, searchTypeFromFilter } from "../../../store/sales/actions";

function findSearchTypeFromFilter(filter) {
    return searchTypeFromFilter[filter]
}

function loadPage(props) {
    props.fetchProspects(props.page, props.recordsPerPage, findSearchTypeFromFilter(props.prospectsFilter));
}

class ProspectsPageSelector extends Component {
	constructor(props) {
        super(props);
    }

    render () {
        //console.log(this.props)
        var hooks = { ...this.props };
        const startIdx = this.props.totalNumRecords == 0 ? 0 : (this.props.displayPage-1)*this.props.recordsPerPage + 1;
        const endIdx = startIdx == 0 && this.props.totalNumRecords == 0 ? 0 : startIdx - 1 + (this.props.records == null ? 0 :  this.props.records.length);
        const showing = this.props.displayPage;
        const maxPages = this.props.recordsPerPage > this.props.totalNumRecords ? 1 : Math.ceil(this.props.totalNumRecords/this.props.recordsPerPage);

        var links = [];
        for (var i = this.props.displayPage - 1;  i <= maxPages && links.length < 3;i++)
            if (i > 0)
                links.push(i)
        return (
            <div class="hstack gap-4 d-lg-flex align-items-lg-start">
                <span>Showing records <strong>{startIdx}</strong> to <strong>{endIdx}</strong> of <strong>{this.props.totalNumRecords}</strong></span>
                <Pagination>
                    <Pagination.First  
                        onClick={() => {
                            loadPage({...hooks,page:1})
                        }} ></Pagination.First >
                    {
                        links.map(function(page){
                                return (<Pagination.Item active={showing === page}
                                            onClick={() => {
                                                loadPage({...hooks,page:page})}} >{page}</Pagination.Item>
                                );
                            })
                    }
                    <Pagination.Last 
                        onClick={() => {
                            loadPage({...hooks,page:maxPages})
                        }} ></Pagination.Last>

                </Pagination>
            </div>
      );
  }
}

const mapStateToProps = ({ sales }) => {
  return { 
        recordsPerPage: sales.prospectsPageSize,
        totalNumRecords: sales.prospectsCount,
        displayPage: sales.prospectsDisplayPage,
        records: sales.prospects,
        prospectsFilter: sales.prospectsFilter,
    };
};

const mapDispatchToProps = (dispatch)=> {
    return {
        fetchProspects: (page, pagesize, prospectsFilter) => dispatch(fetchProspects(page, pagesize, prospectsFilter)),
    };
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(ProspectsPageSelector);