import React, {Component} from 'react';
import {connect} from 'react-redux';
import classNames from  'classnames';
import CurrencyFormat from "react-currency-format";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { redrawAmortisation } from "../../../../store/sales/actions";


const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);

class LoanPackage extends Component {
    constructor(props) {
        super(props)
    }


    redrawAmortization() {
        this.props.redrawAmortisation(this.props.amortizationTable, this.props.lenderProducts, 
            this.props.loanTerm , this.props?.loanPackage)        
    }

    componentDidUpdate() {
        this.redrawAmortization()
    }

    render() {
        //console.log(this.props)
        const availableLoanProducts = this.props.lenderProducts
        const amortization  = this.props.amortizationTable
        const totalLoanAmount = this.props?.loanPackage && availableLoanProducts ? this.props.loanPackage.reduce((prev,s) => {
                                            return prev + s.loanAmount.value
                                        },0) : 0
        const totalInstalmentAmount = this.props?.loanPackage && availableLoanProducts  ? this.props.loanPackage.reduce((prev,s) => {
                                            const product =   availableLoanProducts[s.loanProduct._id]
                                            const loanKey = s.loanProduct._id + '-' + s.loanAmount.value
                                            const instalmentAmount = 
                                                product?.features?.loanInterestCategory === 'Interest Only' ? (s.loanAmount.value * product.ratesAndFees.interestRate/1200) :
                                                amortization && amortization[loanKey] ? amortization[loanKey].instalmentAmount.value : 0

                                            return prev + instalmentAmount
                                        },0) : 0
        const includeOfferedRate = this.props?.loanPackage && this.props.loanPackage.find(s => {
            return s?.brokersDiscount && s?.brokersDiscount  > 0 ? true : false
        })
        const includeVariation = this.props?.loanPackage && this.props.loanPackage.find(s => {
            const product =   availableLoanProducts[s.loanProduct._id]
            return product?.variation && product?.variation !== null ? true : false
        })
        return (
        <>
        <div class="vstack">
            <div class="pb-2 pb-lg-1">
                <OverlayTrigger placement="top-start" overlay={renderTooltip({locationtext:'Proposed package of loan products'})} >
                <h4 class="fw-bold d-xxl-flex justify-content-xxl-start mb-2">Loan package</h4>
                </OverlayTrigger>
            </div>
            <div class="table-responsive" >
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Lender</th>
                            <th>Product name</th>
                            <th><span>Loan term</span></th>
                            <th><span>Loan category</span></th>
                            {includeVariation ? <th><span>Variation</span></th> : null}
                            <th><span>Interest rate</span></th>
                            <th>Comparison rate</th>
                            {includeOfferedRate ?  <th><span>Discounted interest rate</span></th> : null}
                            <th>Loan amount</th>
                            <th>Estimated repayments</th>
                        </tr>
                    </thead>
                    <tbody>
                        {!this.props?.loanPackage 
                            || !availableLoanProducts || Number(Object.values(availableLoanProducts).length) === Number(0) 
                            ? null :
                            this.props.loanPackage.map(s => {
                                const product =   availableLoanProducts[s.loanProduct._id]
                                const loanKey = s.loanProduct._id + '-' + s.loanAmount.value
                                const instalmentAmount = 
                                    product?.features?.loanInterestCategory === 'Interest Only' ? (s.loanAmount.value * product.ratesAndFees.interestRate/1200) :
                                    amortization && amortization[loanKey] ? amortization[loanKey].instalmentAmount.value : null
                                const variation = product?.variation && product.variation.length > 50 ? product.variation.substring(0,50) + '...' : product?.variation

                                return product ? (
                                    <tr key={product._id}>
                                        <td><strong>{product?.lender.name}</strong></td>
                                        <td>{product?.productName}</td>
                                        <td>{this.props.loanTerm} Yrs</td>
                                        <td>{product?.features?.loanInterestCategory}</td>
                                        {includeVariation ? 
                                            <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:product?.variation})} >
                                                <td>{variation}</td></OverlayTrigger> : null}
                                        <td>{product?.ratesAndFees?.interestRate} %</td>
                                        <td>{product?.ratesAndFees?.comparisonRate} %</td>
                                        {includeOfferedRate ?  <td>{s?.offeredRate} %</td> : null}
                                        <td><CurrencyFormat value={s.loanAmount.value} displayType={'text'} thousandSeparator={true} prefix={'$'}
                                                decimalScale={2} fixedDecimalScale={true}  /></td>
                                        <td>{!instalmentAmount ? null : 
                                            <span><CurrencyFormat value={instalmentAmount} displayType={'text'} thousandSeparator={true} prefix={'$'} 
                                                decimalScale={2} fixedDecimalScale={true} /> pm</span>}</td>
                                    </tr>
                                ) : (<></>) 
                            })
                        }
                    </tbody>
                </table>
                <div class="hstack d-flex d-xxl-flex justify-content-evenly align-items-stretch align-content-stretch align-items-xxl-start" >
                                    <div class="hstack d-flex d-xxl-flex justify-content-evenly align-items-stretch align-content-stretch align-items-xxl-start"><strong>Total loan amount : </strong><CurrencyFormat value={totalLoanAmount} displayType={'text'} thousandSeparator={true} prefix={'$'}
                                    decimalScale={2} fixedDecimalScale={true}  /></div>
                                    <div class="hstack d-flex d-xxl-flex justify-content-evenly align-items-stretch align-content-stretch align-items-xxl-start"><strong>Total estimated monthly instalment : </strong><CurrencyFormat value={totalInstalmentAmount} displayType={'text'} thousandSeparator={true} prefix={'$'}
                                    decimalScale={2} fixedDecimalScale={true}  /></div>
                                </div>
            </div>
        </div>

        </>
        )
    }
}


const mapStateToProps = state => {
    return {
        amortizationTable : state.sales.editModeAmortizationTable,
        lenderProducts : state.sales.selectedLoanProducts,
        loanPackage:state.sales.loanScenarios, 
        loanTerm: state.sales.selectedLoanTermInYrs,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        redrawAmortisation: 
            (amortization, availableProducts, loanTermInYears, products) =>
            dispatch(
                redrawAmortisation(amortization, availableProducts, loanTermInYears, products, true)
            )
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoanPackage);