import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { showClientsBookPage, showInvitePage} from '../../../store/clients/actions';
import { showUpgradeWarning } from '../../../store/upgrades/actions';
import UpgradeWarning from "../../common/UpgradeWarning";


function Landing(props) {
    const dispatch = useDispatch()
    const upgradesEnabled = useSelector(state => state.upgradePage?.upgradesEnabled)
    return ( 
        <div class="container-fluid">
            <div class="card shadow">
                <div class="card-body">
                    <section class="py-4 py-xl-5">
                        <div class="container">
                            <div class="text-center p-4 p-lg-5">
                                <h1 class="fw-bold mb-4">Client book</h1>
                                <p class="fw-bold text-primary mb-2">What would you like to do</p>
                                <div class="hstack gap-1 d-lg-flex justify-content-lg-center">
                                    <button class="btn btn-light fs-5 py-2 px-4" type="button"
                                    onClick={() => dispatch(showInvitePage())}>
                                    Invite new client
                                    </button>
                                    <button class="btn btn-light fs-5 py-2 px-4" type="button"
                                    onClick={() => dispatch(showClientsBookPage())}>
                                        Show my clients
                                    </button>
                                </div>
                                <UpgradeWarning />
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
     );
}

export default Landing;