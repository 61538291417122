export const fetchLenderOptions = {
  success: "fetch/lenders/success",
  failed: "fetch/lender/fail",
};

export const fetchProductsActions = {
  success: "fetch/products/success",
  failed: "fetch/products/fail",
  countsuccess: "fetch/products/countsuccess",
  countfailed: "fetch/products/countfail",
  applyfilters: "fetch/products/filters",
  changepagesize: "fetch/products/pagesize",
  applysortorder: "fetch/products/sortorder",
};


export const productActions = {
  show: "product/show",
  hide: "product/hide",
};

export const saveOptions = {
  saveLender: "save/lender/success",
}

export default { fetchLenderOptions, saveOptions, fetchProductsActions, productActions };