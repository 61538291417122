import axios from "axios";
import store from "../store"

export const toQueryString = (params) => {
    return (
        "?" +
        Object.entries(params)
        .map(
            ([key, value]) =>
            `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
        )
        .join("&")
    );
}

export async function getMessagesResponse() {
    var qParams = Object.assign({"operationType" : "SearchForUnseenMessages"});
    const response = await axios.get(process.env.REACT_APP_API_baseuri + '/messages/metadata' + toQueryString(qParams), 
            { headers: {
                'Authorization': store.getState().auth.token
            }})
    const messageJSON = await response.data
    return messageJSON
}