import React, { Component } from 'react';
import { connect } from "react-redux";
import LenderAutofill from './LenderAutofill';
import ProductFilters from './productFilters';
import ProductsTable from './ProductsTable';
import ProductDetailsModal from "./productDetailsModal";
import CloseButton from '../closeButton';
import { fetchLenders } from "../../../../store/loanProducts/actions";

class LoanProducts extends Component {
	constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchLenderOptions()
    }

    render () {
        return ( 
        <> 
        (
        <div class="container-fluid">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                        <div class="col">
                            <h5 class="mb-0">Loan products</h5>
                        </div>
                        <div class="col">
                            <CloseButton />
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col">
                            <div class="vstack gap-2 d-xxl-flex align-items-xxl-start">
                                <div class="card flex-grow-1 align-self-stretch borderNone">
                                    <div class="card-body">
                                        <div class="vstack gap-2">
                                            <div class="row">
                                                <div class="col">
                                                    <div class="vstack gap-2">
                                                        <div class="hstack gap-4 d-xl-flex align-items-xl-start justify-content-around">
                                                            <div class="col4">
                                                                < LenderAutofill />
                                                            </div>
                                                            <div class="col">
                                                                {this.props.selectedLender == null ? null : < ProductFilters /> }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.props.selectedLender == null ? null : 
                                            <div class="row">
                                                <div class="col">
                                                    <div class="card">
                                                        <div class="card-body">
                                                            < ProductsTable />
                                                            <ProductDetailsModal />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
        </>
     );    
    }
}

const mapStateToProps = ({ loanProducts }) => {
  return { 
        selectedLender : loanProducts.selectedLender
    };
};

const mapDispatchToProps = (dispatch)=> {
    return {
        fetchLenderOptions: () => dispatch(fetchLenders()),
    };
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(LoanProducts);