import React, {Component} from 'react';
import {connect} from 'react-redux';
import HiddenText from "../../../common/HiddenText";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";



const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);

class SubmissionDocuments extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        //console.log(this.props)
        return (
        <>

        {this.props?.requiredDocuments && this.props?.requiredDocuments.length === 0 ? null :

        <div class="vstack">
            <div class="pb-2 pb-lg-1">
                <OverlayTrigger placement="top-start" overlay={renderTooltip({locationtext:'Documents required from borrower for further processing'})} >
                <h4 class="fw-bold d-xxl-flex justify-content-xxl-start mb-2">Submission documents</h4>
                </OverlayTrigger>
            </div>
            <div class="table-responsive" style={{overflow: "visible"}}>
                <table class="table table-striped">
                    <tbody>
                        <tr>
                            <td>
                                <ul class="list-group">
                                    {this.props?.requiredDocuments && this.props.requiredDocuments.map(d => {
                                        return (
                                            <li class="list-group-item"><span>{d.description}</span></li>
                                        )
                                    })}
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        }
        </>
        )
    }
}

const mapStateToProps = state => {
    return {
        prospectInView : state.clients.prospectInView,
        proposalInView : state.sales.proposalInView,
		accessRoles : state.brokerAgentProfile.accessRoles,
        ...state.editDocuments
    }
}

const mapDispatchToProps = dispatch => {
    return {
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SubmissionDocuments);