import React, { Component } from 'react';
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { switchBorrowerInView, fetchBorrowersWithChats, fetchBorrowerDetails, fetchBorrowerChatMessages, 
    addBorrowerChatMessage,  } from '../../../store/chats/actions';
import { sawBorrowerChatMessage } from "../../../store/clients/actions";
import classNames from  'classnames';
import moment from 'moment';


function getChatTime(timestamp) {
    const givenTs = moment(timestamp)
    return givenTs.isBefore(moment().startOf('year')) ? givenTs.format("DD/MM/YYYY") : givenTs.isBefore(moment().startOf('day')) ? givenTs.format("DD/MM"): givenTs.format("hh:mm A")
}

function ClientsButton() {
    const navigate = useNavigate();
    return (
        <>
            <div><a class="btn btn-primary fs-5 py-2 px-4" role="button" 
                onClick={() => navigate("/clients",{replace:true})}
            ><i class="far fa-user"></i></a></div>
        </>
    );
}

function BorrowerListTab(props) {

    return (
        <>
            <ul class="nav nav-tabs flex-column">
            {
                !props.borrowers ? null : 
                props.borrowers.map(borrower => {
                    var messages = props.borrowerMessages[borrower]
                    var unseen = !messages ? 0 : messages.filter(e => e.status ==='unseen' && e.sender === 'borrower').length

                    return (
                        <li class="nav-item">
                            <div class="hstack gap-2">
                            <a class="nav-link" 
                                onClick={e => {
                                    e.preventDefault()
                                    props.switchBorrowerInView(borrower)
                                }} >{props?.borrowerDetails[borrower]?.firstName}</a>
                                {unseen == 0 ? null : <span class="badge bg-primary">{unseen}</span>}
                            </div>
                        </li>
                    )}
                )}
            </ul>
        </>
    );
}
const INIT = {textMessage : ''}
class BorrowerChats extends Component {

    constructor(props) {
        super(props);
        this.state = INIT
    }

    componentDidMount() {
        this.props.fetchBorrowersWithChats()
        this.messagePoller = setInterval(() => {
                    if (this.props?.borrowerInView)
                            this.props.fetchBorrowerChatMessages(this.props?.borrowerInView)
                }, 60000)        
    }

    componentWillUnmount() {
        clearInterval(this.messagePoller)
    }

    componentDidUpdate() {
        if (this.props.borrowers)
            this.props.borrowers.forEach(borrower => {
                if (!this.props.borrowerDetails[borrower])
                    this.props.fetchBorrowerDetails(borrower)

                if (!this.props.borrowerMessages[borrower]) {
                    this.props.fetchBorrowerChatMessages(borrower)
               }
            });
       
    }
    
    render () {
    //console.log(this.props?.borrowerDetails[this.props?.borrowerInView])
    return (
      <>
        <div class="container">
            <div class="card shadow mb-4">
                <div class="card-header py-3">
                    <h6 class="text-primary fw-bold m-0">Chat messages</h6>
                </div>
                <div class="row">
                    <div class="col d-md-flex justify-content-md-start">
                        <BorrowerListTab {...this.props} />
                    </div>
                    <div class="col-10">

                        {!this.props?.borrowerInView ? 

                        <div class="bg-light border rounded border-0 border-light d-flex flex-column justify-content-between flex-lg-row p-4 p-md-5">
                            <div class="pb-2 pb-lg-1">
                                <h2 class="fw-bold mb-2">No unread chat messages to show</h2>
                                <div class="vstack gap-2">
                                    <p class="mb-0">You can send messages from the clients details section</p>
                                    <ClientsButton />
                                </div>
                            </div>
                        </div>
                        : 
                        <div class="card">
                            <div class="card-body">
                                <div class="vstack" style={{margin:'5%'}}>
                                    <div class="input-group">
                                        <input class="form-control" type="text" value={this.state.textMessage} onChange={(e) => {this.setState({textMessage : e.target.value})}} />
                                        <button class="btn btn-light" type="button" 
                                            onClick={() => {
                                                this.props.addBorrowerChatMessage(this.props?.borrowerInView, this.state.textMessage)
                                                this.setState(INIT)
                                            }}>Send</button>
                                    </div>
                                </div>

                                {!this.props?.borrowerMessages[this.props?.borrowerInView] ? null : 
                                <div class="hstack gap-2" style={{"max-width": "95%","overflow": "auto", "min-height": "300px"}}>
                                {
                                    this.props?.borrowerMessages[this.props?.borrowerInView].map(
                                        message => {
                                            if (message.sender == 'borrower' && message.status == 'unseen') {
                                                setTimeout(() => {
                                                    this.props.sawBorrowerChatMessage(message._id)
                                                }, 60000)
                                            }

                                            var tileClass = classNames({
                                                'alert alert-primary d-xxl-flex align-self-start justify-content-xxl-end': message.sender == 'agent',
                                                'alert alert-dark align-self-end': message.sender == 'borrower' && message.status == 'seen',
                                                'alert alert-success align-self-end': message.sender == 'borrower' && message.status == 'unseen',
                                                }); 
                                            var blockClass = classNames({
                                                'vstack d-xl-flex flex-grow-0 justify-content-xl-start align-items-xl-start': message.sender == 'agent',
                                                'vstack d-xl-flex flex-grow-0 justify-content-xl-end align-items-xl-end': message.sender == 'borrower',
                                            });
                                            var nameLabel = message.sender === 'agent' ? 'Me' : 
                                                this.props?.borrowerDetails[this.props?.borrowerInView] ? 
                                                this.props.borrowerDetails[this.props.borrowerInView]?.firstName : null
                                            return (
                                            <div class={blockClass} 
                                                style={message.sender == 'agent' ? { marginRight:"1%", alignSelf:"end"} : { marginRight:"1%", }}>
                                                <div class="vstack">
                                                    <div class={tileClass} role="alert" style={{minWidth:"100px",}}>
                                                        <div class="vstack">
                                                            <span><strong>{nameLabel}</strong> {message.text}</span>
                                                            <div class="vstack d-xxl-flex flex-row-reverse justify-content-between">
                                                                <span style={{marginLeft:"5%",fontSize : "10px"}}>{getChatTime(message.createdTs)}</span>
                                                                {message?.status == 'unseen' ? 
                                                                <svg class="bi bi-broadcast" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                                                    <path d="M3.05 3.05a7 7 0 0 0 0 9.9.5.5 0 0 1-.707.707 8 8 0 0 1 0-11.314.5.5 0 0 1 .707.707zm2.122 2.122a4 4 0 0 0 0 5.656.5.5 0 1 1-.708.708 5 5 0 0 1 0-7.072.5.5 0 0 1 .708.708zm5.656-.708a.5.5 0 0 1 .708 0 5 5 0 0 1 0 7.072.5.5 0 1 1-.708-.708 4 4 0 0 0 0-5.656.5.5 0 0 1 0-.708zm2.122-2.12a.5.5 0 0 1 .707 0 8 8 0 0 1 0 11.313.5.5 0 0 1-.707-.707 7 7 0 0 0 0-9.9.5.5 0 0 1 0-.707zM10 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"></path>
                                                                </svg> :
                                                                <svg class="bi bi-eye-fill" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                                                    <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z"></path>
                                                                    <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z"></path>
                                                                </svg> }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            );
                                        }
                                    )
                                }
                                </div>
                                }
                            </div>
                        </div>
                        
                        }

                        </div>
                </div>
            </div>
        </div>
      </>
  );
}
}


const mapStateToProps = ({ chats }) => {
  return { 
      ...chats
    };
};

const mapDispatchToProps = (dispatch)=> {
    return {
        fetchBorrowerDetails: (borrowerId) => dispatch(fetchBorrowerDetails(borrowerId)),
        fetchBorrowerChatMessages: (borrowerId) => dispatch(fetchBorrowerChatMessages(borrowerId)),
        fetchBorrowersWithChats: () => dispatch(fetchBorrowersWithChats()),
        switchBorrowerInView: (borrowerId) => dispatch(switchBorrowerInView(borrowerId)),
        addBorrowerChatMessage : (borrowerId, textMessage) => { dispatch(addBorrowerChatMessage(borrowerId, textMessage))},
        sawBorrowerChatMessage: (textMessageId) => { dispatch(sawBorrowerChatMessage(textMessageId)) }
    };
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(BorrowerChats);