import _ from './actionType';
import * as constants from '../constants';

export const initialState = { 
  error: null,
  requiredDocumentTypes: [],
  requiredDocuments : [],

  selectIdDocs: false,
  deselectIdDocs: false,
  selectBankStmts: false,
  deselectBankStmts: false,
  selectIncomeDocs: false,
  deselectIncomeDocs: false,
  selectOtherDocs: false,
  deselectOtherDocs: false,
}

export default function editDocuments(state = initialState, action) {

  switch (action.type) {
    case _.loadAllDocumentTypes.success:
        return {
          ...state,
          requiredDocumentTypes : action.payload,
        }
    case _.loadAllDocumentTypes.failure:
        return {
          ...state,
          error : action.payload,
        }
    case _.setupFromProspect:
      //console.log(action)

      return {
          ...state,
          requiredDocuments : action.payload?.requiredDocs,
        }

    case _.setDocumentSelection.resetRequiredDocs:
        return {
            ...state,
            requiredDocuments : [] ,
            selectIdDocs: false,
            deselectIdDocs: false,
            selectBankStmts: false,
            deselectBankStmts: false,
            selectIncomeDocs: false,
            deselectIncomeDocs: false,
            selectOtherDocs: false,
            deselectOtherDocs: false,
        }

    case _.setDocumentSelection.addRemoveDocument:
      var requiredDocuments = action.payload?.toggleOnOff ? 
        state.requiredDocuments.concat(state.requiredDocumentTypes.filter(d => d._id === action.payload?.documentType?._id)) :
        state.requiredDocuments.filter(d => d._id !== action.payload?.documentType?._id)
        return {
            ...state,
            requiredDocuments : requiredDocuments ,
        }

    case _.setDocumentSelection.selectIdDocs:
      var selectOn = !state.selectIdDocs
      var requiredDocuments = state.requiredDocuments.filter(d => d.type !== constants.ID_DOCS )
      requiredDocuments = selectOn ? 
        requiredDocuments.concat(state.requiredDocumentTypes.filter(d => d.type === constants.ID_DOCS)) :
        requiredDocuments
        return {
            ...state,
            selectIdDocs: !state.selectIdDocs,
            deselectIdDocs: false,
            requiredDocuments : requiredDocuments,
        }
    case _.setDocumentSelection.deselectIdDocs:
        return {
            ...state,
            selectIdDocs: false,
            deselectIdDocs: !state.deselectIdDocs,
            requiredDocuments : !state.deselectIdDocs ? 
              state.requiredDocuments.filter(d => d.type !== constants.ID_DOCS ) : 
              state.requiredDocuments,
        }

    case _.setDocumentSelection.selectBankStmts:
      var selectOn = !state.selectBankStmts
      var requiredDocuments = state.requiredDocuments.filter(d => d.type !== constants.BANK_DOCS )
      requiredDocuments = selectOn ? 
        requiredDocuments.concat(state.requiredDocumentTypes.filter(d => d.type === constants.BANK_DOCS)) :
        requiredDocuments
        return {
            ...state,
            selectBankStmts: !state.selectBankStmts,
            deselectBankStmts: false,
            requiredDocuments : requiredDocuments,
        }
    case _.setDocumentSelection.deselectBankStmts:
        return {
            ...state,
            selectBankStmts: false,
            deselectBankStmts: !state.deselectBankStmts,
            requiredDocuments : !state.deselectBankStmts ? 
              state.requiredDocuments.filter(d => d.type !== constants.BANK_DOCS) : 
              state.requiredDocuments,
        }

    case _.setDocumentSelection.selectIncomeDocs:
      var selectOn = !state.selectIncomeDocs
      var requiredDocuments = state.requiredDocuments.filter(d => d.type !== constants.INC_PROOF )
      requiredDocuments = selectOn ? 
        requiredDocuments.concat(state.requiredDocumentTypes.filter(d => d.type === constants.INC_PROOF)) :
        requiredDocuments
        return {
            ...state,
            selectIncomeDocs: !state.selectIncomeDocs,
            deselectIncomeDocs: false,
            requiredDocuments : requiredDocuments,
        }
    case _.setDocumentSelection.deselectIncomeDocs:
        return {
            ...state,
            selectIncomeDocs: false,
            deselectIncomeDocs:  !state.deselectIncomeDocs,
            requiredDocuments : !state.deselectIncomeDocs ? 
              state.requiredDocuments.filter(d => d.type !== constants.INC_PROOF) : 
              state.requiredDocuments,
        }

    case _.setDocumentSelection.selectOtherDocs:
      var selectOn = !state.selectOtherDocs
      var requiredDocuments = state.requiredDocuments.filter(d => d.type !== constants.OTHER_DOCS )
      requiredDocuments = selectOn ? 
        requiredDocuments.concat(state.requiredDocumentTypes.filter(d => d.type === constants.OTHER_DOCS)) :
        requiredDocuments
        return {
            ...state,
            selectOtherDocs: !state.selectOtherDocs,
            deselectOtherDocs: false,
            requiredDocuments : requiredDocuments,
        }
    case _.setDocumentSelection.deselectOtherDocs:
        return {
            ...state,
            selectOtherDocs: false,
            deselectOtherDocs: !state.deselectOtherDocs,
            requiredDocuments : !state.deselectOtherDocs? 
              state.requiredDocuments.filter(d => d.type !== constants.OTHER_DOCS) : 
              state.requiredDocuments,
        }

    default:
      return state;
  }
}
