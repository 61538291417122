import React, { Component } from 'react';
import { connect } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import Upgrade from "./Upgrades/Upgrade";
import Messages from "./messages";
import RecentActivities from "./recentActivities";
import Clients from "./clients";

import { enableUpgrades, fetchSubscriptionPackages } from "../../../store/upgrades/actions";

const renderTooltip = props => (
  <Tooltip {...props}>{props?.locationtext}</Tooltip>
);

function UpgradeSection({visible}) {
    const dispatch = useDispatch();
    const search = useLocation().search;
    const queryParams = new URLSearchParams(search)

    dispatch(fetchSubscriptionPackages())

    // We only show upgrades section for trial accounts
    if (queryParams.get("appEntryMode") && queryParams.get("appEntryMode") === 'TRIAL_ACCOUNT') {
        dispatch(enableUpgrades())
    }
    return (<>
        {visible ? <Upgrade /> : null }
    </>);

}

function OntheCards() {
    return (
    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Upcoming improvements and features'})} >
            <h6 class="text-danger fw-bold m-0">On the cards</h6>
            </OverlayTrigger>
        </div>
        <ul class="list-group list-group-flush">
            <li class="list-group-item">
                <div class="row align-items-center no-gutters">
                    <div class="col me-2">
                        <div class="vstack gap-2">
                            <div class="hstack gap-2"><svg class="bi bi-calendar-event-fill fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M4 .5a.5.5 0 0 0-1 0V1H2a2 2 0 0 0-2 2v1h16V3a2 2 0 0 0-2-2h-1V.5a.5.5 0 0 0-1 0V1H4V.5zM16 14V5H0v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2zm-3.5-7h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5z"></path>
                                </svg>
                                <h6 class="mb-0"><strong>Next major release</strong></h6>
                            </div><span class="d-lg-flex align-items-end justify-content-lg-end">Upcoming release with a whole load of new features like signed customer agreements, document storage..... and more</span>
                        </div>
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="row align-items-center no-gutters">
                    <div class="col me-2">
                        <div class="vstack gap-2">
                            <div class="hstack gap-2"><svg class="bi bi-chat-quote fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z"></path>
                                    <path d="M7.066 6.76A1.665 1.665 0 0 0 4 7.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 0 0 .6.58c1.486-1.54 1.293-3.214.682-4.112zm4 0A1.665 1.665 0 0 0 8 7.668a1.667 1.667 0 0 0 2.561 1.406c-.131.389-.375.804-.777 1.22a.417.417 0 0 0 .6.58c1.486-1.54 1.293-3.214.682-4.112z"></path>
                                </svg>
                                <h6 class="mb-0"><strong>Chat with us</strong></h6>
                            </div><span class="d-lg-flex align-items-end justify-content-lg-end">Easier communication for immediate sales and support enquiries. Our chat module will put you in touch with a real person to assist</span>
                        </div>
                    </div>
                </div>
            </li>
            <li class="list-group-item">
                <div class="row align-items-center no-gutters">
                    <div class="col me-2">
                        <div class="vstack gap-2">
                            <div class="hstack gap-2"><svg class="bi bi-bug fs-3" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                    <path d="M4.355.522a.5.5 0 0 1 .623.333l.291.956A4.979 4.979 0 0 1 8 1c1.007 0 1.946.298 2.731.811l.29-.956a.5.5 0 1 1 .957.29l-.41 1.352A4.985 4.985 0 0 1 13 6h.5a.5.5 0 0 0 .5-.5V5a.5.5 0 0 1 1 0v.5A1.5 1.5 0 0 1 13.5 7H13v1h1.5a.5.5 0 0 1 0 1H13v1h.5a1.5 1.5 0 0 1 1.5 1.5v.5a.5.5 0 1 1-1 0v-.5a.5.5 0 0 0-.5-.5H13a5 5 0 0 1-10 0h-.5a.5.5 0 0 0-.5.5v.5a.5.5 0 1 1-1 0v-.5A1.5 1.5 0 0 1 2.5 10H3V9H1.5a.5.5 0 0 1 0-1H3V7h-.5A1.5 1.5 0 0 1 1 5.5V5a.5.5 0 0 1 1 0v.5a.5.5 0 0 0 .5.5H3c0-1.364.547-2.601 1.432-3.503l-.41-1.352a.5.5 0 0 1 .333-.623zM4 7v4a4 4 0 0 0 3.5 3.97V7H4zm4.5 0v7.97A4 4 0 0 0 12 11V7H8.5zM12 6a3.989 3.989 0 0 0-1.334-2.982A3.983 3.983 0 0 0 8 2a3.983 3.983 0 0 0-2.667 1.018A3.989 3.989 0 0 0 4 6h8z"></path>
                                </svg>
                                <h6 class="mb-0"><strong>Something ain't right</strong></h6>
                            </div><span class="d-lg-flex align-items-end justify-content-lg-end">We take quality seriously and make every effort to maintain a high standard in our products and services. However, if you do find something amiss, our support studio aims to make it easier for our customers to report and follow support cases.</span>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>        
    );
}

function TakeATour() {
    return (
        <>
<section class="py-4 py-xl-5">
    <div class="pb-2 pb-lg-1">
        <h2 class="fw-bold mb-2">We&#39;re here to help</h2>
        <p class="mb-0"><br />If you&#39;re new to LoanSeller, you can use our application tour page to familiarise yourself with the application functionality and get productive in no time at all</p>
        <p class="mb-0"></p>
        <div class="hstack gap-5 d-xxl-flex justify-content-xxl-center align-items-xxl-center">
            <div class="my-2"><a class="btn btn-primary fs-5 py-2 px-4" role="button" href="#" data-bs-toggle="modal" data-bs-target="#modal-tour">Take a tour</a></div>
        </div>
        <p></p>
    </div>
</section>        
        </>
    );
}

class Dashboard extends Component {
	constructor(props) {
        super(props);
    }

    render () {
        return (
        <>
        <div class="container-fluid">
            <div class="row">
                <div class="col">
                    <div class="row">
                        <div class="col-lg-5 col-xl-6 flex-fill">
                            <UpgradeSection visible={this.props.upgradesEnabled}/>
                            <RecentActivities/>
                        </div>
                        <div class="col-6 col-md-6 col-xl-4 flex-fill mb-4">
                            <div class="vstack gap-2">
                                <Clients />
                                <Messages />
                                
                                {/* <Proposals /> <Applications />
                                <Cases /> */}
                            </div>
                        </div>                        
                    </div>
                </div>
                <div class="col-3">
                    <OntheCards />
                </div>                         
            </div>
        </div>
        </>
        );
    }
};

const mapStateToProps = ({ upgradePage }) => {
     const {upgradesEnabled} = upgradePage;
  return { 
        upgradesEnabled 
    };
};

export default connect( mapStateToProps )(Dashboard);