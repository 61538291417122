import React from 'react';
import { connect } from "react-redux";
import classNames from  'classnames';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


const renderTooltip = props => (
    <Tooltip {...props}>{props?.locationtext}</Tooltip>
);

class HiddenText extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
    // Show when unmasked override is set or when not a trial account
    const show = 
        (this.props?.unmasked && this.props?.unmasked === true) 
    return (
        <>
        {show ? 
                <span>{this.props.children}</span> :<OverlayTrigger placement="bottom" overlay={renderTooltip({ locationtext: this.props?.message ? this.props?.message : 'Upgrade for full details' })} >
                        <span>****</span>
                </OverlayTrigger> 
        }
        </>
    )
    }

}

const mapStateToProps = state => {
    return {
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HiddenText);
