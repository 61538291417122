import {  } from "react-bootstrap";
import React, {  } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from 'react-bootstrap';
import { Link } from "react-router-dom";

import BasicPackage from "./BasicPackage";
import TeamsPackage from "./TeamsPackage";
import PayeeDetails from "./PayeeDetails"
import PaymentCardDetails from "./PaymentCardDetails"
import AsicDetails from "./AsicDetails";
import CloseButton from "../../../common/CloseButton";
import SalesGenerator from "./SalesGenerator";
import { fetchPaymentReference } from '../../../../store/upgrades/actions';

const CreateSubscription = (props) => {
    const dispatch = useDispatch()
    const subscriptionPackage = useSelector(state => state.upgradePage?.subscriptionPackage)
    const error = useSelector(state => state.upgradePage?.error)
    const agency = useSelector( state => state?.brokerAgentProfile?.agency)
    const [formStage, setFormStage] = React.useState("asicDetails")
    function formStageCallback(formToDisplay) {
        setFormStage(formToDisplay)
    }
  return (
    <> {error != null ?
    <Modal.Dialog show={error} >
        <Modal.Header>
            <Modal.Title>LoanSeller Broker Platform</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <p><strong>Oh-no!</strong>. We were unable to process the upgrade request. Please try again in a short while. <br/>Sorry for the inconvenience</p>
        </Modal.Body>
        <Modal.Footer>
                <Link class="btn btn-primary" to="/" >Close</Link>
        </Modal.Footer>
    </Modal.Dialog> :
    <div class="card">
        <div class="card-header">
            <div class="row">
            <div class="col">
                <h5 class="mb-0">Lets get started</h5>
            </div>
            <div class="col">
                <CloseButton  />
            </div>
            </div>
        </div>
        <div class="card-body">
            {
                formStage == "paymentCardDetails" ?
                    <div class="alert alert-info" role="alert" style={{"font-size": "14px"}}>
                        <span><strong>Almost done</strong>. Please set up a payment method for subscription payments. At this time we only accept credit cards as a means of payment</span> :
                        </div> : null
            }
            
            <div class="row">
                <div class="col">
                    { formStage == "paymentCardDetails" && (
                        <>
                        <div class="card shadow border-start-success py-2">
                        <div class="card-body">
                        < PaymentCardDetails 
                            prev={() => {formStageCallback("payeeDetails")}}
                         />
                        </div>
                        </div>
                        </>
                    )}
                    { (formStage == "paymentCardDetails" ||  formStage == "asicDetails") && (
                    <>
                        <div class="card shadow border-start-success py-2">
                        <div class="card-body">
                        {
                            formStage == "paymentCardDetails" ? null :
                            <div class="alert alert-info" role="alert" style={{"font-size": "14px"}}>
                            {
                                subscriptionPackage?.packageName === 'Sales generator' ?
                                <div>
                                    <span>Congrats on choosing <strong>Sales generator</strong>. This is our premium service and comes with our cutting edge software platform delivered with a personal touch. To deliver the best value, we would need to discuss your business needs. 
                                    <br/><br/> Our friendly sales team members will get in touch with you soon and have your business generating revenue from <strong>LoanShopper</strong> in no time. 
                                    <br/><br/> Please take a few moments to <strong>update</strong> your agency details for our records.</span>
                                </div> : 
                                agency?.companyDetails?.abn ?
                                <span>To set up a subscription, we will first need to confirm your broker agency details</span> : 
                                <span>To set up a subscription, we will first need your broker agency details</span>
                            }
                            </div>
                        }
                        < AsicDetails 
                            readOnly={formStage == "paymentCardDetails"}
                            onSuccess={() => {
                                formStageCallback(subscriptionPackage.packageName == "Sales generator" ? null : "payeeDetails")
                                window.scrollTo(0, 0)}}/>
                        </div>
                        </div>
                    </>
                    )}
                    { (formStage == "paymentCardDetails" || formStage == "payeeDetails") && (
                        <>
                        <div class="card shadow border-start-success py-2">
                        <div class="card-body">
                        {formStage == "paymentCardDetails" ? null : 
                            <div class="alert alert-info" role="alert"><span><strong>Perfect!!</strong> Now for some billing details</span></div>
                        }
                        < PayeeDetails 
                            prev={() => {formStageCallback("asicDetails")}}
                            readOnly={formStage == "paymentCardDetails"}
                            onSuccess={(uniqueReference) => {
                                dispatch(fetchPaymentReference(uniqueReference))
                                formStageCallback("paymentCardDetails")
                                window.scrollTo(0, 0)}} />
                        </div>
                        </div>
                        </>
                    )}

                </div>
                {
                    subscriptionPackage.name === 'Basic' ? <><BasicPackage hideStartToday={true} readOnly={formStage == "paymentCardDetails"} /></> :
                    subscriptionPackage.name === 'Teams' ? <><TeamsPackage hideStartToday={true} readOnly={formStage == "paymentCardDetails"} /></> : null
                }
            </div>
        </div>
    </div>
    }
    </>
);
};

export default CreateSubscription;
