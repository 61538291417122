import { useSelector } from 'react-redux';
import VerificationForm from './verificationForm';
import LoginCard from './loginCard';
import ResetPasswordForm from './ResetPasswordForm';


function Login (props) {
  const { awaitingEmailVerification, showVerificationSuccess } = useSelector((state) => state.auth);
  //console.log(props)
  return (
    <section class="position-relative py-4 py-xl-5">
      {
        props?.resetPassword ? <ResetPasswordForm {...props} /> :
        showVerificationSuccess  || awaitingEmailVerification ? <VerificationForm /> : 
        <LoginCard />
      }
    </section>
  );
};

export default Login;
