import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { submitVerificationCode, showLoginPage } from '../../../../store/auth/actions';


function PasswordChangeSuccessfulModal(props) {
    const { authorizationUrl, clientId, redirectUri, responseType, oauthState } = useSelector((state) => state.auth);

    return ( 
        <Modal.Dialog show={props.showChangePasswordSuccess}>
            <Modal.Header>
                <Modal.Title>Congrats</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Password changed successfully. Please login with your new password.</p>
            </Modal.Body>
            <Modal.Footer>
                <Button 
                    onClick={() => {
                            showLoginPage(authorizationUrl, responseType, clientId, redirectUri, oauthState);
                        }} 
                        >Login</Button>
            </Modal.Footer>
        </Modal.Dialog>
     );
}

export default PasswordChangeSuccessfulModal;