import types from './actionType';
import { getProduct, getAmortization } from "../../request";


export const discardProposalInSummary = () => {
    return {
      type: types.discardProposal,
    }
}


export const setUp = (proposal) => {
    return {
      type: types.setupFromProposal,
      payload : proposal
    }
}

export const storeProduct = (productId) => async (dispatch) => {
  getProduct(productId)
    .then(res => {
      if (res != null && res.data) {
        dispatch({
          type: types.fetchProduct.success,
          payload: res.data,
          });
      }
      return res;
    })
    .catch((error) => {
      dispatch({
        type: types.fetchProduct.fail,
        payload: error ,
      });
      return null;
    });
};

export const fetchAmortizationTable = (index, loanProductId, loanAmount, loanTermInYrs, interestRate, editMode) => async (dispatch) => {
  getAmortization(loanAmount, loanTermInYrs, interestRate)
        .then(res => {
            if (res != null && res.data)
                dispatch({
                    type: types.runAmortization.success,
                    payload: { data: res.data, index : index, loanProductId: loanProductId, editMode: editMode},
                });
            else 
                dispatch({
                    type: types.runAmortization.fail,
                });
        })
        .catch((error) => {
            dispatch({
                type: types.runAmortization.fail,
                payload: { message: 'Failed to fetch amortization table' },
            });
            return null;
        });
};