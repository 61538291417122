import React, {Component} from 'react';
import {connect} from 'react-redux';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import classNames from  'classnames';
import AddProductsPanel from './AddProductsPanel';
import CloseButton from './CloseButton'
import { showLanding } from "../../../store/sales/actions";

const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);

class ProductsSelection extends Component {
    constructor(props) {
        super(props)
    }

    nextBtnClass(nextEnabled) {
        return classNames({
            'btn btn-primary ml-auto js-btn-next': nextEnabled,
            'btn disabled btn-primary ml-auto js-btn-next': !nextEnabled,
            }); 
    }

    render() {

    return (
    <>
    <div id="add-products" class="multisteps-form__panel shadow p-4 rounded bg-white" data-animation="scaleIn">
        <div id="products-form-content" class="multisteps-form__content">

            <div class="vstack gap-1 d-xxl-flex flex-row flex-grow-1 flex-fill">
                <div class="hstack justify-content-between gap-1 d-xxl-flex flex-row flex-grow-1 flex-fill">
                    <h2 class="fw-bold d-xxl-flex mb-2">Select loan products</h2>
                    <div class="hstack justify-content-between">
                        <div id="products-next-prev-buttons" class="button-row d-flex">
                            <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Previous'})} >
                                <button class="btn btn btn-primary js-btn-prev" type="button" >Prev</button>
                            </OverlayTrigger>
                            <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Next'})} >
                                <button class={this.nextBtnClass(this.props.nextEnabled)} type="button" >Next</button>
                            </OverlayTrigger>
                        </div>
                        <CloseButton />
                    </div> 
                </div>
            </div>
            <AddProductsPanel />

        </div>
    </div>
    </>
        )
    }

}


const mapStateToProps = state => {
    return {
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showLanding: () => dispatch(showLanding()),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProductsSelection);