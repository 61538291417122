import React from 'react';


function WorkplanTab() {
    return ( 
        <div class="tab-pane" role="tabpanel" id="tab-3">

        <div class="card">
            <div class="card shadow flex-fill border-start-success py-2">
                <div class="card-body">
                    <div class="row d-lg-flex justify-content-lg-center">

                    <div class="col-11">
                        <div class="vstack">
                            <div>

                                <div class="vstack gap-2">
                                    <div class="row">
                                        <div class="col-12 maxWidth100">
                                            <div class="hstack gap-1 justify-content-evenly">
                                                <div class="flex-grow-1 align-self-center indigoBox">
                                                    <div class="hstack justify-content-between"><small>Mar</small><small>$1,250,000</small></div>
                                                    <div class="hstack flex-grow-1 justify-content-evenly">
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg secondary"><span class="font10px">12/02</span></div>
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg secondary"><span class="font10px">12/02</span></div>
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg whiteBlueUnderline"><span class="font10px">12/02</span></div>
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1 align-self-center indigoBox">
                                                    <div class="hstack justify-content-between"><small>April</small><small>$2,250,000</small></div>
                                                    <div class="hstack flex-grow-1 justify-content-evenly">
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1 align-self-center indigoBox"><small>May</small>
                                                    <div class="hstack flex-grow-1 justify-content-evenly">
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                                    </div>
                                                </div>
                                                <div class="flex-grow-1 align-self-center indigoBox"><small>Jun</small>
                                                    <div class="hstack flex-grow-1 justify-content-evenly">
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                                        <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3 maxWidth100">
                                            <div>
                                                <div class="progress">
                                                    <div class="progress-bar bg-secondary progress-bar-striped width50percent" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"><span class="visually-hidden">50%</span></div>
                                                </div>
                                                <div class="hstack gap-2"><strong>ANZ</strong><small>Mr Joe Dukakis</small><a href="#"><i class="far fa-list-alt" data-bs-toggle="modal" data-bs-target="#modal-all-tasks"></i></a></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3 maxWidth100">
                                            <div>
                                                <div class="progress">
                                                    <div class="progress-bar bg-primary progress-bar-striped width50percent" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"><span class="visually-hidden">50%</span></div>
                                                </div>
                                                <div class="hstack gap-2"><strong>Westpac</strong><small>Mr Joe Dukakis</small><i class="far fa-list-alt" data-bs-toggle="modal" data-bs-target="#modal-all-tasks"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-1 maxWidth100">
                                            <div>
                                                <div class="progress">
                                                    <div class="progress-bar bg-success progress-bar-striped width100percent" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"><span class="visually-hidden">100%</span></div>
                                                </div>
                                                <div class="hstack gap-2"><strong>CBA</strong><small>Mr Joe Dukakis</small><i class="far fa-list-alt" data-bs-toggle="modal" data-bs-target="#modal-all-tasks"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3 maxWidth100">
                                            <div>
                                                <div class="progress">
                                                    <div class="progress-bar bg-danger progress-bar-striped width50percent" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"><span class="visually-hidden">50%</span></div>
                                                </div>
                                                <div class="hstack gap-2"><strong>ANZ</strong><small>Mr Joe Dukakis</small><i class="far fa-list-alt" data-bs-toggle="modal" data-bs-target="#modal-all-tasks"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 maxWidth100">
                                            <div>
                                                <div class="progress">
                                                    <div class="progress-bar bg-primary progress-bar-striped width25percent" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><span class="visually-hidden">25%</span></div>
                                                </div>
                                                <div class="hstack gap-2"><strong>ANZ</strong><small>Mr Joe Dukakis</small><i class="far fa-list-alt" data-bs-toggle="modal" data-bs-target="#modal-all-tasks"></i></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    </div>
                </div>
            </div>
        </div>

        </div>
     );
}

export default WorkplanTab;