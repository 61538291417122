import { useSelector } from 'react-redux';

const Documents = (props) => {


  return (
      <>
<div class="container">
    <div class="card shadow mb-4">
        <div class="card-header py-3">
            <h6 class="text-primary fw-bold m-0">Borrower Documents</h6>
        </div>
        <div class="row">
            <div class="col d-md-flex justify-content-md-start">
                <ul class="nav nav-tabs flex-column">
                    <li class="nav-item"><a class="nav-link" href="#"><span>Most recent </span><svg class="bi bi-calendar2-date" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M6.445 12.688V7.354h-.633A12.6 12.6 0 0 0 4.5 8.16v.695c.375-.257.969-.62 1.258-.777h.012v4.61h.675zm1.188-1.305c.047.64.594 1.406 1.703 1.406 1.258 0 2-1.066 2-2.871 0-1.934-.781-2.668-1.953-2.668-.926 0-1.797.672-1.797 1.809 0 1.16.824 1.77 1.676 1.77.746 0 1.23-.376 1.383-.79h.027c-.004 1.316-.461 2.164-1.305 2.164-.664 0-1.008-.45-1.05-.82h-.684zm2.953-2.317c0 .696-.559 1.18-1.184 1.18-.601 0-1.144-.383-1.144-1.2 0-.823.582-1.21 1.168-1.21.633 0 1.16.398 1.16 1.23z"></path>
                                <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H2z"></path>
                                <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5V4z"></path>
                            </svg></a></li>
                    <li class="nav-item"><a class="nav-link active" href="#"><span>Aplhabetical order </span><i class="far fa-user" style={{"font-size": "16px"}}></i></a></li>
                </ul>
            </div>
            <div class="col-9">
                <div class="card">
                    <div class="card-header">
                        <div class="hstack flex-grow-1 flex-fill justify-content-between">
                            <div style={{"max-width": "35%"}}>
                                <div class="input-group input-group-sm" style={{"border-radius": "100","border-width": "1px"}}><input class="bg-light form-control border-0 small" type="text" placeholder="Search for name" /><button class="btn btn-primary py-0" type="button"><i class="fas fa-search"></i></button></div>
                            </div><button class="btn btn-primary d-md-flex justify-content-md-end" type="button"><span>Download </span><i class="fas fa-download"></i></button>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="vstack gap-2">
                            <div class="hstack gap-2 d-md-flex flex-grow-1 flex-fill justify-content-between align-items-stretch align-items-md-start">
                              <div class="card text-secondary bg-white">
                                  <div class="card-body"  style={{background: "#536d85",color: "var(--bs-light)"}}>
                                  <ul class="nav nav-tabs flex-column">
                                      <li class="nav-item">
                                          <div class="vstack gap-2 d-md-flex align-items-md-start"><a class="active" href="#">Arnold</a>
                                              <ul class="list-group">
                                                  <li class="list-group-item">
                                                      <div class="form-check"><input id="formCheck-1" class="form-check-input" type="checkbox" /><label class="form-check-label" for="formCheck-1"><span>Driver License - 23/12/2022</span></label></div>
                                                  </li>
                                                  <li class="list-group-item">
                                                      <div class="form-check"><input id="formCheck-1" class="form-check-input" type="checkbox" /><label class="form-check-label" for="formCheck-1"><span>Driver License - 23/12/2022</span></label></div>
                                                  </li>
                                              </ul>
                                          </div>
                                      </li>
                                  </ul>

                                  </div>
                              </div>
                            </div>

                            <nav class="d-md-flex justify-content-md-end">
                                <ul class="pagination">
                                    <li class="page-item"><a class="page-link" href="#">1</a></li>
                                    <li class="page-item"><a class="page-link" href="#">2</a></li>
                                    <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    <li class="page-item"><a class="page-link" href="#">4</a></li>
                                    <li class="page-item"><a class="page-link" href="#">5</a></li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>      
      </>
  );
};

export default Documents;