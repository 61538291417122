import React, { useEffect } from "react";
import { BrowserRouter as Router, } from "react-router-dom";

import Root from "./Root";

function App() {
 useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, []);
  return (
    <Router>
      <Root/>
    </Router>
  );
}

export default App;