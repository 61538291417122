export const CLIENTS_ACTION_TYPES = {
    CHANGE_DISPLAY: 'changeClientDisplayPage',
    INVITE_ERRORS: 'clientInviteInfoErrors',
    SHOW_RESULT_MODAL: 'showResultModal',
    HIDE_RESULT_MODAL: 'hideResultModal',
    CLIENT_BOOK_ERRORS: 'clientBookErrors',
    CLEAR_CLIENT_BOOK_ERRORS: 'clearClientBookErrors',
    GET_NEW_CONNECTION_COUNT: 'getNewConnectionCount',
    GET_ACTIVE_CONNECTION_COUNT: 'getActiveConnectionCount',
    SWITCH_BOOK: 'switchBook',
    GET_NEW_CONNECTIONS: 'getNewConnections',
    GET_ACTIVE_CONNECTIONS: 'getActiveConnections',
    CLIENT_IN_DETAIL_VIEW: 'clientInDetailView',
    GET_CLIENT_BORROWING_ESTIMATES: 'getClientBorrowingEstimates',
    GET_BORROWER_DOCUMENTS: 'getBorrowerDocuments',
    GET_DOCUMENT: 'getDocument',
    GET_CHAT_MESSAGES: 'getChatMessages',
    POST_CHAT_MESSAGE: 'postChatMessage',
    SAW_CHAT_MESSAGE: 'sawChatMessage',
    ADD_PROSPECT_TO_LIST: 'addProspectToList',
    SWITCH_MASKED: 'switchMasked',
    CHANGE_MENU: 'changeMenu',
}
