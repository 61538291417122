import React, { Component, useState, } from 'react';
import { connect } from "react-redux";
import { useDispatch, useSelector } from 'react-redux';

import { showLandingPage, showInvitePage, fetchNewConnectionCount, fetchActiveConnectionCount, switchBookView, fetchNewConnections, 
    fetchActiveConnections, setClientInDetailView, clearErrors, addProspect } from '../../../store/clients/actions';
import classNames from  'classnames';
import moment from 'moment';
import InvitationsPageSelector from "./InvitationsPagination";
import ActiveClientsPanel from "./ActiveClientsPanel";
import ErrorModal from "../../common/ErrorModal";
import ClientDetailsModal from "./ClientDetailsModal";

function ActiveConnectionsButton(props) {
    var buttonClass = classNames({
      'bs-icon-xl bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg': props.showActiveConnections,
      'bs-icon-xl bs-icon-circle bs-icon-primary-light d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg': !props.showActiveConnections,
    });

    return (
        <> 
        <div class={buttonClass} onClick={() => {
            props.switchBookView(!props.showActiveConnections)
            props.fetchActiveConnections(props.connectionsDisplayPage, props.connectionsPageSize, props.activeClientSearch)
            }} >
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="-32 0 512 512" width="1em" height="1em" fill="currentColor">
        <path d="M185.2 356.5c7.7-18.5-1-39.7-19.6-47.4l-29.5-12.2c11.4-4.3 24.3-4.5 36.4.5 12.2 5.1 21.6 14.6 26.7 26.7 5 12.2 5 25.6-.1 37.7-10.5 25.1-39.4 37-64.6 26.5-11.6-4.8-20.4-13.6-25.4-24.2l28.5 11.8c18.6 7.8 39.9-.9 47.6-19.4zM400 32H48C21.5 32 0 53.5 0 80v160.7l116.6 48.1c12-8.2 26.2-12.1 40.7-11.3l55.4-80.2v-1.1c0-48.2 39.3-87.5 87.6-87.5s87.6 39.3 87.6 87.5c0 49.2-40.9 88.7-89.6 87.5l-79 56.3c1.6 38.5-29.1 68.8-65.7 68.8-31.8 0-58.5-22.7-64.5-52.7L0 319.2V432c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-99.7 222.5c-32.2 0-58.4-26.1-58.4-58.3s26.2-58.3 58.4-58.3 58.4 26.2 58.4 58.3-26.2 58.3-58.4 58.3zm.1-14.6c24.2 0 43.9-19.6 43.9-43.8 0-24.2-19.6-43.8-43.9-43.8-24.2 0-43.9 19.6-43.9 43.8 0 24.2 19.7 43.8 43.9 43.8z"></path>
    </svg></div>
        </>
    );
}

function NewConnectionsButton(props) {
    var buttonClass = classNames({
      'bs-icon-xl bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg': props.showNewConnections,
      'bs-icon-xl bs-icon-circle bs-icon-primary-light d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg': !props.showNewConnections,
    });

    return (
        <> 
        <div class={buttonClass} onClick={() => {
            props.switchBookView(props.showNewConnections)
            props.fetchNewConnections(props.invitationsDisplayPage, props.invitationsPageSize)
            }} >
            <i class="icon-user-follow d-xl-flex justify-content-xl-end"></i></div>
        </>
    );
}

function CloseButton(props) {
    const dispatch = useDispatch();
    return (
        <>
            <button class="btn btn-primary" type="button" onClick={ () => dispatch(showLandingPage())}>
                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" class="bi bi-x-circle-fill">
                    <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                </svg>
            </button>
        </>
    );
}

function NoClientsPanel(props) {
    const dispatch = useDispatch();
    return (
        <>
        <section class="py-4 py-xl-5">
            <div class="container">
                <div class="bg-light border rounded border-0 border-light d-flex flex-column justify-content-between flex-lg-row p-4 p-md-5">
                    <div class="pb-2 pb-lg-1">
                        <h2 class="fw-bold mb-2">There are no records available to view</h2>
                        <p class="mb-0">Lets start by sending an invitation to a client</p>
                    </div>
                    <div class="my-2"><a class="btn btn-primary fs-5 py-2 px-4" role="button" onClick={ () => dispatch(showInvitePage())} >Invite now</a></div>
                </div>
            </div>
        </section>        
        </>
    );
}

function NoConnectionsPanel(props) {
    const dispatch = useDispatch();
    return (
        <>
        <section class="py-4 py-xl-5">
            <div class="container">
                <div class="bg-light border rounded border-0 border-light d-flex flex-column justify-content-between flex-lg-row p-4 p-md-5">
                    <div class="pb-2 pb-lg-1">
                        <h2 class="fw-bold mb-2">No records available to view</h2>
                        <p class="mb-0">Lets start by sending an invitation to a client</p>
                    </div>
                    <div class="my-2"><a class="btn btn-primary fs-5 py-2 px-4" role="button" onClick={ () => dispatch(showInvitePage())} >Invite now</a></div>
                </div>
            </div>
        </section>        
        </>
    );
}

function InvitedClientsPanel(props) {
    return (
    <>
        <div class="vstack gap-2">
        <div class="table-responsive">
            <table class="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Last invited</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        props.clientInvitations.map(function(each){
                        return (<tr>
                        <td>{each.personName}</td>
                        <td>{each.email}{each.email != null && each.mobileNumber != null ? <p/> : null}{each.mobileNumber}</td>
                        <td>{moment(each.lastUpdatedTs).format('Do MMMM YYYY')}</td>
                    </tr>);
                    })
                    }
                </tbody>
            </table>
        </div>
        <div class="hstack gap-2 justify-content-end">
            <InvitationsPageSelector />
        </div>
        </div>
    </>        
    );
}

class ClientsBook extends Component {
	constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.fetchNewConnectionCount()
        this.props.fetchActiveConnectionCount(this.props.activeClientSearch)
        this.props.fetchNewConnections(this.props.invitationsDisplayPage, this.props.invitationsPageSize)
        this.props.fetchActiveConnections(this.props.connectionsDisplayPage, this.props.connectionsPageSize, this.props.activeClientSearch)
    }
    
    componentDidUpdate() {
        this.props.clientConnections.forEach(connection => {
            if (!this.props.prospects.find(e => connection?.borrowerId === e?.mainBorrower?._id )) {
                this.props.addProspect(connection.borrowerId)
            }
        });
    }


    render () {
        //console.log(this.props)
        return ( 
        <>
        <ClientDetailsModal />
        <div class="container-fluid">
            {this.props.error ? <ErrorModal {...this.props} onClose={this.props.clearErrors} /> : null }
            <div class="card shadow">
                <div class="card-header">
                    <div class="row">
                        <div class="col">
                            <h6 class="text-primary fw-bold m-0">Clients</h6>
                        </div>
                        <div class="col d-md-flex d-lg-flex flex-grow-1 justify-content-md-end justify-content-lg-end">
                        </div>
                        <div class="col d-md-flex flex-grow-0 align-items-start justify-content-md-end">
                            <CloseButton />
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-2">
                            <div class="text-center d-flex flex-column justify-content-center align-items-center py-3">
                                <ActiveConnectionsButton {...this.props} />
                                <div class="px-3">
                                    <h2 class="fw-bold mb-0">{this.props.activeConnectionCount}</h2>
                                    <p class="mb-0">Active</p>
                                </div>
                            </div>
                            <div class="text-center d-flex flex-column justify-content-center align-items-center py-3">
                                <NewConnectionsButton {...this.props}  />
                                <div class="px-3">
                                    <h2 class="fw-bold mb-0">{this.props.invitationsCount}</h2>
                                    <p class="mb-0">Invited</p>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                        {this.props.activeConnectionCount == 0 && this.props.invitationsCount == 0 ?  <NoConnectionsPanel /> : 
                            this.props.activeConnectionCount == 0 && this.props.showActiveConnections  ?  <NoClientsPanel /> :
                                this.props.showActiveConnections ?  <ActiveClientsPanel  /> :
                                    this.props.invitationsCount == 0 ? <NoClientsPanel /> : <InvitedClientsPanel {...this.props} /> 
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
     )}
}


const mapStateToProps = ({ clients }) => {
  return { 
      ...clients
    };
};

const mapDispatchToProps = (dispatch)=> {
    return {
        fetchNewConnectionCount: () => dispatch(fetchNewConnectionCount()),
        fetchActiveConnectionCount: (borrowerLookupKey) => dispatch(fetchActiveConnectionCount(borrowerLookupKey)),
        switchBookView: (toggle) => dispatch(switchBookView(toggle)),
        fetchNewConnections: (page, pagesize) => dispatch(fetchNewConnections(page, pagesize)),
        fetchActiveConnections: (page, pagesize, borrowerLookupKey) => dispatch(fetchActiveConnections(page, pagesize, borrowerLookupKey)),
        clearErrors: () => dispatch(clearErrors()),
        addProspect: (borrowerId) => dispatch(addProspect(borrowerId)),
    };
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(ClientsBook);