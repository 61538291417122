import React from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, } from "react-redux";
import { saveAgencyDetails } from '../../../../store/brokerAgent/actions';
import { checkAddress } from '../../../../store/addressLookup/actions';
import { checkCompanyABNAndName } from '../../../../store/companyNameLookup/actions';

function SaveAgencyButton(props) {
    const dispatch = useDispatch()
    return ( 
        <div class="d-flex justify-content-center align-content-top">
            <Button 
            type="submit"
            style={{visibility:"visible"}}
            onClick={(e) => {
                e.preventDefault();
                dispatch(checkAddress(props.agency))
                dispatch(checkCompanyABNAndName(props.agency))
                dispatch(saveAgencyDetails(props))
                
            }}
            >Save</Button>
        </div>
     );
}

export default SaveAgencyButton;