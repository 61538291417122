import { combineReducers } from 'redux';

import authReducer from './auth/reducer';
import billingListReducer from './billingList/reducer';
import salesReducer from './sales/reducer';
import editDocumentsReducer from './sales/editDocuments/reducer';
import editLenderReducer from './sales/editLender/reducer';
import proposalSummaryReducer from './sales/proposalSummary/reducer';
import updateBillingReducer from './updateBilling/reducer';
import activitiesReducer from './dashboardActivities/reducer';
import proposalReducer from './dashboardProposals/reducer';
import messagesReducer from './dashboardMessages/reducer';
import recentProspectsReducer from './dashboardProspects/reducer';
import lenderProfilesReducer from './dashboardLenders/reducer';
import outstandingBalanceReducer from './dashboardOutstandingBalance/reducer';
import brokerAgentProfileReducer from './brokerAgent/reducer';
import autocompleteReducer from './addressLookup/reducer';
import companyNameAutocompleteReducer from './companyNameLookup/reducer';
import upgradeReducer from './upgrades/reducer';
import billingReducer from "./billing/reducer";
import clientsReducer from './clients/reducer';
import productsReducer from './loanProducts/reducer';
import chatsReducer from './chats/reducer';
import billing from './billing/reducer';

const globalReducer = combineReducers({
  auth: authReducer,
  sales: salesReducer,
  editDocuments: editDocumentsReducer,
  editLender: editLenderReducer,
  proposalSummary: proposalSummaryReducer,
  billingList: billingListReducer,
  updateBilling: updateBillingReducer,
  dashboardProposal: proposalReducer,
  dashboardActivities: activitiesReducer,
  dashboardMessages: messagesReducer,
  dashboardProspects: recentProspectsReducer,
  dashboardLenders: lenderProfilesReducer,
  dashboardOutstandingBalance: outstandingBalanceReducer,
  brokerAgentProfile: brokerAgentProfileReducer,
  companyAddressAutocomplete: autocompleteReducer,
  companyNameAutocomplete: companyNameAutocompleteReducer,
  upgradePage: upgradeReducer,
  billingReducer: billingReducer,
  clients: clientsReducer,
  loanProducts: productsReducer,
  chats:chatsReducer,
  billing: billing,
});

export default globalReducer;
