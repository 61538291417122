export const COMPANY_NAME_ACTION_TYPES = {
    FETCH_LOADING: 'companyNameFetchLoading',
    FETCH_SUCCESS: 'companyNameFetchSuccess',
    FETCH_FAILURE: 'companyNameFetchFailure',
    SAVE_SUCCESS: 'selectedCompanySaveSuccess',
    VALIDATION_ERROR: 'validationError'
}

export default function getActionType(success) {
    const type = success? COMPANY_NAME_ACTION_TYPES.FETCH_SUCCESS : COMPANY_NAME_ACTION_TYPES.FETCH_FAILURE
    return type
}

export const fetchLoading = () => {
    return {
        type: COMPANY_NAME_ACTION_TYPES.FETCH_LOADING
    }
}

export const fetchSuccess = fetchResults => {
    return {
        type: COMPANY_NAME_ACTION_TYPES.FETCH_SUCCESS,
        payload: fetchResults 
    }
}

export const fetchFailure = error => {
    return {
        type: COMPANY_NAME_ACTION_TYPES.FETCH_FAILURE,
        payload: error
    }
}

export const validationError = error => {
    return {
        type: COMPANY_NAME_ACTION_TYPES.VALIDATION_ERROR,
        payload: error
    }
}

export const saveSuccess = selectedCompany => {
    return {
        type: COMPANY_NAME_ACTION_TYPES.SAVE_SUCCESS,
        payload: selectedCompany
    }
}