import React, {Component} from 'react';
import {connect} from 'react-redux';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { countDisplay, buttonClass, fetchRefinanceCount, fetchProspects, toggleProspectDisplay, searchTypeFromFilter } from "../../../store/sales/actions";

const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);

const _filter = 2

class RefinanceButton extends Component {
    constructor(props) {
        super(props)
    }

	componentDidMount() {
		this.props.fetchCount()
	}

    render() {
        return (
        <>
        <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Refinance'})} >
        <div class="text-center d-flex flex-column justify-content-center align-items-center py-3">
            <div class={ this.props.buttonClass(this.props.prospectsFilter === _filter) } 
                onClick={() => {
                        this.props.fetchCount()
                        this.props.fetchProspects(1, this.props.prospectsPageSize,searchTypeFromFilter[_filter])
                        this.props.toggleProspectDisplay(_filter)
                    }}>

                <svg class="bi bi-arrow-repeat" xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"></path>
                    <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"></path>
                </svg></div>
                <div class="px-3">
                <h4 class="fw-bold mb-0">{this.props.countDisplay(this.props.refinanceCount)}</h4>
                </div>
        </div>
        </OverlayTrigger>
        </>
        )
    }
}


const mapStateToProps = state => {
    return {
        ...state.sales,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        countDisplay: (count) => countDisplay(count),
        buttonClass: (dark) => buttonClass(dark),
        fetchCount: () => dispatch(fetchRefinanceCount()),
        fetchProspects: (page, pagesize, searchType) => dispatch(fetchProspects(page, pagesize, searchType)),
        toggleProspectDisplay: (filter) => dispatch(toggleProspectDisplay(filter)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RefinanceButton);