import React from 'react';


function CaseProgress() {
    return ( 
        <div class="card text-nowrap shadow d-flex flex-row flex-nowrap border-start-success py-2 overflow-scroll">
            <div class="card-body">
                <h5>Case progress</h5>
                <div class="vstack">
                    <div class="hstack gap-2 justify-content-evenly">
                        <div class="flex-grow-1 align-self-center indigoBox">
                            <div class="hstack flex-grow-1 justify-content-evenly">
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg secondary"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg secondary"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg secondary"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg secondary"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg secondary"><span class="font10px">12/02</span></div>
                            </div>
                        </div>
                        <div class="flex-grow-1 align-self-center indigoBox">
                            <div class="hstack flex-grow-1 justify-content-evenly">
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg secondary"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg secondary"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg secondary"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg secondary"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg secondary"><span class="font10px">12/02</span></div>
                            </div>
                        </div>
                        <div class="flex-grow-1 align-self-center indigoBox">
                            <div class="hstack flex-grow-1 justify-content-evenly">
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg secondary"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg secondary"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg whiteBlueUnderline"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                            </div>
                        </div>
                        <div class="flex-grow-1 align-self-center indigoBox">
                            <div class="hstack flex-grow-1 justify-content-evenly">
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                            </div>
                        </div>
                        <div class="flex-grow-1 align-self-center indigoBox">
                            <div class="hstack flex-grow-1 justify-content-evenly">
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                            </div>
                        </div>
                        <div class="flex-grow-1 align-self-center indigoBox">
                            <div class="hstack flex-grow-1 justify-content-evenly">
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg"><span class="font10px">12/02</span></div>
                                <div class="bs-icon-sm bs-icon-circle bs-icon-primary d-flex flex-shrink-0 justify-content-center align-items-center d-inline-block mb-2 bs-icon lg greenBackground"><span class="font10px">12/02</span></div>
                            </div>
                        </div>
                    </div>
                    <div class="vstack gap-1">
                        <div class="flex-grow-1">
                            <div class="hstack">
                                <div></div>
                                <div class="vstack gap-1 width5percent">
                                    <div class="progress width5percent darkgrayBackground">
                                        <div class="progress-bar bg-secondary progress-bar-striped width50percent" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"><span class="visually-hidden">50%</span></div>
                                    </div><i class="far fa-handshake" data-bs-toggle="modal" data-bss-tooltip="" data-bs-target="#modal-taskchecklist" title="Onboarding"></i>
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <div class="hstack gap-0">
                                <div class="width5percent"></div>
                                <div class="vstack gap-1">
                                    <div class="progress width15percent darkgrayBackground">
                                        <div class="progress-bar bg-warning progress-bar-striped width50percent" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"><span class="visually-hidden">50%</span></div>
                                    </div><i class="fas fa-glasses" data-bs-toggle="modal" data-bss-tooltip="" data-bs-target="#modal-taskchecklist" title="Scrutiny"></i>
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <div class="hstack gap-0">
                                <div class="width5percent"></div>
                                <div class="vstack gap-1">
                                    <div class="progress width45percent darkgrayBackground">
                                        <div class="progress-bar bg-success progress-bar-striped width100percent" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"><span class="visually-hidden">100%</span></div>
                                    </div><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" class="bi bi-cloud-upload" data-bs-toggle="modal" data-bss-tooltip="" data-bs-target="#modal-taskchecklist" title="Lodgement">
                                        <path fill-rule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"></path>
                                        <path fill-rule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"></path>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1 width45percent">
                            <div class="vstack gap-1">
                                <div class="progress">
                                    <div class="progress-bar bg-primary progress-bar-striped width25percent" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"><span class="visually-hidden">25%</span></div>
                                </div><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" class="bi bi-bank2" data-bs-toggle="modal" data-bss-tooltip="" data-bs-target="#modal-taskchecklist" title="Submission">
                                    <path d="M8.277.084a.5.5 0 0 0-.554 0l-7.5 5A.5.5 0 0 0 .5 6h1.875v7H1.5a.5.5 0 0 0 0 1h13a.5.5 0 1 0 0-1h-.875V6H15.5a.5.5 0 0 0 .277-.916l-7.5-5zM12.375 6v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zm-2.5 0v7h-1.25V6h1.25zM8 4a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM.5 15a.5.5 0 0 0 0 1h15a.5.5 0 1 0 0-1H.5z"></path>
                                </svg>
                            </div>
                        </div>
                        <div class="flex-grow-1 width15percent">
                            <div class="vstack gap-1">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped width0percent" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"><span class="visually-hidden">0%</span></div>
                                </div><i class="fas fa-user-check" data-bs-toggle="modal" data-bss-tooltip="" data-bs-target="#modal-taskchecklist" title="Conveyance"></i>
                            </div>
                        </div>
                        <div class="flex-grow-1">
                            <div class="vstack gap-1">
                                <div class="progress">
                                    <div class="progress-bar progress-bar-striped width0percent" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"><span class="visually-hidden">0%</span></div>
                                </div><i class="fas fa-hand-holding-usd" data-bs-toggle="modal" data-bss-tooltip="" data-bs-target="#modal-taskchecklist" title="Discharge"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     );
}

export default CaseProgress;