import React, { Component } from 'react';
import { connect } from "react-redux";
import Pagination from 'react-bootstrap/Pagination';
import { fetchPaidInvoicesMetadata, fetchPaidInvoices } from "../../../store/billing/actions";

function loadPage(props) {
    props.fetchPaidInvoicesMetadata();
    props.fetchPaidInvoices(props.page, props.pageSize);
}

class InvoicesPagination extends Component {
	constructor(props) {
        super(props);
    }

    componentDidMount() {
        loadPage({...this.props, page:1,pageSize: this.props.recordsPerPage})
    }

    render () {
        var hooks = { ...this.props };
        const startIdx = this.props.totalNumRecords == 0 ? 0 : (this.props.displayPage-1)*this.props.recordsPerPage + 1;
        const endIdx = startIdx == 0 && this.props.totalNumRecords == 0 ? 0 : startIdx - 1 + (this.props.records == null ? 0 :  this.props.records.length);
        const showing = this.props.displayPage;
        const maxPages = this.props.recordsPerPage > this.props.totalNumRecords ? 1 : Math.ceil(this.props.totalNumRecords/this.props.recordsPerPage);
        var links = [];
        for (var i = this.props.displayPage - 1;  i <= maxPages && links.length < 3;i++)
                if (i > 0)
                    links.push(i)
        return (
            <div class="hstack gap-4 d-lg-flex align-items-lg-start">
                <span>Showing records <strong>{startIdx}</strong> to <strong>{endIdx}</strong> of <strong>{this.props.totalNumRecords}</strong></span>
                <Pagination>
                    <Pagination.First  
                        onClick={() => {
                            loadPage({...hooks,page:1})
                        }} ></Pagination.First >
                        
                {
                    links.map(function(page){
                            return (<Pagination.Item active={showing === page}
                                        onClick={() => {
                                            loadPage({...hooks,page:page})}} >{page}</Pagination.Item>
                            );
                        })
                }
                    <Pagination.Last 
                        onClick={() => {
                            loadPage({...hooks,page:maxPages})
                        }} ></Pagination.Last>

                </Pagination>
            </div>
      );
  }
}

const mapStateToProps = ({ billing }) => {
  return { 
        recordsPerPage: billing.pageSize,
        totalNumRecords: billing.numInvoices,
        displayPage: billing.displayPage,
        records: billing.receipts,
    };
};

const mapDispatchToProps = (dispatch)=> {
    return {
        fetchPaidInvoicesMetadata: () => dispatch(fetchPaidInvoicesMetadata()),
        fetchPaidInvoices: (page, pageSize) => dispatch(fetchPaidInvoices(page, pageSize)),
    };
};

export default connect(
    mapStateToProps, 
    mapDispatchToProps
)(InvoicesPagination);