import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showLandingPage } from '../../../store/clients/actions';
import ClientInviteForm from './clientInviteForm';
import ClientInviteResultModal from './clientInviteResultModal';



function Invite() {
    const dispatch = useDispatch()
    const showResultModal = useSelector(state => state.clients?.showResultModal)    
    return ( 
        <div class="container-fluid">
            <div class="card shadow">
                <div class="card-body">
                    <div class="d-flex justify-content-end">
                        <button class="btn btn-primary" type="button" onClick={ () => dispatch(showLandingPage())}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="currentColor" viewBox="0 0 16 16" class="bi bi-x-circle-fill">
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z"></path>
                            </svg>
                        </button>
                    </div>                    
                    {showResultModal ? 
                        <ClientInviteResultModal /> :  <ClientInviteForm />
                    }
                </div>
            </div>
        </div>
     );
}

export default Invite;