import { Col, ProgressBar } from "react-bootstrap";

function getProgressBarVariant(lenderProfile, upperThreshold, lowerThreshold) {
    if (lenderProfile.lendingPercentage >= upperThreshold) {
        return null
    }
    if (lenderProfile.lendingPercentage < lowerThreshold)  {
        return "danger"
    }
    return "warning"
}

function getProgressBarValue(lenderProfile) {
   return lenderProfile.lendingPercentage * 100 
}

function getDisplayableAmount(amount) {
    const displayableAmount = "$" + amount.toLocaleString(undefined, {maximumFractionDigits:0})
    return displayableAmount
}

export default function iterativeRender(lenderProfilesList, numElements=3) {
    let counter = 0
    if(lenderProfilesList.length > 0 && counter < 3){
        return lenderProfilesList.map(function(each){
            if(counter >= numElements) {
                return null
            }
            counter ++
            return (
                <>
                <Col>
                    <h4 className="small fw-bold">
                        {each.name}
                        <span className="float-end">{getDisplayableAmount(each.totalLendingAmount.value) + "/-"}</span>
                    </h4>
                    <ProgressBar
                        className="mb-4"
                        now={getProgressBarValue(each)}
                        variant={getProgressBarVariant(each, 0.55, 0.3)}
                    />
                </Col>
                </>
            )
        })
      } 
}
