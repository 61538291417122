import React, {Component} from 'react';
import {connect} from 'react-redux';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import HiddenText from '../../../common/HiddenText'
import CloseButton from '../CloseButton'
import * as constants from "../../../../store/sales/constants"
import { showLanding } from "../../../../store/sales/actions";
import { toggleDocumentRequired, toggleAllDocs } from "../../../../store/sales/editDocuments/actions";

const renderTooltip = props => (
    <Tooltip>{props?.locationtext}</Tooltip>
);

class DocumentSelection extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        const primaryApplicantName = this.props.prospectInView.mainBorrower.title + ' ' + this.props.prospectInView.mainBorrower.firstName + ' ' + this.props.prospectInView.mainBorrower.lastName
        const jointApplicantName = !this.props.prospectInView.borrowerProfile.hasCoborrower ? null : 
            this.props.prospectInView.loanRequest.borrowers.jointApplicant.title + ' ' + 
            this.props.prospectInView.loanRequest.borrowers.jointApplicant.firstName + ' ' + 
            this.props.prospectInView.loanRequest.borrowers.jointApplicant.lastName
        const checkedDriverLicence = this.props.requiredDocuments.find(d => d.name == constants.DRIVER_LICENCE) !== undefined
        const checkedPassport = this.props.requiredDocuments.find(d => d.name == constants.PASSPORT) !== undefined
        const checkedMedicare = this.props.requiredDocuments.find(d => d.name == constants.MEDICARE) !== undefined
        const checkedBirthCertificate = this.props.requiredDocuments.find(d => d.name == constants.BIRTH_CERTIFICATE) !== undefined
        const checkedBankAcStmt = this.props.requiredDocuments.find(d => d.name == constants.BANK_ACCOUNT_STMT) !== undefined
        const checkedCredCardStmt = this.props.requiredDocuments.find(d => d.name == constants.CREDIT_CARD_STMT) !== undefined
        const checkedHomeLoanStmt = this.props.requiredDocuments.find(d => d.name == constants.HOME_LOAN_STMT) !== undefined
        const checkedPersonalLoanStmt = this.props.requiredDocuments.find(d => d.name == constants.PERSONAL_LOAN_STMT) !== undefined
        const checkedPayslips = this.props.requiredDocuments.find(d => d.name == constants.PAYSLIPS) !== undefined
        const checkedNoa = this.props.requiredDocuments.find(d => d.name == constants.NOA)  !== undefined
        const checkedPersonalTaxReturns =  this.props.requiredDocuments.find(d => d.name == constants.PERSONAL_TAX_RETURNS) !== undefined
        const checkedBas = this.props.requiredDocuments.find(d => d.name == constants.BAS) !== undefined
        const checkedRentalIncStmt = this.props.requiredDocuments.find(d => d.name == constants.RENTAL_INCOME_STMT) !== undefined
        const checkedSharesAccStmt = this.props.requiredDocuments.find(d => d.name == constants.SHARES_ACC_STMT) !== undefined
        const checkedCoTaxReturns = this.props.requiredDocuments.find(d => d.name == constants.COMPANY_TAX_RETURNS)  !== undefined
        const checkedPersonalCarLoanAccStmt = this.props.requiredDocuments.find(d => d.name == constants.PERSONAL_CAR_LOAN_ACC_STMT) !== undefined 
        const checkedCouncilRates = this.props.requiredDocuments.find(d => d.name == constants.COUNCIL_RATES_NOTICES)  !== undefined
        const checkedTrustDeed =  this.props.requiredDocuments.find(d => d.name == constants.TRUST_DEED) !== undefined
        const checkedRentalLease = this.props.requiredDocuments.find(d => d.name == constants.RENTAL_LEASE_AGREEMENT) !== undefined 
        const unmasked = this.props.prospectInView?.clientConnectionId ? true : false
        return (
        <>
        <div id="select-documents-to-request" class="multisteps-form__panel shadow p-4 rounded bg-white" data-animation="scaleIn">
            <div id="request-documents-form-content" class="multisteps-form__content">
            <div class="vstack gap-2">
                <div class="vstack gap-1 d-xxl-flex flex-row flex-grow-1 flex-fill">
                    <div class="hstack justify-content-between gap-1 d-xxl-flex flex-row flex-grow-1 flex-fill">
                        <h2 class="fw-bold d-xxl-flex mb-2">Borrowers submission documents</h2>
                        <div class="hstack justify-content-between">
                            <div id="documents-next-prev-buttons" class="button-row d-flex">
                            <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Previous'})} >
                                <button class="btn btn btn-primary js-btn-prev" type="button" >Prev</button></OverlayTrigger>
                            <OverlayTrigger placement="bottom" overlay={renderTooltip({locationtext:'Next'})} >
                                <button class="btn btn btn-primary ml-auto js-btn-next" type="button" >Next</button></OverlayTrigger>
                            </div>
                            <CloseButton />
                        </div>
                    </div>
                </div>                
                <span>Select documents required for loan processing</span>
                <div id="documents-accordion" class="accordion" role="tablist">
                    <div class="accordion-item">
                        <h2 class="accordion-header" role="tab"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#documents-accordion .document-item-1" aria-expanded="false" aria-controls="documents-accordion .document-item-1">Identity documents</button></h2>
                        <div class="accordion-collapse collapse document-item-1" role="tabpanel" data-bs-parent="#documents-accordion">
                            <div class="accordion-body">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div class="hstack gap-2">
                                                        <div class="form-check">
                                                            <input id="select-all-id-docs" name="radio-id-docs" class="form-check-input" type="radio"
                                                                checked={this.props.selectIdDocs} onChange={() => {this.props.toggleAllDocs(constants.ID_DOCS, !this.props.selectIdDocs)}} /><label class="form-check-label" for="select-all-id-docs">Select all</label></div>
                                                        <div class="form-check">
                                                            <input id="deselect-all-id-docs" name="radio-id-docs" class="form-check-input" type="radio"
                                                                checked={this.props.deselectIdDocs} onChange={() => {this.props.toggleAllDocs(constants.ID_DOCS, this.props.deselectIdDocs)}} /><label class="form-check-label" for="deselect-all-id-docs">Clear all</label></div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="hstack gap-2"><HiddenText unmasked={unmasked}><span>{primaryApplicantName}</span></HiddenText>
                                                    {this.props.prospectInView.borrowerProfile.hasCoborrower === true ?
                                                    <div class="hstack gap-2"><span>and</span><HiddenText unmasked={unmasked}><span>{jointApplicantName}</span></HiddenText></div>
                                                    : null }
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.DRIVER_LICENCE)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedDriverLicence ? "checked" : ""} 
                                                    onChange={() => {
                                                        this.props.toggleDocumentRequired(
                                                            this.props.requiredDocumentTypes.find(d => d.name == constants.DRIVER_LICENCE),
                                                            !checkedDriverLicence)}} /></td>
                                            </tr>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.PASSPORT)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedPassport  ? "checked" : ""} 
                                                    onChange={() => {this.props.toggleDocumentRequired(
                                                        this.props.requiredDocumentTypes.find(d => d.name == constants.PASSPORT),
                                                        !checkedPassport)}} /></td>
                                            </tr>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.MEDICARE)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedMedicare  ? "checked" : ""} 
                                                    onChange={() => {this.props.toggleDocumentRequired(
                                                        this.props.requiredDocumentTypes.find(d => d.name == constants.MEDICARE), 
                                                        !checkedMedicare)}} /></td>
                                            </tr>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.BIRTH_CERTIFICATE)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedBirthCertificate  ? "checked" : ""} 
                                                    onChange={() => {this.props.toggleDocumentRequired(
                                                        this.props.requiredDocumentTypes.find(d => d.name == constants.BIRTH_CERTIFICATE),
                                                        !checkedBirthCertificate)}} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" role="tab"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#documents-accordion .document-item-2" aria-expanded="false" aria-controls="documents-accordion .document-item-2">Bank statements</button></h2>
                        <div class="accordion-collapse collapse document-item-2" role="tabpanel" data-bs-parent="#documents-accordion">
                            <div class="accordion-body">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div class="hstack gap-2">
                                                        <div class="form-check">
                                                            <input id="select-all-bank-statements" name="radio-id-bank" class="form-check-input" type="radio" 
                                                                checked={this.props.selectBankStmts} onChange={() => {this.props.toggleAllDocs(constants.BANK_DOCS, !this.props.selectBankStmts, this.props.prospectInView.borrowerProfile.hasCoborrower)}}  /><label class="form-check-label" for="select-all-bank-statements">Select all</label></div>
                                                        <div class="form-check">
                                                            <input id="deselect-all-bank-statements" name="radio-id-bank" class="form-check-input" type="radio" 
                                                                checked={this.props.deselectBankStmts} onChange={() => {this.props.toggleAllDocs(constants.BANK_DOCS, this.props.deselectBankStmts, this.props.prospectInView.borrowerProfile.hasCoborrower)}}  /><label class="form-check-label" for="deselect-all-bank-statements">Clear all</label></div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="hstack gap-2"><HiddenText unmasked={unmasked}><span>{primaryApplicantName}</span></HiddenText>
                                                    {this.props.prospectInView.borrowerProfile.hasCoborrower === true ?
                                                    <div class="hstack gap-2"><span>and</span><HiddenText unmasked={unmasked}><span>{jointApplicantName}</span></HiddenText></div>
                                                    : null }
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.BANK_ACCOUNT_STMT)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedBankAcStmt ? "checked" : ""} 
                                                    onChange={() => {this.props.toggleDocumentRequired(
                                                        this.props.requiredDocumentTypes.find(d => d.name == constants.BANK_ACCOUNT_STMT),
                                                        !checkedBankAcStmt)}} /></td>
                                            </tr>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.CREDIT_CARD_STMT)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedCredCardStmt  ? "checked" : ""} 
                                                    onChange={() => {this.props.toggleDocumentRequired(
                                                        this.props.requiredDocumentTypes.find(d => d.name == constants.CREDIT_CARD_STMT),
                                                        !checkedCredCardStmt)}} /></td>
                                            </tr>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.HOME_LOAN_STMT)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedHomeLoanStmt  ? "checked" : ""} 
                                                    onChange={() => {this.props.toggleDocumentRequired(
                                                        this.props.requiredDocumentTypes.find(d => d.name == constants.HOME_LOAN_STMT),
                                                        !checkedHomeLoanStmt)}} /></td>
                                            </tr>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.PERSONAL_LOAN_STMT)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedPersonalLoanStmt  ? "checked" : ""} 
                                                    onChange={() => {this.props.toggleDocumentRequired(
                                                        this.props.requiredDocumentTypes.find(d => d.name == constants.PERSONAL_LOAN_STMT),
                                                        !checkedPersonalLoanStmt)}} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" role="tab"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#documents-accordion .document-item-3" aria-expanded="false" aria-controls="documents-accordion .document-item-3">Income and liability statements</button></h2>
                        <div class="accordion-collapse collapse document-item-3" role="tabpanel" data-bs-parent="#documents-accordion">
                            <div class="accordion-body">

                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <div class="hstack gap-2">
                                                        <div class="form-check">
                                                            <input id="select-all-income-docs" name="radio-id-income-docs" class="form-check-input" type="radio" 
                                                                checked={this.props.selectIncomeDocs} onChange={() => {this.props.toggleAllDocs(constants.INC_PROOF, !this.props.selectIncomeDocs)}}  /><label class="form-check-label" style={{fontWeight: "bold"}} for="select-all-income-docs">Select all</label></div>
                                                        <div class="form-check">
                                                            <input id="deselect-all-income-docs" name="radio-id-income-docs" class="form-check-input" type="radio" 
                                                                checked={this.props.deselectIncomeDocs} onChange={() => {this.props.toggleAllDocs(constants.INC_PROOF, this.props.deselectIncomeDocs)}}  /><label class="form-check-label" style={{fontWeight: "bold"}} for="deselect-all-income-docs">Clear all</label></div>
                                                    </div>
                                                </th>
                                                <th>
                                                    <div class="hstack gap-2"><HiddenText unmasked={unmasked}><span>{primaryApplicantName}</span></HiddenText>
                                                    {this.props.prospectInView.borrowerProfile.hasCoborrower === true ?
                                                    <div class="hstack gap-2"><span>and</span><HiddenText unmasked={unmasked}><span>{jointApplicantName}</span></HiddenText></div>
                                                    : null }
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.PAYSLIPS)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedPayslips  ? "checked" : ""} 
                                                    onChange={() => {this.props.toggleDocumentRequired(
                                                        this.props.requiredDocumentTypes.find(d => d.name == constants.PAYSLIPS),
                                                        !checkedPayslips)}} /></td>
                                            </tr>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.NOA)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedNoa ? "checked" : ""} 
                                                    onChange={() => {this.props.toggleDocumentRequired(
                                                        this.props.requiredDocumentTypes.find(d => d.name == constants.NOA),
                                                        !checkedNoa)}} /></td>
                                            </tr>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.PERSONAL_TAX_RETURNS)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedPersonalTaxReturns ? "checked" : ""} 
                                                    onChange={() => {this.props.toggleDocumentRequired(
                                                        this.props.requiredDocumentTypes.find(d => d.name == constants.PERSONAL_TAX_RETURNS),
                                                        !checkedPersonalTaxReturns)}} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" role="tab"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#documents-accordion .document-item-4" aria-expanded="false" aria-controls="documents-accordion .document-item-4">Other relevant documents</button></h2>
                        <div class="accordion-collapse collapse document-item-4" role="tabpanel" data-bs-parent="#documents-accordion">
                            <div class="accordion-body">
                                <div class="hstack gap-2">
                                    <div class="form-check">
                                        <input id="select-all-other-docs" name="radio-id-other-docs" class="form-check-input" type="radio"
                                            checked={this.props.selectOtherDocs} onChange={() => {this.props.toggleAllDocs(constants.OTHER_DOCS, !this.props.selectOtherDocs)}} /><label class="form-check-label" for="select-all-other-docs">Select all</label></div>
                                    <div class="form-check">
                                        <input id="deselect-all-other-docs" name="radio-id-other-docs" class="form-check-input" type="radio"
                                            checked={this.props.deselectOtherDocs} onChange={() => {this.props.toggleAllDocs(constants.OTHER_DOCS, this.props.deselectOtherDocs)}} /><label class="form-check-label" for="deselect-all-other-docs">Clear all</label></div>
                                </div>

                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <span>Other income and liability documents</span>
                                                </th>
                                                <th>
                                                    <span class="fw-light">Individual applicant or joint sources of income or liability</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.BAS)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedBas ? "checked" : ""} 
                                                    onChange={() => {this.props.toggleDocumentRequired(
                                                        this.props.requiredDocumentTypes.find(d => d.name == constants.BAS),
                                                        !checkedBas)}} /></td>
                                            </tr>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.RENTAL_INCOME_STMT)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedRentalIncStmt  ? "checked" : ""} 
                                                    onChange={() => {this.props.toggleDocumentRequired(
                                                        this.props.requiredDocumentTypes.find(d => d.name == constants.RENTAL_INCOME_STMT),
                                                        !checkedRentalIncStmt)}} /></td>
                                            </tr>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.SHARES_ACC_STMT)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedSharesAccStmt  ? "checked" : ""} 
                                                    onChange={() => {this.props.toggleDocumentRequired(
                                                        this.props.requiredDocumentTypes.find(d => d.name == constants.SHARES_ACC_STMT),
                                                        !checkedSharesAccStmt)}} /></td>
                                            </tr>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.COMPANY_TAX_RETURNS)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedCoTaxReturns ? "checked" : ""} 
                                                    onChange={() => {this.props.toggleDocumentRequired(
                                                        this.props.requiredDocumentTypes.find(d => d.name == constants.COMPANY_TAX_RETURNS),
                                                        !checkedCoTaxReturns)}} /></td>
                                            </tr>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.PERSONAL_CAR_LOAN_ACC_STMT)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedPersonalCarLoanAccStmt  ? "checked" : ""} 
                                                    onChange={() => {this.props.toggleDocumentRequired(
                                                        this.props.requiredDocumentTypes.find(d => d.name == constants.PERSONAL_CAR_LOAN_ACC_STMT),
                                                        !checkedPersonalCarLoanAccStmt)}} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <span>Other relevant documents</span>
                                                </th>
                                                <th>
                                                    <span class="fw-light">Documents relevant in context of mortgage application processing</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.COUNCIL_RATES_NOTICES)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedCouncilRates ? "checked" : ""} 
                                                    onChange={() => {this.props.toggleDocumentRequired(
                                                        this.props.requiredDocumentTypes.find(d => d.name == constants.COUNCIL_RATES_NOTICES),
                                                        !checkedCouncilRates)}} /></td>
                                            </tr>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.TRUST_DEED)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedTrustDeed  ? "checked" : ""} 
                                                    onChange={() => {this.props.toggleDocumentRequired(
                                                        this.props.requiredDocumentTypes.find(d => d.name == constants.TRUST_DEED),
                                                        !checkedTrustDeed)}} /></td>
                                            </tr>
                                            <tr>
                                                <td>{this.props.requiredDocumentTypes.find(d => d.name == constants.RENTAL_LEASE_AGREEMENT)?.description}</td>
                                                <td><input type="checkbox" 
                                                    checked={checkedRentalLease  ? "checked" : ""} 
                                                    onChange={() => {this.props.toggleDocumentRequired(
                                                        this.props.requiredDocumentTypes.find(d => d.name == constants.RENTAL_LEASE_AGREEMENT),
                                                        !checkedRentalLease)}} /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            </div>
        </div>
        </>
        )
    }
}

const mapStateToProps = state => {
    return {
        ...state.editDocuments,
        prospectInView : state.clients.prospectInView,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        showLanding: () => dispatch(showLanding()),
        toggleDocumentRequired: (document, toggle) => dispatch(toggleDocumentRequired(document, toggle)),
        toggleAllDocs: (type, toggle) => dispatch(toggleAllDocs(type, toggle)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DocumentSelection);