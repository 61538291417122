import {  } from "react-bootstrap";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Moment from 'moment';
import { storeCardReference } from "../../../../store/upgrades/actions";

import CurrencyFormat from "react-currency-format";
import ErrorContainer from "../../../common/ErrorContainer";
import Spinner from 'react-bootstrap/Spinner';

const PaymentCardDetails = (props) => {
    const response = useSelector(state => state.upgradePage?.createPaymentReferenceResponse)
    //console.log(response)
    const paymentReference = useSelector(state => state.upgradePage?.paymentReference)
    const submitUrl = response && response.success ? 
        response.dataSet.find((element) => element?.key == 'formSubmitUrl').value : 
        null
    const accessCode = response && response.success ? 
        response.dataSet.find((element) => element?.key == 'accessCode').value : 
        null
    const basicSubscription = 
        paymentReference?.subscriptions ? 
            paymentReference.subscriptions.find(subscription => subscription.name === 'Basic') : 
            null
    const [recurringChecked, setRecurringChecked] = React.useState(false)
    const [updatesChecked, setUpdatesChecked] = React.useState(false)
    const [termsAndConditionsChecked, setTermsAndConditionsChecked] = React.useState(false)
    const [formErrors, setFormErrors] = React.useState([])
    const [cardName, setCardName] = React.useState()
    const [cardNumber, setCardNumber] = React.useState()
    const [cardMonth, setCardMonth] = React.useState()
    const [cardYear, setCardYear] = React.useState()
    const [cardCVN, setCardCVN] = React.useState()
    const [wait,setWait] = React.useState(false)
    const nav = useNavigate();
    const dispatch = useDispatch();

    const validateCardExpiry = (cardMonth, cardYear) => {
        return cardMonth !== null && cardYear !== null &&
            Moment('01/' + cardMonth + '/' + cardYear,'DD/MM/YYYY').isAfter(Moment())
    }

  return (
    <div id="form-content-3" class="multisteps-form__content">
        <div class="hstack gap-3">
            <h5>Payment</h5>
        </div>
        <form id="payment_form" method="POST" class="multisteps-form__form" action={submitUrl} >

        <div class="row">
            <div class="col" >
            <div class="vstack">
                <div id="input-grp-single" class="form-row mt-4">
                    <div id="form-content-3" class="multisteps-form__content">
                        <div>
                            <input class="form-control" type="hidden" name="EWAY_ACCESSCODE" value={accessCode} /><input class="form-control" type="hidden" name="EWAY_PAYMENTTYPE" value="Credit Card"/>
                            <div id="input-grp-single-1" class="form-row mt-4">
                                <div class="row">
                                <div class="col-12">
                                    <ErrorContainer error={formErrors.find((element) => element?.type === 'termsAndConditionsChecked' && !element?.valid)}>
                                    <div class="hstack">
                                        <div class="form-check"><input id="formCheck-tandc" class="form-check-input" type="checkbox" 
                                                                                        checked={termsAndConditionsChecked ? "checked" : ""} onChange={() => {setTermsAndConditionsChecked(!termsAndConditionsChecked)}} /><label class="form-check-label" for="formCheck-2"></label></div>
                                        <div class="hstack gap-1"><span>I have read and accept the</span><a href="https://www.loananalysis.com.au/home/loanseller/terms-and-conditions/" target="_blank"><strong class="text-primary">Terms and Conditions</strong></a><small>and</small><a href="https://www.loananalysis.com.au/home/loanseller/privacy-policy/"><strong class="text-primary" target="_blank">Privacy Policy</strong></a></div>
                                    </div>                                    
                                    </ErrorContainer>
                                </div>
                                </div>
                                <div class="row">
                                <div class="col-12">
                                    <ErrorContainer error={formErrors.find((element) => element?.type === 'recurringChecked' && !element?.valid)}>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="formCheck-1"
                                        checked={recurringChecked ? "checked" : ""} onChange={() => {setRecurringChecked(!recurringChecked)}} />
                                        <label class="form-check-label" for="formCheck-1">These card details will be used for future subscription fees and charges</label>
                                    </div>
                                    </ErrorContainer>
                                </div>
                                </div>
                                <div class="row">
                                <div class="col-12">
                                    <ErrorContainer error={formErrors.find((element) => element?.type === 'updatesChecked' && !element?.valid)}>
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" id="formCheck-2"
                                        checked={updatesChecked ? "checked" : ""} onChange={() => setUpdatesChecked(!updatesChecked)}/>
                                        <label class="form-check-label" for="formCheck-1">Payment method can be updated at any time in the Billing section</label>
                                    </div>                                                            
                                    </ErrorContainer>
                                </div>
                                </div>
                            </div>

                            <div id="input-grp-single-3" class="form-row mt-4">
                                    <ErrorContainer error={formErrors.find((element) => element?.type === 'cardName' && !element?.valid)}>
                                <div class="col-12">
                                    <input class="form-control multisteps-form__input" type="text" placeholder="Card name" name="EWAY_CARDNAME"
                                    onChange={(e)=>{setCardName(e.target.value)}} /></div>
                                    </ErrorContainer>
                            </div>

                            <div id="input-grp-single-6" class="form-row mt-4">
                                    <ErrorContainer error={formErrors.find((element) => element?.type === 'cardNumber' && !element?.valid)}>
                                <div class="col-12">
                                    <input class="form-control multisteps-form__input" type="text" placeholder="Card number" name="EWAY_CARDNUMBER" 
                                    onChange={(e)=>{setCardNumber(e.target.value)}} onBlur={e => {
                                        var last4Digits = cardNumber.substring(cardNumber.length - 4)
                                        dispatch(storeCardReference(paymentReference._id, last4Digits))
                                    }
                                        }/></div>
                                    </ErrorContainer>
                            </div>

                            <div id="input-grp-single-10" class="form-row mt-4">
                                <div class="row">
                                    <div class="col">
                                        <div id="input-grp-single-11" class="form-row mt-4">
                                    <ErrorContainer error={formErrors.find((element) => element?.type === 'cardMonth' && !element?.valid)}>
                                            <div class="col-12">
                                                <input class="form-control multisteps-form__input" type="number" placeholder="MM" min="1" max="12" step="1"  name="EWAY_CARDEXPIRYMONTH" 
                                                onChange={(e)=>{setCardMonth(e.target.value)}}/></div>
                                    </ErrorContainer>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div id="input-grp-single-21" class="form-row mt-4">
                                    <ErrorContainer error={formErrors.find((element) => element?.type === 'cardYear' && !element?.valid)}>
                                            <div class="col-12">
                                                <input class="form-control multisteps-form__input" type="number" min={Moment().format('YYYY')} max={Moment().add(20, 'years').format('YYYY')} step="1"  placeholder="YYYY" name="EWAY_CARDEXPIRYYEAR"
                                                onChange={(e)=>{setCardYear(e.target.value)}}/></div>
                                    </ErrorContainer>
                                        </div>
                                    </div>
                                    <div class="col">
                                        <div id="input-grp-single-20" class="form-row mt-4">
                                    <ErrorContainer error={formErrors.find((element) => element?.type === 'cardCVN' && !element?.valid)}>
                                            <div class="col-12">
                                                <input class="form-control multisteps-form__input" type="number" min="100" max="999" step="1"  placeholder="CVN" name="EWAY_CARDCVN"
                                                onChange={(e)=>{setCardCVN(e.target.value)}}/></div>
                                    </ErrorContainer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>

            <div class="col d-lg-flex justify-content-between flex-wrap justify-content-lg-end align-items-lg-end" style={{"padding": "2%"}}>
                <div class="vstack">
                    <div class="vstack">
                        <div class="card" style={{"margin": "2%"}}>
                            <div class="card-header">
                                <h5 class="mb-0">Summary</h5>
                            </div>
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr></tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    {basicSubscription ? <span>{basicSubscription.type} subscription for LoanShopper Basic plan</span> : null}
                                                </td>
                                                <td><CurrencyFormat value={paymentReference?.netSalesAmount?.value} displayType={'text'} thousandSeparator={true} prefix={'$'}
                                decimalScale={2} fixedDecimalScale={true}  />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>GST</td>
                                                <td><CurrencyFormat value={paymentReference?.gstAmount?.value} displayType={'text'} thousandSeparator={true} prefix={'$'}
                                decimalScale={2} fixedDecimalScale={true}  />                                              
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>Total</th>
                                                <th><CurrencyFormat value={paymentReference?.paymentAmount?.value} displayType={'text'} thousandSeparator={true} prefix={'$'}
                                decimalScale={2} fixedDecimalScale={true}  />                                              
                                                </th>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="float-end d-lg-flex align-self-end form-row mt-4" id="input-grp-single-2">
                        <div class="hstack gap-2 d-lg-flex">
                            {wait ? 
                            <Spinner animation="grow" variant="danger" /> : null }
                            <button class="btn btn-primary" type="submit" 
                                disabled={!cardName || !cardMonth || !cardNumber || !cardYear || !cardCVN || !updatesChecked || !recurringChecked}
                                onClick={(e) => {
                                        var checks = [
                                            {type:"updatesChecked", valid:updatesChecked, errorMessage : '', },
                                            {type:"recurringChecked", valid:recurringChecked, errorMessage : '', },
                                            {type:"termsAndConditionsChecked", valid:termsAndConditionsChecked, errorMessage : '', },
                                            {type:"cardName", valid:cardName, errorMessage : '', },
                                            {type:"cardNumber", valid:/^\d{16}$/.test(cardNumber), errorMessage : '', },
                                            {type:"cardMonth", valid:validateCardExpiry(cardMonth, cardYear), errorMessage : 'Card expiry date is not valid', },
                                            {type:"cardYear", valid:validateCardExpiry(cardMonth, cardYear), errorMessage : 'Card expiry date is not valid', },
                                            {type:"cardCVN", valid:cardCVN, errorMessage : '', },
                                        ];
                                        setFormErrors(checks)

                                        if (!checks.find((element) => !element?.valid)) {
                                            setWait(true)    
                                            return true
                                        } else {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            return false
                                        }
                                    }} >Submit</button>
                            <button class="btn btn-primary" type="button"  onClick={() => nav("/") }>Cancel</button></div>
                    </div>
                </div>
            </div>

        </div>
        </form>

    </div>
);
};

export default PaymentCardDetails;
